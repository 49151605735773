import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class CurrentMHTreatmentPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.ASK_CURRENT_MH_PROFESSIONAL> {
  chatFlow = ChatFlowsEnum.ASK_CURRENT_MH_PROFESSIONAL

  /** Overrides */

  getDefaultPayload(): Partial<ReferralPayload> {
    return {
      currentSupport: this.ctx.state?.currentMHTreatment,
      currentSupportDetails: this.ctx.state?.currentMHTreatmentDetails
    } as Partial<ReferralPayload>
  }
}

// prettier-ignore
ChatFlowPayloadBuilder.builders[ChatFlowsEnum.ASK_CURRENT_MH_PROFESSIONAL] = CurrentMHTreatmentPayloadBuilder
