export interface ICCG {
  id: string
  name: string
  code: string
}

// 👇 https://directory.spineservices.nhs.uk/ORD/2-0-0/organisations?PrimaryRoleId=RO98&Name=CCG&Status=Active&Limit=1000
export enum CCGCode {
  "AIREDALE_WHARFEDALE_CRAVEN" = "02N",
  "ASHFORD" = "09C",
  "AYLESBURY_VALE" = "10Y",
  "BARKING_DAGENHAM" = "07L",
  "BARNET" = "07M",
  "BARNSLEY" = "02P",
  "BASILDON_BRENTWOOD" = "99E",
  "BASSETLAW" = "02Q",
  "BATH_NE_SOMERSET" = "11E",
  "BATH_NE_SOMERSET_SWINDON_WILTSHIRE" = "92G",
  "BEDFORDSHIRE" = "06F",
  "BEDFORDSHIRE_LUTON_MILTON_KEYNES" = "M1J4Y",
  "BERKSHIRE_W" = "15A",
  "BEXLEY" = "07N",
  "BIRMINGHAM_CROSSCITY" = "13P",
  "BIRMINGHAM_SOLIHULL" = "15E",
  "BIRMINGHAM_S_CENTRAL" = "04X",
  "BLACKBURN_WITH_DARWEN" = "00Q",
  "BLACKPOOL" = "00R",
  "BLACK_COUNTRY_W_BIRMINGHAM" = "D2P2L",
  "BNSSG" = "15C",
  "BOLTON" = "00T",
  "BRACKNELL_ASCOT" = "10G",
  "BRADFORD_CITY" = "02W",
  "BRADFORD_DISTRICTS" = "02R",
  "BRADFORD_DISTRICT_CRAVEN" = "36J",
  "BRENT" = "07P",
  "BRIGHTON_HOVE" = "09D",
  "BRISTOL" = "11H",
  "BROMLEY" = "07Q",
  "BUCKINGHAMSHIRE" = "14Y",
  "BURY" = "00V",
  "CALDERDALE" = "02T",
  "CAMBRIDGESHIRE_PETERBOROUGH" = "06H",
  "CAMDEN" = "07R",
  "CANNOCK_CHASE" = "04Y",
  "CANTERBURY_COASTAL" = "09E",
  "CASTLE_POINT_ROCHFORD" = "99F",
  "CENTRAL_LONDON_WMINSTER" = "09A",
  "CENTRAL_MANCHESTER" = "00W",
  "CHESHIRE" = "27D",
  "CHILTERN" = "10H",
  "CHORLEY_S_RIBBLE" = "00X",
  "CITY_HACKNEY" = "07T",
  "COASTAL_W_SUSSEX" = "09G",
  "CORBY" = "03V",
  "COUNTY_DURHAM" = "84H",
  "COVENTRY_RUGBY" = "05A",
  "COVENTRY_WARWICKSHIRE" = "B2M3M",
  "CRAWLEY" = "09H",
  "CROYDON" = "07V",
  "DARLINGTON" = "00C",
  "DARTFORD_GRAVESHAM_SWANLEY" = "09J",
  "DERBY_DERBYSHIRE" = "15M",
  "DEVON" = "15N",
  "DONCASTER" = "02X",
  "DORSET" = "11J",
  "DUDLEY" = "05C",
  "DURHAM_DALES_EASINGTON_SEDGEFIELD" = "00D",
  "EALING" = "07W",
  "EBOURNE_HAILSHAM_SEAFORD" = "09F",
  "ENFIELD" = "07X",
  "EREWASH" = "03X",
  "E_BERKSHIRE" = "15D",
  "E_CHESHIRE" = "01C",
  "E_LANCASHIRE" = "01A",
  "E_LEICESTERSHIRE_RUTLAND" = "03W",
  "E_RIDING_OF_YORKSHIRE" = "02Y",
  "E_STAFFORDSHIRE" = "05D",
  "E_SURREY" = "09L",
  "E_SUSSEX" = "97R",
  "FAREHAM_GOSPORT" = "10K",
  "FRIMLEY" = "D4U1Y",
  "FYLDE_WYRE" = "02M",
  "GATESHEAD" = "00F",
  "GLOUCESTERSHIRE" = "11M",
  "GREATER_HUDDERSFIELD" = "03A",
  "GREATER_PRESTON" = "01E",
  "GREAT_YARMOUTH_WAVENEY" = "06M",
  "GREENWICH" = "08A",
  "GUILDFORD_WAVERLEY" = "09N",
  "HALTON" = "01F",
  "HAMBLETON_RICHMONDSHIRE_WHITBY" = "03D",
  "HAMMERSMITH_FULHAM" = "08C",
  "HAMPSHIRE_SOUTHAMPTON_ISLE_OF_WIGHT" = "D9Y0V",
  "HARDWICK" = "03Y",
  "HARINGEY" = "08D",
  "HARROGATE_RURAL_DISTRICT" = "03E",
  "HARROW" = "08E",
  "HARTLEPOOL_STOCKTON-ON-TEES" = "00K",
  "HASTINGS_ROTHER" = "09P",
  "HAVERING" = "08F",
  "HEREFORDSHIRE" = "05F",
  "HEREFORDSHIRE_WORCESTERSHIRE" = "18C",
  "HERTS_VALLEYS" = "06N",
  "HEYWOOD_MIDDLETON_ROCHDALE" = "01D",
  "HIGH_WEALD_LEWES_HAVENS" = "99K",
  "HILLINGDON" = "08G",
  "HORSHAM_MID_SUSSEX" = "09X",
  "HOUNSLOW" = "07Y",
  "HULL" = "03F",
  "IPSWICH_E_SUFFOLK" = "06L",
  "ISLE_OF_WIGHT" = "10L",
  "ISLINGTON" = "08H",
  "KENT_MEDWAY" = "91Q",
  "KERNOW" = "11N",
  "KINGSTON" = "08J",
  "KIRKLEES" = "X2C4Y",
  "KNOWSLEY" = "01J",
  "LAMBETH" = "08K",
  "LEEDS" = "15F",
  "LEEDS_N" = "02V",
  "LEEDS_SE" = "03G",
  "LEEDS_W" = "03C",
  "LEICESTER_CITY" = "04C",
  "LEWISHAM" = "08L",
  "LINCOLNSHIRE" = "71E",
  "LINCOLNSHIRE_E" = "03T",
  "LINCOLNSHIRE_W" = "04D",
  "LIVERPOOL" = "99A",
  "LUTON" = "06P",
  "MANCHESTER" = "14L",
  "MANSFIELD_ASHFIELD" = "04E",
  "MEDWAY" = "09W",
  "MERTON" = "08R",
  "MID_ESSEX" = "06Q",
  "MILTON_KEYNES" = "04F",
  "MORECAMBE_BAY" = "01K",
  "NENE" = "04G",
  "NEWARK_SHERWOOD" = "04H",
  "NEWBURY_DISTRICT" = "10M",
  "NEWCASTLE_GATESHEAD" = "13T",
  "NEWCASTLE_NE" = "00G",
  "NEWCASTLE_W" = "00H",
  "NEWHAM" = "08M",
  "NE_ESSEX" = "06T",
  "NE_HAMPSHIRE_FARNHAM" = "99M",
  "NE_HERTFORDSHIRE" = "06K",
  "NE_LINCOLNSHIRE" = "03H",
  "NE_LONDON" = "A3A8R",
  "NORFOLK_WAVENEY" = "26A",
  "NORTHAMPTONSHIRE" = "78H",
  "NORTHERN_EASTERN_WESTERN_DEVON" = "99P",
  "NORTHUMBERLAND" = "00L",
  "NORWICH" = "06W",
  "NOTTINGHAM_CITY" = "04K",
  "NOTTINGHAM_NE" = "04L",
  "NOTTINGHAM_NOTTINGHAMSHIRE" = "52R",
  "NOTTINGHAM_W" = "04M",
  "NW_LONDON" = "W2U3Z",
  "NW_READING" = "10N",
  "NW_SURREY" = "09Y",
  "N_CENTRAL_LONDON" = "93C",
  "N_CUMBRIA" = "01H",
  "N_DERBYSHIRE" = "04J",
  "N_DURHAM" = "00J",
  "N_HAMPSHIRE" = "10J",
  "N_KIRKLEES" = "03J",
  "N_LINCOLNSHIRE" = "03K",
  "N_MANCHESTER" = "01M",
  "N_NORFOLK" = "06V",
  "N_SOMERSET" = "11T",
  "N_STAFFORDSHIRE" = "05G",
  "N_TYNESIDE" = "99C",
  "N_YORKSHIRE" = "42D",
  "OLDHAM" = "00Y",
  "OXFORDSHIRE" = "10Q",
  "PORTSMOUTH" = "10R",
  "REDBRIDGE" = "08N",
  "REDDITCH_BROMSGROVE" = "05J",
  "RICHMOND" = "08P",
  "ROTHERHAM" = "03L",
  "RUSHCLIFFE" = "04N",
  "SALFORD" = "01G",
  "SANDWELL_W_BIRMINGHAM" = "05L",
  "SCARBOROUGH_RYEDALE" = "03M",
  "SE_HAMPSHIRE" = "10V",
  "SE_LONDON" = "72Q",
  "SE_STAFFORDSHIRE_SEISDON_PENINSULA" = "05Q",
  "SHEFFIELD" = "03N",
  "SHROPSHIRE" = "05N",
  "SHROPSHIRE_TELFORD_WREKIN" = "M2L0M",
  "SLOUGH" = "10T",
  "SOLIHULL" = "05P",
  "SOMERSET" = "11X",
  "SOUTHAMPTON" = "10X",
  "SOUTHEND" = "99G",
  "SOUTHPORT_FORMBY" = "01V",
  "SOUTHWARK" = "08Q",
  "STAFFORD_SURROUNDS" = "05V",
  "STOCKPORT" = "01W",
  "STOKE_ON_TRENT" = "05W",
  "ST_HELENS" = "01X",
  "SUNDERLAND" = "00P",
  "SURREY_DOWNS" = "99H",
  "SURREY_HEARTLANDS" = "92A",
  "SURREY_HEATH" = "10C",
  "SUTTON" = "08T",
  "SWALE" = "10D",
  "SWINDON" = "12D",
  "SW_LINCOLNSHIRE" = "04Q",
  "SW_LONDON" = "36L",
  "S_CHESHIRE" = "01R",
  "S_DERBYSHIRE" = "04R",
  "S_DEVON_TORBAY" = "99Q",
  "S_GLOUCESTERSHIRE" = "12A",
  "S_KENT_COAST" = "10A",
  "S_LINCOLNSHIRE" = "99D",
  "S_MANCHESTER" = "01N",
  "S_NORFOLK" = "06Y",
  "S_READING" = "10W",
  "S_SEFTON" = "01T",
  "S_TEES" = "00M",
  "S_TYNESIDE" = "00N",
  "S_WARWICKSHIRE" = "05R",
  "S_WORCESTERSHIRE" = "05T",
  "TAMESIDE_GLOSSOP" = "01Y",
  "TEES_VALLEY" = "16C",
  "TELFORD_WREKIN" = "05X",
  "THANET" = "10E",
  "THURROCK" = "07G",
  "TOWER_HAMLETS" = "08V",
  "TRAFFORD" = "02A",
  "VALE_OF_YORK" = "03Q",
  "VALE_ROYAL" = "02D",
  "WAKEFIELD" = "03R",
  "WALSALL" = "05Y",
  "WALTHAM_FOREST" = "08W",
  "WANDSWORTH" = "08X",
  "WARRINGTON" = "02E",
  "WARWICKSHIRE_N" = "05H",
  "WIGAN_BOROUGH" = "02H",
  "WILTSHIRE" = "99N",
  "WINDSOR_ASCOT_MAIDENHEAD" = "11C",
  "WIRRAL" = "12F",
  "WOKINGHAM" = "11D",
  "WOLVERHAMPTON" = "06A",
  "WYRE_FOREST" = "06D",
  "W_CHESHIRE" = "02F",
  "W_ESSEX" = "07H",
  "W_HAMPSHIRE" = "11A",
  "W_KENT" = "99J",
  "W_LANCASHIRE" = "02G",
  "W_LEICESTERSHIRE" = "04V",
  "W_LONDON" = "08Y",
  "W_NORFOLK" = "07J",
  "W_SUFFOLK" = "07K",
  "W_SUSSEX" = "70F"
}
