import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import { isOnline } from "../../../utils/isOnline"
import IAppointment, { AppointmentTimeslot, IAppointmentStatus } from "../../../models/IAppointment"
import moment from "moment"
import client from "./_client"

const TOTAL_RETRIES = 3

export async function getVitalityAppointments(
  id: string,
  retry = 0
): Promise<[IAppointment[] | undefined, IAppointmentStatus]> {
  let body = {}
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) return [undefined, IAppointmentStatus.NoInternetConnection]

    const timeStart = moment().add(2, "hours")
    const timeEnd = moment().add(1, "weeks")
    const remainderStart = 30 - (timeStart.minute() % 30)
    const remainderEnd = 30 - (timeEnd.minute() % 30)
    const timeStartRounded = moment(timeStart).add(remainderStart, "minutes").toISOString()
    const timeEndRounded = moment(timeEnd).add(remainderEnd, "minutes").toISOString()
    const appointmentTimes = { timeStart: timeStartRounded, timeEnd: timeEndRounded }

    body = { _id: id, ...appointmentTimes }
    const data = await client.post("/v1/vitality/appointments/available", body)
    const parsedData = parseAppointments(data)
    return [parsedData, IAppointmentStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "getAppointments fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`getAppointments body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("getAppointments retry")
      await delay(1)
      return await getVitalityAppointments(id, retry + 1)
    }
    return [undefined, IAppointmentStatus.RequestFailed]
  }
}

export async function reserveAppointment(
  id: string,
  timeStart: string,
  timeEnd: string,
  retry = 0
): Promise<[Response | undefined, IAppointmentStatus]> {
  const body = { _id: id, timeStart, timeEnd }
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) return [undefined, IAppointmentStatus.NoInternetConnection]

    const data = await client.post("/v1/vitality/appointments/reserve", body)
    return [data, IAppointmentStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "reserveAppointment fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`reserveAppointment body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("reserveAppointment retry")
      await delay(1)
      return await reserveAppointment(id, timeStart, timeEnd, retry + 1)
    }
    return [undefined, IAppointmentStatus.RequestFailed]
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}

function parseAppointments(data: any): IAppointment[] {
  const map: Record<string, AppointmentTimeslot[]> = {}

  data.sort((a: AppointmentTimeslot, b: AppointmentTimeslot) => {
    const c: number = new Date(a.timestamp).getTime()
    const d: number = new Date(b.timestamp).getTime()
    return c - d
  })

  data.forEach(timeslot => {
    const date = moment.utc(timeslot.timestamp).format("DD MM YYYY")

    if (map[date]) {
      const formattedTimeslot = {
        ...timeslot,
        time: moment(timeslot.timestamp).format("HH:mm")
      }
      map[date] = [...map[date], formattedTimeslot]
    } else {
      const formattedTimeslot = {
        ...timeslot,
        time: moment(timeslot.timestamp).format("HH:mm")
      }
      map[date] = [formattedTimeslot]
    }
  })
  return Object.keys(map).map(date => ({ date, timeslots: map[date] }))
}
