import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { IntroductionIAPTScript, IntroductionIAPTScriptState } from "./IntroductionIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

enum TherapyTypes {
  personal = "1-1 Therapy",
  group = "Group Therapy",
  phone = "Phone Therapy",
  video = "Video Therapy"
}

interface State extends IntroductionIAPTScriptState {
  groupInterest?: boolean
  employmentInterest?: boolean
}

export type IntroductionEssexScriptState = State

export class IntroductionEssexScript extends IntroductionIAPTScript {
  readonly name: string = "IntroductionEssexScript"

  /** Script Steps */
  @step.logState
  greet(_d: IStepData<State>): IStepResult {
    const { directReferral } = this.rootStore.configStore
    const organisationName = this.rootStore.configStore.organisationName

    const queryParams = new URLSearchParams(window.location.search)
    const type = queryParams.get("therapyType")
    let therapyKind = ""

    if (type && Object.keys(TherapyTypes).includes(type)) {
      therapyKind = type
      this.referralStore.setCustomField("therapyKind", therapyKind)
      this.referralStore.addClinicalNote(`User selected ${TherapyTypes[therapyKind]} from website`)
    }

    return {
      body: this.t(
        [
          "Hi there!",
          "I’m Limbic",
          "I'm a friendly robot assistant to help you access psychological support from {organisationName}"
        ],
        { organisationName }
      ),
      nextStep: directReferral
        ? therapyKind
          ? this.sayReferralIntro
          : this.sayWhatIsOffered
        : this.askCanIHelp
    }
  }

  @step.logState
  handleCanIHelp(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.sayWhatIsOffered }
    }
    return { nextStep: this.sayIfYouChangeYourMindWakeMeUp }
  }

  @step.logState
  sayWhatIsOffered(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "We're the first NHS organisation to offer online video therapy courses, with sessions that deal with a wide range of feelings and symptoms",
        "We're also able to arrange access to Psychological Wellbeing Practitioners, Counsellors and Cognitive Behavioural Therapists both remotely and in person",
        "The type of therapy you need, and the way you want to access it, may be very different to someone else in your situation. That's why we provide a range of treatment to suit your needs as an individual",
        "Therapy for you would like to offer all patients a choice between self referring into Talking therapy and online courses via our Omnitherapy platform",
        "Appointments seven days a week, 6am - 11pm, with no long waiting times",
        "Confidential. No video calling, just typing so your conversations can not be overheard",
        "No travel required",
        "Accessible from anywhere, all you need is a device that can connect to 4G or the internet",
        "You have three options now:",
        "1. <b>Omnitherapy</b>: Instantly begin an online video course, delivered by trained NHS therapists",
        "2. <b>Talking Therapy</b>: Continue with a self-referral for 1-1 Therapy / Group Therapy / Phone Therapy or Video Therapy"
      ]),
      prompt: {
        id: this.getPromptId("sayWhatIsOffered"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Talking Therapy"), value: "referral" },
          { body: this.t("Omnitherapy"), value: "courses" }
        ],
        isUndoAble: true
      },
      nextStep: this.handleWhatIsOffered
    }
  }

  @step.logState
  sayWhatIsOfferedShortVersion(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Of course!",
        "Therapy For You would like to offer all patients a choice between online courses via our Omnitherapy platform and self-referral into Talking Therapy",
        "You have three options now:\n" +
          "\n" +
          "1. <b>Omnitherapy</b>: Instantly begin an online video course, delivered by trained NHS therapists\n" +
          "2. <b>Talking Therapy</b>: Continue with a self-referral for 1-1 Therapy / Group Therapy / Phone Therapy or Video Therapy\n"
      ]),
      prompt: {
        id: this.getPromptId("sayWhatIsOffered"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Talking Therapy"), value: "referral" },
          { body: this.t("Omnitherapy"), value: "courses" }
        ],
        isUndoAble: true
      },
      nextStep: this.handleWhatIsOffered
    }
  }

  @step.logStateAndResponse
  handleWhatIsOffered(d: IStepData<State, "courses" | "referral">): IStepResult {
    switch (d.response) {
      case "courses":
        return { nextStep: this.goToCourses }
      case "referral":
        return { nextStep: this.askGroupInterest }
      default:
        return { nextStep: this.end }
    }
  }

  @step.logState
  askGroupInterest(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "We offer a wide range of therapy options including small online groups run by trained professionals that can support you to develop lasting tools and techniques to improve your wellbeing",
        "If appropriate, would you be interested in discussing the option of group therapy during your initial assessment?"
      ]),
      prompt: {
        id: this.getPromptId("askGroupInterest"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleGroupInterest
    }
  }

  @step.logStateAndResponse
  handleGroupInterest(d: IStepData<State, boolean>): IStepResult {
    d.state.groupInterest = d.response
    return {
      nextStep: this.askEmploymentSupportInterest
    }
  }

  @step.logState
  askEmploymentSupportInterest(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Our service also offers employment support for those who are registered with our service, whilst you are waiting for or receiving therapy",
        "This service can support you if you are looking to return to work, to help you remain in employment or to explore options for alternative employment",
        "If appropriate, would you be interested in discussing the option for employment support during your initial assessment?"
      ]),
      prompt: {
        id: this.getPromptId("askEmploymentSupportInterest"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleEmploymentSupportInterest
    }
  }

  @step.logStateAndResponse
  handleEmploymentSupportInterest(d: IStepData<State, boolean>): IStepResult {
    d.state.employmentInterest = d.response
    return {
      nextStep: this.end
    }
  }

  @step.logState
  sayReferralIntro(_d: IStepData<State>): IStepResult {
    const therapyKind = this.t(TherapyTypes[this.referralStore.getCustomField("therapyKind")])
    return {
      body: this.t(
        [
          "You have requested to create a self-referral for {therapyKind}",
          "{therapyKind} is part of our Talking Therapy treatment offer including: 1-1 Therapy / Group Therapy / Phone Therapy and Video Therapy",
          "Once you’ve completed the self referral form, you’ll be offered an assessment call to discuss the appropriate treatment options",
          "If you’re still deciding, we can direct you to the ‘Ways to get help’ page if you’d like to look at other options"
        ],
        { therapyKind }
      ),
      prompt: {
        id: this.getPromptId("sayReferralIntro"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Continue"), value: true },
          { body: this.t("Ways to get help"), value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleReferralIntro
    }
  }

  @step.logStateAndResponse
  handleReferralIntro(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return {
        nextStep: this.end
      }
    } else {
      return {
        nextStep: this.goToTherapyOptions
      }
    }
  }

  @step.logState
  goToTherapyOptions(_d: IStepData<State>): IStepResult {
    const therapyKind = this.t(TherapyTypes[this.referralStore.getCustomField("therapyKind")])
    return {
      body: this.t(
        [
          "It's been great chatting to you",
          "Press on See my options, to visit the ‘Ways to get help’ page",
          "Or press undo if you want to continue with your {therapyKind} referral"
        ],
        { therapyKind }
      ),
      prompt: {
        id: this.getPromptId("goToTherapyOptions"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          {
            body: this.t("See my options"),
            onPress: () => {
              const a = document.createElement("a")
              a.setAttribute("href", "https://www.therapyforyou.co.uk/ways-to-get-help")
              a.click()
            }
          }
        ]
      },
      nextStep: this.end
    }
  }

  @step.logState
  goToCourses(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "It's been great chatting to you",
        "I'll take you there right now",
        "Write 'hi' or 'hello' at any time if you want to carry on with me instead",
        "Or press undo if you want to review the options again"
      ]),
      prompt: {
        id: this.getPromptId("goToCourses"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          {
            body: this.t("Find out more"),
            onPress: () => {
              const a = document.createElement("a")
              a.setAttribute("href", "https://www.therapyforyou.co.uk/courses/online")
              a.setAttribute("target", "_blank")
              a.click()
            }
          },
          {
            body: this.t("Register"),
            onPress: () => {
              const a = document.createElement("a")
              a.setAttribute("href", "https://www.therapyforyou.co.uk/register")
              a.setAttribute("target", "_blank")
              a.click()
            }
          },
          {
            body: this.t("Review options"),
            value: undefined
          }
        ],
        textPrompt: {
          placeholder: this.t('Type "Hello Limbic" to continue here'),
          forceValue: true
        }
      },
      nextStep: this.handleSayHelloToContinue
    }
  }

  @step.logState
  handleSayHelloToContinue(d: IStepData<State, string>): IStepResult {
    if (d.response === "Review options") {
      return { nextStep: this.sayWhatIsOfferedShortVersion }
    }
    return { body: this.t("Welcome back!"), nextStep: this.end }
  }
}

export default class IntroductionEssexDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionEssex
  readonly name: string = "IntroductionEssexDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IntroductionEssexDialogue.id, new IntroductionEssexScript(), state, snapshot)
  }
}
