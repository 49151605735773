import { useCallback, useEffect, useRef, useState } from "react"
import { COUNTRIES_MAP, IAutocompletePrediction } from "@limbic/types"
import useCache from "./useCache"
import { AddressAPIsRequestStatus, getAddressAutocomplete } from "../../backend/api/limbic/address"

interface IAddressAutoCompleteResponse {
  addresses?: IAutocompletePrediction[]
  error?: string
}

export function useAddressAutoComplete() {
  const [isLoading, setIsLoading] = useState(false)
  const abortControllerRef = useRef<AbortController | null>()
  const [getCache, setCache] = useCache<IAutocompletePrediction[]>()

  const fetchAddresses = useCallback(
    async (
      query: string,
      countries?: (keyof typeof COUNTRIES_MAP)[]
    ): Promise<IAddressAutoCompleteResponse> => {
      try {
        if (query.length < 3) return { addresses: [], error: undefined }

        const countriesParam = countries?.join("|") ?? ""
        const fullQuery = countriesParam ? `${query}/${countriesParam}` : query
        const cacheKey = `${query}-${countriesParam}`

        const cachedAddresses = getCache(cacheKey)
        if (cachedAddresses) {
          abortControllerRef.current?.abort()
          abortControllerRef.current = null
          return { addresses: cachedAddresses, error: undefined }
        }

        setIsLoading(true)
        abortControllerRef.current?.abort() // making sure
        abortControllerRef.current = new AbortController()
        const [addresses, status] = await getAddressAutocomplete(
          fullQuery,
          abortControllerRef.current
        )

        if (status === AddressAPIsRequestStatus.Success && addresses?.length) {
          setCache(cacheKey, addresses)
          return { addresses, error: undefined }
        }

        return { addresses: undefined, error: "Failed to fetch address predictions" }
      } catch (e) {
        return { addresses: undefined, error: e.message }
      } finally {
        setIsLoading(false)
      }
    },
    [getCache, setCache]
  )

  useEffect(() => {
    return () => {
      abortControllerRef.current?.abort()
    }
  }, [])

  return { fetchAddresses, isLoading }
}
