import BaseScript from "../../../BaseScript"
import { step } from "../../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { SurveyScriptState } from "../../../createSurveyDialogue"
import { ISurveyResponse } from "../../../../models/ISurvey"
import {
  IDefaultChatFlowMessagesCollectSMI,
  IDefaultChatFlowSettingsCollectSMI
} from "@limbic/types"
import { FF } from "../../../../featureFlags"
import { Severity } from "../../../../models/Logger/Severity"

export interface ICollectSMISettings extends IDefaultChatFlowSettingsCollectSMI {
  messages?: IDefaultChatFlowMessagesCollectSMI
}

type State = SurveyScriptState
export type CollectSMIScriptState = State

export class CollectSMIScript extends BaseScript<State> {
  readonly name: string = "CollectSMIScript"
  protected shouldUpdateReferral: boolean
  protected messages: IDefaultChatFlowMessagesCollectSMI

  constructor(settings: ICollectSMISettings | undefined = {}) {
    super()
    this.messages = settings?.messages ?? {}
    this.shouldUpdateReferral = settings?.shouldUpdateReferral ?? true
  }

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    if (!this.getFeatureFlag(FF.AC_901_SMI_MODULE)) {
      const e = new Error("Using SMI Module in a region with the feature flag disabled")
      this.logException(e, "start", Severity.Error)
      return { nextStep: this.end }
    }
    if (this.messages.smiIntroMessage?.length) return { nextStep: this.sayIntro }
    return { nextStep: this.step1 }
  }

  @step.logState
  sayIntro(d: IStepData<State>): IStepResult {
    if (this.messages.smiIntroMessage?.length) {
      return {
        body: this.t(this.messages.smiIntroMessage, this.getContext(d.state)),
        prompt: {
          id: this.getPromptId("sayIntro"),
          type: "inlinePicker",
          choices: [{ body: this.t("Okay") }]
        },
        nextStep: this.step1
      }
    }
    return { nextStep: this.step1 }
  }

  @step
  step1(_d: IStepData<State>): IStepResult {
    const q =
      "Over the past year, have there been times when you felt very happy indeed without a break for days on end?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step1"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [this.getQuestionChoice(q, "No", 0), this.getQuestionChoice(q, "Yes", 1)]
      },
      nextStep: this.handleStep1
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("1", "smiResponses")
  handleStep1(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step2 }
  }

  @step
  step2(_d: IStepData<State>): IStepResult {
    const q =
      "Over the past year, have you ever felt that your thoughts were directly interfered with or controlled by some outside force or person?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step2"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [this.getQuestionChoice(q, "No", 0), this.getQuestionChoice(q, "Yes", 1)]
      },
      nextStep: this.handleStep2
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("2", "smiResponses")
  handleStep2(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step3 }
  }

  @step
  step3(_d: IStepData<State>): IStepResult {
    const q =
      "Over the past year, have there been times when you felt that people were against you?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step3"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [this.getQuestionChoice(q, "No", 0), this.getQuestionChoice(q, "Yes", 1)]
      },
      nextStep: this.handleStep3
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("3", "smiResponses")
  handleStep3(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step4 }
  }

  @step
  step4(_d: IStepData<State>): IStepResult {
    const q =
      "Over the past year, have there been times when you felt that something strange was going on?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step4"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [this.getQuestionChoice(q, "No", 0), this.getQuestionChoice(q, "Yes", 1)]
      },
      nextStep: this.handleStep4
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("4", "smiResponses")
  handleStep4(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step5 }
  }

  @step
  step5(_d: IStepData<State>): IStepResult {
    const q =
      "Over the past year, have there been times when you heard or saw things that other people couldn’t?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step5"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [this.getQuestionChoice(q, "No", 0), this.getQuestionChoice(q, "Yes", 1)]
      },
      nextStep: this.handleStep5
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("5", "smiResponses")
  handleStep5(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step6 }
  }

  @step
  step6(_d: IStepData<State>): IStepResult {
    const q =
      "Over the past year, have you noticed that your emotions feel less strong than they used to?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step6"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [this.getQuestionChoice(q, "No", 0), this.getQuestionChoice(q, "Yes", 1)]
      },
      nextStep: this.handleStep6
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("6", "smiResponses")
  handleStep6(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step7 }
  }

  @step
  step7(_d: IStepData<State>): IStepResult {
    const q =
      "Has there ever been a period of time when you felt so good or so hyper that other people thought you were not your normal self or you were so hyper that you got into trouble?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step7"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [this.getQuestionChoice(q, "No", 0), this.getQuestionChoice(q, "Yes", 1)]
      },
      nextStep: this.handleStep7
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("7", "smiResponses")
  handleStep7(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step8 }
  }

  @step
  step8(_d: IStepData<State>): IStepResult {
    const q =
      "Has there ever been a period of time when you felt much more self-confident than usual?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step8"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [this.getQuestionChoice(q, "No", 0), this.getQuestionChoice(q, "Yes", 1)]
      },
      nextStep: this.handleStep8
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("8", "smiResponses")
  handleStep8(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step9 }
  }

  @step
  step9(_d: IStepData<State>): IStepResult {
    const q = "Has there ever been a period of time when you had much more energy than usual?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step9"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [this.getQuestionChoice(q, "No", 0), this.getQuestionChoice(q, "Yes", 1)]
      },
      nextStep: this.handleStep9
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("9", "smiResponses")
  handleStep9(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step10 }
  }

  @step
  step10(_d: IStepData<State>): IStepResult {
    const q =
      "Has there ever been a period of time when you were much more social or outgoing than usual, for example, you telephoned friends in the middle of the night?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step10"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [this.getQuestionChoice(q, "No", 0), this.getQuestionChoice(q, "Yes", 1)]
      },
      nextStep: this.handleStep10
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("10", "smiResponses")
  handleStep10(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step11 }
  }

  @step
  step11(_d: IStepData<State>): IStepResult {
    const q =
      "Do you find that most people will take advantage of you if you let them know too much about you?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step11"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [this.getQuestionChoice(q, "No", 0), this.getQuestionChoice(q, "Yes", 1)]
      },
      nextStep: this.handleStep11
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("11", "smiResponses")
  handleStep11(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step12 }
  }

  @step
  step12(_d: IStepData<State>): IStepResult {
    const q = "Do you generally feel nervous or anxious around people?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step12"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [this.getQuestionChoice(q, "No", 0), this.getQuestionChoice(q, "Yes", 1)]
      },
      nextStep: this.handleStep12
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("12", "smiResponses")
  handleStep12(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step13 }
  }

  @step
  step13(_d: IStepData<State>): IStepResult {
    const q = "Do you avoid getting to know people because you're worried they may not like you?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step13"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [this.getQuestionChoice(q, "No", 0), this.getQuestionChoice(q, "Yes", 1)]
      },
      nextStep: this.handleStep13
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("13", "smiResponses")
  handleStep13(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step14 }
  }

  @step
  step14(_d: IStepData<State>): IStepResult {
    const q =
      "Do you keep changing the way you present yourself to people because you don't know who you really are?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step14"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [this.getQuestionChoice(q, "No", 0), this.getQuestionChoice(q, "Yes", 1)]
      },
      nextStep: this.handleStep14
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("14", "smiResponses")
  handleStep14(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step15 }
  }

  @step
  step15(_d: IStepData<State>): IStepResult {
    const q =
      "Do you often get angry or irritated because people don't recognize your special talents or achievements as much as they should?"
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step15"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [this.getQuestionChoice(q, "No", 0), this.getQuestionChoice(q, "Yes", 1)]
      },
      nextStep: this.handleStep15
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("15", "smiResponses")
  handleStep15(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.handleScores }
  }

  @step.logState
  handleScores(d: IStepData<State>): IStepResult {
    if (this.shouldUpdateReferral) this.sendSMI(d.state)
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getQuestionChoice(title: string, answer: string, points: number) {
    return {
      body: this.t(answer),
      value: { title, answer, points }
    }
  }
}

export default class CollectSMIDialogue extends AdHocDialogue<State, CollectSMIScript> {
  static id = DialogueIDs.CollectSMI
  readonly name: string = "CollectSMIDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>, settings?: ICollectSMISettings) {
    super(
      CollectSMIDialogue.id,
      new CollectSMIScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
