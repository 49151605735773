import {
  ARMED_FORCES_PCMIS_EVERYTURN,
  CIVIL_STATUS_PCMIS_EVERYTURN,
  DISABILITY_PCMIS_EVERYTURN,
  ETHNICITY_PCMIS_EVERYTURN,
  GENDER_PCMIS_EVERYTURN,
  GenderBirthAssigned,
  LANGUAGE_PCMIS_EVERYTURN,
  LTC_PCMIS_EVERYTURN,
  PERINATAL_PCMIS_EVERYTURN,
  RELIGION_PCMIS_EVERYTURN,
  SEXUALITY_PCMIS_EVERYTURN
} from "@limbic/types"

export const religions: Record<string, RELIGION_PCMIS_EVERYTURN> = {
  "No Religious group or secular": "NONE",
  Agnostic: "AGNOSTIC",
  "Baha'i": "BAHAI",
  Buddhist: "BUDDHIST",
  "Church of England": "CHURCH_OF_ENGLAND",
  Hindu: "HINDU",
  Jain: "JAIN",
  Jewish: "JEWISH",
  Muslim: "MUSLIM",
  Protestant: "PROTESTANT",
  Christian: "CHRISTIAN",
  "Orthodox Christian": "CHRISTIAN_ORTHODOX",
  "Orthodox Jewish": "JEWISH_ORTHODOX",
  Pagan: "PAGAN",
  Rastafarian: "RASTAFARIAN",
  "Roman Catholic": "ROMAN_CATHOLIC",
  "Shi'ite Muslim": "SHIITE_MUSLIM",
  Sikh: "SIKH",
  "Sunni Muslim": "SUNNI_MUSLIM",
  "Parsi / Zoroastrian": "ZOROASTRIAN",
  "Any other religion": "OTHER",
  "Prefer not to say": "NOT_ANSWERED"
}

export const disabilities: Record<string, DISABILITY_PCMIS_EVERYTURN> = {
  None: "NONE",
  Hearing: "HEARING",
  "Learning Disability": "LEARNING",
  "Physical Disability": "MOTOR",
  Sight: "SIGHT",
  Speech: "SPEECH",
  Other: "NOT_LISTED"
}

export const exArmedForces: Record<string, ARMED_FORCES_PCMIS_EVERYTURN> = {
  "Yes - ex-services": "YES",
  "Yes - currently serving": "YES",
  "Dependant on ex-serving member": "DEPENDENT",
  No: "NO",
  "Not stated (Person asked but declined to provide a response)": "NOT_ANSWERED"
}

export const ethnicities: Record<string, ETHNICITY_PCMIS_EVERYTURN> = {
  "White - British": "WHITE_BRITISH",
  "White - Irish": "WHITE_IRISH",
  "White - Any other White background": "WHITE_OTHER",
  "Mixed - White and Black Caribbean": "MIXED_WHITE_BLACK_CARIBBEAN",
  "Mixed - White and Black African": "MIXED_WHITE_BLACK_AFRICAN",
  "Mixed - White and Asian": "MIXED_WHITE_ASIAN",
  "Mixed - Any other mixed background": "MIXED_OTHER",
  "Asian or Asian British - Indian": "ASIAN_INDIAN",
  "Asian or Asian British - Pakistani": "ASIAN_PAKISTANI",
  "Asian or Asian British - Bangladeshi": "ASIAN_BANGLADESHI",
  "Asian or Asian British - Any other Asian background": "ASIAN_OTHER",
  "Black or Black British - Caribbean": "BLACK_CARIBBEAN",
  "Black or Black British - African": "BLACK_AFRICAN",
  "Black or Black British - Any other Black background": "BLACK_OTHER",
  "Other Ethnic Groups - Chinese": "CHINESE",
  "Other Ethnic Groups - Any other ethnic group": "OTHER",
  "Prefer not to say": "NOT_ANSWERED"
}

export const genders: Record<string, GENDER_PCMIS_EVERYTURN> = {
  "Male (including trans man)": "MALE",
  "Female (including trans woman)": "FEMALE",
  "Non-binary": "NON_BINARY",
  Other: "NOT_LISTED",
  "I don't want to say": "NOT_ANSWERED"
}

export const sameGenderAsBirth: Record<string, GenderBirthAssigned> = {
  Yes: "YES",
  No: "NO",
  "I don't know": "UNKNOWN",
  "I don't want to say": "NOT_ANSWERED"
}

export const sexualities: Record<string, SEXUALITY_PCMIS_EVERYTURN> = {
  "Heterosexual/straight": "HETEROSEXUAL",
  "Gay/lesbian": "HOMOSEXUAL",
  Bisexual: "BISEXUAL",
  "Prefer not to say": "NOT_ANSWERED",
  "Other sexual orientation": "NOT_LISTED"
}

export const maritalStatuses: Record<string, CIVIL_STATUS_PCMIS_EVERYTURN> = {
  Single: "SINGLE",
  "Married/Civil Partner": "MARRIED",
  "Divorced/Dissolved Civil Partnership": "DIVORCED",
  "Widowed/Surviving Civil Partner": "WIDOWED",
  Separated: "SEPARATED",
  "Prefer not to say": "NOT_ANSWERED"
}

export const languages: Record<string, LANGUAGE_PCMIS_EVERYTURN> = {
  English: "ENGLISH",
  Akan: "AKAN",
  Albanian: "ALBANIAN",
  Amharic: "AMHARIC",
  Arabic: "ARABIC",
  Bengali: "BENGALI",
  Sylheti: "SYLHETI",
  Brawa: "BRAWA",
  Somali: "SOMALI",
  "British Signing Language": "BRITISH_SIGN_LANGUAGE",
  Cantonese: "CANTONESE",
  Vietnamese: "VIETNAMESE",
  Creole: "CREOLE",
  Dutch: "DUTCH",
  Ethiopian: "ETHIOPIAN",
  "Farsi (Persian)": "PERSIAN_FARSI",
  Finnish: "FINNISH",
  Flemish: "FLEMISH",
  French: "FRENCH",
  "French creole": "FRENCH_CREOLE",
  Gaelic: "GAELIC",
  German: "GERMAN",
  Greek: "GREEK",
  Gujarati: "GUJARATI",
  Hakka: "HAKKA",
  Hausa: "HAUSA",
  Hebrew: "HEBREW",
  Hindi: "HINDI",
  "Igbo (Ibo)": "IGBO",
  Italian: "ITALIAN",
  Japanese: "JAPANESE",
  Korean: "KOREAN",
  Kurdish: "KURDISH",
  Lingala: "LINGALA",
  Luganda: "LUGANDA",
  "Makaton (sign language)": "MAKATON_SIGN_LANGUAGE",
  Malayalam: "MALAYALAM",
  Mandarin: "MANDARIN",
  Norwegian: "NORWEGIAN",
  "Pashto (Pushtoo)": "PASHTO",
  Patois: "PATOIS",
  Polish: "POLISH",
  Portuguese: "PORTUGUESE",
  Punjabi: "PUNJABI",
  Russian: "RUSSIAN",
  Serbian: "SERBIAN",
  Croatian: "CROATIAN",
  Sinhala: "SINHALA",
  Spanish: "SPANISH",
  Swahili: "SWAHILI",
  Swedish: "SWEDISH",
  "Tagalog (Filipino)": "TAGALOG_FILIPINO",
  Tamil: "TAMIL",
  Thai: "THAI",
  Tigrinya: "TIGRINYA",
  Turkish: "TURKISH",
  Urdu: "URDU",
  Welsh: "WELSH",
  Yoruba: "YORUBA",
  Other: "NOT_LISTED"
}

export const ltcs: Record<string, LTC_PCMIS_EVERYTURN> = {
  Asthma: "ASTHMA",
  Cancer: "CANCER",
  "Chronic Pain including Fibromyalgia": "CHRONIC_PAIN",
  COPD: "COPD",
  Diabetes: "DIABETES",
  "Digestive Tract Conditions": "DIGESTIVE",
  Epilepsy: "EPILEPSY",
  "Heart Disease": "CHD",
  "Irritable Bowel Syndrome": "IBS",
  "Musculoskeletal Disorder": "CMS",
  "Other Respiratory Disease": "RESPIRATORY",
  "Skin Condition including Eczema": "SKIN"
}

export const perinatalStatuses: Record<string, PERINATAL_PCMIS_EVERYTURN> = {
  "Currently pregnant": "PREGNANT",
  "Child under 1 year old": "CHILD_UNDER_1",
  "Pregnant and child under 1 year old": "PREGNANT_AND_CHILD_UNDER_1",
  "Prefer not to say": "NOT_ANSWERED",
  "Not known": "UNKNOWN"
}

export type employmentSupportsType =
  | "Yes - Returning to work"
  | "Yes - Staying in work"
  | "Yes - Career support"
  | "Yes - Finding employment"
  | "No employment support needed"

export const employmentSupports: employmentSupportsType[] = [
  "Yes - Returning to work",
  "Yes - Staying in work",
  "Yes - Career support",
  "Yes - Finding employment",
  "No employment support needed"
]

const form = {
  form_url: "https://www.everyturn.org/talking-therapies/self-refer/",
  filled_by: "API",
  perinatalStatuses: Object.keys(perinatalStatuses),
  sexualities: Object.keys(sexualities),
  genders: Object.keys(genders),
  sameGenderAsBirth: Object.keys(sameGenderAsBirth),
  ethnicities: Object.keys(ethnicities),
  exArmedForces: Object.keys(exArmedForces),
  disabilities: Object.keys(disabilities),
  LTCs: Object.keys(ltcs),
  languages: Object.keys(languages),
  maritalStatuses: Object.keys(maritalStatuses),
  religions: Object.keys(religions),
  // No mapping takes place for employmentStatus - state.employmentStatus is used
  employmentStatuses: [
    "Employed full time",
    "Employed part time",
    "Employed but signed off from work",
    "Unemployed",
    "Full time student",
    "Retired",
    "Full time homemaker or carer",
    "Long term sick or disabled",
    "Unpaid voluntary work"
  ],
  // No mapping takes place for employmentSupports - state.employmentSupports is used
  employmentSupports,
  // No mapping takes place for previousIaptTreatments - state.previousIaptTreatments is used
  previousIaptTreatments: ["Never", "Once", "Twice", "Three or more times"],
  // No mapping takes place for benefits - state.benefits is used
  benefits: ["JSA", "ESA", "PIP", "Incapacity Benefit", "Universal Credit", "None"],
  // No mapping takes place for disasters - state.disasters is used
  disasters: ["None", "Manchester Bombings", "Hillsborough", "New Ferry Blast"]
}

export default form
