export default interface IPostcode {
  postcode: string
  longitude: number
  latitude: number
  ccg: string
  ccgId: string
  country?: string
}

export enum PostcodeStatus {
  Success = "SUCCESS",
  InvalidPostcode = "INVALID_POSTCODE",
  RequestFailed = "REQUEST_FAILED",
  NoInternetConnection = "NO_INTERNET_CONNECTION",
  PostcodeNotFound = "POSTCODE_NOT_FOUND"
}
