import { isOnline } from "../../../utils/isOnline"
import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import type { IResponsePDSPatient } from "@limbic/types"
import type { IGPServiceODS } from "../../../models/IGPService"
import client from "./_client"

export enum PDSFindRequestStatus {
  NOT_FOUND = "NOT_FOUND",
  MULTIPLE_RECORDS = "MULTIPLE_RECORDS",
  NO_INTERNET = "NO_INTERNET",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED"
}

const TOTAL_RETRIES = 2

export interface IPDSFindRequestPayload {
  dob: string // 'YYYY-MM-DD'
  postcode?: string
  nameFirst?: string
  nameLast?: string
}

export interface IResponsePDSPatientResponse extends Omit<IResponsePDSPatient, "gp"> {
  gp?: IGPServiceODS[]
}

export async function pdsFind(
  payload: IPDSFindRequestPayload,
  retry = 0
): Promise<[IResponsePDSPatientResponse | undefined, PDSFindRequestStatus]> {
  let result: Response | undefined = undefined
  try {
    if (!(await isOnline())) return [undefined, PDSFindRequestStatus.NO_INTERNET]

    result = await client.post.raw("/v1/pds/patient/find", payload)

    if (result.status === 204) return [undefined, PDSFindRequestStatus.NOT_FOUND]
    if (result.status === 409) return [undefined, PDSFindRequestStatus.MULTIPLE_RECORDS]

    const json = await result.json()
    const data = client.extractData(json)

    return [data, PDSFindRequestStatus.SUCCESS]
  } catch (e) {
    Logger.getInstance().exception(e, "pdsFind fetch failed")
    if (retry < TOTAL_RETRIES) {
      const message = `pdsFind body for retry ${retry}`
      Logger.getInstance().breadcrumb({ message, data: { body: JSON.stringify(payload) } })
      Logger.getInstance().message("pdsFind retry")
      await delay(1)
      return await pdsFind(payload, retry + 1)
    }

    if (result?.status === 500) return [undefined, PDSFindRequestStatus.INTERNAL_ERROR]
    return [undefined, PDSFindRequestStatus.FAILED]
  }
}
