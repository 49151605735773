import { useCallback, useRef } from "react"

export default function useCache<T>(): [
  (key: string) => T | undefined,
  (key: string, value: T) => void
] {
  const cache = useRef<Record<string, T>>({})

  const getCache = useCallback((key: string): T | undefined => {
    return cache.current[key]
  }, [])

  const setCache = useCallback((key: string, value: T): void => {
    cache.current[key] = value
  }, [])

  return [getCache, setCache]
}
