import { step } from "../../../backend/chatbot/decorators/step"
import type { IntroductionIAPTScriptState } from "./IntroductionIAPTDialogue"
import { IntroductionIAPTScript } from "./IntroductionIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"

type State = IntroductionIAPTScriptState

export class IntroductionEveryturnScript extends IntroductionIAPTScript {
  readonly name: string = "IntroductionEveryturnScript"

  /* Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    const hostname = window.location.hostname
    const queryParams = new URLSearchParams(window.location.search)
    const type = queryParams.get("referral-source")
    const clinicalNotes = this.referralStore.clinicalNotes
    const urlNote = [`Referral came in through ${hostname}`]
    if (type === "phone") {
      urlNote.push(`Referral was made by ${type}`)
      this.rootStore.botStore.setMessageDelay(0, 0, 0)
    }
    this.referralStore.__dangerouslySetClinicalNotes([...clinicalNotes, ...urlNote])
    return super.start(d)
  }

  @step.logState
  greet(_d: IStepData<State>): IStepResult {
    const { directReferral } = this.rootStore.configStore
    return {
      body: this.t([
        "Hi there!",
        "I’m Limbic",
        "I can help you to access NHS talking therapies from Everyturn Mental Health"
      ]),
      nextStep: directReferral ? this.sayStaticReferralURL : this.askCanIHelp
    }
  }

  /**
   * Overriding this because we need a custom
   * copy in one of the prompt choices
   * ¯\_(ツ)_/¯
   */
  @step.logState
  askCanIHelp(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Can I help?"),
      prompt: {
        id: this.getPromptId("askCanIHelp"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: this.t(
              "Yes, I'd like to refer myself to NHS Talking Therapies delivered by Everyturn Mental Health"
            ),
            value: true
          },
          { body: this.t("No, I'm just browsing"), value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleCanIHelp
    }
  }
}

/* istanbul ignore next */
export default class IntroductionEveryturnDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionEveryturn
  readonly name: string = "IntroductionEveryturnDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IntroductionEveryturnDialogue.id, new IntroductionEveryturnScript(), state, snapshot)
  }
}
