import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GetNameIAPTScript, GetNameIAPTScriptState } from "./GetNameIAPTDialogue"
import { MHMTitles } from "../../../models/Constants"
import { PronounMaydenMHM } from "@limbic/types"

interface State extends GetNameIAPTScriptState {
  preferredTitle?: MHMTitles
  preferredPronouns?: PronounMaydenMHM
}

export type GetNameMHMScriptState = State

export class GetNameMHMScript extends GetNameIAPTScript {
  readonly name: string = "GetNameMHMScript"

  /** Script Steps */

  @step.logState
  askIsPreferredName(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t("Is {name} your preferred first name?", { name }),
      prompt: {
        id: this.getPromptId("askIsPreferredName"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleIsPreferredName
    }
  }

  @step.logStateAndResponse
  async handleIsPreferredName(d: IStepData<State, boolean>): Promise<IStepResult> {
    return {
      nextStep: d.response //
        ? this.sayNiceToMeetYou
        : this.sayPleaseGivePreferredName
    }
  }

  @step
  sayPleaseGivePreferredName(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("What would you like to be called?"),
      nextStep: this.showPromptForPreferredName
    }
  }

  @step.logState
  showPromptForPreferredName(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForPreferredName"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePreferredNameWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: GetNameMHMScript) => {
    const preferredName = d.response?.trim()
    d.state.preferredName = preferredName
    script.rootStore.applicationStore.setPreferredName(preferredName)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: GetNameMHMScript) => s.sayPleaseGivePreferredName
  })
  async handlePreferredNameWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.checkPreferredName }
  }

  @step.logState
  async checkPreferredName(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.preferredName || d.state.preferredName.trim() === "") {
      return { nextStep: this.sayPleaseGivePreferredName }
    }
    return { nextStep: this.sayNiceToMeetYou }
  }

  @step
  sayNiceToMeetYou(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t("Nice to meet you {name}", { name }),
      prompt: {
        id: this.getPromptId("sayNiceToMeetYou"),
        type: "inlinePicker",
        choices: [{ body: this.t("Nice to meet you too") }]
      },
      nextStep: this.askHowWouldYouLikeToBeAddressed
    }
  }

  @step.logState
  askHowWouldYouLikeToBeAddressed(d: IStepData<State>): IStepResult {
    const titles = this.getTitles()
    if (!titles?.length) {
      this.logBreadcrumb("TITLES NOT FOUND", d.state, { titles })
      this.logMessage("TITLES NOT FOUND")
      return { nextStep: this.end }
    }

    return {
      body: this.t("And how would you like to be addressed?"),
      prompt: {
        id: this.getPromptId("askHowWouldYouLikeToBeAddressed"),
        type: "inlinePicker",
        choices: titles.map(title => ({ body: this.t(title), value: title })),
        isUndoAble: true
      },
      nextStep: this.handleHowWouldYouLikeToBeAddressed
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, MHMTitles>) => {
    d.state.preferredTitle = d.response
  })
  handleHowWouldYouLikeToBeAddressed(_d: IStepData<State, MHMTitles>): IStepResult {
    return { nextStep: this.askPreferredPronouns }
  }

  @step.logState
  askPreferredPronouns(d: IStepData<State>): IStepResult {
    const pronouns = this.getPreferredPronouns(d.state)
    if (!pronouns?.length) {
      this.logBreadcrumb("PRONOUNS NOT FOUND", d.state, { pronouns })
      this.logMessage("PRONOUNS NOT FOUND")
      return { nextStep: this.end }
    }
    return {
      body: this.t("And what are your preferred pronouns?"),
      prompt: {
        id: this.getPromptId("askPreferredPronouns"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: this.t("Prefer not to say"),
            value: false,
            backgroundColor: "#8C96D4FF",
            selectIndividually: true
          },
          ...pronouns.map(g => ({ body: this.t(g), value: g }))
        ]
      },
      nextStep: this.handlePreferredPronouns
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, PronounMaydenMHM>) => {
    if (d.response) d.state.preferredPronouns = d.response
  })
  handlePreferredPronouns(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  async onCheckFullName(_state: State): Promise<IStepResult> {
    return {
      nextStep: this.askIsPreferredName
    }
  }

  getTitles(): MHMTitles[] {
    return ["Mr", "Mrs", "Miss", "Ms", "Dr", "Rev", "Prof", "Mx", "Sir", "Lady"]
  }

  getPreferredPronouns(_state: State): PronounMaydenMHM[] {
    return [
      "She/Her/Her",
      "He/Him/His",
      "They/Them/Their",
      "Ae/Aer/Aer",
      "Ey/Em/Eir",
      "Fae/Faer/Faer",
      "Per/Per/Pers",
      "Ve/Ver/Vers",
      "Xe/Xem/Xyr",
      "Ze/Hir/Hir"
    ]
  }
}

/* istanbul ignore next */
export default class GetNameMHMDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameMHM
  readonly name: string = "GetNameMHMDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameMHMDialogue.id, new GetNameMHMScript(), state, snapshot)
  }
}
