import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"
import invariant from "../../../../utils/invariant"

export default class USAddressPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_US_ADDRESS> {
  chatFlow = ChatFlowsEnum.COLLECT_US_ADDRESS

  /** Overrides */

  getTransformedKeys(transformedPayload?: Record<string, any>): string[] {
    const transformedKeys = super.getTransformedKeys(transformedPayload)
    if (transformedKeys.length) return ["addressHome"]
    return []
  }
  getDefaultPayload(): Partial<Partial<ReferralPayload>> {
    const usAddressKey = this.getSourceKey("usAddressKey")
    invariant(usAddressKey, "couldn't get the mapping key usAddressKey")
    const addressHome = this.ctx.state?.[usAddressKey]
    return {
      addressHome
    }
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_US_ADDRESS] = USAddressPayloadBuilder
