import {
  IDefaultChatFlowMessagesCollectUSAddress,
  IDefaultChatFlowSettingsCollectUSAddress,
  IUSAddress
} from "@limbic/types"
import BaseScript, { BaseScriptState } from "../../../BaseScript"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"

type State = BaseScriptState
export type CollectUSAddressScriptState = State
export interface ICollectUSAddressSettings extends IDefaultChatFlowSettingsCollectUSAddress {
  messages?: IDefaultChatFlowMessagesCollectUSAddress
}

export class CollectUSAddressScript extends BaseScript<State> {
  readonly name: string = "CollectUSAddressScript"
  protected readonly messages: IDefaultChatFlowMessagesCollectUSAddress | undefined
  protected readonly shouldAskPermissionToMail?: boolean
  protected readonly usAddressKey?: string

  constructor(settings?: ICollectUSAddressSettings | undefined) {
    super()
    this.messages = settings?.messages ?? {}
    this.shouldAskPermissionToMail = settings?.shouldAskPermissionToMail ?? false
    this.usAddressKey = settings?.usAddressKey ?? "addressHome"
  }

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    return { nextStep: this.askAddress }
  }

  @step.logState
  askAddress(d: IStepData<State>): IStepResult {
    return {
      body: this.t(this.messages?.askUSAddress ?? "What's your address?", this.getContext(d.state)),
      nextStep: this.showUSAddressPrompt
    }
  }

  @step.logState
  showUSAddressPrompt(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("usAddressPrompt"),
        type: "usAddress",
        dataPointsName: "usAddress"
      },
      nextStep: this.handleUSAddress
    }
  }

  @step
  handleUSAddress(d: IStepData<State, IUSAddress>): IStepResult {
    d.state[this.usAddressKey!] = d.response

    if (!this.shouldAskPermissionToMail) {
      d.state[this.usAddressKey!].consentMail = true
      return { nextStep: this.end }
    }
    return { nextStep: this.askPermissionToSendMailToAddress }
  }

  @step.logState
  askPermissionToSendMailToAddress(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.askPermissionToMail ??
          "Are you happy for us to send you written communications to your home?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askPermissionToSendMailToAddress"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handlePermissionToSendMailToAddress
    }
  }

  @step.logState
  async handlePermissionToSendMailToAddress(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state[this.usAddressKey!].consentMail = d.response

    return { nextStep: this.end }
  }
}

export default class CollectUSAddressDialogue extends AdHocDialogue<State, CollectUSAddressScript> {
  static id = DialogueIDs.CollectUSAddress
  readonly name: string = "CollectUSAddressDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectUSAddressSettings
  ) {
    super(
      CollectUSAddressDialogue.id,
      new CollectUSAddressScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
