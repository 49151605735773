import moment from "moment"
import SelfReferralIAPTScript, { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import invariant from "../../../utils/invariant"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type {
  LIMBIC_IMPACT_LEVEL,
  ReferralPayloadMock,
  GENDER_MOCK,
  DISABILITY_MOCK,
  PERINATAL_MOCK,
  ETHNICITY_MOCK,
  ARMED_FORCES_MOCK,
  SEXUALITY_MOCK,
  LTC_MOCK
} from "@limbic/types"
import {
  disabilities,
  ethnicities,
  exArmedForces,
  genders,
  ltcs,
  perinatalStatuses,
  sexualities
} from "../../../config/referralForms/demo"
import { parsePhoneNumber } from "awesome-phonenumber"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isValidPhoneNumber"

type State = SelfReferralIAPTScriptState
export type SelfReferralDemoScriptState = State
export class SelfReferralDemoScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralScript"

  /** Script Steps */

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: this.t(["Good news, {name}", "Your referral to {iaptName} is nearly ready to go ✉️"], {
        name,
        iaptName
      }),
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Great"), value: false },
          { body: this.t("Good to know"), value: false }
        ]
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async getReferralPayload(state: State): Promise<ReferralPayloadMock> {
    const instanceID = state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile
    const parsed = parsePhoneNumber(state.phoneNumber || "0", { regionCode: "GB" })

    return {
      instanceID,
      nameFirst: this.getFirstName(state),
      nameLast: this.getLastName(state),
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      phoneHome: isValidLandline
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      email: state.email,
      consentEmail: state.canSendEmail,
      gender: this.getGender(state),
      disability: this.getDisability(state),
      perinatal: this.getPerinatal(state),
      ethnicity: this.getEthnicity(state),
      armedForces: this.getArmedForce(state),
      sexuality: this.getSexuality(state),
      ltc: this.getLTC(state),
      ltcAffectMood: state.ltcAffectsMood,
      ltcMoodImpact: this.getLTCMoodImpact(state),
      ltcManagement: this.getLTCManagement(state),
      consentDataShare: true,
      consentDataStore: true,
      alcohol: state.alcohol,
      alcoholFrequency: state.alcoholFrequency,
      alcoholQuantity: state.alcoholQuantity,
      substances: state.substances,
      substancesAreMedication: state.substancesAreMedications,
      substancesInfo:
        !state.substancesAreMedications && state.substancesInfo
          ? [state.substancesInfo]
          : undefined,
      medication:
        state.substancesAreMedications && state.medicationInfo //
          ? [state.medicationInfo]
          : undefined,
      medicationWithinDosage: state.substancesAreMedications
        ? !!state.medicationWithinDoseRange
        : undefined,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      consentResearch: state.consentResearch
    }
  }

  getGender(state: State): GENDER_MOCK {
    const gender = genders[state.gender!]
    return gender ?? "UNKNOWN"
  }

  getDisability(state: State): DISABILITY_MOCK {
    const disability = disabilities[state.disability!]
    return disability ?? "NONE"
  }

  getPerinatal(state: State): PERINATAL_MOCK {
    const perinatal = perinatalStatuses[state.perinatalStatus!]
    return perinatal ?? "NONE"
  }

  getEthnicity(state: State): ETHNICITY_MOCK {
    const ethnicity = ethnicities[state.ethnicity!]
    return ethnicity ?? "UNKNOWN"
  }

  getArmedForce(state: State): ARMED_FORCES_MOCK {
    const armedForces = exArmedForces[state.isExArmedForces!]
    return armedForces ?? "NO"
  }

  getSexuality(state: State): SEXUALITY_MOCK {
    const sexuality = sexualities[state.sexuality!]
    return sexuality ?? "UNKNOWN"
  }

  getLTC(state: State): LTC_MOCK[] | undefined {
    const ltc = state.longTermMedicalCondition?.map(i => ltcs[i]).filter(Boolean)
    return ltc?.length ? ltc : undefined
  }

  getLTCMoodImpact(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      somewhat: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcMoodImpact!]
  }

  getLTCManagement(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      fairly: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcManagement!]
  }
}

/* istanbul ignore next */
export default class SelfReferralDemoDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralDemo
  readonly name = "SelfReferralDemoDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralDemoDialogue.id, new SelfReferralDemoScript(), state, snapshot)
  }
}
