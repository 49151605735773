import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import {
  DiscussionSteps,
  IDefaultChatFlowSettingsQuestionnaires,
  QuestionnaireDialogue
} from "@limbic/types"
import { step } from "../../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { SurveyScriptState } from "../../../createSurveyDialogue"
import AssessmentScript from "../../assessment/AssessmentScript"

type State = SurveyScriptState
export type QuestionnairesScriptState = State
export type IQuestionnairesSettings = IDefaultChatFlowSettingsQuestionnaires

export class QuestionnairesScript extends AssessmentScript {
  readonly name: string = "QuestionnairesScript"
  protected questionnaire?: QuestionnaireDialogue | undefined
  protected shouldUpdateReferral?: boolean

  constructor(settings: IQuestionnairesSettings | undefined = {}) {
    super()
    this.questionnaire = settings?.questionnaire
    this.shouldUpdateReferral = settings?.shouldUpdateReferral ?? true
  }

  /* Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    this.rootStore.applicationStore.setTotalProgressBars(1)
    this.rootStore.applicationStore.setCurrentProgressBar(1)
    this.rootStore.applicationStore.setCurrentProgress(0)
    return { nextStep: this.goToAssessment }
  }

  @step.logState
  goToAssessment(_d: IStepData<State>): IStepResult {
    switch (this.questionnaire) {
      case DiscussionSteps.PHQ9:
        return { nextStep: this.goToPHQ9 }
      case DiscussionSteps.PHQ2:
        return { nextStep: this.goToPHQ2 }
      case DiscussionSteps.BDI2:
        return { nextStep: this.goToBDI2 }
      case DiscussionSteps.HAM_D:
        return { nextStep: this.goToHAM_D }
      case DiscussionSteps.GAD7:
        return { nextStep: this.goToGAD7 }
      case DiscussionSteps.GAD2:
        return { nextStep: this.goToGAD2 }
      case DiscussionSteps.HAM_A:
        return { nextStep: this.goToHAM_A }
      case DiscussionSteps.Phobia:
        return { nextStep: this.goToIAPTPhobiaScale }
      case DiscussionSteps.WSAS:
        return { nextStep: this.goToIAPTWorkAndSocialAdjustment }
      case DiscussionSteps.OCI:
        return { nextStep: this.goToOCI }
      case DiscussionSteps.PCL5:
        return { nextStep: this.goToPCL5 }
      case DiscussionSteps.PDSS:
        return { nextStep: this.goToPDSS }
      case DiscussionSteps.SHAI:
        return { nextStep: this.goToSHAI18 }
      case DiscussionSteps.SocialPhobia:
        return { nextStep: this.goToSPIN }
      case DiscussionSteps.SpecificPhobia:
        return { nextStep: this.goToSpecificPhobia }
      default:
        return { nextStep: this.end }
    }
  }

  @step.logStateAndResponse
  async handlePHQ9(d: IStepData<State>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    if (this.shouldUpdateReferral) {
      this.sendPHQ9(d.state)
    }

    return { nextStep: this.end }
  }

  @step.logStateAndResponse
  async handlePHQ2(d: IStepData<State>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    if (this.shouldUpdateReferral) {
      this.sendPHQ2(d.state)
    }

    return { nextStep: this.end }
  }

  @step.logStateAndResponse
  async handleBDI2(d: IStepData<State>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    if (this.shouldUpdateReferral) {
      this.sendBDI2(d.state)
    }

    return { nextStep: this.end }
  }

  @step.logStateAndResponse
  async handleHAM_D(d: IStepData<State>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    if (this.shouldUpdateReferral) {
      this.sendHAM_D(d.state)
    }

    return { nextStep: this.end }
  }

  @step.logStateAndResponse
  async handleGAD7(d: IStepData<State>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    if (this.shouldUpdateReferral) {
      this.sendGAD7(d.state)
    }

    return { nextStep: this.end }
  }

  @step.logStateAndResponse
  async handleGAD2(d: IStepData<State>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    if (this.shouldUpdateReferral) {
      this.sendGAD2(d.state)
    }

    return { nextStep: this.end }
  }

  @step.logStateAndResponse
  async handleHAM_A(d: IStepData<State>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    if (this.shouldUpdateReferral) {
      this.sendHAM_A(d.state)
    }

    return { nextStep: this.end }
  }

  @step.logStateAndResponse
  async handleIAPTPhobiaScale(d: IStepData<State>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    if (this.shouldUpdateReferral) {
      this.sendPhobiaScale(d.state)
    }

    return { nextStep: this.end }
  }

  @step.logStateAndResponse
  async handleIAPTWorkAndSocialAdjustment(d: IStepData<State>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    if (this.shouldUpdateReferral) {
      this.sendWSAS(d.state)
    }

    return { nextStep: this.end }
  }

  @step.logStateAndResponse
  async handleOCI(d: IStepData<State>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    if (this.shouldUpdateReferral) {
      this.sendOCI(d.state)
    }

    return { nextStep: this.end }
  }

  @step.logStateAndResponse
  async handlePCL5(d: IStepData<State>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    if (this.shouldUpdateReferral) {
      this.sendPCL5(d.state)
    }

    return { nextStep: this.end }
  }

  @step.logStateAndResponse
  async handlePDSS(d: IStepData<State>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    if (this.shouldUpdateReferral) {
      this.sendPDSS(d.state)
    }

    return { nextStep: this.end }
  }

  @step.logStateAndResponse
  async handleSHAI18(d: IStepData<State>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    if (this.shouldUpdateReferral) {
      this.sendSHAI18(d.state)
    }

    return { nextStep: this.end }
  }

  @step.logStateAndResponse
  async handleSPIN(d: IStepData<State>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    if (this.shouldUpdateReferral) {
      this.sendSPIN(d.state)
    }

    return { nextStep: this.end }
  }

  @step.logStateAndResponse
  async handleSpecificPhobiaQuestionnaire(d: IStepData<State>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    if (this.shouldUpdateReferral) {
      this.sendSpecificPhobia(d.state)
    }

    return { nextStep: this.end }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.rootStore.applicationStore.setTotalProgressBars(0)
    return super.end(d)
  }
}

export default class QuestionnairesDialogue extends AdHocDialogue<State, QuestionnairesScript> {
  static id = DialogueIDs.Questionnaires
  readonly name: string = "QuestionnairesDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: IQuestionnairesSettings
  ) {
    super(
      QuestionnairesDialogue.id,
      new QuestionnairesScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
