import Logger from "../utils/Logger"
import pkg from "../../package.json"
import Loggable from "../models/Loggable"
import invariant from "../utils/invariant"

const dist = process.env.REACT_APP_DIST
const botConfigVersion = process.env.REACT_APP_BOT_VERSION ?? "draft"
const dsn = process.env.REACT_APP_SENTRY_DSN
invariant(dsn, "REACT_APP_SENTRY_DSN is required")

const allowUrls = undefined
Logger.setup(pkg.version, dsn, dist, allowUrls)
const loggerSingleton = Logger.getInstance()
loggerSingleton.setTag("botConfigVersion", botConfigVersion)

Loggable.setLogger(loggerSingleton)
