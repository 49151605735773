import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import {
  AssessmentType,
  DiscussionSteps,
  IDefaultChatFlowSettingsAssessmentCustomisableADSM,
  ProblemCategories,
  QuestionnaireDialogue
} from "@limbic/types"
import {
  AssessmentWithADSMScript,
  AssessmentWithADSMScriptState
} from "../../assessment/AssessmentWithADSMScript"
import { FF } from "../../../../featureFlags"
import { Severity } from "../../../../models/Logger/Severity"

const PROGRESS_BARS_WITHOUT_ADSM = 4

export type IAssessmentAndTreatmentsSettings = IDefaultChatFlowSettingsAssessmentCustomisableADSM

type State = AssessmentWithADSMScriptState

export type AssessmentCustomisableADSMScriptState = State

export class AssessmentCustomisableADSMScript extends AssessmentWithADSMScript {
  readonly name: string = "AssessmentCustomisableADSMScript"
  protected enableADSM: boolean
  protected customQuestionnaires?: Record<AssessmentType, QuestionnaireDialogue> | undefined
  constructor(settings: IAssessmentAndTreatmentsSettings | undefined = {}) {
    super()
    this.enableADSM = settings?.enableADSM ?? true
    this.customQuestionnaires = settings?.customQuestionnaires
  }

  /** Script Steps */

  @step
  start(d: IStepData<State>): IStepResult {
    if (!this.getFeatureFlag(FF.AC_902_CUSTOMISABLE_ASSESSMENT)) {
      const e = new Error(
        "Using Customisable Assessment in a region with the feature flag disabled"
      )
      this.logException(e, "start", Severity.Error)
      return { nextStep: this.end }
    }
    return super.start(d)
  }

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.goToDepressionAssessment }
  }

  @step.logState
  goToDepressionAssessment(_d: IStepData<State>): IStepResult {
    // prettier-ignore
    const discussionStep = this.customQuestionnaires?.[AssessmentType.Depression] ?? DiscussionSteps.PHQ9
    return this.goToQuestionnaire(discussionStep)
  }

  @step.logState
  goToAnxietyAssessment(_d: IStepData<State>): IStepResult {
    // prettier-ignore
    const discussionStep = this.customQuestionnaires?.[AssessmentType.Anxiety] ?? DiscussionSteps.GAD7
    return this.goToQuestionnaire(discussionStep)
  }

  @step.logState
  goToPhobiaAssessment(_d: IStepData<State>): IStepResult {
    // prettier-ignore
    const discussionStep = this.customQuestionnaires?.[AssessmentType.Phobia] ?? DiscussionSteps.Phobia
    return this.goToQuestionnaire(discussionStep)
  }

  @step.logState
  goToFunctionalImpairmentAssessment(_d: IStepData<State>): IStepResult {
    // prettier-ignore
    const discussionStep = this.customQuestionnaires?.[AssessmentType.FunctionalImpairment] ?? DiscussionSteps.WSAS
    return this.goToQuestionnaire(discussionStep)
  }

  @step.logState
  async checkADSM(d: IStepData<State>): Promise<IStepResult> {
    if (this.enableADSM) return await super.checkADSM(d)
    this.retroActivelyIncludeScreeningQuestions(d.state)
    return { nextStep: this.checkMostAffectingPrimary }
  }

  @step.logState
  handleAdditionalQuestionnaires(_d: IStepData<State>): IStepResult {
    const adsmQuestionnaires = this.rootStore.clinicalStore.adsmQuestionnaires
    const currentIndex = this.rootStore.clinicalStore.currentADSMQuestionnairePosition!
    const nextQuestionnaire = adsmQuestionnaires?.[currentIndex]
    if (nextQuestionnaire) {
      this.rootStore.applicationStore.setCurrentProgressBar(
        PROGRESS_BARS_WITHOUT_ADSM + currentIndex + 1
      )
      this.rootStore.applicationStore.setCurrentProgress(0)
      this.rootStore.clinicalStore.setCurrentADSMQuestionnairePosition(currentIndex + 1)

      switch (nextQuestionnaire) {
        case "PCL5":
          return { nextStep: this.askPTSD }
        case "OCI":
          return { nextStep: this.goToOCDAssessment }
        case "PDSS":
          return { nextStep: this.goToPanicAssessment }
        case "SPECIFIC_PHOBIA":
          return { nextStep: this.goToSpecificPhobiaAssessment }
        case "SPIN":
          return { nextStep: this.goToSocialPhobiaAssessment }
        case "SHAI":
          return { nextStep: this.goToHealthAnxietyAssessment }
      }
    }

    this.rootStore.applicationStore.setTotalProgressBars(0)
    return { nextStep: this.checkMostAffectingPrimary }
  }

  @step.logStateAndResponse
  handlePTSD(d: IStepData<State, boolean>): IStepResult {
    d.state.PTSDInvolvesDeath = d.response
    if (!d.response) return { nextStep: this.handleAdditionalQuestionnaires }
    return { nextStep: this.goToPTSDAssessment }
  }

  @step.logState
  goToPTSDAssessment(_d: IStepData<State>): IStepResult {
    // prettier-ignore
    const discussionStep = this.customQuestionnaires?.[AssessmentType.PTSD] ?? DiscussionSteps.PCL5
    return this.goToQuestionnaire(discussionStep)
  }

  @step.logState
  goToOCDAssessment(_d: IStepData<State>): IStepResult {
    // prettier-ignore
    const discussionStep = this.customQuestionnaires?.[AssessmentType.OCD] ?? DiscussionSteps.OCI
    return this.goToQuestionnaire(discussionStep)
  }

  @step.logState
  goToPanicAssessment(_d: IStepData<State>): IStepResult {
    // prettier-ignore
    const discussionStep = this.customQuestionnaires?.[AssessmentType.Panic] ?? DiscussionSteps.PDSS
    return this.goToQuestionnaire(discussionStep)
  }

  @step.logState
  goToSpecificPhobiaAssessment(_d: IStepData<State>): IStepResult {
    // prettier-ignore
    const discussionStep = this.customQuestionnaires?.[AssessmentType.SpecificPhobia] ?? DiscussionSteps.SpecificPhobia
    return this.goToQuestionnaire(discussionStep)
  }

  @step.logState
  goToSocialPhobiaAssessment(_d: IStepData<State>): IStepResult {
    // prettier-ignore
    const discussionStep = this.customQuestionnaires?.[AssessmentType.SocialPhobia] ?? DiscussionSteps.SocialPhobia
    return this.goToQuestionnaire(discussionStep)
  }

  @step.logState
  goToHealthAnxietyAssessment(_d: IStepData<State>): IStepResult {
    // prettier-ignore
    const discussionStep = this.customQuestionnaires?.[AssessmentType.HealthAnxiety] ?? DiscussionSteps.SHAI
    return this.goToQuestionnaire(discussionStep)
  }

  @step.logState
  handleMostAffectingPrimary(d: IStepData<State, ProblemCategories | "none">): IStepResult {
    // this is extended because the base assessment script
    // has a different flow to what the ADSM version does
    // so when enableADSM is false we need to change what we do

    if (this.enableADSM) return super.handleMostAffectingPrimary(d)

    const primaries = this.rootStore.clinicalStore.primaryProblems.slice()
    const secondaries = this.rootStore.clinicalStore.secondaryProblems.slice()
    if (d.response !== "none") {
      const newPrimaries = [d.response]
      const newSecondaries = [...new Set([...secondaries, ...primaries])].filter(
        c => !newPrimaries.includes(c)
      )
      this.rootStore.clinicalStore.setPrimaries(newPrimaries)
      this.rootStore.clinicalStore.setSecondaries(newSecondaries)
      this.logBreadcrumb("Most affecting primary answered", d.state, {
        ProblemCategories,
        primaryProblems: this.clinicalStore.primaryProblems,
        secondaryProblems: this.clinicalStore.secondaryProblems,
        flags: this.clinicalStore.flags,
        response: d.response
      })
      this.logMessage("Problem Descriptors Changed")
    }
    const primary = d.response === "none" ? undefined : d.response
    this.addMostAffectingPrimaryClinicalNote(primaries, primary)
    return { nextStep: this.finishAssessment }
  }

  /** Generic Handlers */

  goToQuestionnaire(questionnaire: QuestionnaireDialogue): IStepResult<State> {
    switch (questionnaire) {
      case DiscussionSteps.PHQ9:
        return { nextStep: this.goToPHQ9 }
      case DiscussionSteps.PHQ2:
        return { nextStep: this.goToPHQ2 }
      case DiscussionSteps.BDI2:
        return { nextStep: this.goToBDI2 }
      case DiscussionSteps.HAM_D:
        return { nextStep: this.goToHAM_D }
      case DiscussionSteps.GAD7:
        return { nextStep: this.goToGAD7 }
      case DiscussionSteps.GAD2:
        return { nextStep: this.goToGAD2 }
      case DiscussionSteps.HAM_A:
        return { nextStep: this.goToHAM_A }
      case DiscussionSteps.Phobia:
        return { nextStep: this.goToIAPTPhobiaScale }
      case DiscussionSteps.WSAS:
        return { nextStep: this.goToIAPTWorkAndSocialAdjustment }
      case DiscussionSteps.OCI:
        return { nextStep: this.goToOCI }
      case DiscussionSteps.PCL5:
        return { nextStep: this.goToPCL5 }
      case DiscussionSteps.PDSS:
        return { nextStep: this.goToPDSS }
      case DiscussionSteps.SHAI:
        return { nextStep: this.goToSHAI18 }
      case DiscussionSteps.SocialPhobia:
        return { nextStep: this.goToSPIN }
      case DiscussionSteps.SpecificPhobia:
        return { nextStep: this.goToSpecificPhobia }
      default:
        return { nextStep: this.finishAssessment }
    }
  }

  async onHandlePHQ9(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.goToAnxietyAssessment }
  }

  async onHandlePHQ2(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.goToAnxietyAssessment }
  }

  async onHandleBDI2(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.goToAnxietyAssessment }
  }

  async onHandleHAM_D(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.goToAnxietyAssessment }
  }

  async onHandleGAD7(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.goToPhobiaAssessment }
  }

  async onHandleGAD2(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.goToPhobiaAssessment }
  }

  async onHandleHAM_A(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.goToPhobiaAssessment }
  }

  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.goToFunctionalImpairmentAssessment }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }

  async onHandlePCL5(_state: State): Promise<IStepResult> {
    return { nextStep: this.handleAdditionalQuestionnaires }
  }

  async onHandleOCI(_state: State): Promise<IStepResult> {
    return { nextStep: this.handleAdditionalQuestionnaires }
  }

  async onHandlePDSS(_state: State): Promise<IStepResult> {
    return { nextStep: this.handleAdditionalQuestionnaires }
  }

  async onHandleSpecificPhobiaQuestionnaire(_state: State): Promise<IStepResult> {
    return { nextStep: this.handleAdditionalQuestionnaires }
  }

  async onHandleSPIN(_state: State): Promise<IStepResult> {
    return { nextStep: this.handleAdditionalQuestionnaires }
  }

  async onHandleSHAI18(_state: State): Promise<IStepResult> {
    return { nextStep: this.handleAdditionalQuestionnaires }
  }
}

export default class AssessmentCustomisableADSMDialogue extends AdHocDialogue<
  State,
  AssessmentCustomisableADSMScript
> {
  static id = DialogueIDs.AssessmentCustomisableADSM
  readonly name: string = "AssessmentCustomisableADSMDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: IAssessmentAndTreatmentsSettings
  ) {
    super(
      AssessmentCustomisableADSMDialogue.id,
      new AssessmentCustomisableADSMScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
