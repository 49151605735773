import ChatFlowPayloadBuilder from "./ChatFlowPayloadBuilder"
import "./builders/PhoneNumberPayloadBuilder"
import "./builders/AlcoholPayloadBuilder"
import "./builders/SubstancesPayloadBuilder"
import "./builders/CovidPayloadBuilder"
import "./builders/AddressPayloadBuilder"
import "./builders/PriorMHTreatmentPayloadBuilder"
import "./builders/CurrentMHTreatmentPayloadBuilder"
import "./builders/EmailPayloadBuilder"
import "./builders/ADHDPayloadBuilder"
import "./builders/ASDPayloadBuilder"
import "./builders/NationalityPayloadBuilder"
import "./builders/EthnicityPayloadBuilder"
import "./builders/NHSNumberPayloadBuilder"
import "./builders/MainIssuePayloadBuilder"
import "./builders/GoalForTherapyPayloadBuilder"
import "./builders/SexualityPayloadBuilder"
import "./builders/PreferredCorrespondencePayloadBuilder"
import "./builders/LanguagePayloadBuilder"
import "./builders/GenderPayloadBuilder"
import "./builders/DisabilityPayloadBuilder"
import "./builders/LTCPayloadBuilder"
import "./builders/ReligionPayloadBuilder"
import "./builders/USAddressPayloadBuilder"

export default ChatFlowPayloadBuilder
