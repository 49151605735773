import {
  IClinicalPath,
  ITreatmentOption,
  Questionnaires,
  ClinicalFlags,
  ProblemCategories,
  clinicalPaths,
  complexComorbidPath,
  undeterminedPath,
  CSSRSRisk,
  CSSRSRiskMap
} from "@limbic/types"
import Persistable from "../../models/Persistable"
import validateClinicalPath, { ClinicalPathValidation } from "../../utils/validateClinicalPath"
import getClinicalPath from "../../utils/getClinicalPath"
import { RiskLevel } from "../../models/Constants"
import { IUserClinicalPathDescriptor } from "../../models/IClinicalPath"
import Syncable from "../../models/Syncable"
import { action, computed, observable } from "mobx"

// 👇 This is to enable testing of the ADSM flow internally i.e. on demos/staging
const ADSM_RANDOMIZATION = String(process.env.REACT_APP_ADSM_RANDOMIZATION ?? "enabled")
const adsmActivatedByDefault = ADSM_RANDOMIZATION !== "enabled"

export default class ClinicalStore extends Persistable implements Syncable {
  readonly name: string = "ClinicalStore"
  defaultPaths: IClinicalPath[]
  customPaths: IClinicalPath[]
  complexComorbidPath?: IClinicalPath
  undeterminedPath?: IClinicalPath
  @observable primaryProblems: ProblemCategories[]
  @observable secondaryProblems: ProblemCategories[]
  @observable flags: ClinicalFlags[]

  @observable riskLevel: RiskLevel
  @observable riskLevelReason?: string
  @observable cssrsRiskLevel?: CSSRSRisk
  @observable isCrisis: boolean
  @observable triggerWords?: string[]
  @observable triggerWordsPhrase?: string
  @observable assessmentFinished: boolean
  @observable clinicalPath?: IClinicalPath
  @observable treatmentOptions: ITreatmentOption[]
  @observable probabilities?: Record<string, number>

  isADSMActive?: boolean
  @observable adsmQuestionnaires?: Array<keyof Questionnaires>
  currentADSMQuestionnairePosition?: number
  isCrisisFromTriggerWords: boolean

  requiresUrgentSupport?: boolean
  restart?(): void

  constructor() {
    super()
    this.defaultPaths = this.hydrate("defaultPaths") ?? []
    this.customPaths = this.hydrate("customPaths") ?? []
    this.complexComorbidPath = this.hydrate("complexComorbidPath")
    this.undeterminedPath = this.hydrate("undeterminedPath")
    this.primaryProblems = this.hydrate("primaryProblems") ?? []
    this.secondaryProblems = this.hydrate("secondaryProblems") ?? []
    this.flags = this.hydrate("flags") ?? []
    this.riskLevel = this.hydrate("riskLevel") ?? RiskLevel.Low
    this.riskLevelReason = this.hydrate("riskLevelReason")
    this.cssrsRiskLevel = this.hydrate("cssrsRiskLevel")
    this.isCrisis = this.hydrate("isCrisis") ?? false
    this.triggerWords = this.hydrate("triggerWords")
    this.triggerWordsPhrase = this.hydrate("triggerWordsPhrase")
    this.assessmentFinished = this.hydrate("assessmentFinished") ?? false
    this.clinicalPath = this.hydrate("clinicalPath")
    this.treatmentOptions = this.hydrate("treatmentOptions") ?? []
    this.probabilities = this.hydrate("probabilities")
    this.isADSMActive = this.hydrate("isADSMActive") ?? false
    this.adsmQuestionnaires = this.hydrate("adsmQuestionnaires") ?? []
    this.currentADSMQuestionnairePosition = this.hydrate("currentADSMQuestionnairePosition") ?? 0
    this.isCrisisFromTriggerWords = this.hydrate("isCrisisFromTriggerWords") ?? false
    this.requiresUrgentSupport = this.hydrate("requiresUrgentSupport") ?? false
  }

  @action
  rehydrate(): void {
    this.defaultPaths = this.hydrate("defaultPaths") ?? []
    this.customPaths = this.hydrate("customPaths") ?? []
    this.complexComorbidPath = this.hydrate("complexComorbidPath")
    this.undeterminedPath = this.hydrate("undeterminedPath")
    this.primaryProblems = this.hydrate("primaryProblems") ?? []
    this.secondaryProblems = this.hydrate("secondaryProblems") ?? []
    this.flags = this.hydrate("flags") ?? []
    this.riskLevel = this.hydrate("riskLevel") ?? RiskLevel.Low
    this.riskLevelReason = this.hydrate("riskLevelReason")
    this.cssrsRiskLevel = this.hydrate("cssrsRiskLevel")
    this.isCrisis = this.hydrate("isCrisis") ?? false
    this.triggerWords = this.hydrate("triggerWords")
    this.triggerWordsPhrase = this.hydrate("triggerWordsPhrase")
    this.assessmentFinished = this.hydrate("assessmentFinished") ?? false
    this.clinicalPath = this.hydrate("clinicalPath")
    this.treatmentOptions = this.hydrate("treatmentOptions") ?? []
    this.probabilities = this.hydrate("probabilities")
    this.isADSMActive = this.hydrate("isADSMActive") ?? false
    this.adsmQuestionnaires = this.hydrate("adsmQuestionnaires") ?? []
    this.currentADSMQuestionnairePosition = this.hydrate("currentADSMQuestionnairePosition") ?? 0
    this.requiresUrgentSupport = this.hydrate("requiresUrgentSupport") ?? false
  }

  setDefaultPaths(paths?: IClinicalPath[]): void {
    this.defaultPaths = paths?.length ? paths : clinicalPaths
    this.persist("defaultPaths", this.defaultPaths)
  }

  setCustomPaths(paths?: IClinicalPath[]): void {
    this.customPaths = paths ?? []
    this.persist("customPaths", paths ?? [])
  }

  setComplexComorbidPath(path?: IClinicalPath): void {
    this.complexComorbidPath = path ?? complexComorbidPath
    this.persist("complexComorbidPath", this.complexComorbidPath)
  }

  setUndeterminedPath(path?: IClinicalPath): void {
    this.undeterminedPath = path ?? undeterminedPath
    this.persist("undeterminedPath", this.undeterminedPath)
  }

  @action
  setPrimaries(problems: ProblemCategories[]): void {
    this.primaryProblems = [...new Set(problems)] // ensure no duplicates
    this.persist("primaryProblems", this.primaryProblems)
  }

  @action
  setSecondaries(problems: ProblemCategories[]): void {
    this.secondaryProblems = [...new Set(problems)] // ensure no duplicates
    this.persist("secondaryProblems", this.secondaryProblems)
  }

  @action
  setFlags(flags: ClinicalFlags[]): void {
    this.flags = [...new Set(flags)] // ensure no duplicates
    this.persist("flags", this.flags)
  }

  @action
  setRiskLevel(riskLevel: RiskLevel): void {
    this.riskLevel = riskLevel
    this.persist("riskLevel", riskLevel)
  }

  @action
  setRiskLevelReason(riskLevelReason?: string): void {
    this.riskLevelReason = riskLevelReason
    this.persist("riskLevelReason", riskLevelReason)
  }

  setRequiresUrgentSupport(requiresUrgentSupport: boolean): void {
    this.requiresUrgentSupport = requiresUrgentSupport
    this.persist("requiresUrgentSupport", requiresUrgentSupport)
  }

  @action
  setIsCrisis(isCrisis: boolean): void {
    this.isCrisis = isCrisis
    this.persist("isCrisis", isCrisis)
  }

  @action
  setTriggerWords(triggerWords?: string[]): void {
    this.triggerWords = triggerWords
    this.persist("triggerWords", triggerWords)
  }

  @action
  setTriggerWordsPhrase(triggerWordsPhrase?: string): void {
    this.triggerWordsPhrase = triggerWordsPhrase
    this.persist("triggerWordsPhrase", triggerWordsPhrase)
  }

  @action
  setAssessmentFinished(assessmentFinished: boolean): void {
    this.assessmentFinished = assessmentFinished
    this.persist("assessmentFinished", assessmentFinished)
  }

  @action
  setClinicalPath(path: IClinicalPath | undefined): void {
    this.clinicalPath = path
    this.persist("clinicalPath", path)
  }

  @action
  setTreatmentOptions(treatments: ITreatmentOption[]): void {
    const newTreatments = [...treatments].sort((a, b) => a.order - b.order)
    this.treatmentOptions = newTreatments
    this.persist("treatmentOptions", newTreatments)
  }

  setADSMQuestionnaires(questionnaires: Array<keyof Questionnaires>): void {
    this.adsmQuestionnaires = questionnaires
    this.persist("adsmQuestionnaires", questionnaires)
  }

  setCurrentADSMQuestionnairePosition(index: number): void {
    this.currentADSMQuestionnairePosition = index
    this.persist("currentADSMQuestionnairePosition", index)
  }

  @action
  declineTreatment(treatment: ITreatmentOption): void {
    const newList = this.treatmentOptions.map(t => {
      if (t.name !== treatment.name) return t
      return { ...t, declined: true, accepted: false }
    })
    this.setTreatmentOptions(newList)
  }

  @action
  acceptTreatment(treatment: ITreatmentOption): void {
    const newList = this.treatmentOptions.map(t => {
      if (t.name !== treatment.name) return { ...t, accepted: false }
      return { ...t, declined: false, accepted: true }
    })
    this.setTreatmentOptions(newList)
  }

  @action
  setProbabilities(probabilities?: Record<string, number>): void {
    this.probabilities = probabilities
    this.persist("probabilities", probabilities)
  }

  @action
  addPrimaryProblem(problem: ProblemCategories): void {
    this.setPrimaries([...this.primaryProblems, problem])
  }

  @action
  addSecondaryProblem(problem: ProblemCategories): void {
    this.setSecondaries([...this.secondaryProblems, problem])
  }

  @action
  addFlag(flag: ClinicalFlags): void {
    this.setFlags([...this.flags, flag])
  }

  @action
  generateClinicalPath(): void {
    const input = this.pathDescriptor
    let path = getClinicalPath(this.defaultPaths, this.customPaths, input)
    if (!path && input.primaryProblems.length > 1) {
      path = this.complexComorbidPath
    }
    if (!path) path = this.undeterminedPath
    this.setClinicalPath(path)
    this.setTreatmentOptions(path?.treatments || [])
  }

  validateClinicalPath(): ClinicalPathValidation {
    return validateClinicalPath(this.pathDescriptor)
  }

  getAcceptedTreatment(): ITreatmentOption | undefined {
    return this.treatmentOptions.find(t => t.accepted)
  }

  getDeclinedTreatments(): ITreatmentOption[] {
    return this.treatmentOptions.filter(t => t.declined)
  }

  randomiseADSMActivation(): void {
    const isActive = adsmActivatedByDefault ? true : Math.random() * 100 < 50
    this.setADSMActive(isActive)
  }

  setADSMActive(value: boolean): void {
    this.isADSMActive = value
    this.persist("isADSMActive", value)
  }

  disableADSM(): void {
    this.isADSMActive = false
    this.persist("isADSMActive", false)
  }

  setIsCrisisFromTriggerWords(isCrisisFromTriggerWords: boolean): void {
    this.isCrisisFromTriggerWords = isCrisisFromTriggerWords
    this.persist("isCrisisFromTriggerWords", isCrisisFromTriggerWords)
  }

  setCSSRSRiskLevel(riskLevel: keyof typeof CSSRSRisk): void {
    const cssrsRiskLevel = CSSRSRisk[riskLevel]
    this.cssrsRiskLevel = cssrsRiskLevel
    const newRiskLevel = CSSRSRiskMap[riskLevel]
    // if the new risk level is lower than the current risk level
    // then we should not proceed with updating it. Usually, we don't
    // overwrite the risk level reason when the new risk level is the
    // same as what we already had, but in the case of CSSRS, we want
    // to do so, that's why we only skip when it's lower and not when
    // it's the same too
    const shouldIgnoreUpdate =
      (this.riskLevel === RiskLevel.High && newRiskLevel !== RiskLevel.High) ||
      (this.riskLevel === RiskLevel.Moderate && newRiskLevel === RiskLevel.Low)

    if (!shouldIgnoreUpdate) {
      this.setRiskLevel(newRiskLevel)
      this.setRiskLevelReason(cssrsRiskLevel)
    }
  }

  /** Getters / Setters */

  @computed
  get isHighRisk(): boolean {
    return this.riskLevel === RiskLevel.High
  }

  @computed
  get isModerateRisk(): boolean {
    return this.riskLevel === RiskLevel.Moderate
  }

  @computed
  get isRisk(): boolean {
    return this.isHighRisk || this.isModerateRisk
  }

  @computed
  get pathDescriptor(): IUserClinicalPathDescriptor {
    return {
      primaryProblems: this.primaryProblems,
      secondaryProblems: this.secondaryProblems,
      flags: this.flags
    }
  }
}

if (process.env.REACT_APP__DEV_TOOLS__ === "enabled") {
  ClinicalStore.prototype.restart = action(function restart(this: ClinicalStore) {
    this.setPrimaries([])
    this.setSecondaries([])
    this.setFlags([])
    this.setRiskLevel(RiskLevel.Low)
    this.setRiskLevelReason()
    this.setIsCrisis(false)
    this.setTriggerWords()
    this.setTriggerWordsPhrase()
    this.setAssessmentFinished(false)
    this.setClinicalPath(undefined)
    this.setTreatmentOptions([])
    this.setADSMQuestionnaires([])
    this.setCurrentADSMQuestionnairePosition(0)
    this.disableADSM()
  })
}
