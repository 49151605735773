import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = RiskPathwayScriptState
export type RiskPathwayMindScriptState = State

export class RiskPathwayMindScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayMindScript"

  /** Script Steps */

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Are you able to keep yourself, and any dependants in your care, safe until your appointment (within 28 days)?"
      ),
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until your appointment (within 28 days)?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>, script: RiskPathwayMindScript) => {
    d.state.canKeepSelfSafe = d.response
    script.referralStore.setCustomField<State>("canKeepSelfSafe", d.response)
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  async sayCrisis(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "Mental health emergencies are serious. You're not wasting anyone's time",
          "It is normal for people to have thoughts of this nature at times",
          "However {organisationName} is not an emergency response service"
        ],
        { name, organisationName }
      ),
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "If you feel like you might attempt suicide, or may have seriously harmed yourself, you need urgent medical help, please call 999 for an ambulance",
        "If you can keep yourself safe for a short while, but you still need urgent advice please call NHS 111",
        "If you need to talk right now, you can call Samaritans on 116 123",
        'If you are in Wales you can also use the C.A.L.L Mental Health Helpline which is free and available 24/7 on 0800 132 737 or text "help" to 81066'
      ]),
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111 (Option 2), Samaritans (116 123), C.A.L.L (0800 132 737), SMS to 81066"
    )
    return {
      body: this.t("Ok, let's carry on with the mental health check in"),
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }
}

/* istanbul ignore next */
export default class RiskPathwayMindDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayMind
  readonly name: string = "RiskPathwayMindDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayMindDialogue.id, new RiskPathwayMindScript(), state, snapshot)
  }
}
