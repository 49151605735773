import { useCallback, useEffect, useRef, useState } from "react"
import { IUSAddress } from "@limbic/types"
import useCache from "./useCache"
import { AddressAPIsRequestStatus, getPlaceDetails } from "../../backend/api/limbic/address"

interface IPlaceDetailsResponse {
  addressDetails?: Partial<IUSAddress>
  error?: string
}

export function usePlaceDetails() {
  const [isLoading, setIsLoading] = useState(false)
  const abortControllerRef = useRef<AbortController | null>()
  const [getCache, setCache] = useCache<IUSAddress>()

  const fetchPlaceDetails = useCallback(
    async (placeID: string): Promise<IPlaceDetailsResponse> => {
      try {
        const cacheKey = `placeDetails-${placeID}`
        const cachedDetails = getCache(cacheKey)
        if (cachedDetails) {
          abortControllerRef.current?.abort()
          abortControllerRef.current = null
          return { addressDetails: cachedDetails, error: undefined }
        }

        setIsLoading(true)
        abortControllerRef.current?.abort() // making sure
        abortControllerRef.current = new AbortController()
        const [address, status] = await getPlaceDetails(placeID, abortControllerRef.current)

        if (status === AddressAPIsRequestStatus.Success && address) {
          setCache(cacheKey, address)
          return { addressDetails: address, error: undefined }
        }

        return { addressDetails: undefined, error: "Failed to fetch place details" }
      } catch (e) {
        return { addressDetails: undefined, error: e.message }
      } finally {
        setIsLoading(false)
      }
    },
    [getCache, setCache]
  )

  useEffect(() => {
    return () => {
      abortControllerRef.current?.abort()
    }
  }, [])

  return { fetchPlaceDetails, isLoading }
}
