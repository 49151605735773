import { ClinicalGroups, disordersMap, ProblemCategories, ITreatmentOption } from "@limbic/types"
import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import formatUnicorn from "../../../utils/formatUnicorn"
import type { IStep, IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import ISelectable from "../../../models/ISelectable"

interface State extends BaseScriptState {
  currentTreatmentOptionIndex?: number
  totalTreatmentsOffered?: number
  isBelowCaseness?: boolean
}

export type TreatmentOptionsScriptState = State

export default abstract class TreatmentOptionsScript extends BaseScript<State> {
  getBelowCasenessStep?(state: State): IStep<State> | undefined

  /** Script Steps */

  @step.logState
  @step.setState<State>({ currentTreatmentOptionIndex: 0, totalTreatmentsOffered: 0 })
  start(d: IStepData<State>): IStepResult<State> {
    return { nextStep: this.checkClinicalPath }
  }

  @step.logState
  checkClinicalPath(d: IStepData<State>): IStepResult {
    const isBelowCaseness = this.getIsBelowCaseness()
    d.state.isBelowCaseness = isBelowCaseness
    if (isBelowCaseness) {
      const nextStep = this.getBelowCasenessStep?.(d.state) || this.end
      return { nextStep }
    }
    return { nextStep: this.sayIntro }
  }

  @step.logState
  sayIntro(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    let symptoms = "these symptoms"

    const isAnxietyPhobia = this.getIsAnxietyPhobia()
    if (isAnxietyPhobia) symptoms = "symptoms of phobia"

    const isAnxietyPanic = this.getIsAnxietyPanic()
    if (isAnxietyPanic) symptoms = "symptoms of panic"

    const isAnxietyWorry = this.getIsAnxietyWorry()
    if (isAnxietyWorry) symptoms = "symptoms of worry"

    const isGAD = this.getIsGAD()
    if (isGAD) symptoms = "symptoms of anxiety"
    return {
      body: this.t("So {name}, I think you would really benefit from some help with {symptoms}", {
        name,
        symptoms
      }),
      prompt: {
        id: this.getPromptId("sayIntro"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: false },
          { body: this.t("Why is that?"), value: true }
        ]
      },
      nextStep: this.handleIntro
    }
  }

  @step.logState
  handleIntro(d: IStepData<State>): IStepResult {
    if (d.response) {
      return { nextStep: this.explainSymptoms }
    }
    return { nextStep: this.startTreatments }
  }

  @step.logState
  explainSymptoms(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Well, based on the answer you gave, you're actually scoring above clinical thresholds for these symptoms",
        "Just to be safe, I think it would be sensible to speak to a trained health professional"
      ]),
      prompt: {
        id: this.getPromptId("explainSymptoms"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }, { body: this.t("Fair enough") }]
      },
      nextStep: this.startTreatments
    }
  }

  @step.logState
  startTreatments(_d: IStepData<State>): IStepResult {
    const treatmentOptions = this.rootStore.clinicalStore.treatmentOptions
    if (!treatmentOptions.length) return { nextStep: this.end }
    return { nextStep: this.goToCurrentTreatmentOption }
  }

  @step.logState
  goToCurrentTreatmentOption(d: IStepData<State>): IStepResult {
    const currentIndex = d.state.currentTreatmentOptionIndex!
    const treatmentOption = this.rootStore.clinicalStore.treatmentOptions[currentIndex]
    const isValid = this.getIsValidTreatmentOption(d.state, treatmentOption)
    if (!isValid) return { nextStep: this.goToNextTreatmentOption }
    return { nextStep: this.suggestCurrentTreatmentOption }
  }

  @step.logState
  suggestCurrentTreatmentOption(d: IStepData<State>): IStepResult | Promise<IStepResult> {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const currentIndex = d.state.currentTreatmentOptionIndex!
    const treatmentOption = this.rootStore.clinicalStore.treatmentOptions[currentIndex]
    const isFirst = d.state.totalTreatmentsOffered === 0
    const suggestion = isFirst //
      ? "To help with this, {organisationName} recommends that the best treatment for you will be the {treatmentOption}"
      : "An alternative treatment option for you is the {treatmentOption}"

    const ctx = { name, organisationName, iaptName }
    const explainer = (treatmentOption.explainer || []).map(i => formatUnicorn(i, ctx))
    return {
      body: this.t(
        [suggestion, ...explainer, "Would you like to go ahead with the {treatmentOption}?"],
        { organisationName, treatmentOption: treatmentOption.formattedName }
      ),
      prompt: {
        id: this.getPromptId(`suggestCurrentTreatmentOption - ${treatmentOption.name}`),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false },
          treatmentOption?.explainMore?.text.length && {
            body: this.t("Can you explain more?"),
            value: "explain"
          }
        ].filter(Boolean) as ISelectable[]
      },
      nextStep: this.handleCurrentTreatmentOption
    }
  }

  @step.logState
  explainCurrentTreatmentOption(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const currentIndex = d.state.currentTreatmentOptionIndex!
    const treatmentOption = this.rootStore.clinicalStore.treatmentOptions[currentIndex]
    const explainMore = treatmentOption.explainMore?.text || []
    const choices = treatmentOption.explainMore?.choices || [
      { body: this.t("Yes"), value: true },
      { body: this.t("No"), value: false }
    ]

    return {
      body: this.t([...explainMore], { name, organisationName, iaptName }),
      prompt: {
        id: this.getPromptId(`explainCurrentTreatmentOption - ${treatmentOption.name}`),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.handleCurrentTreatmentOption
    }
  }

  @step.logState
  handleCurrentTreatmentOption(d: IStepData<State, boolean | "explain">): IStepResult {
    d.state.totalTreatmentsOffered = d.state.totalTreatmentsOffered! + 1
    const currentIndex = d.state.currentTreatmentOptionIndex!
    const current = this.rootStore.clinicalStore.treatmentOptions[currentIndex]
    this.setTreatmentSuggested(d.state, current)
    if (d.response === "explain") {
      return { nextStep: this.explainCurrentTreatmentOption }
    }
    if (d.response) {
      return { nextStep: this.sayAcceptedTreatmentOption }
    }
    return { nextStep: this.sayDeclinedTreatmentOption }
  }

  @step.logState
  sayAcceptedTreatmentOption(d: IStepData<State>): IStepResult | Promise<IStepResult> {
    const name = this.getName(d.state)
    const currentIndex = d.state.currentTreatmentOptionIndex!
    const current = this.rootStore.clinicalStore.treatmentOptions[currentIndex]
    this.rootStore.clinicalStore.acceptTreatment(current)
    this.setTreatmentAccepted(d.state, current, true)
    this.setTreatment(d.state, current)
    this.updateReferralType(d.state)

    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const acceptedResponse = current.acceptedResponse || []
    return {
      body: this.t(
        ["Great - it's really good you're open to trying this, {name}", ...acceptedResponse],
        { name, organisationName, iaptName }
      ),
      nextStep: this.end
    }
  }

  @step.logState
  sayDeclinedTreatmentOption(d: IStepData<State>): IStepResult | Promise<IStepResult> {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const currentIndex = d.state.currentTreatmentOptionIndex!
    const current = this.rootStore.clinicalStore.treatmentOptions[currentIndex]
    this.rootStore.clinicalStore.declineTreatment(current)
    this.setTreatmentAccepted(d.state, current, false)
    this.updateReferralType(d.state)

    const declinedResponse = current.declinedResponse || []
    return {
      body: this.t(["That's okay", ...declinedResponse], { name, organisationName, iaptName }),
      nextStep: this.goToNextTreatmentOption
    }
  }

  @step.logState
  goToNextTreatmentOption(d: IStepData<State>): IStepResult {
    const treatmentOptions = this.rootStore.clinicalStore.treatmentOptions
    const currentIndex = d.state.currentTreatmentOptionIndex!
    if (currentIndex >= treatmentOptions.length - 1) {
      return { nextStep: this.end }
    }
    d.state.currentTreatmentOptionIndex!++
    return { nextStep: this.goToCurrentTreatmentOption }
  }

  /** Generic Handlers */

  getIsBelowCaseness(): boolean {
    const clinicalPath = this.rootStore.clinicalStore.clinicalPath
    return [
      ClinicalGroups.Undetermined,
      ClinicalGroups.BelowCaseness,
      ClinicalGroups.BelowCaseness_MS_FI,
      ClinicalGroups.BelowCaseness_S_FI
    ].includes(clinicalPath?.clinicalGroup as any)
  }

  getIsAnxietyPhobia(): boolean {
    const primaryProblems = this.rootStore.clinicalStore.primaryProblems
    const problem = primaryProblems.length === 1 && primaryProblems[0]
    const phobias = [
      ...disordersMap.anxiety.socialPhobia,
      ...disordersMap.anxiety.phobia,
      ...disordersMap.anxiety.agoraPhobia
    ]
    return !!problem && phobias.includes(problem)
  }

  getIsAnxietyPanic(): boolean {
    const primaryProblems = this.rootStore.clinicalStore.primaryProblems
    const problem = primaryProblems.length === 1 && primaryProblems[0]
    return !!problem && disordersMap.anxiety.panic.includes(problem)
  }

  getIsAnxietyWorry(): boolean {
    const primaryProblems = this.rootStore.clinicalStore.primaryProblems
    const problem = primaryProblems.length === 1 && primaryProblems[0]
    const worries = [...disordersMap.anxiety.gad, ...ProblemCategories.HealthAnxiety]
    return !!problem && worries.includes(problem)
  }

  getIsGAD(): boolean {
    const primaryProblems = this.rootStore.clinicalStore.primaryProblems
    const problem = primaryProblems.length === 1 && primaryProblems[0]
    return disordersMap.anxiety.gad.includes(problem as ProblemCategories)
  }

  // TODO: This is not enough when the organisation has a lot of
  //       clinics with different thresholds for each iapt like VHG
  getIsValidTreatmentOption(state: State, treatmentOption?: ITreatmentOption): boolean {
    const age = state.birthday ? this.getUserAge(state) : 0
    const ageThresholds = treatmentOption?.age || [0]
    const aFrom = ageThresholds[0]
    if (age < aFrom) return false
    const aTo = ageThresholds[1]
    // noinspection RedundantIfStatementJS
    if (aTo != null && age > aTo) return false
    return true
  }
}
