import type IAppLaunchConfig from "../models/IAppLaunchConfig"
import { IAppLaunchConfigOptions } from "../models/IAppLaunchConfig"
import getServiceByAPIKeyWithVersion from "../backend/api/limbic/getServiceByAPIKeyWithVersion"
import {
  clinicalPaths,
  complexComorbidPath,
  IBotServiceData,
  IChatBotFlow,
  IClinicalPath,
  undeterminedPath,
  validateClinicalPath,
  DiscussionSteps,
  IDashboardDoc
} from "@limbic/types"
import Logger from "../utils/Logger"
import { apiKeysMap } from "../config/apiKeysMap"
import { init } from "./init"
import { defaultDiscussionSteps } from "./initData"
import { DialogueIDs } from "../conversation/DialogueIDs"
import invariant from "../utils/invariant"
import { IKeyMappingConfig } from "@limbic/types/dist/access/config/keyMapping"
import { dialoguesMap_Dynamic } from "../config/discussion/dialoguesMap"
import { CHATFLOWS_DISCUSSION_STEPS_MAP } from "../conversation/createDynamicDialogue"
import dialoguesRegistry, { IDialoguesRegistry } from "../conversation/dialoguesRegistry"

const isProduction = process.env.REACT_APP_BACKEND_ENV === "production"

type BotVersion = "draft" | "published"

const BOT_VERSION = process.env.REACT_APP_BOT_VERSION ?? "draft"
// const SERVICE_TOKEN = process.env.REACT_APP_SERVICE_TOKEN

export async function initDashboardConfig(setupConfig: IAppLaunchConfig): Promise<void> {
  // TODO: put this back once all services have their own token added in their injection scripts
  // const token = setupConfig.token ?? SERVICE_TOKEN
  // invariant<string>(token, "service token was not set")

  const API_KEY = setupConfig.API_KEY
  invariant(API_KEY, "API_KEY was not set")

  let overrides = hydrateOverrides()
  const botVersion = ["draft", "published"].includes(BOT_VERSION) ? BOT_VERSION : "draft"

  if (!overrides) {
    const localConfig: IAppLaunchConfigOptions | undefined = apiKeysMap[API_KEY]

    // if we have a local config, and we're in this initializer,
    // it means we are in a service that has settings in both
    // the codebase and the dashboard - therefore a hybrid
    const isHybridService = !!localConfig

    if (isHybridService) {
      invariant(
        Object.keys(localConfig?.dialoguesMap ?? {}).length,
        `Could not get local dialogues map for ${API_KEY}`
      )
      invariant(
        localConfig?.discussionStepsOrder?.length,
        `Could not get local steps order for ${API_KEY}`
      )
    }

    const dialoguesMap = localConfig?.dialoguesMap ?? dialoguesMap_Dynamic

    const serviceData = await getServiceByAPIKeyWithVersion(API_KEY, botVersion as BotVersion)
    invariant<IDashboardDoc>(serviceData, `Could not get ${botVersion} service data for ${API_KEY}`)

    const settings = serviceData[botVersion]
    invariant<IBotServiceData>(settings, `Could not get ${botVersion} settings for ${API_KEY}`)

    const basicConfig = getBasicConfig(settings)
    if (!isHybridService) {
      invariant(Object.keys(basicConfig).length, `Could not get basic config for ${API_KEY}`)
    }

    const discussionStepsOrder = getDiscussionStepsOrder(settings, API_KEY)
    invariant(discussionStepsOrder?.length, `Could not get discussion steps order for ${API_KEY}`)

    const dialogueFlows = getDialogueFlows(settings)
    invariant(Object.keys(dialogueFlows).length, `Could not get dialogue flows for ${API_KEY}`)

    ensureNextDialoguesAndChatFlowsExist(dialoguesMap, JSON.parse(JSON.stringify(dialogueFlows)))

    const backendMapping = getBackendMapping(settings)
    invariant(
      Object.keys(backendMapping?.transformMap ?? {}).length,
      `Could not get backend mapping for ${API_KEY}`
    )
    invariant(
      Object.keys(backendMapping?.targetKeys ?? {}).length,
      `Could not get backend mapping for ${API_KEY}`
    )

    const defaultClinicalPaths = getDefaultClinicalPaths(settings)
    const customClinicalPaths = getCustomClinicalPaths(settings)
    const complexComorbidPath = getComplexComorbidPath(settings)
    const undeterminedPath = getUndeterminedPath(settings)
    validateClinicalPaths(
      defaultClinicalPaths,
      customClinicalPaths,
      complexComorbidPath,
      undeterminedPath
    )

    const iaptCCGMap = {}
    const iaptGPMap = {}
    const eligibleIAPTIds: string[] = []
    const customBigBot = settings.bigBot
    const customIAPTS = settings.eligibility?.bot?.iapts?.length
      ? settings.eligibility?.bot.iapts.map(iapt => {
          const shouldUseProductionEmails = isProduction && BOT_VERSION === "published"
          const formattedIAPT = {
            ...iapt,
            id: iapt.id ?? iapt.name,
            emails: shouldUseProductionEmails ? (iapt.emails ?? iapt.emailsDemo) : iapt.emailsDemo
          }
          return formattedIAPT
        })
      : undefined

    if (customIAPTS?.length) {
      customIAPTS.forEach(iapt => {
        // If customIAPTS - set eligibleIAPTIds
        eligibleIAPTIds.push(iapt.id!)
        if (iapt.ccgs?.length) {
          // If customIAPTS & ccgs - set iaptCCGMap
          iapt.ccgs.forEach(ccgCode => {
            iaptCCGMap[ccgCode] = iapt.id
          })
        }
        // If customIAPTS - check if gpCodes exist and set iaptGPMap
        if (iapt.gpCodes?.length) {
          iapt.gpCodes.forEach(gpCode => {
            iaptGPMap[gpCode] = iapt.id
          })
        }
      })
    }

    // Note: any new dynamic dialogues we create should be added here
    if (dialogueFlows[DiscussionSteps.Intro]) {
      dialoguesMap.intro = DialogueIDs.IntroductionDynamic
    }
    if (dialogueFlows[DiscussionSteps.Permissions]) {
      /**
       * Temporarily deleting peaceOfMind
       * Not to be used anymore from the dashboard
       * Add what is needed in permissions
       */
      delete dialoguesMap.peaceOfMind
      dialoguesMap.permissions = DialogueIDs.PermissionsDynamic
    }
    if (dialogueFlows[DiscussionSteps.SelfReferral]) {
      /**
       * Temporarily deleting selfReferralPitch
       * Not to be used anymore from the dashboard
       * Add what is needed in selfReferral
       */
      delete dialoguesMap.selfReferralPitch
      dialoguesMap.selfReferral = DialogueIDs.SelfReferralDynamic
    }
    if (dialogueFlows[DiscussionSteps.Eligibility]) {
      dialoguesMap.eligibility = DialogueIDs.EligibilityCheckDynamic
    }
    if (dialogueFlows[DiscussionSteps.GetName]) {
      dialoguesMap.getName = DialogueIDs.GetNameDynamic
    }
    if (dialogueFlows[DiscussionSteps.Crisis]) {
      dialoguesMap.crisis = DialogueIDs.CrisisDynamic
    }
    if (dialogueFlows[DiscussionSteps.Assessment]) {
      /**
       * Temporarily deleting assessmentPitch
       * Not to be used anymore from the dashboard
       * Add what is needed in assessment
       */
      delete dialoguesMap.assessmentPitch
      dialoguesMap.assessment = DialogueIDs.AssessmentDynamic

      /**
       * When assessment dialogueFlow exists we need to set all assessment
       * related dialogues (i.e. that branch off assessment at any time)
       * This includes:
       *  - AssessmentDynamic (if its not ADSM)
       *  - AssessmentADSM (if its ADSM enabled)
       *  - PHQ9Dynamic (in order to handle PHQ9Q9)
       *  - RiskPathwayDynamic (in order to handle RiskPathway and custom messages)
       */
      delete dialoguesMap.assessmentPitch
      dialoguesMap.assessment = DialogueIDs.AssessmentDynamic
      dialoguesMap.assessmentADSM = DialogueIDs.AssessmentADSMDynamic
      dialoguesMap.phq9 = DialogueIDs.PHQ9Dynamic
      dialoguesMap.riskPathway = DialogueIDs.RiskPathwayDynamic
    }
    if (dialogueFlows[DiscussionSteps.Goodbye]) {
      dialoguesMap.goodbye = DialogueIDs.GoodbyeDynamic
    }

    overrides = {
      dashboardServiceKey: serviceData.serviceApiKey,
      ...localConfig,
      ...basicConfig,
      ...(setupConfig.overrides ?? {}),
      defaultLanguage: settings.configuration?.translations?.defaultLanguage,
      supportedLanguages: settings.configuration?.translations?.supportedLanguages,
      discussionStepsOrder,
      dialoguesMap,
      defaultClinicalPaths,
      customClinicalPaths,
      complexComorbidPath,
      undeterminedPath,
      dialogueFlows,
      backendMapping
    }

    if (Object.keys(iaptCCGMap).length) overrides.iaptCCGMap = iaptCCGMap
    if (Object.keys(iaptGPMap).length) overrides.iaptGPMap = iaptGPMap
    if (customIAPTS?.length) overrides.customIAPTS = customIAPTS
    if (eligibleIAPTIds.length) overrides.eligibleIAPTIds = eligibleIAPTIds
    if (customBigBot) overrides.customBigBot = customBigBot
  }

  persistOverrides(overrides)
  init({
    API_KEY,
    overrides,
    withDashboard: false,
    ...(setupConfig.autoExpand && { autoExpand: setupConfig.autoExpand })
  })
}

function hydrateOverrides(): IAppLaunchConfigOptions | undefined {
  const data = sessionStorage.getItem("@limbic:dashboard:overrides")
  if (data) return JSON.parse(data)
}

function persistOverrides(overrides: IAppLaunchConfigOptions): void {
  sessionStorage.setItem("@limbic:dashboard:overrides", JSON.stringify(overrides))
}

function getBasicConfig(settings: IBotServiceData): Partial<IAppLaunchConfigOptions> {
  const config = {
    title: settings.configuration?.title,
    serviceName: settings.configuration?.serviceName,
    organisationName: settings.configuration?.organisationName,
    organisationPhoneNumbers: settings.configuration?.organisationPhoneNumbers
      ?.filter(Boolean)
      .join("\n"),
    organisationTerms: settings.configuration?.organisationTerms,
    crisisPhoneNumbers: settings.configuration?.crisisPhoneNumbers,
    logo: settings.configuration?.logo,
    userMessageBackground: settings.configuration?.userMessageBackground,
    faqLink: settings.configuration?.faqLink,
    advanced: settings.configuration?.advanced,
    ageThreshold: settings.configuration?.ageThreshold,
    freetextLimit: settings.configuration?.freetextLimit
  }

  return Object.fromEntries(Object.entries(config).filter(([_, v]) => v != null))
}

function checkMixedSubItemsPresence(items) {
  let hasSubItems = false
  let noSubItems = false

  items.forEach(item => {
    if (Array.isArray(item.subItems) && item.subItems.length > 0) {
      hasSubItems = true
    } else {
      noSubItems = true
    }
  })

  return hasSubItems && noSubItems
}

function getDiscussionStepsOrder(
  settings: IBotServiceData,
  apiKey: string
): DiscussionSteps[] | undefined {
  if (settings.stepsOrder && Object.keys(settings.stepsOrder).length) {
    let stepsOrder

    const includesPartialSubItems = checkMixedSubItemsPresence(settings.stepsOrder)
    if (includesPartialSubItems) {
      const e = new Error(`stepsOrder from dashboard for ${apiKey} has partial subItems`)
      Logger.getInstance().exception(e, "initDashboard -> getDiscussionStepsOrder")
    }
    /**
     * 👆 The above check is to notify us in case that the steps order coming from the
     * dashboard are a mix of objects that include and do not include subItems
     *
     * 👇 The below conditional is to transition the dashboard stepsOrder away
     * from using the subitems - until then we need to have a failsafe to avoid
     * any production bots crashing
     */
    if (
      settings.stepsOrder.every(step => Array.isArray(step.subItems) && step.subItems.length > 0)
    ) {
      stepsOrder =
        settings.stepsOrder
          ?.filter(step => !step.disabled)
          .map(s => s.subItems?.map(s => s.value as unknown as DiscussionSteps))
          .flat() ?? []
    } else {
      stepsOrder = settings.stepsOrder?.filter(step => !step.disabled).map(s => s.value)
    }

    return stepsOrder.length ? stepsOrder : defaultDiscussionSteps[apiKey]
  }
  return defaultDiscussionSteps[apiKey]
}

function getDefaultClinicalPaths(settings: IBotServiceData): IClinicalPath[] {
  const paths: IClinicalPath[] = settings.clinicalPaths?.defaultClinicalPaths ?? clinicalPaths
  return paths.filter(p => p.id !== "undetermined" && p.id !== "complex_comorbid")
}

function getCustomClinicalPaths(settings: IBotServiceData): IClinicalPath[] {
  return settings.clinicalPaths?.customClinicalPaths?.paths ?? []
}

function getComplexComorbidPath(settings: IBotServiceData): IClinicalPath {
  const paths: IClinicalPath[] = settings.clinicalPaths?.defaultClinicalPaths ?? clinicalPaths
  return paths.find(p => p.id === "complex_comorbid") ?? complexComorbidPath
}

function getUndeterminedPath(settings: IBotServiceData): IClinicalPath {
  const paths: IClinicalPath[] = settings.clinicalPaths?.defaultClinicalPaths ?? clinicalPaths
  return paths.find(p => p.id === "undetermined") ?? undeterminedPath
}

function validateClinicalPaths(
  defaultClinicalPaths: IClinicalPath[],
  customClinicalPaths: IClinicalPath[],
  complexComorbidPath: IClinicalPath,
  undeterminedPath: IClinicalPath
) {
  const invalidClinicalPaths = [
    ...Object.values(defaultClinicalPaths),
    ...Object.values(customClinicalPaths),
    complexComorbidPath,
    undeterminedPath
  ]
    .map(
      path =>
        path.matcher &&
        validateClinicalPath({
          primaryProblems: path.matcher.primaryProblems,
          secondaryProblems: path.matcher.secondaryProblems ?? [],
          flags: path.matcher.flags ?? []
        })
    )
    .filter(Boolean)
    .filter(v => !v?.isValid)

  if (invalidClinicalPaths.length) {
    const e = new Error("Invalid clinical paths found")
    Logger.getInstance().exception(e, "init -> Clinical Paths Setup")
  }
}

function getDialogueFlows(settings: IBotServiceData): IChatBotFlow {
  if (!Object.keys(settings?.flow ?? {}).length) return {}

  return Object.keys(settings.flow!).reduce(
    (flow, step) => ({ ...flow, [step]: settings.flow![step].bot }),
    {}
  )
}

function getBackendMapping(settings: IBotServiceData): IKeyMappingConfig {
  return {
    transformMap: settings.backendMapping?.transformMap,
    targetKeys: settings.backendMapping?.targetKeys
  }
}

function extractNextDialogueAndChatFlow(
  data: IChatBotFlow
): Record<"nextDialogueValues" | "chatFlowValues", string[]> {
  const nextDialogueValues: string[] = []
  const chatFlowValues: string[] = []

  function processStep(obj, typeKey: "chatFlow" | "nextDialogue", dataArray) {
    const dialogueName = obj[typeKey]

    dataArray.push(dialogueName)
  }

  function search(obj: any) {
    if (Array.isArray(obj)) {
      obj.forEach(item => search(item)) // Recurse for arrays
    } else if (typeof obj === "object" && obj !== null) {
      if (obj.type === "action" && obj.nextDialogue) {
        processStep(obj, "nextDialogue", nextDialogueValues)
      } else if (obj.type === "chatFlow") {
        processStep(obj, "chatFlow", chatFlowValues)
      }
      Object.entries(obj).forEach(([_key, value]) => {
        search(value)
      })
    }
  }

  search(data)

  return { nextDialogueValues, chatFlowValues }
}

export function ensureNextDialoguesAndChatFlowsExist(
  dialoguesMap: Record<string, string>,
  dialogueFlows: IChatBotFlow
): void {
  /** Get all next dialogues and chatflows with the needed properties */
  const nextDialoguesAndChatFlows = extractNextDialogueAndChatFlow(dialogueFlows)
  const { nextDialogueValues, chatFlowValues } = nextDialoguesAndChatFlows
  const errors: string[] = []

  nextDialogueValues.forEach(nextDialogue => {
    const dialogueID = dialoguesMap[nextDialogue] as keyof IDialoguesRegistry | undefined
    if (!dialogueID) {
      return errors.push(`Next dialogue ${nextDialogue} not found in dialoguesMap`)
    }
    try {
      // try-catching because .get will throw if the dialogueID is not expected
      // so we need both that scenario and the case where a given dialogueID is
      // expected but doesn't result in something truthy
      const dialogue = dialoguesRegistry.get(dialogueID)
      invariant(dialogue, ".")
    } catch (e) {
      errors.push(`Dialogue ${dialogueID} not found in dialoguesRegistry`)
    }
  })

  chatFlowValues.forEach(chatFlow => {
    const dialogueName = CHATFLOWS_DISCUSSION_STEPS_MAP[chatFlow]
    const dialogueID = dialoguesMap[dialogueName] as keyof IDialoguesRegistry | undefined
    if (!dialogueID) {
      return errors.push(`Chat flow ${chatFlow} not found in dialoguesMap`)
    }

    try {
      // try-catching because .get will throw if the dialogueID is not expected
      // so we need both that scenario and the case where a given dialogueID is
      // expected but doesn't result in something truthy
      const dialogue = dialoguesRegistry.get(dialogueID)
      invariant(dialogue, ".")
    } catch (e) {
      errors.push(`Chat flow ${dialogueID} not found in dialoguesRegistry`)
    }
  })

  if (errors.length) {
    const errorMessage = [...new Set(errors)].join(", ")
    Logger.getInstance().exception(new Error("Dialogues not found"), errorMessage)
    throw new Error(errorMessage)
  }
}
