import createSurveyDialogue from "../createSurveyDialogue"
import { DialogueIDs } from "../DialogueIDs"
import ISurvey, { SurveyType } from "../../models/ISurvey"

const answers = [
  { points: 0, answer: "Not present" },
  { points: 1, answer: "Mild" },
  { points: 2, answer: "Moderate" },
  { points: 3, answer: "Severe" },
  { points: 4, answer: "Very severe" }
]

const HAM_A: ISurvey = {
  startingQuestion: [
    "This next set of 14 questions is designed to measure common symptoms of anxiety.",
    "For each question, please select the response that best describes how you have been feeling over the last week."
  ],
  questions: [
    {
      id: "1",
      questionName: "HAM-A - step1",
      question:
        "1. Anxious mood (Worries, anticipation of the worst, fearful anticipation, irritability)",
      type: "inline",
      progress: 0.07,
      answers
    },
    {
      id: "2",
      questionName: "HAM-A - step2",
      question:
        "2. Tension (Feelings of tension, fatigability, startle response, moved to tears easily, trembling, feelings of restlessness, inability to relax)",
      type: "inline",
      progress: 0.14,
      answers
    },
    {
      id: "3",
      questionName: "HAM-A - step3",
      question: "3. Fears (of dark, of strangers, of being left alone, of animals, etc.)",
      type: "inline",
      progress: 0.21,
      answers
    },
    {
      id: "4",
      questionName: "HAM-A - step4",
      question:
        "4. Insomnia (Difficulty in falling asleep, broken sleep, unsatisfying sleep and fatigue\n" +
        "on waking, dreams, nightmares, night terrors)",
      type: "inline",
      progress: 0.29,
      answers
    },
    {
      id: "5",
      questionName: "HAM-A - step5",
      question: "5. Intellectual (Difficulties in concentration, poor memory)",
      type: "inline",
      progress: 0.36,
      answers
    },
    {
      id: "6",
      questionName: "HAM-A - step6",
      question:
        "6. Depressed Mood (Loss of interest, lack of pleasure in hobbies, depression, early waking, diurnal swing)",
      type: "inline",
      progress: 0.43,
      answers
    },
    {
      id: "7",
      questionName: "HAM-A - step7",
      question:
        "7. Somatic - muscular (Pains and aches, twitching, stiffness, myoclonic jerks, grinding of teeth, unsteady voice, increased muscular tone)",
      type: "inline",
      progress: 0.5,
      answers
    },
    {
      id: "8",
      questionName: "HAM-A - step8",
      question:
        "8. Somatic - sensory (Tinnitus, blurring of vision, hot and cold flushes, feelings of weakness, pricking sensation)",
      type: "inline",
      progress: 0.57,
      answers
    },
    {
      id: "9",
      questionName: "HAM-A - step9",
      question:
        "9. Cardiovascular symptoms (Tachycardia, palpitations, pain in chest, throbbing of vessels, fainting feelings, missing beat)",
      type: "inline",
      progress: 0.64,
      answers
    },
    {
      id: "10",
      questionName: "HAM-A - step10",
      question:
        "10. Respiratory symptoms (Pressure or constriction in chest, choking feelings, sighing, dyspnea)",
      type: "inline",
      progress: 0.71,
      answers
    },
    {
      id: "11",
      questionName: "HAM-A - step11",
      question:
        "11. Gastrointestinal symptoms (Difficulty in swallowing, wind abdominal pain, burning sensations, abdominal fullness, nausea, vomiting, borborygmi, looseness of bowels, loss of weight, constipation)",
      type: "inline",
      progress: 0.79,
      answers
    },
    {
      id: "12",
      questionName: "HAM-A - step12",
      question:
        "12. Genitourinary symptoms (Frequency of micturition, urgency of micturition, amenorrhea, menorrhagia, development of frigidity, premature ejaculation, loss of libido, impotence",
      type: "inline",
      progress: 0.86,
      answers
    },
    {
      id: "13",
      questionName: "HAM-A - step13",
      question:
        "13. Autonomic symptoms (Dry mouth, flushing, pallor, tendency to sweat, giddiness, tension headache, raising of hair)",
      type: "inline",
      progress: 0.93,
      answers
    },
    {
      id: "14",
      questionName: "HAM-A - step14",
      question:
        "14. Behavior at interview (Fidgeting, restlessness or pacing, tremor of hands, furrowed brow, strained face, sighing or rapid respiration, facial pallor, swallowing, etc.",
      type: "inline",
      progress: 1,
      answers
    }
  ]
}
export default createSurveyDialogue(DialogueIDs.HAM_A, HAM_A, SurveyType.HAM_A)
