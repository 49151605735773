/* eslint-disable @typescript-eslint/no-empty-function */
import { ProblemCategories, Questionnaires } from "@limbic/types"
import AssessmentIAPTScript, { AssessmentIAPTScriptState } from "./AssessmentIAPTScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { problemCategoryUserFriendlyNames, TrackingEvents } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { adsmTriage, adsmTriage2 } from "../../../backend/api/limbic/adsmTriage"
import { capitaliseFirst } from "../../../utils/string"
import { ADSMStatus } from "../../../models/ADSMStatus"
import ISelectable from "../../../models/ISelectable"
import { FF } from "../../../featureFlags"

const PROGRESS_BARS_WITHOUT_ADSM = 4

export interface AssessmentWithADSMScriptState extends AssessmentIAPTScriptState {
  hasSocialPhobia?: boolean
  hasSpecificPhobiaNBI?: boolean
  hasSpecificPhobia?: boolean
  hasPanic?: boolean
  hasHealthAnxiety?: boolean
  hasOCD?: boolean
  hasPTSD?: boolean
  PTSDInvolvesDeath?: boolean
}

export abstract class AssessmentWithADSMScript<
  State extends AssessmentWithADSMScriptState = AssessmentWithADSMScriptState
> extends AssessmentIAPTScript<State> {
  readonly name: string = "AssessmentWithADSMScript"

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    if (!this.referralStore.isMainSpokenLanguageEnglish) {
      this.rootStore.applicationStore.setTotalProgressBars(PROGRESS_BARS_WITHOUT_ADSM)
    } else {
      this.rootStore.applicationStore.setTotalProgressBars(PROGRESS_BARS_WITHOUT_ADSM + 2)
    }
    this.rootStore.applicationStore.setCurrentProgressBar(1)
    this.rootStore.applicationStore.setCurrentProgress(0)
    return { nextStep: this.step1 }
  }

  @step.logState
  async sayBelowCaseness(d: IStepData<State>): Promise<IStepResult> {
    const result = await super.sayBelowCaseness(d)
    return { ...result, nextStep: this.checkADSM }
  }

  @step.logState
  async sayDepression(d: IStepData<State>): Promise<IStepResult> {
    const result = await super.sayDepression(d)
    return { ...result, nextStep: this.checkADSM }
  }

  @step.logState
  async handleDepressionOrAnxiety(
    d: IStepData<State, "lowMood" | "anxiety">
  ): Promise<IStepResult> {
    const result = await super.handleDepressionOrAnxiety(d)
    if (d.response === "lowMood") return { ...result, nextStep: this.checkADSM }
    return result
  }

  @step.logState
  sayThanksForSharing(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t("Ok, thanks for sharing {name}", { name }),
      nextStep: this.checkADSM
    }
  }

  @step.logState
  async checkADSM(d: IStepData<State>): Promise<IStepResult> {
    const signupCode = this.referralStore.signupCode
    const patientID = this.referralStore.patientId
    // NOTE: We need to upload datapoints before we do the ADSM study

    if (signupCode || patientID) {
      const age = this.getUserAge(d.state)
      await this.rootStore.dataPointsStore.sendDataPoints(age)
    }

    const dataPointsFailed = this.rootStore.dataPointsStore.dataPointsFailed
    const speakEnglish = this.referralStore.isMainSpokenLanguageEnglish
    let shouldRetroActivelyIncludeScreeningQuestions = false

    if (!speakEnglish) {
      this.track(TrackingEvents.ADSM_DEACTIVATED_NON_ENGLISH_SPEAKING)
      this.clinicalStore.disableADSM()
      shouldRetroActivelyIncludeScreeningQuestions = true
      void this.referralStore.updateReferral({
        adsm: {
          selectedAsParticipant: false,
          deactivatedAsParticipantReason: "non_english_speaking"
        }
      })
    }

    if (dataPointsFailed) {
      this.track(TrackingEvents.ADSM_DEACTIVATED_ERROR)
      this.clinicalStore.disableADSM()
      shouldRetroActivelyIncludeScreeningQuestions = true
      void this.referralStore.updateReferral({
        adsm: {
          selectedAsParticipant: false,
          deactivatedAsParticipantReason: "dataPoints_missing"
        }
      })
    }

    if (!dataPointsFailed && signupCode && speakEnglish) {
      const [newQuestionnaires, status, probabilities] = await adsmTriage(signupCode)
      this.rootStore.clinicalStore.setProbabilities(probabilities)
      if (newQuestionnaires?.length) {
        this.rootStore.clinicalStore.setADSMQuestionnaires(newQuestionnaires)
        this.rootStore.clinicalStore.setCurrentADSMQuestionnairePosition(0)
        this.rootStore.applicationStore.setTotalProgressBars(
          PROGRESS_BARS_WITHOUT_ADSM + newQuestionnaires.length
        )
        return {
          body: this.t(
            "Based on the information you have provided so far, we would like to ask you some more questions tailored to your symptoms"
          ),
          nextStep: this.handleAdditionalQuestionnaires
        }
      }

      if ([ADSMStatus.RequestFailed, ADSMStatus.NoInternetConnection].includes(status)) {
        this.track(TrackingEvents.ADSM_DEACTIVATED_REQUEST_ERROR)
        this.clinicalStore.disableADSM()
        shouldRetroActivelyIncludeScreeningQuestions = true
        void this.referralStore.updateReferral({
          adsm: {
            selectedAsParticipant: false,
            deactivatedAsParticipantReason: "adsmTriage_request_failed"
          }
        })
      }
    }
    this.rootStore.applicationStore.setTotalProgressBars(0)
    if (shouldRetroActivelyIncludeScreeningQuestions) {
      // When we end up not doing the ADSM flow, we need to use
      // the screening questions to determine the clinical groups
      // as if they were answered in the non ADSM assessment script
      this.retroActivelyIncludeScreeningQuestions(d.state)
    }

    return { nextStep: this.checkMostAffectingPrimary }
  }

  async adsmTriage(
    signupCode: string
  ): Promise<[Array<keyof Questionnaires> | undefined, ADSMStatus]> {
    // the purpose of doing this is to be able to mock the adsmTriage
    // in tests without mocking the whole adsmTriage module and thus
    // making the tests able to run in parallel
    if (this.getFeatureFlag(FF.AC448_ADSM_ML_V2)) {
      const [newQuestionnaires, status] = await adsmTriage2(signupCode)
      return [newQuestionnaires, status]
    }
    const [newQuestionnaires, status] = await adsmTriage(signupCode)
    return [newQuestionnaires, status]
  }

  @step
  handleAdditionalQuestionnaires(_d: IStepData<State>): IStepResult {
    const adsmQuestionnaires = this.rootStore.clinicalStore.adsmQuestionnaires
    const currentIndex = this.rootStore.clinicalStore.currentADSMQuestionnairePosition!
    const nextQuestionnaire = adsmQuestionnaires?.[currentIndex]
    if (nextQuestionnaire) {
      this.rootStore.applicationStore.setCurrentProgressBar(
        PROGRESS_BARS_WITHOUT_ADSM + currentIndex + 1
      )
      this.rootStore.applicationStore.setCurrentProgress(0)
      this.rootStore.clinicalStore.setCurrentADSMQuestionnairePosition(currentIndex + 1)

      if (nextQuestionnaire === "PCL5") return { nextStep: this.askPTSD }
      if (nextQuestionnaire === "OCI") return { nextStep: this.goToOCI }
      if (nextQuestionnaire === "SCOFF") return { nextStep: this.goToSCOFF }
      if (nextQuestionnaire === "PDSS") return { nextStep: this.goToPDSS }
      if (nextQuestionnaire === "SPECIFIC_PHOBIA") return { nextStep: this.goToSpecificPhobia }
      if (nextQuestionnaire === "SPIN") return { nextStep: this.goToSPIN }
      if (nextQuestionnaire === "SHAI") return { nextStep: this.goToSHAI18 }
    }

    this.rootStore.applicationStore.setTotalProgressBars(0)
    return { nextStep: this.checkMostAffectingPrimary }
  }

  @step.logState
  askPTSD(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Have you experienced a traumatic event involving actual or threatened death, serious injury, or sexual violence (to yourself, a close friend or family member, or that you have witnessed)?"
      ),
      prompt: {
        id: this.getPromptId("askPTSD"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handlePTSD
    }
  }

  @step.logStateAndResponse
  handlePTSD(d: IStepData<State, boolean>): IStepResult {
    d.state.pcl5Responses = [
      {
        id: "0",
        title:
          "Have you experienced a traumatic event involving actual or threatened death, serious injury, or sexual violence (to yourself, a close friend or family member, or that you have witnessed)?",
        answer: d.response ? "Yes" : "No"
      }
    ]
    if (!d.response) {
      void this.sendPCL5(d.state)
      return { nextStep: this.handleAdditionalQuestionnaires }
    }
    return { nextStep: this.goToPCL5 }
  }

  @step.logState
  handleMostAffectingPrimary(d: IStepData<State, ProblemCategories | "none">): IStepResult {
    const primaries = this.rootStore.clinicalStore.primaryProblems.slice()
    const selectedPrimary = d.response === "none" ? undefined : d.response

    if (selectedPrimary) {
      this.referralStore.setCustomField("mostAffectingPrimary", selectedPrimary)
    }
    this.addMostAffectingPrimaryClinicalNote(primaries, selectedPrimary)
    return { nextStep: this.askWhichPrimaryCameFirst }
  }

  @step.logState
  askWhichPrimaryCameFirst(_d: IStepData<State>): IStepResult {
    const primaries: ISelectable[] = this.rootStore.clinicalStore.primaryProblems //
      .map(name => ({
        body: this.t(problemCategoryUserFriendlyNames[name]?.toLocaleLowerCase()),
        value: name
      }))
    return {
      body: this.t("Which symptoms did you notice first?"),
      prompt: {
        id: this.getPromptId("askWhichPrimaryCameFirst"),
        trackResponse: true,
        type: "inlinePicker",
        choices: primaries.concat({ body: this.t("I don't know"), value: "none" }).map(primary => ({
          body: capitaliseFirst(primary.body),
          value: primary.value
        })),
        dataPointsName: "askWhichPrimaryCameFirst"
      },
      nextStep: this.handleWhichPrimaryCameFirst
    }
  }

  @step.logState
  handleWhichPrimaryCameFirst(d: IStepData<State, ProblemCategories | "none">): IStepResult {
    const primaries = this.rootStore.clinicalStore.primaryProblems.slice()
    const secondaries = this.rootStore.clinicalStore.secondaryProblems.slice()
    const selectedPrimary = d.response === "none" ? undefined : d.response

    this.addWhichPrimaryCameFirstClinicalNote(selectedPrimary)

    const mostAffectingPrimary = this.referralStore.getCustomField("mostAffectingPrimary")

    if ((selectedPrimary || mostAffectingPrimary) && primaries.length > 1) {
      const newPrimary = mostAffectingPrimary || selectedPrimary
      const newPrimaries = [newPrimary]
      const newSecondaries = [...new Set([...secondaries, ...primaries])] //
        .filter(c => c !== newPrimary)
      this.rootStore.clinicalStore.setPrimaries(newPrimaries)
      this.rootStore.clinicalStore.setSecondaries(newSecondaries)
      this.logBreadcrumb("Which PD came first answered", d.state, {
        ProblemCategories,
        primaryProblems: this.clinicalStore.primaryProblems,
        secondaryProblems: this.clinicalStore.secondaryProblems,
        flags: this.clinicalStore.flags,
        response: d.response
      })
      this.logMessage("Problem Descriptors Changed")
    }

    return { nextStep: this.finishAssessment }
  }

  /** Generic Handlers */

  onCheckOutcomes(_state: State): void {
    this.rootStore.applicationStore.setCurrentProgress(1)
  }

  //👇these onHandle methods are overridden to not add
  //  primary problems from any screening questions
  onHandleSocialPhobiaScore(state: State): void {
    const socialPhobiaScore = this.getSocialPhobiaScore(state)!
    if (socialPhobiaScore >= 4) state.hasSocialPhobia = true
  }

  onHandleAnxietyNBI(state: State, hasAnxietyNBI: boolean): void {
    state.hasSpecificPhobiaNBI = hasAnxietyNBI
    state.hasSpecificPhobia = !hasAnxietyNBI
  }

  onHandleAnxietyPhysicalSensations(state: State, hasAnxietyPhysicalSensations: boolean): void {
    if (hasAnxietyPhysicalSensations) state.hasPanic = true
  }

  onHandleAnxietyExtras(state: State, anxietyExtras: "health" | "ocd" | "ptsd" | "nos"): void {
    if (anxietyExtras === "health") state.hasHealthAnxiety = true
    if (anxietyExtras === "ocd") state.hasOCD = true
    if (anxietyExtras === "ptsd") state.hasPTSD = true
  }

  retroActivelyIncludeScreeningQuestions(state: State): void {
    if (state.hasSocialPhobia) this.clinicalStore.addPrimaryProblem(ProblemCategories.SocialPhobia)
    if (state.hasSpecificPhobiaNBI)
      this.clinicalStore.addPrimaryProblem(ProblemCategories.SpecificPhobiaNBI)
    if (state.hasSpecificPhobia)
      this.clinicalStore.addPrimaryProblem(ProblemCategories.SpecificPhobia)
    if (state.hasPanic) this.clinicalStore.addPrimaryProblem(ProblemCategories.Panic)
    if (state.hasHealthAnxiety)
      this.clinicalStore.addPrimaryProblem(ProblemCategories.HealthAnxiety)
    if (state.hasOCD) this.clinicalStore.addPrimaryProblem(ProblemCategories.OCD)
    if (state.hasPTSD) this.clinicalStore.addPrimaryProblem(ProblemCategories.PTSD)
    this.logBreadcrumb("Retroactively Including screening questions", state, {
      ProblemCategories,
      primaryProblems: this.clinicalStore.primaryProblems,
      secondaryProblems: this.clinicalStore.secondaryProblems,
      flags: this.clinicalStore.flags
    })
    this.logMessage("Problem Descriptors Changed")
  }

  async onHandlePCL5(state: State): Promise<IStepResult | undefined> {
    return { nextStep: this.handleAdditionalQuestionnaires }
  }

  async onHandleOCI(state: State): Promise<IStepResult | undefined> {
    return { nextStep: this.handleAdditionalQuestionnaires }
  }

  async onHandleSCOFF(state: State): Promise<IStepResult | undefined> {
    return { nextStep: this.handleAdditionalQuestionnaires }
  }

  async onHandlePDSS(state: State): Promise<IStepResult | undefined> {
    return { nextStep: this.handleAdditionalQuestionnaires }
  }

  async onHandleSpecificPhobiaQuestionnaire(state: State): Promise<IStepResult | undefined> {
    return { nextStep: this.handleAdditionalQuestionnaires }
  }

  async onHandleSPIN(state: State): Promise<IStepResult | undefined> {
    return { nextStep: this.handleAdditionalQuestionnaires }
  }

  async onHandleSHAI18(state: State): Promise<IStepResult | undefined> {
    return { nextStep: this.handleAdditionalQuestionnaires }
  }
}
