import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { PermissionsIAPTScript } from "./PermissionsIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { PermissionsScriptState } from "./PermissionsScript"

type State = PermissionsScriptState
export type PermissionsDorkingScriptState = State

export class PermissionsDorkingScript extends PermissionsIAPTScript {
  readonly name: string = "PermissionsDorkingScript"

  /** Script Steps */

  @step.logState
  showTOSLinks(_d: IStepData<State>): IStepResult {
    const organisationName =
      this.rootStore.configStore.organisationName ?? this.rootStore.configStore.serviceName
    const organisationTerms = this.rootStore.configStore.organisationTerms ?? ""
    return {
      body: this.t(
        [
          "To get you the best referral, I will need to share your answers with {organisationName}",
          "I want to reassure you that your details will be stored safely and kept confidential",
          "You can find all the details here:\n\n" +
            "Limbic [Terms of Service](https://www.limbic.ai/terms-of-use)\n" +
            "Limbic [Privacy Policy](https://www.limbic.ai/privacy)\n{organisationTerms}",
          "When you feel low in mood and anxious, prioritising yourself can be difficult to do. Research shows the importance of attending regular sessions to ensure you get the most from the support you receive, so it is really important that you make a commitment to this process. Missed and cancelled appointments are very costly to the NHS and detrimental to your care",
          "We ask that you give us at least 48 hours notice if you need to reschedule or cancel your appointment and you contact the service as soon as you can if you have an emergency. DHC Talking Therapies cannot replace multiple missed or rescheduled appointments"
        ],
        { organisationName, organisationTerms }
      ),
      nextStep: this.promptIUnderstandTerms
    }
  }
}

/* istanbul ignore next */
export default class PermissionsDorkingDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsDorking
  readonly name: string = "PermissionsDorkingDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsDorkingDialogue.id, new PermissionsDorkingScript(), state, snapshot)
  }
}
