import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import moment from "moment"

type State = RiskPathwayScriptState
export type RiskPathwayVitalityScriptState = State

export class RiskPathwayVitalityScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayVitalityScript"

  /** Script Steps */

  @step.logState
  step1(d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are you able to keep yourself, and any dependants in your care, safe?"),
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe",
        isUndoAble: false
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canKeepSelfSafe = d.response
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const serviceName = this.rootStore.configStore.serviceName

    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However, this is not an emergency response service",
          "There are alternative services that are better placed to support you",
          "Call the NHS helpline on 111 and select the option for mental health support. This helpline is open 24 hours a day, 7 days a week",
          "If you need urgent, life threatening medical help please call [999](tel:999)",
          "One of {serviceName}'s clinical team will contact you within the next working day to talk through these thoughts with you (This team operates Monday to Friday)"
        ],
        { name, serviceName }
      ),
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }],
        dataPointsName: "sayCrisis"
      },
      nextStep: this.sayThanksLetContinue
    }
  }

  @step.logState
  sayThanksLetContinue(d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField("crisisNumbersShared", "999 and 111")

    const name = this.getName(d.state)
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: this.t(
        [
          "I really appreciate you sharing these thoughts with me, {name}",
          "I'll continue with the digital assessment, but if you do need to stop at any time, that's fine (someone from {serviceName} will be in touch regardless)"
        ],
        { name, serviceName }
      ),
      prompt: {
        id: this.getPromptId("sayThanksLetContinue"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }],
        dataPointsName: "sayThanksLetContinue"
      },
      nextStep: this.sayClosingMessage
    }
  }

  @step.logState
  sayThanksForSharing(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t("I really appreciate you sharing these thoughts with me, {name}", { name }),
      nextStep: this.sayClosingMessage
    }
  }

  @step.logState
  sayClosingMessage(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Okay..."),
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.sayThanksForSharing }
  }

  getPersonalInfoHTML(state: State): string {
    // prettier-ignore
    return ([] as Array<string | false | undefined>)
      .concat(
        `<b>Name:</b> ${state.username}<br/>`,
        !!state.birthday && `<b>Date of Birth:</b> ${moment(state.birthday).format("DD/MM/YYYY")}<br/>`,
        `<b>Phone number:</b> ${state.phoneNumber || "N/A"}<br/>`,
      )
      .filter(Boolean)
      .join("\n")
  }

  getClinicalNotesHTML(_state: State): string {
    return ""
  }

  getQuestionnairesInfoHTML(_state: State): string {
    return ""
  }

  getAdditionalInfoHTML(state: State): string {
    const triggerWords = this.clinicalStore.triggerWords?.length
      ? `<b>Crisis Trigger Words:</b> ${this.clinicalStore.triggerWords?.join(", ")}<br/>`
      : ""
    const triggerWordsPhrase =
      this.clinicalStore.triggerWords?.length && this.clinicalStore.triggerWordsPhrase //
        ? `<b>User Input With Trigger Words:</b> ${this.clinicalStore.triggerWordsPhrase}<br/>`
        : ""
    return `
      ${triggerWords}
      ${triggerWordsPhrase}
    `
  }
}

/* istanbul ignore next */
export default class RiskPathwayVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayVitality
  readonly name: string = "RiskPathwayVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayVitalityDialogue.id, new RiskPathwayVitalityScript(), state, snapshot)
  }
}
