import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import {
  IDefaultChatFlowMessagesCheckCrisisDetection,
  IDefaultChatFlowSettingsCheckCrisisDetection
} from "@limbic/types"
import { step } from "../../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import formatUnicorn from "../../../../utils/formatUnicorn"
import { joinWithAnd } from "../../../../utils/array"
import { RiskLevelReason } from "../../../../models/Constants"
import CrisisDetector from "../../../../models/CrisisDetector"

export type ICheckCrisisDetectionSettings = IDefaultChatFlowSettingsCheckCrisisDetection & {
  messages?: IDefaultChatFlowMessagesCheckCrisisDetection
}

interface State extends BaseScriptState {
  disableDetectionIfWrong?: boolean
}

export type CheckCrisisDetectionScriptState = State

export class CheckCrisisDetectionScript extends BaseScript<State> {
  readonly name: string = "CheckCrisisDetectionScript"
  protected trigger: string
  protected readonly messages: IDefaultChatFlowMessagesCheckCrisisDetection | undefined
  protected readonly crisisNumbersShared?: string

  constructor(settings?: ICheckCrisisDetectionSettings | undefined) {
    super()
    this.trigger = joinWithAnd(this.clinicalStore.triggerWords?.filter(Boolean).map(w => `"${w}"`))
    this.messages = settings?.messages ?? {}
    this.crisisNumbersShared =
      settings?.crisisNumbersShared ??
      "NHS 111, 999, First Response (0800 9521181), Samaritans, Shout"
  }

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    this.setCrisisDetected(d.state)
    return { nextStep: this.sayTriggerWordsDetected }
  }

  @step.logState
  sayTriggerWordsDetected(d: IStepData<State>): IStepResult {
    let body = [`I noticed that you wrote the words ${this.trigger}`]
    if (this.messages?.sayTriggerWordsDetected && this.messages?.sayTriggerWordsDetected.length) {
      body = this.messages?.sayTriggerWordsDetected.map(message =>
        formatUnicorn(message, this.getContext(d.state))
      )
    }
    return {
      body: this.t(body, this.getContext(d.state)),
      nextStep: this.askAreYouInCrisis
    }
  }

  @step
  askAreYouInCrisis(d: IStepData<State>): IStepResult {
    let body = [
      "This has made me think you might be at immediate risk of harm or in a life threatening urgent situation",
      "Is this true?"
    ]
    if (this.messages?.askAreYouInCrisis && this.messages?.askAreYouInCrisis.length) {
      body = this.messages?.askAreYouInCrisis.map(message =>
        formatUnicorn(message, this.getContext(d.state))
      )
    }
    return {
      body: this.t(body, this.getContext(d.state)),
      prompt: {
        id: this.getPromptId("askAreYouInCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askAreYouInCrisis"
      },
      nextStep: this.handleAreYouInCrisis
    }
  }

  @step
  async handleAreYouInCrisis(d: IStepData<State, boolean>): Promise<IStepResult> {
    this.clinicalStore.setIsCrisis(d.response)
    this.setCrisisDetectionCorrect(d.state, d.response)

    if (d.response) {
      this.setRiskLevelHigh(d.state, RiskLevelReason.CRISIS_DETECTION)
      this.referralStore
        .updateReferral({
          riskLevel: this.clinicalStore.riskLevel,
          riskLevelReason: this.clinicalStore.riskLevelReason ?? "",
          triggerWords: this.clinicalStore.triggerWords
        })
        .catch(e => this.logException(e, "handleAreYouInCrisis -> updateReferral"))
      this.updateReferralType(d.state)
    }

    if (!d.response && d.state.disableDetectionIfWrong) {
      CrisisDetector.getInstance().disableCrisisDetectionForNextInput()
    }

    return {
      nextStep: d.response //
        ? this.saySorryToHear
        : this.saySorryForTheMisunderstanding
    }
  }

  @step.logState
  saySorryForTheMisunderstanding(d: IStepData<State>): IStepResult {
    let body = [
      "Okay",
      "My creators have taught me to listen carefully for specific words or phrases",
      "In case you need help",
      "Sorry for the misunderstanding",
      "Ok, let's carry on with the mental health check in"
    ]
    if (
      this.messages?.saySorryForTheMisunderstanding &&
      this.messages?.saySorryForTheMisunderstanding.length
    ) {
      body = this.messages?.saySorryForTheMisunderstanding.map(message =>
        formatUnicorn(message, this.getContext(d.state))
      )
    }
    return {
      body: this.t(body, this.getContext(d.state)),
      nextStep: this.end
    }
  }

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    let body = [
      `Sorry to hear that ${name}`,
      "It is normal for people to have thoughts of this nature at times",
      "However, this is not an emergency response service"
    ]
    if (this.messages?.saySorryToHear && this.messages?.saySorryToHear.length) {
      body = this.messages?.saySorryToHear.map(message =>
        formatUnicorn(message, this.getContext(d.state))
      )
    }
    return {
      body: this.t(body, this.getContext(d.state)),
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(d: IStepData<State>): IStepResult {
    let body = [
      "You can call First Response, our 24/7 crisis service at 0800 9521181",
      "If you need urgent support, please dial NHS 111 and select Option 2",
      "In medical emergency and life threatening situations only, please dial 999, or attend your local A&E department",
      "Other helplines available:\n" +
        "You can contact Samaritans 24 hours a day, 365 days a year. You can call 116 123 (free from any phone) or email jo@samaritans.org\n" +
        "If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258. Shout offers a confidential 24/7 service providing support if you are in need of immediate help"
    ]
    if (this.messages?.sayCrisisNumbers && this.messages?.sayCrisisNumbers.length) {
      body = this.messages?.sayCrisisNumbers.map(message =>
        formatUnicorn(message, this.getContext(d.state))
      )
    }
    return {
      body: this.t(body, this.getContext(d.state)),
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      this.crisisNumbersShared ?? "NHS 111, 999, First Response (0800 9521181), Samaritans, Shout"
    )
    const organisationName = this.rootStore.configStore.organisationName
    let body = [
      "To recap:\n" +
        `We'll continue with the referral, however ${organisationName} does not provide urgent care. If you require immediate help, please contact one of the numbers listed above`
    ]
    if (this.messages?.recapMessage && this.messages?.recapMessage.length) {
      body = this.messages?.recapMessage.map(message =>
        formatUnicorn(message, this.getContext(d.state))
      )
    }
    return {
      /**
       * The following is needed, because:
       *  1. If user replies Yes in early crisis question
       *  2. User types crisis word in Feedback question
       *  3. Should not state "We'll continue with the referral..."
       *
       * TODO: More abstract solution would be to:
       *  - Include 2 customisable messages in the dashboard
       *    a. recapMessageCanRefer
       *    b. recapMessageCannotRefer
       *  - Show the message recapMessageCannotRefer if requiresUrgentSupport === true
       * */
      body: d.state.requiresUrgentSupport
        ? this.t(
            "If you require immediate help, please contact one of the numbers listed above",
            this.getContext(d.state)
          )
        : this.t(body, this.getContext(d.state)),
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getContext(state: State): Record<string, any> {
    return {
      ...this.rootStore.configStore,
      name: this.getName(state),
      trigger: this.trigger
    }
  }
}

export default class CheckCrisisDetectionDialogue extends AdHocDialogue<
  State,
  CheckCrisisDetectionScript
> {
  static id = DialogueIDs.CheckCrisisDetection
  readonly name: string = "CheckCrisisDetectionDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICheckCrisisDetectionSettings
  ) {
    super(
      CheckCrisisDetectionDialogue.id,
      new CheckCrisisDetectionScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
