import { step } from "../../../backend/chatbot/decorators/step"
import isValidPhoneNumber from "../../../utils/isValidPhoneNumber"
import { joinWithAnd } from "../../../utils/array"
import BaseScript, { BaseScriptState } from "../../BaseScript"
import getNextWorkingDay from "../../../utils/getNextWorkingDay"
import { RiskLevelReason } from "../../../models/Constants"
import CrisisDetector from "../../../models/CrisisDetector"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { LanguageCodes } from "@limbic/types"

interface State extends BaseScriptState {
  disableDetectionIfWrong?: boolean
}
export type CrisisScriptState = State

export default abstract class CrisisScript extends BaseScript<State> {
  /** Optional Abstract Generic Handlers */

  onHandleAreYouInCrisis?(state: State): Promise<IStepResult<State> | void>
  onHandleSayInstructions?(state: State): Promise<IStepResult | void>
  onHandlePhoneNumber?(state: State): Promise<IStepResult<State> | void>

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    this.setCrisisDetected(d.state)
    return { nextStep: this.checkDetectedCrisis }
  }

  @step.logState
  checkDetectedCrisis(d: IStepData<State>): IStepResult {
    const hasUserInput = !!d.state.userInput
    const hasTriggerWords = Number(this.clinicalStore.triggerWords?.filter(Boolean).length) > 0
    if (hasUserInput && !hasTriggerWords) {
      return { nextStep: this.sayUserInputIsCrisis }
    }
    return { nextStep: this.sayTriggerWordsDetected }
  }

  @step.logState
  sayTriggerWordsDetected(_d: IStepData<State>): IStepResult {
    const trigger = joinWithAnd(
      this.clinicalStore.triggerWords?.filter(Boolean).map(w => `"${w}"`),
      this.t("and")
    )
    return {
      body: this.t("I noticed that you wrote the words {trigger}", { trigger }),
      nextStep: this.askAreYouInCrisis
    }
  }

  @step.logState
  sayUserInputIsCrisis(d: IStepData<State>): IStepResult {
    return {
      body: this.t('I noticed you answered "{userInput}" to that last question', {
        userInput: d.state.userInput
      }),
      nextStep: this.sayExplanation
    }
  }

  @step
  sayExplanation(d: IStepData<State>): IStepResult {
    this.clinicalStore.setIsCrisis(true)
    this.setCrisisDetectionCorrect(d.state, true)
    const body = ([] as string[]).concat(
      d.state.userInputExplanation!,
      "However, your safety is my number one priority. And this is not an emergency response service.",
      "I'm therefore going to ask someone from our clinical team to ring you to discuss this matter with you directly"
    )
    return {
      body: this.t(body),
      nextStep: d.state.phoneNumber ? this.theyllCallYou : this.askPhoneNumber
    }
  }

  @step
  askAreYouInCrisis(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "This has made me think you might be at immediate risk of harm or in a life threatening urgent situation",
        "Is this true?"
      ]),
      prompt: {
        id: this.getPromptId("askAreYouInCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askAreYouInCrisis"
      },
      nextStep: this.handleAreYouInCrisis
    }
  }

  @step
  async handleAreYouInCrisis(d: IStepData<State, boolean>): Promise<IStepResult> {
    this.clinicalStore.setIsCrisis(d.response)
    this.setCrisisDetectionCorrect(d.state, d.response)

    if (d.response) {
      this.setRiskLevelHigh(d.state, RiskLevelReason.CRISIS_DETECTION)
      this.referralStore
        .updateReferral({
          riskLevel: this.clinicalStore.riskLevel,
          riskLevelReason: this.clinicalStore.riskLevelReason ?? "",
          triggerWords: this.clinicalStore.triggerWords
        })
        .catch(e => this.logException(e, "handleAreYouInCrisis -> updateReferral"))
      this.updateReferralType(d.state)
    }

    const result = await this.onHandleAreYouInCrisis?.(d.state)
    if (result) return result

    if (!d.response && d.state.disableDetectionIfWrong) {
      CrisisDetector.getInstance().disableCrisisDetectionForNextInput()
    }
    return {
      nextStep: d.response //
        ? this.saySorryToHear
        : this.saySorryForTheMisunderstanding
    }
  }

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However, this is not an emergency response service"
        ],
        { name }
      ),
      nextStep: this.sayIllAskSomeoneToCallYou
    }
  }

  @step
  sayIllAskSomeoneToCallYou(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Your safety is my top priority, so I'm going to ask someone from our clinical team to ring you to discuss this matter with you directly"
      ),
      nextStep: d.state.phoneNumber ? this.theyllCallYou : this.askPhoneNumber
    }
  }

  @step
  async theyllCallYou(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.phoneNumber) {
      return { nextStep: this.askPhoneNumber }
    }

    const nextWorkingDay = await getNextWorkingDay()
    return {
      body: this.t(
        this.rootStore.applicationStore.translator?.language === LanguageCodes.EN
          ? "They'll call you {nextWorkingDay} on the number you provided earlier ({phoneNumber})"
          : "They'll call you within the next working day on the number you provided earlier ({phoneNumber})",
        { nextWorkingDay, phoneNumber: d.state.phoneNumber }
      ),
      prompt: {
        id: this.getPromptId("theyllCallYou"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: true },
          { body: this.t("Change number"), value: false }
        ]
      },
      nextStep: this.handleTheyllCallYou
    }
  }

  @step.logStateAndResponse
  handleTheyllCallYou(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.sayInstructions }
    }
    return { nextStep: this.askPhoneNumber }
  }

  @step.logState
  askPhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("What's the best phone number to call you on?"),
      prompt: {
        id: this.getPromptId("askPhoneNumber"),
        type: "phoneNumber"
      },
      nextStep: this.handlePhoneNumber
    }
  }

  @step.logState
  async handlePhoneNumber(d: IStepData<State, string>): Promise<IStepResult> {
    const isValid = isValidPhoneNumber(d.response)
    if (!isValid) {
      return {
        body: this.t("Sorry this is not a valid phone number. Let's try again"),
        nextStep: this.askPhoneNumber
      }
    }
    d.state.phoneNumber = d.response
    this.referralStore.setCustomField("phoneNumber", d.response)
    const result = await this.onHandlePhoneNumber?.(d.state)
    if (result) return result
    const nextWorkingDay = await getNextWorkingDay()
    return {
      body: this.t(
        [
          "Thanks",
          this.rootStore.applicationStore.translator?.language === LanguageCodes.EN
            ? "Someone from our clinical team will call you {nextWorkingDay} on ({phoneNumber})"
            : "Someone from our clinical team will call you within the next working day on ({phoneNumber})"
        ],
        { nextWorkingDay, phoneNumber: d.state.phoneNumber }
      ),
      nextStep: this.sayInstructions
    }
  }

  // TODO: the email sending part needs to be extracted into a step
  //       that subclasses will extend.
  //       Something like an onCrisisDetectionFinished
  @step
  async sayInstructions(d: IStepData<State>): Promise<IStepResult> {
    return {
      body: this.t([
        "In the meantime, if you feel that you or someone you know is in danger, please call 999 immediately",
        "Further support is also provided by the Samaritans, available anytime by calling: 116 123"
      ]),
      prompt: {
        id: this.getPromptId("sayInstructions"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }, { body: this.t("I understand") }]
      },
      nextStep: this.handleSayInstructions
    }
  }

  @step.logState
  async handleSayInstructions(d: IStepData<State>): Promise<IStepResult> {
    this.referralStore.setCustomField("crisisNumbersShared", "999 and The Samaritans")
    const result = await this.onHandleSayInstructions?.(d.state)
    if (result) return result
    return { nextStep: this.end }
  }

  @step.logState
  saySorryForTheMisunderstanding(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Okay",
        "My creators have taught me to listen carefully for specific words or phrases",
        "In case you need help",
        "Sorry for the misunderstanding"
      ]),
      nextStep: this.end
    }
  }

  /** Generic Handlers */
}
