import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = CrisisScriptState
export type CrisisDorkingScriptState = State

export class CrisisDorkingScript extends CrisisScript {
  readonly name: string = "CrisisDorkingScript"

  /** Script Steps */

  @step
  saySorryToHear(_d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: [
        `Thank you for sharing this information`,
        "Sometimes people have thoughts of harming themselves or that life isn’t worth living. If you are concerned about your safety, it is important that you talk to someone, support is available",
        `However ${serviceName} is not an emergency response service`,
        `If you need urgent support, please dial NHS 111 and select Option 2`
      ],
      prompt: {
        id: this.getPromptId("saySorryToHear"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName

    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111 (Option 2), NHS (07717 989 024), Local (0800 145 6485), Samaritans (116 123), SHOUT (85258)"
    )

    return {
      body: [
        "You could also visit a local 'safe haven' for support. [Where to find your local safe haven?](https://www.sabp.nhs.uk/our-services/mental-health/safe-havens)",
        "If you are hard of hearing, have speech difficulties or prefer not to talk over the phone, you can contact the following organisations for support via text message:\n\n" +
          "NHS Mental Health Crisis Line – text service available on 07717 989 024. If you have speech or hearing difficulties, this service can be accessed by dialling through the BT Relay app or textphone: 18001 0800 915 4644 [More info here](https://www.relayuk.bt.com/)\n\n" +
          "Shout – text shout to 85258 for 24/7 free support\n\n" +
          "For immediate support if you cannot keep yourself safe text 999 if you have registered your phone, or go to A&E",
        "Other helplines available:\n\n" +
          "You can contact Samaritans 24 hours a day, 365 days a year on 116 123 (free from any phone) or email jo@samaritans.org\n\n" +
          `We’ll continue with the referral, however ${serviceName} does not provide urgent care. If you require immediate help, please contact one of the numbers listed above`
      ],
      nextStep: this.end
    }
  }

  @step.logState
  saySorryForTheMisunderstanding(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Okay",
        "My creators have taught me to listen carefully for specific words or phrases",
        "In case you need help",
        "Sorry for the misunderstanding",
        "Ok, let's carry on with the mental health check in"
      ],
      nextStep: this.end
    }
  }
}

/* istanbul ignore next */
export default class CrisisDorkingDialogue extends AdHocDialogue<State, CrisisDorkingScript> {
  static id = DialogueIDs.CrisisDorking
  readonly name: string = "CrisisDorkingDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisDorkingDialogue.id, new CrisisDorkingScript(), state, snapshot)
  }
}
