import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class AddressPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.CHECK_POSTCODE_FROM_ADDRESS_LOOKUP> {
  chatFlow = ChatFlowsEnum.CHECK_POSTCODE_FROM_ADDRESS_LOOKUP

  /** Overrides */

  getDefaultPayload(): Partial<ReferralPayload> {
    return {
      addressHome: {
        address1: this.ctx.state?.address,
        address2: this.ctx.state?.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: this.ctx.state?.city || "unknown",
        county: this.ctx.state?.county || "unknown",
        postcode:
          this.ctx.state?.userPostcode?.postcode ||
          this.ctx.state?.invalidPostcodeEntered ||
          "unknown",
        // Some services might skip the consent question from
        // the preset flow, in which cases it should be fine to
        // assume a default value of false. If they skip it so
        // that they ask it in their own custom question, then
        // we should be able to map it with the boolean target
        // key of `addressHome.consentMail`
        consentMail: !!this.ctx.state?.canSendMailToAddress
      }
    } as Partial<ReferralPayload>
  }
}

// prettier-ignore
ChatFlowPayloadBuilder.builders[ChatFlowsEnum.CHECK_POSTCODE_FROM_ADDRESS_LOOKUP] = AddressPayloadBuilder
