import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class NHSNumberPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_NHS_NUMBER> {
  chatFlow = ChatFlowsEnum.COLLECT_NHS_NUMBER

  /** Overrides */

  getDefaultPayload(): Partial<ReferralPayload> {
    return { nhsNumber: this.ctx.state?.nhsNumber } as Partial<ReferralPayload>
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_NHS_NUMBER] = NHSNumberPayloadBuilder
