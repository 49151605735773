import {
  ACCESS_INSTANCES,
  BigBotConfiguration,
  IChatBotFlow,
  IIAPTBotService,
  IKeyMappingConfig,
  LanguageCodes
} from "@limbic/types"
import { observable } from "mobx"
import Loggable from "../../models/Loggable"
import type { IAppLaunchConfigOptions } from "../../models/IAppLaunchConfig"
import type { IAPTCCGMap, IAPTGPMap, IIAPTService } from "../../models/IIAPTService"
import { Translations } from "../../i18n/Languages"

export default class ConfigStore extends Loggable {
  readonly name: string = "ConfigStore"
  directReferral?: boolean
  // TODO: this 👇 needs to be optional
  eligibleIAPTIds!: IIAPTService["id"][]
  iaptCCGMap?: IAPTCCGMap
  iaptGPMap?: IAPTGPMap
  key!: string
  dashboardServiceKey?: string
  backendInstanceID?: keyof typeof ACCESS_INSTANCES
  wellbeingHubEmails?: string[]
  freetextLimit?: number
  ageThreshold?: number
  adsmEnabled?: boolean
  dialogueFlows?: IChatBotFlow
  backendMapping?: IKeyMappingConfig
  directSubmission?: boolean
  customIAPTS?: IIAPTBotService[]
  customBigBot?: BigBotConfiguration
  translations?: Translations
  defaultLanguage?: LanguageCodes
  supportedLanguages?: LanguageCodes[]

  @observable fullscreen?: boolean
  @observable logo!: string
  @observable title!: string
  @observable userMessageBackground?: string
  @observable serviceName?: string
  @observable organisationName?: string | undefined
  @observable organisationPhoneNumbers?: string
  @observable organisationTerms?: string
  @observable organisationGenericPhoneNumber?: string
  @observable crisisPhoneNumbers?: string
  @observable emails?: string[]
  @observable enableParams?: boolean
  @observable iaptIcons?: string[]
  @observable faqLink!: string
  @observable formLink?: string
  @observable enableSOS?: boolean

  constructor() {
    super()
    this.reset()
  }

  reset(): void {
    this.key = ""
    this.directReferral = false
    this.backendInstanceID = undefined
    this.dashboardServiceKey = undefined
    this.logo = "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png"
    this.title = "Limbic Assistant"
    this.serviceName = undefined
    this.organisationName = undefined
    this.organisationTerms = undefined
    this.organisationPhoneNumbers = undefined
    this.organisationGenericPhoneNumber = undefined
    this.emails = undefined
    this.userMessageBackground = "#6A6983"
    this.fullscreen = false
    this.iaptIcons = []
    this.formLink = undefined
    this.enableParams = false
    this.faqLink = "https://limbic.ai/faq"
    this.eligibleIAPTIds = []
    this.freetextLimit = 0
    this.ageThreshold = 18
    this.enableSOS = false
    this.adsmEnabled = false
    this.dialogueFlows = {}
    this.directSubmission = false
    this.customIAPTS = undefined
    this.customBigBot = undefined
    /**
     * 👇 Initialize to undefined so we can know if it was defined by the dashboard
     * */
    this.translations = {}
    this.defaultLanguage = LanguageCodes.EN
    this.supportedLanguages = []
  }

  setConfig(key: string, config: IAppLaunchConfigOptions): void {
    this.overrideConfigByKey("key", key)
      .overrideConfigByKey("directReferral", config.directReferral)
      .overrideConfigByKey("logo", config.logo)
      .overrideConfigByKey("title", config.title)
      .overrideConfigByKey("backendInstanceID", config.backendInstanceID)
      .overrideConfigByKey("dashboardServiceKey", config.dashboardServiceKey)
      .overrideConfigByKey("serviceName", config.serviceName)
      .overrideConfigByKey("organisationName", config.organisationName)
      .overrideConfigByKey("organisationTerms", config.organisationTerms)
      .overrideConfigByKey("organisationPhoneNumbers", config.organisationPhoneNumbers)
      .overrideConfigByKey("crisisPhoneNumbers", config.crisisPhoneNumbers)
      .overrideConfigByKey("organisationGenericPhoneNumber", config.organisationGenericPhoneNumber)
      .overrideConfigByKey("emails", config.emails)
      .overrideConfigByKey("userMessageBackground", config.userMessageBackground)
      .overrideConfigByKey("fullscreen", config.fullscreen ?? false)
      .overrideConfigByKey("iaptIcons", config.iaptIcons ?? [])
      .overrideConfigByKey("formLink", config.formLink ?? "")
      .overrideConfigByKey("enableParams", config.enableParams ?? false)
      .overrideConfigByKey("faqLink", config.faqLink ?? "")
      .overrideConfigByKey("eligibleIAPTIds", config.eligibleIAPTIds)
      .overrideConfigByKey("iaptCCGMap", config.iaptCCGMap ?? {})
      .overrideConfigByKey("iaptGPMap", config.iaptGPMap ?? {})
      .overrideConfigByKey("freetextLimit", config.freetextLimit)
      .overrideConfigByKey("ageThreshold", config.ageThreshold)
      .overrideConfigByKey("enableSOS", config.enableSOS)
      .overrideConfigByKey("adsmEnabled", config.adsmEnabled)
      .overrideConfigByKey("dialogueFlows", config.dialogueFlows)
      .overrideConfigByKey("backendMapping", config.backendMapping)
      .overrideConfigByKey("directSubmission", config.directSubmission)
      .overrideConfigByKey("customIAPTS", config.customIAPTS)
      .overrideConfigByKey("customBigBot", config.customBigBot)
      .overrideConfigByKey("translations", config.translations)
      .overrideConfigByKey("defaultLanguage", config.defaultLanguage)
      .overrideConfigByKey("supportedLanguages", config.supportedLanguages)
  }

  overrideConfigByKey<Key extends keyof this>(key: Key, value?: this[Key]): ConfigStore {
    this[key] = value ?? this[key]
    return this
  }
}
