import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

export const iaptGPsMapLWC: IAPTGPMap = {
  B5H6N: IAPTIDs.LWC_SERVICE_1,
  C4U1D: IAPTIDs.LWC_SERVICE_1,
  M81062: IAPTIDs.LWC_SERVICE_1,
  M85001: IAPTIDs.LWC_SERVICE_1,
  M85002: IAPTIDs.LWC_SERVICE_1,
  M85002001: IAPTIDs.LWC_SERVICE_1,
  M85005: IAPTIDs.LWC_SERVICE_1,
  M85006: IAPTIDs.LWC_SERVICE_1,
  M85007: IAPTIDs.LWC_SERVICE_1,
  M85008: IAPTIDs.LWC_SERVICE_1,
  M85009: IAPTIDs.LWC_SERVICE_1,
  M85009001: IAPTIDs.LWC_SERVICE_1,
  M85011: IAPTIDs.LWC_SERVICE_1,
  M85013: IAPTIDs.LWC_SERVICE_1,
  M85014: IAPTIDs.LWC_SERVICE_1,
  M85016: IAPTIDs.LWC_SERVICE_1,
  M85018: IAPTIDs.LWC_SERVICE_1,
  M85019: IAPTIDs.LWC_SERVICE_1,
  M85020: IAPTIDs.LWC_SERVICE_1,
  M85021: IAPTIDs.LWC_SERVICE_1,
  M85023: IAPTIDs.LWC_SERVICE_1,
  M85024: IAPTIDs.LWC_SERVICE_1,
  M85024002: IAPTIDs.LWC_SERVICE_1,
  M85025: IAPTIDs.LWC_SERVICE_1,
  M85026: IAPTIDs.LWC_SERVICE_1,
  M85027: IAPTIDs.LWC_SERVICE_1,
  M85028: IAPTIDs.LWC_SERVICE_1,
  M85028001: IAPTIDs.LWC_SERVICE_1,
  M85028002: IAPTIDs.LWC_SERVICE_1,
  M85029: IAPTIDs.LWC_SERVICE_1,
  M85030: IAPTIDs.LWC_SERVICE_1,
  M85031: IAPTIDs.LWC_SERVICE_1,
  M85033: IAPTIDs.LWC_SERVICE_1,
  M85033001: IAPTIDs.LWC_SERVICE_1,
  M85034: IAPTIDs.LWC_SERVICE_1,
  M85035: IAPTIDs.LWC_SERVICE_1,
  M85037: IAPTIDs.LWC_SERVICE_1,
  M85041: IAPTIDs.LWC_SERVICE_1,
  M85042: IAPTIDs.LWC_SERVICE_1,
  M85043: IAPTIDs.LWC_SERVICE_1,
  M85046: IAPTIDs.LWC_SERVICE_1,
  M85046001: IAPTIDs.LWC_SERVICE_1,
  M85046002: IAPTIDs.LWC_SERVICE_1,
  M85046003: IAPTIDs.LWC_SERVICE_1,
  M85046004: IAPTIDs.LWC_SERVICE_1,
  M85046005: IAPTIDs.LWC_SERVICE_1,
  M85047: IAPTIDs.LWC_SERVICE_1,
  M85048: IAPTIDs.LWC_SERVICE_1,
  M85048001: IAPTIDs.LWC_SERVICE_1,
  M85051: IAPTIDs.LWC_SERVICE_1,
  M85053: IAPTIDs.LWC_SERVICE_1,
  M85055: IAPTIDs.LWC_SERVICE_1,
  M85056: IAPTIDs.LWC_SERVICE_1,
  M85056001: IAPTIDs.LWC_SERVICE_1,
  M85058: IAPTIDs.LWC_SERVICE_1,
  M85060: IAPTIDs.LWC_SERVICE_1,
  M85060001: IAPTIDs.LWC_SERVICE_1,
  M85061: IAPTIDs.LWC_SERVICE_1,
  M85062: IAPTIDs.LWC_SERVICE_1,
  M85063: IAPTIDs.LWC_SERVICE_1,
  M85063001: IAPTIDs.LWC_SERVICE_1,
  M85063002: IAPTIDs.LWC_SERVICE_1,
  M85063003: IAPTIDs.LWC_SERVICE_1,
  M85063004: IAPTIDs.LWC_SERVICE_1,
  M85063005: IAPTIDs.LWC_SERVICE_1,
  M85063006: IAPTIDs.LWC_SERVICE_1,
  M85063007: IAPTIDs.LWC_SERVICE_1,
  M85063008: IAPTIDs.LWC_SERVICE_1,
  M85063010: IAPTIDs.LWC_SERVICE_1,
  M85065: IAPTIDs.LWC_SERVICE_1,
  M85066: IAPTIDs.LWC_SERVICE_1,
  M85069: IAPTIDs.LWC_SERVICE_1,
  M85070: IAPTIDs.LWC_SERVICE_1,
  M85071: IAPTIDs.LWC_SERVICE_1,
  M85074: IAPTIDs.LWC_SERVICE_1,
  M85076: IAPTIDs.LWC_SERVICE_1,
  M85077: IAPTIDs.LWC_SERVICE_1,
  M85077001: IAPTIDs.LWC_SERVICE_1,
  M85078: IAPTIDs.LWC_SERVICE_1,
  M85079: IAPTIDs.LWC_SERVICE_1,
  M85081: IAPTIDs.LWC_SERVICE_1,
  M85082: IAPTIDs.LWC_SERVICE_1,
  M85084: IAPTIDs.LWC_SERVICE_1,
  M85086: IAPTIDs.LWC_SERVICE_1,
  M85087: IAPTIDs.LWC_SERVICE_1,
  M85088: IAPTIDs.LWC_SERVICE_1,
  M85094: IAPTIDs.LWC_SERVICE_1,
  M85097: IAPTIDs.LWC_SERVICE_1,
  M85098: IAPTIDs.LWC_SERVICE_1,
  M85105: IAPTIDs.LWC_SERVICE_1,
  M85107: IAPTIDs.LWC_SERVICE_1,
  M85108: IAPTIDs.LWC_SERVICE_1,
  M85110: IAPTIDs.LWC_SERVICE_1,
  M85110001: IAPTIDs.LWC_SERVICE_1,
  M85113: IAPTIDs.LWC_SERVICE_1,
  M85115: IAPTIDs.LWC_SERVICE_1,
  M85116: IAPTIDs.LWC_SERVICE_1,
  M85117: IAPTIDs.LWC_SERVICE_1,
  M85118: IAPTIDs.LWC_SERVICE_1,
  M85123: IAPTIDs.LWC_SERVICE_1,
  M85124: IAPTIDs.LWC_SERVICE_1,
  M85128: IAPTIDs.LWC_SERVICE_1,
  M85134: IAPTIDs.LWC_SERVICE_1,
  M85136: IAPTIDs.LWC_SERVICE_1,
  M85136002: IAPTIDs.LWC_SERVICE_1,
  M85136003: IAPTIDs.LWC_SERVICE_1,
  M85139: IAPTIDs.LWC_SERVICE_1,
  M85141: IAPTIDs.LWC_SERVICE_1,
  M85142: IAPTIDs.LWC_SERVICE_1,
  M85143: IAPTIDs.LWC_SERVICE_1,
  M85145: IAPTIDs.LWC_SERVICE_1,
  M85146: IAPTIDs.LWC_SERVICE_1,
  M85149: IAPTIDs.LWC_SERVICE_1,
  M85153: IAPTIDs.LWC_SERVICE_1,
  M85154: IAPTIDs.LWC_SERVICE_1,
  M85155: IAPTIDs.LWC_SERVICE_1,
  M85156: IAPTIDs.LWC_SERVICE_1,
  M85158: IAPTIDs.LWC_SERVICE_1,
  M85164: IAPTIDs.LWC_SERVICE_1,
  M85164001: IAPTIDs.LWC_SERVICE_1,
  M85167: IAPTIDs.LWC_SERVICE_1,
  M85170: IAPTIDs.LWC_SERVICE_1,
  M85171: IAPTIDs.LWC_SERVICE_1,
  M85172: IAPTIDs.LWC_SERVICE_1,
  M85172001: IAPTIDs.LWC_SERVICE_1,
  M85174: IAPTIDs.LWC_SERVICE_1,
  M85174001: IAPTIDs.LWC_SERVICE_1,
  M85175: IAPTIDs.LWC_SERVICE_1,
  M85176: IAPTIDs.LWC_SERVICE_1,
  M85177: IAPTIDs.LWC_SERVICE_1,
  M85178: IAPTIDs.LWC_SERVICE_1,
  M85178003: IAPTIDs.LWC_SERVICE_1,
  M85179: IAPTIDs.LWC_SERVICE_1,
  M85600: IAPTIDs.LWC_SERVICE_1,
  M85600001: IAPTIDs.LWC_SERVICE_1,
  M85624: IAPTIDs.LWC_SERVICE_1,
  M85624001: IAPTIDs.LWC_SERVICE_1,
  M85634: IAPTIDs.LWC_SERVICE_1,
  M85642: IAPTIDs.LWC_SERVICE_1,
  M85669: IAPTIDs.LWC_SERVICE_1,
  M85670: IAPTIDs.LWC_SERVICE_1,
  M85676: IAPTIDs.LWC_SERVICE_1,
  M85679: IAPTIDs.LWC_SERVICE_1,
  M85680: IAPTIDs.LWC_SERVICE_1,
  M85684: IAPTIDs.LWC_SERVICE_1,
  M85686: IAPTIDs.LWC_SERVICE_1,
  M85693: IAPTIDs.LWC_SERVICE_1,
  M85694: IAPTIDs.LWC_SERVICE_1,
  M85697: IAPTIDs.LWC_SERVICE_1,
  M85699: IAPTIDs.LWC_SERVICE_1,
  M85701: IAPTIDs.LWC_SERVICE_1,
  M85706: IAPTIDs.LWC_SERVICE_1,
  M85706001: IAPTIDs.LWC_SERVICE_1,
  M85706002: IAPTIDs.LWC_SERVICE_1,
  M85706003: IAPTIDs.LWC_SERVICE_1,
  M85711: IAPTIDs.LWC_SERVICE_1,
  M85713: IAPTIDs.LWC_SERVICE_1,
  M85715: IAPTIDs.LWC_SERVICE_1,
  M85716: IAPTIDs.LWC_SERVICE_1,
  M85717: IAPTIDs.LWC_SERVICE_1,
  M85721: IAPTIDs.LWC_SERVICE_1,
  M85722: IAPTIDs.LWC_SERVICE_1,
  M85730: IAPTIDs.LWC_SERVICE_1,
  M85732: IAPTIDs.LWC_SERVICE_1,
  M85733: IAPTIDs.LWC_SERVICE_1,
  M85735: IAPTIDs.LWC_SERVICE_1,
  M85736: IAPTIDs.LWC_SERVICE_1,
  M85739: IAPTIDs.LWC_SERVICE_1,
  M85746: IAPTIDs.LWC_SERVICE_1,
  M85749: IAPTIDs.LWC_SERVICE_1,
  M85756: IAPTIDs.LWC_SERVICE_1,
  M85757: IAPTIDs.LWC_SERVICE_1,
  M85759: IAPTIDs.LWC_SERVICE_1,
  M85766: IAPTIDs.LWC_SERVICE_1,
  M85770: IAPTIDs.LWC_SERVICE_1,
  M85774: IAPTIDs.LWC_SERVICE_1,
  M85778: IAPTIDs.LWC_SERVICE_1,
  M85779: IAPTIDs.LWC_SERVICE_1,
  M85781: IAPTIDs.LWC_SERVICE_1,
  M85783: IAPTIDs.LWC_SERVICE_1,
  M85792: IAPTIDs.LWC_SERVICE_1,
  M85794: IAPTIDs.LWC_SERVICE_1,
  M85797: IAPTIDs.LWC_SERVICE_1,
  M85803: IAPTIDs.LWC_SERVICE_1,
  M88020: IAPTIDs.LWC_SERVICE_1,
  M89001: IAPTIDs.LWC_SERVICE_1,
  M89002: IAPTIDs.LWC_SERVICE_1,
  M89003: IAPTIDs.LWC_SERVICE_1,
  M89003001: IAPTIDs.LWC_SERVICE_1,
  M89003002: IAPTIDs.LWC_SERVICE_1,
  M89003003: IAPTIDs.LWC_SERVICE_1,
  M89003004: IAPTIDs.LWC_SERVICE_1,
  M89003005: IAPTIDs.LWC_SERVICE_1,
  M89005: IAPTIDs.LWC_SERVICE_1,
  M89007: IAPTIDs.LWC_SERVICE_1,
  M89008: IAPTIDs.LWC_SERVICE_1,
  M89009: IAPTIDs.LWC_SERVICE_1,
  M89009001: IAPTIDs.LWC_SERVICE_1,
  M89010: IAPTIDs.LWC_SERVICE_1,
  M89012: IAPTIDs.LWC_SERVICE_1,
  M89013: IAPTIDs.LWC_SERVICE_1,
  M89013001: IAPTIDs.LWC_SERVICE_1,
  M89015: IAPTIDs.LWC_SERVICE_1,
  M89016: IAPTIDs.LWC_SERVICE_1,
  M89016001: IAPTIDs.LWC_SERVICE_1,
  M89017: IAPTIDs.LWC_SERVICE_1,
  M89017001: IAPTIDs.LWC_SERVICE_1,
  M89017002: IAPTIDs.LWC_SERVICE_1,
  M89017003: IAPTIDs.LWC_SERVICE_1,
  M89017004: IAPTIDs.LWC_SERVICE_1,
  M89017005: IAPTIDs.LWC_SERVICE_1,
  M89017006: IAPTIDs.LWC_SERVICE_1,
  M89019: IAPTIDs.LWC_SERVICE_1,
  M89021: IAPTIDs.LWC_SERVICE_1,
  M89024: IAPTIDs.LWC_SERVICE_1,
  M89026: IAPTIDs.LWC_SERVICE_1,
  M89027: IAPTIDs.LWC_SERVICE_1,
  M89027001: IAPTIDs.LWC_SERVICE_1,
  M89030: IAPTIDs.LWC_SERVICE_1,
  M89608: IAPTIDs.LWC_SERVICE_1,
  M91642: IAPTIDs.LWC_SERVICE_1,
  NQT61: IAPTIDs.LWC_SERVICE_1,
  O1H9G: IAPTIDs.LWC_SERVICE_1,
  V9Y6R: IAPTIDs.LWC_SERVICE_1,
  Y00159: IAPTIDs.LWC_SERVICE_1,
  Y00159001: IAPTIDs.LWC_SERVICE_1,
  Y00412: IAPTIDs.LWC_SERVICE_1,
  Y00471: IAPTIDs.LWC_SERVICE_1,
  Y00492: IAPTIDs.LWC_SERVICE_1,
  Y01057: IAPTIDs.LWC_SERVICE_1,
  Y01068: IAPTIDs.LWC_SERVICE_1,
  Y02567: IAPTIDs.LWC_SERVICE_1,
  Y02571: IAPTIDs.LWC_SERVICE_1,
  Y02794: IAPTIDs.LWC_SERVICE_1,
  Y02893: IAPTIDs.LWC_SERVICE_1,
  Y03597: IAPTIDs.LWC_SERVICE_1,
  Y05826: IAPTIDs.LWC_SERVICE_1,
  Y06378: IAPTIDs.LWC_SERVICE_1
}
