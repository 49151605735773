import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import { isOnline } from "../../../utils/isOnline"
import { SubmitReferralStatus } from "../../../models/SubmitReferral"
import client from "./_client"

const TOTAL_RETRIES = 3

const headers = new Headers()
headers.set("Content-Type", "application/json")

export async function submitReferral(
  patientId: string,
  retry = 0
): Promise<[boolean, SubmitReferralStatus]> {
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) return [false, SubmitReferralStatus.NoInternetConnection]

    const headers = client.headers({ "x-api-key": client.getTimedResourceAPIKey(patientId) })
    const data = await client.post("/v1/referral/submit-external", undefined, undefined, headers)
    const isSubmitSuccessful = data?.status === "SUCCESS"
    return [isSubmitSuccessful, SubmitReferralStatus.Success]
  } catch (e) {
    Logger.getInstance().breadcrumb({
      message: "submitReferral fetch failed",
      data: { id: patientId }
    })
    Logger.getInstance().exception(e, "submitReferral fetch failed")
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("submitReferral retry")
      await delay(1)
      return await submitReferral(patientId, retry + 1)
    }
    return [false, SubmitReferralStatus.RequestFailed]
  }
}
