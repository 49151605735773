import ISurvey, { SurveyType } from "../../models/ISurvey"
import createSurveyDialogue from "../createSurveyDialogue"
import { DialogueIDs } from "../DialogueIDs"

const answers = [
  { points: 1, answer: "Not at all typical" },
  { points: 2, answer: "Rarely typical of me" },
  { points: 3, answer: "Somewhat typical of me" },
  { points: 4, answer: "Often typical of me" },
  { points: 5, answer: "Very typical of me" }
]

const answersReversePoints = answers.map(answer => {
  return {
    points: 5 - answer.points + 1,
    answer: answer.answer
  }
})

const PSWQ: ISurvey = {
  startingQuestion: [
    "This next set of 16 questions is designed to measure your level of worry.",
    "For each question, please select the response that best describes how typical each statement is of you."
  ],
  questions: [
    {
      id: "1",
      questionName: "PSWQ - step1",
      question: "1. If I do not have enough time to do everything, I do not worry about it.",
      type: "inline",
      progress: 0.06,
      answers: answersReversePoints
    },
    {
      id: "2",
      questionName: "PSWQ - step2",
      question: "2. My worries overwhelm me.",
      type: "inline",
      progress: 0.12,
      answers
    },
    {
      id: "3",
      questionName: "PSWQ - step3",
      question: "3. I do not tend to worry about things.",
      type: "inline",
      progress: 0.18,
      answers: answersReversePoints
    },
    {
      id: "4",
      questionName: "PSWQ - step4",
      question: "4. Many situations make me worry.",
      type: "inline",
      progress: 0.24,
      answers
    },
    {
      id: "5",
      questionName: "PSWQ - step5",
      question: "5. I know I should not worry about things, but I just cannot help it.",
      type: "inline",
      progress: 0.3,
      answers
    },
    {
      id: "6",
      questionName: "PSWQ - step6",
      question: "6. When I am under pressure, I worry a lot.",
      type: "inline",
      progress: 0.36,
      answers
    },
    {
      id: "7",
      questionName: "PSWQ - step7",
      question: "7. I am always worrying about something.",
      type: "inline",
      progress: 0.42,
      answers
    },
    {
      id: "8",
      questionName: "PSWQ - step8",
      question: "8. I find it easy to dismiss worrisome thoughts.",
      type: "inline",
      progress: 0.48,
      answers: answersReversePoints
    },
    {
      id: "9",
      questionName: "PSWQ - step9",
      question:
        "9. As soon as I finish one task, I start to worry about everything else I have to do.",
      type: "inline",
      progress: 0.54,
      answers
    },
    {
      id: "10",
      questionName: "PSWQ - step10",
      question: "10. I never worry about anything.",
      type: "inline",
      progress: 0.6,
      answers: answersReversePoints
    },
    {
      id: "11",
      questionName: "PSWQ - step11",
      question:
        "11. When there is nothing more I can do about a concern, I do not worry about it anymore.",
      type: "inline",
      progress: 0.66,
      answers: answersReversePoints
    },
    {
      id: "12",
      questionName: "PSWQ - step12",
      question: "12. I have been a worrier all my life.",
      type: "inline",
      progress: 0.72,
      answers
    },
    {
      id: "13",
      questionName: "PSWQ - step13",
      question: "13. I notice that I have been worrying about things.",
      type: "inline",
      progress: 0.78,
      answers
    },
    {
      id: "14",
      questionName: "PSWQ - step14",
      question: "14. Once I start worrying, I cannot stop.",
      type: "inline",
      progress: 0.84,
      answers
    },
    {
      id: "15",
      questionName: "PSWQ - step15",
      question: "15. I worry all the time.",
      type: "inline",
      progress: 0.9,
      answers
    },
    {
      id: "16",
      questionName: "PSWQ - step16",
      question: "16. I worry about projects until they are all done.",
      type: "inline",
      progress: 1,
      answers
    }
  ]
}

export default createSurveyDialogue(DialogueIDs.PSWQ, PSWQ, SurveyType.PSWQ)
