import React from "react"
import { createRoot } from "react-dom/client"
import "../app/styles/styles.scss"
import pkg from "../../package.json"
import Logger from "../utils/Logger"
import Tracker from "../utils/Tracker"
import Trackable from "../models/Trackable"
import Persistable from "../models/Persistable"
import RootStore from "../app/stores/rootStore"
import { RootStoreProvider } from "../app/contexts/RootStoreContext"
import BaseScript from "../conversation/BaseScript"
import App from "../app/App"
import CrisisDetector from "../models/CrisisDetector"
import { getBrowserLanguage } from "../utils/language"
import invariant from "../utils/invariant"
import { apiKeysMap } from "../config/apiKeysMap"
import customFields from "../config/customFields"
import validateCustomFields from "../utils/validateCustomFields"
import { Translator } from "../i18n/Translator"
import IAppLaunchConfig from "../models/IAppLaunchConfig"
import type { WindowWithLimbicNameSpace } from "./index"
import { initDashboardConfig } from "./initDashboardConfig"
import { setCrisisDetectionConfig } from "@limbic/crisis-detection"
import SnippetInjector from "../app/components/SnippetInjector/SnippetInjector"

declare let window: WindowWithLimbicNameSpace

const botConfigVersion = process.env.REACT_APP_BOT_VERSION ?? "draft"
const mixPanelAPIKey = process.env.REACT_APP_MIXPANEL_API_KEY ?? ""

export function init(setupConfig: IAppLaunchConfig): void {
  console.log("==> Initialising Limbic Web Bot...")

  try {
    Persistable.setStorage(sessionStorage)
    CrisisDetector.setStorage(localStorage)
    RootStore.getInstance()
  } catch (e) {
    Logger.getInstance().exception(e, "init -> singletons setup")
  }

  if (!window.LimbicChatbot.initialized) {
    const key = setupConfig.API_KEY
    invariant<keyof typeof apiKeysMap>(key, "no API key found")

    // `withDashboard` is needed to break the loop between
    // the custom initializer that will end up using `init` again
    // but with the same API_KEY.
    const withDashboard = setupConfig.withDashboard ?? true
    const isDashboardService = !(key in apiKeysMap) || apiKeysMap[key]?.isDashboardService
    if (isDashboardService && withDashboard) return void initDashboardConfig(setupConfig)

    window.LimbicChatbot.initialized = true
    const config = { ...apiKeysMap[key], ...(setupConfig.overrides || {}) }
    const fullScreen = !!config.fullscreen
    invariant(config.dialoguesMap, "dialoguesMap is required")
    invariant(config.discussionStepsOrder, "discussionStepsOrder is required")

    try {
      if (mixPanelAPIKey) Tracker.setAPIKey(mixPanelAPIKey)
      Tracker.setup({ BOT_API_KEY: key })
      const trackerSingleton = Tracker.getInstance()
      const urlParams = new URLSearchParams(window.location.search)
      trackerSingleton.register({ build: pkg.version })
      trackerSingleton.setPeople({
        lastBuildUsed: pkg.version,
        browserLanguage: getBrowserLanguage(),
        ...config,
        source: urlParams ? urlParams.get("source") : "",
        url: window.location.href,
        fullScreen,
        botConfigVersion
      })
      trackerSingleton.register(config)
      trackerSingleton.register({ botConfigVersion })
      Trackable.setTracker(trackerSingleton)
    } catch (e) {
      console.log(`init: ${e.message}`)
      Logger.getInstance().exception(e, "init -> Trackers setup")
    }

    try {
      RootStore.ConfigStore.setConfig(key, config)
      RootStore.DiscussionStore.setDiscussionStepsOrder(config.discussionStepsOrder)
      RootStore.DiscussionStore.setDialoguesMap(config.dialoguesMap)
      RootStore.ClinicalStore.setDefaultPaths(config.defaultClinicalPaths)
      RootStore.ClinicalStore.setCustomPaths(config.customClinicalPaths)
      RootStore.ClinicalStore.setComplexComorbidPath(config.complexComorbidPath)
      RootStore.ClinicalStore.setUndeterminedPath(config.undeterminedPath)
      RootStore.ReferralStore.setSkipOnlineReferral(config.skipOnlineReferral ?? false)
      if (config.backendInstanceID) RootStore.ReferralStore.setInstanceID(config.backendInstanceID)

      const allFields = customFields[key]?.allFields ?? []
      const requiredFields = customFields[key]?.requiredFields ?? []
      validateCustomFields(allFields, requiredFields, setupConfig.customFields)
      if (setupConfig.customFields) {
        Object.entries(setupConfig.customFields) //
          .forEach(([key, value]) => RootStore.ReferralStore.setCustomField(key, value))
      }
      if (setupConfig.autoExpand === "disabled") {
        RootStore.ApplicationStore.setAutoExpand(false)
      }
      const urlParams = getURLParams()
      RootStore.ReferralStore.setCustomField("urlParams", urlParams)

      setCrisisDetectionConfig({ nativeEnabled: false, remoteEnabled: false })
      const crisisDetector = CrisisDetector.getInstance()
      const translatorSingleton = Translator.getInstance(config.defaultLanguage)
      translatorSingleton.setTranslations(config.translations)
      RootStore.ApplicationStore.setTranslator(translatorSingleton)
      translatorSingleton.events.languageChanged.addListener((lang: string) => {
        void crisisDetector
          .setCrisisDetectionLanguage(lang)
          .catch(e =>
            Logger.getInstance().exception(e, "init -> set crisis detection language listener")
          )
      })
    } catch (e) {
      Logger.getInstance().exception(e, "init -> RootStore setup")
    } finally {
      BaseScript.setRootStore(RootStore.getInstance())
      BaseScript.setTranslator(Translator.getInstance())
    }

    const el = getMountingPoint()
    const root = createRoot(el)
    root.render(
      <React.StrictMode>
        <RootStoreProvider>
          <>
            <SnippetInjector snippets={config?.advanced?.snippet || []} />
            <App />
          </>
        </RootStoreProvider>
      </React.StrictMode>
    )
  }
}

function getMountingPoint(): Element {
  const el = document.querySelector("#limbic-chat")
  if (!el) {
    const newEl = document.createElement("div")
    newEl.setAttribute("id", "limbic-chat")
    document.body.appendChild(newEl)
    return newEl
  }
  return el
}

function getURLParams(): Record<string, any> {
  try {
    const params = new URLSearchParams(window.location.search)
    const result: Record<string, any> = {}
    params.forEach((value, key) => (result[key] = value))
    return result
  } catch {
    return {}
  }
}
