import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = BaseScriptState
export type PermissionsScriptState = State

export abstract class PermissionsScript extends BaseScript<State> {
  onHandleIUnderstandTerms?(state: State): Promise<IStepResult | void>
  onHandleResearchConsent?(state: State): Promise<IStepResult | void>

  /** Script Steps */

  abstract step1(d: IStepData<State>): IStepResult
  // abstract handleIUnderstand(d: IStepData<State>): IStepResult

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.step1 }
  }

  @step.logState
  showTOSLinks(_d: IStepData<State>): IStepResult {
    const organisationName =
      this.rootStore.configStore.organisationName ?? this.rootStore.configStore.serviceName
    const organisationTerms = this.rootStore.configStore.organisationTerms ?? ""
    return {
      body: this.t(
        [
          "To get you the best referral, I will need to share your answers with {organisationName}",
          "I want to reassure you that your details will be stored safely and kept confidential",
          "You can find all the details here:\n\n" +
            "Limbic [Terms of Service](https://www.limbic.ai/terms-of-use)\n" +
            "Limbic [Privacy Policy](https://www.limbic.ai/privacy)\n{organisationTerms}"
        ],
        { organisationName, organisationTerms }
      ),
      nextStep: this.promptIUnderstandTerms
    }
  }

  @step.logState
  promptIUnderstandTerms(_d: IStepData<State>): IStepResult {
    const organisationName =
      this.rootStore.configStore.organisationName ?? this.rootStore.configStore.serviceName

    return {
      prompt: {
        id: this.getPromptId("promptIUnderstandTerms"),
        trackResponse: true,
        // 👇 There seems to be a bug when there are two
        // consecutive checkbox questions, and you hit undo
        // the checkbox component crashes as it receives the
        // data of the previous checkbox and not the checkbox
        // that is loaded on Undo
        // Setting it to isUndoAble false for now
        isUndoAble: false,
        type: "checkbox",
        isRequired: true,
        options: [
          {
            body: this.t("I agree to the terms"),
            key: "agreeTerms",
            isRequired: true,
            initialValue: false
          },
          {
            // prettier-ignore
            body: this.t("I understand my details will be shared with {organisationName}", { organisationName }),
            key: `agreeDetailsShared`,
            isRequired: true,
            initialValue: false
          }
        ].filter(Boolean)
      },
      nextStep: this.handleIUnderstandTerms
    }
  }

  @step.logState
  async handleIUnderstandTerms(d: IStepData<State>): Promise<IStepResult> {
    d.state.agreeTerms = d.response.agreeTerms
    d.state.agreeDetailsShared = d.response.agreeDetailsShared
    const result = await this.onHandleIUnderstandTerms?.(d.state)
    if (result) return result
    return { nextStep: this.askResearchConsent }
  }

  @step.logState
  askResearchConsent(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "I am also working with researchers to improve mental health treatment",
        "Are you ok if Limbic uses your data anonymously to support the development of the product and research, which might be used for scientific publications?"
      ]),
      prompt: {
        id: this.getPromptId("askResearchConsent"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleResearchConsent
    }
  }

  @step.logState
  async handleResearchConsent(d: IStepData<State>): Promise<IStepResult> {
    d.state.consentResearch = d.response
    const result = await this.onHandleResearchConsent?.(d.state)
    if (result) return result
    return {
      nextStep: this.sayReady
    }
  }

  @step.logState
  sayReady(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(["Let's continue", "Ready?"]),
      prompt: {
        id: this.getPromptId("sayReady"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Let's get started") }]
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */
}
