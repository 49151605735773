import { action, computed, observable } from "mobx"
import hydrator from "../../conversation/hydrator"
import type { DialogueHydrator, IBotSnapshot } from "../../backend/chatbot/Bot"
import Bot from "../../backend/chatbot/Bot"
import Persistable from "../../models/Persistable"
import invariant from "../../utils/invariant"
import uuidv4 from "../../backend/chatbot/utils/uuidv4"
import { TrackingEvents } from "../../models/Constants"
import dialoguesRegistry from "../../conversation/dialoguesRegistry"
import { DialogueIDs } from "../../conversation/DialogueIDs"
import { backendEnv } from "../../config/config"
import type Dialogue from "../../backend/chatbot/Dialogue"
import type { IBotMessage, IUserMessage } from "../../backend/chatbot/models/IMessage"
import type ChatStore from "./chatStore"
import type { MockUserAction } from "../../models/IUserResponse"
import { IDataPoints } from "@limbic/types"
import Syncable from "../../models/Syncable"

export default class BotStore extends Persistable implements Syncable {
  readonly name: string = "BotStore"
  readonly chatStore: ChatStore

  bot?: Bot = undefined
  shouldPersistState: boolean
  snapshot?: IBotSnapshot
  generateMockUserActions: boolean
  mockUserActions: MockUserAction[]

  @observable
  private _stepRunning?: number

  restart?(): void

  /** Overrides */

  hydrateValueOfKey(key: string, jsonValue: unknown): any {
    if (key === "createdAt") return new Date(jsonValue as string)
    return jsonValue
  }

  /** Callbacks */

  onSaveCheckPoint?(id: string): void
  onBotUndo?(id: string): void
  getUserName?(): string | undefined
  getDiscussionStarted?(): boolean
  onDataPointsReceived?(dataPoints: IDataPoints): void

  constructor(chatStore: ChatStore) {
    super()
    this.chatStore = chatStore
    this.shouldPersistState = true
    this.generateMockUserActions = backendEnv !== "production"
    this.mockUserActions = []
  }

  setShouldPersistState(value: boolean): BotStore {
    this.shouldPersistState = value
    return this
  }

  @action
  setup(): void {
    this.logBreadcrumb("setup -> loading snapshot")
    const MainFlowDialogue = dialoguesRegistry.get(DialogueIDs.MainFlow)
    this.chatStore.setupWithMessageHistory()
    this.snapshot = this.hydrate("snapshot")
    try {
      const bot = this.snapshot
        ? Bot.fromSnapshot(this.snapshot, hydrator as DialogueHydrator)
        : new Bot(new MainFlowDialogue({}))
      this.setBot(bot)
    } catch (e) {
      this.logException(e, "setup")
      this.setBot(new Bot(new MainFlowDialogue({})))
    }
    this.startConversation(this.snapshot)
  }

  @action
  setupWithDialogue(dialogue: Dialogue): void {
    this.logBreadcrumb("setupWithDialogue")
    invariant(dialogue, "setupWithDialogue can't be called without a dialogue")
    this.chatStore.setup()
    const bot = new Bot(dialogue)
    this.setBot(bot)
    this.startConversation()
  }

  rehydrate(): void {
    this.logBreadcrumb("setup -> rehydrating from snapshot")
    this.snapshot = this.hydrate("snapshot")
    invariant(this.snapshot, "Unable to rehydrate store, snapshot is invalid")
    const bot = Bot.fromSnapshot(this.snapshot, hydrator as DialogueHydrator)
    this.setBot(bot)
    this.startConversation(this.snapshot)
  }

  private setBot(bot: Bot) {
    if (this.bot) {
      this.removeBotListeners()
    }
    this.bot = bot
    this.addBotListeners()
  }

  private addBotListeners(): void {
    if (this.bot) {
      this.removeBotListeners()
      this.bot.events.dialogueError.addListener(this._onDialogueError)
      this.bot.events.dialoguePushed.addListener(this._onDialoguePushed)
      this.bot.events.dialogueRemoved.addListener(this._onDialogueRemoved)
      this.bot.events.typing.addListener(this._onBotTyping)
      this.bot.events.typingMessage.addListener(this._onBotTypingMessage)
      this.bot.events.newMessage.addListener(this._onNewBotMessages)
      this.bot.events.stepStarted.addListener(this._onStepStarted)
      this.bot.events.stepFinished.addListener(this._onStepFinished)
    }
  }

  removeBotListeners(): void {
    if (this.bot) {
      this.bot.events.dialogueError.removeListener(this._onDialogueError)
      this.bot.events.dialoguePushed.removeListener(this._onDialoguePushed)
      this.bot.events.dialogueRemoved.removeListener(this._onDialogueRemoved)
      this.bot.events.typing.removeListener(this._onBotTyping)
      this.bot.events.typing.removeListener(this._onBotTyping)
      this.bot.events.typingMessage.removeListener(this._onBotTypingMessage)
      this.bot.events.newMessage.removeListener(this._onNewBotMessages)
      this.bot.events.stepFinished.removeListener(this._onStepFinished)
    }
  }

  setMessageDelay(maxTyping: number, minTyping: number, thinking: number): void {
    if (this.bot) {
      this.bot.setMessageDelay(maxTyping, minTyping, thinking)
    }
  }

  /** LifeCycle Handlers */

  startConversation(snapshot?: IBotSnapshot): void {
    if (!snapshot) {
      if (this.getDiscussionStarted?.()) {
        this.logMessage("startConversation without a snapshot!")
      }
      this.logBreadcrumb("startConversation start (!snapshot)")
      return this.start()
    }

    if (snapshot?.dialogues?.length > 0) {
      this.logBreadcrumb("startConversation resume")
      this.resume()
      return
    }

    this.logBreadcrumb("startConversation nothing happened")
  }

  start(): void {
    this.logBreadcrumb("Bot will be started.")
    this.bot?.start()
  }

  resume(): void {
    this.logBreadcrumb("Bot will be resumed.")
    this.bot?.resume(this.chatStore.lastMessage)
  }

  startMainDialogue(): void {
    const MainMenuDialogue = dialoguesRegistry.get(DialogueIDs.MainFlow)
    const username = this.getUserName?.()
    this.bot?.startDialogue(new MainMenuDialogue({ username }), true)
  }

  interrupt(): void {
    this.logBreadcrumb("Bot will be interrupted.")
    this.bot?.interrupt()
  }

  undo(id: string): void {
    try {
      const message = this.chatStore.messages.find(m => m.id === id && m.author === "user") as
        | IUserMessage
        | undefined
      this.track(TrackingEvents.UNDO, { identifier: message?.identifier })
    } catch (e) {
      this.logException(e, "undo -> Get identifier of undone message failed")
    }
    try {
      if (this.generateMockUserActions) {
        this.mockUserActions.push({ type: "undo" })
      }

      const botMessage = this.chatStore.findLastBotMessage(id)
      invariant(botMessage?.prompt, "Cannot revert back to non-undoable step")
      this.onBotUndo?.(botMessage.id)
    } catch (e) {
      this.logException(e, "undo")
    }
  }

  respond(body?: string, value?: unknown, dataPoints?: IDataPoints): void {
    const promptId = this.chatStore.userPrompt?.id
    if (dataPoints) this.onDataPointsReceived?.(dataPoints)
    if (promptId && this.generateMockUserActions) {
      this.mockUserActions.push({ type: "answer", id: promptId, body, value })
    }
    const trackResponse = !!this.chatStore.userPrompt?.trackResponse
    if (promptId && trackResponse) {
      this.track(promptId, trackResponse ? { body } : undefined)
    }

    const currentDialogue = this.bot?.activeDialogue
    this.chatStore.addUserMessage(body, value, currentDialogue)
    this.bot?.respond(body, value)
    this.updateSnapShots()
  }

  pushDialogue(dialogue: Dialogue, start = false): void {
    this.logBreadcrumb(`Bot will clear and start dialogue ${dialogue.identifier}`)
    this.bot?.pushDialogue(dialogue, start)
  }

  clearDialogues(): void {
    this.bot?.clearDialogues()
  }

  /** Actions */

  @action
  setStepRunning(timestamp?: number): void {
    this._stepRunning = timestamp
  }

  /** Generic Handlers */

  private updateSnapShots(): void {
    if (this.bot && this.shouldPersistState) {
      this.chatStore.updateSnapShots()
      this.persist("snapshot", this.bot.snapshot)
    }
  }

  emitTypingMessageEnd = () => this.bot?.events.typingMessageEnd.emit()

  /** Event Handlers */

  @action
  private _onBotTyping = (isTyping: boolean): void => {
    if (isTyping) {
      this.chatStore.startBotTyping()
      return
    }
    this.chatStore.stopBotTyping()
  }

  @action
  private _onNewBotMessages = (message: IBotMessage): void => {
    this.chatStore.addBotMessage(message)
    this.updateSnapShots()
    // save a checkpoint so that we can rewind to this point
    // after the user answers the prompt. This needs to happen
    // after we have updated the snapshots because we're basically
    // saving a copy of the most up to date snapshot so we need
    // to make sure this new message is also included
    if (message.prompt?.isUndoAble) this.onSaveCheckPoint?.(message.id)
  }

  @action
  private _onBotTypingMessage = (message: IBotMessage): void => {
    this.chatStore.setTypingMessage(message)
  }

  @action
  private _onStepStarted = (timestamp: number): void => {
    this.setStepRunning(timestamp)
  }

  @action
  private _onStepFinished = (): void => {
    this.setStepRunning()
    this.updateSnapShots()
  }

  @action
  private _onDialogueError = (error: Error): void => {
    this.logException(error, "_onDialogueError()")
    this.chatStore.addBotMessage({
      id: uuidv4(),
      author: "bot",
      createdAt: new Date(),
      body: "Hmmm, something went wrong in my system and I have to start over. Sorry for that",
      _meta: {
        step: this.bot!.activeDialogue?.currentStep?.stepName || "start",
        dialogueName: this.bot!.activeDialogue!.name,
        dialogueUUID: this.bot!.activeDialogue!.uuid
      }
    })
    this.startMainDialogue()
    // TODO: This needs to be handled like we'll handle the wake me up situation
  }

  private _onDialoguePushed = (dialogue: Dialogue): void => {
    this.logBreadcrumb(`Pushed dialogue: "${dialogue.identifier}" - ${dialogue.uuid}`)
  }

  private _onDialogueRemoved = (dialogue: Dialogue): void => {
    this.updateSnapShots()
    this.logBreadcrumb(`Removed dialogue: "${dialogue.identifier}" - ${dialogue.uuid}`)
  }

  /** Getters / Setters */

  @computed
  get stepRunning(): boolean {
    // adding a time threshold for sanity just in case
    // there's any weird edge case that we don't know of
    // where the `stepFinished` event is not emitted, leaving
    // the stepRunning property turned on indefinitely
    return !!this._stepRunning && Date.now() - this._stepRunning <= 60 * 1000
  }
}

if (process.env.REACT_APP__DEV_TOOLS__ === "enabled") {
  BotStore.prototype.restart = action(function restart(this: BotStore) {
    this.setShouldPersistState(false)
    this.persist("snapshot", undefined)
    this.clearDialogues()
    this.setShouldPersistState(true)
    this.setup()
  })
}
