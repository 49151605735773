import { clinicalPaths, complexComorbidPath, undeterminedPath } from "@limbic/types"
import {
  stepsOrder,
  stepsOrder_DEMO_PRIVATE_PROVIDERS,
  stepsOrder_ESSEX,
  stepsOrder_GM_HUB,
  stepsOrder_INSIGHT,
  stepsOrder_Insight_Waitlist,
  stepsOrder_LINCS,
  stepsOrder_MHM,
  stepsOrder_MIND,
  stepsOrder_VITALITY,
  stepsOrder_BRADFORD,
  stepsOrder_CHESHIRE_WIRRAL,
  stepsOrder_LWC,
  stepsOrder_OXFORD_BUCKINGHAMSHIRE,
  stepsOrder_DORKING,
  stepsOrder_BEXLEY,
  stepsOrder_SABP,
  stepsOrder_EVERYTURN
} from "./discussion/stepsOrder"
import {
  dialogueMap_VITALITY,
  dialoguesMap_BUCKINGHSAMHIRE,
  dialoguesMap_OXFORD,
  dialoguesMap_BRADFORD,
  dialoguesMap_CHESHIRE_WIRRAL,
  dialoguesMap_Demo,
  dialoguesMap_DemoDynamic,
  dialoguesMap_DemoPrivateProviders,
  dialoguesMap_DORKING,
  dialoguesMap_ESSEX,
  dialoguesMap_GM_HUB,
  dialoguesMap_INSIGHT,
  dialoguesMap_LINCOLNSHIRE,
  dialoguesMap_MPFT,
  dialoguesMap_LWC,
  dialoguesMap_MHM,
  dialoguesMap_MIND,
  dialoguesMap_SABP,
  dialoguesMap_VHG,
  dialoguesMapINSIGHT_FOLLOWUP,
  dialoguesMapINSIGHT_WAITLIST,
  dialoguesMap_BEXLEY,
  dialoguesMap_SABP_V2,
  dialoguesMap_EVERYTURN
} from "./discussion/dialoguesMap"
import { mergeOverrides } from "./clinical/mergeOverrides"
import {
  clinicalPathsEssex,
  clinicalPathsInsight,
  clinicalPathsLincolnshire,
  clinicalPathsMHM,
  clinicalPathsOxford
} from "./clinical/overrides"
import { clinicalPathsVHG } from "./clinical/overrides/vhg"
import { clinicalPathsSABP } from "./clinical/overrides/sabp"
import { CCGCode } from "../models/ICCG"
import { IAPTIDs } from "../models/IIAPTService"
import { iaptGPMapInsight, iaptGPMapInsightDEMO } from "./iaptMaps/insight"
import { iaptGPMapLincolnshire } from "./iaptMaps/lincolnshire"
import { iaptGPMapEssex, iaptGPMapEssexDemo } from "./iaptMaps/essex"
import { iaptGPMapSABP } from "./iaptMaps/sabp"
import {
  insightCustomClinicalPaths,
  lincolnshireCustomClinicalPaths
} from "./clinical/overrides/custom"
import { iaptGPMapMHM } from "./iaptMaps/mhm"
import { iaptGPsMapLWC } from "./iaptMaps/lwc"
import { iaptGPMapCW } from "./iaptMaps/cheshire_wirral"
import { iaptGPMapOxford } from "./iaptMaps/oxford"
import { iaptGPMapBuckinghamshire } from "./iaptMaps/buckinghamshire"
import { iaptGPMapDorking } from "./iaptMaps/dorking"
import { iaptGPMapMPFT } from "./iaptMaps/mpft"
import { iaptGPMapBexley } from "./iaptMaps/bexley"
import { iaptGPsMapVHG } from "./iaptMaps/vhg"
import { iaptGPMapBradford } from "./iaptMaps/bradford"
import { iaptGPMapSABP_v2 } from "./iaptMaps/sabp_v2"
import { clinicalPathsEveryturn } from "./clinical/overrides/everyturn"
import { iaptGPMapEveryturn } from "./iaptMaps/everyturn"
import { FF, hasFF } from "../featureFlags"
import IAppLaunchConfig, { IAppLaunchConfigOptions } from "../models/IAppLaunchConfig"

const demoPhoneNumbers =
  "Demo Clinic London: +44 1234 567890\n" +
  "Demo Clinic Kent: +44 1234 567890\n" +
  "Demo Clinic Calderdale: +44 1234 567890"

/**
 * These services are mock services for testing purposes only.
 */
const servicesForJestTests =
  process.env.NODE_ENV === "test"
    ? ({
        TESTING_SERVICE_1: {
          fullscreen: false
        },
        TESTING_SERVICE_2: {
          fullscreen: false,
          dialoguesMap: dialoguesMap_DemoDynamic
        }
      } as any)
    : {}

export const apiKeysMap: Partial<Record<IAppLaunchConfig["API_KEY"], IAppLaunchConfigOptions>> = {
  ...servicesForJestTests,
  TESTING_SERVICE_PLAYWRIGHT: {
    isDashboardService: true,
    fullscreen: false,
    eligibleIAPTIds: [IAPTIDs.DEMO],
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_DemoDynamic
  },
  DEMO: {
    title: "Limbic Self Referral Assistant",
    serviceName: "Demo Service",
    organisationName: "Demo Organisation",
    organisationTerms: "",
    organisationPhoneNumbers: demoPhoneNumbers,
    organisationGenericPhoneNumber: "0123 4567 890",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    faqLink: "https://limbic.ai/faq",
    eligibleIAPTIds: [IAPTIDs.DEMO],
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_Demo,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath
  },
  DEMO_PRIVATE_PROVIDERS: {
    backendInstanceID: "DEMO_MAIN",
    title: "Limbic Self Referral Assistant",
    serviceName: "",
    organisationName: "Private Therapy Provider",
    organisationTerms: "",
    organisationPhoneNumbers: "0123 4567 890",
    organisationGenericPhoneNumber: "0123 4567 890",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    faqLink: "https://limbic.ai/faq",
    eligibleIAPTIds: [],
    discussionStepsOrder: stepsOrder_DEMO_PRIVATE_PROVIDERS,
    dialoguesMap: dialoguesMap_DemoPrivateProviders,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath
  }
}

if (hasFF(FF.AC451_EVERYTURN)) {
  const insightPhoneNumbers =
    "NHS Mental Health (Everyturn NHS Talking Therapies)\n\n" +
    "Kent Talking Therapies: +44 300 555 5555\n" +
    "Peterborough Talking Therapies: +44 300 555 0888\n" +
    "Nottingham and Nottinghamshire Talking Therapies: +44 300 555 5582\n" +
    "Bassetlaw Talking Therapies: +44 300 300 0033\n" +
    "Derbyshire Talking Therapies: +44 300 555 5580\n" +
    "Medway Talking Therapies: +44 300 029 3000\n" +
    "Wirral Talking Together: +44 151 649 1859"

  const insightCrisisPhoneNumbers =
    "Bassetlaw: +44 8081 963779\n" +
    "Derby and Derbyshire: +44 8000 280077\n" +
    "Kend and Medway (Adults): +44 8007 839111\n" +
    "Kend and Medway (Under 18s): +44 8000 113474\n" +
    "Nottingham and Nottinghamshire: +44 8081 963779\n" +
    "Peterborough: 111 & press option 2 for the first response service\n" +
    "Wirral: +44 8001 456485"

  const insightTerms =
    "Everyturn Mental Health [Privacy Policy](https://www.insightiapt.org/privacy-policy/)"

  apiKeysMap.INSIGHT_TALKING_THERAPIES = {
    title: "Everyturn Referral Assistant",
    serviceName: "Everyturn Mental Health",
    organisationName: "Everyturn Mental Health",
    organisationTerms: insightTerms,
    organisationPhoneNumbers: insightPhoneNumbers,
    crisisPhoneNumbers: insightCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0300 123 4502",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/everyturn-logo.svg",
    userMessageBackground: "#533767",
    fullscreen: false,
    eligibleIAPTIds: [
      IAPTIDs.INSIGHT_BASSETLAW,
      IAPTIDs.INSIGHT_NOTTS,
      IAPTIDs.INSIGHT_MEDWAY,
      IAPTIDs.INSIGHT_PETTERSBOROUGH,
      IAPTIDs.INSIGHT_WIRRAL,
      IAPTIDs.INSIGHT_KENT,
      IAPTIDs.INSIGHT_DERBY
    ],
    iaptCCGMap: {
      [CCGCode.BASSETLAW]: IAPTIDs.INSIGHT_BASSETLAW,
      [CCGCode.CAMBRIDGESHIRE_PETERBOROUGH]: IAPTIDs.INSIGHT_PETTERSBOROUGH,
      [CCGCode.WIRRAL]: IAPTIDs.INSIGHT_WIRRAL
    },
    iaptGPMap: iaptGPMapInsight,
    discussionStepsOrder: stepsOrder_INSIGHT,
    dialoguesMap: dialoguesMap_INSIGHT,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsInsight),
    customClinicalPaths: insightCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsInsight)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsInsight)[0],
    adsmEnabled: true
  }
  apiKeysMap.INSIGHT_TALKING_THERAPIES_FULLSCREEN = {
    ...apiKeysMap.INSIGHT_TALKING_THERAPIES,
    fullscreen: true,
    formLink: "https://www.everyturn.org/talking-therapies/self-refer/",
    directReferral: true,
    iaptIcons: [],
    adsmEnabled: true
  }
  apiKeysMap.INSIGHT_WAITLIST = {
    ...apiKeysMap.INSIGHT_TALKING_THERAPIES,
    backendInstanceID: "INSIGHT_WAITLIST",
    eligibleIAPTIds: [],
    discussionStepsOrder: stepsOrder_Insight_Waitlist,
    dialoguesMap: dialoguesMapINSIGHT_WAITLIST
  }
  apiKeysMap.INSIGHT_FOLLOWUP = {
    ...apiKeysMap.INSIGHT_TALKING_THERAPIES,
    backendInstanceID: "INSIGHT_FOLLOWUP",
    eligibleIAPTIds: [],
    discussionStepsOrder: stepsOrder_Insight_Waitlist,
    dialoguesMap: dialoguesMapINSIGHT_FOLLOWUP
  }
  apiKeysMap.INSIGHT_TALKING_THERAPIES_DEMO = {
    ...apiKeysMap.INSIGHT_TALKING_THERAPIES,
    backendInstanceID: "INSIGHT_E2E_DEMO",
    eligibleIAPTIds: [
      IAPTIDs.INSIGHT_BASSETLAW_DEMO,
      IAPTIDs.INSIGHT_NOTTS_DEMO,
      IAPTIDs.INSIGHT_MEDWAY_DEMO,
      IAPTIDs.INSIGHT_PETTERSBOROUGH_DEMO,
      IAPTIDs.INSIGHT_WIRRAL_DEMO,
      IAPTIDs.INSIGHT_KENT_DEMO,
      IAPTIDs.INSIGHT_DERBY_DEMO
    ],
    iaptCCGMap: {
      [CCGCode.BASSETLAW]: IAPTIDs.INSIGHT_BASSETLAW_DEMO,
      [CCGCode.CAMBRIDGESHIRE_PETERBOROUGH]: IAPTIDs.INSIGHT_PETTERSBOROUGH_DEMO,
      [CCGCode.WIRRAL]: IAPTIDs.INSIGHT_WIRRAL_DEMO
    },
    iaptGPMap: iaptGPMapInsightDEMO
  }

  apiKeysMap.EVERYTURN = {
    title: "Everyturn Referral Assistant",
    serviceName: "Everyturn Mental Health",
    organisationName: "Everyturn Mental Health",
    organisationTerms: insightTerms,
    organisationPhoneNumbers: insightPhoneNumbers,
    crisisPhoneNumbers: insightCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0300 123 4502",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/everyturn-logo.svg",
    userMessageBackground: "#533767",
    fullscreen: false,
    eligibleIAPTIds: [
      IAPTIDs.EVERYTURN_BASSETLAW,
      IAPTIDs.EVERYTURN_NOTTS,
      IAPTIDs.EVERYTURN_MEDWAY,
      IAPTIDs.EVERYTURN_PETTERSBOROUGH,
      IAPTIDs.EVERYTURN_WIRRAL,
      IAPTIDs.EVERYTURN_KENT,
      IAPTIDs.EVERYTURN_DERBY
    ],
    iaptCCGMap: {
      [CCGCode.BASSETLAW]: IAPTIDs.EVERYTURN_BASSETLAW,
      [CCGCode.CAMBRIDGESHIRE_PETERBOROUGH]: IAPTIDs.EVERYTURN_PETTERSBOROUGH,
      [CCGCode.WIRRAL]: IAPTIDs.EVERYTURN_WIRRAL
    },
    iaptGPMap: iaptGPMapEveryturn,
    discussionStepsOrder: stepsOrder_EVERYTURN,
    dialoguesMap: dialoguesMap_EVERYTURN,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsEveryturn),
    customClinicalPaths: insightCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsEveryturn)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsEveryturn)[0],
    adsmEnabled: true
  }
  apiKeysMap.EVERYTURN_FULLSCREEN = {
    ...apiKeysMap.EVERYTURN,
    fullscreen: true,
    directReferral: true,
    formLink: "https://www.everyturn.org/talking-therapies/self-refer/",
    iaptIcons: []
  }
}

if (hasFF(FF.AC453_VHG)) {
  const vhgPhoneNumbers = ""
  const vhgTerms =
    "Notts Talking Therapies [Terms & Conditions](https://notts-talk.co.uk/terms-conditions/)\n" +
    "Notts Talking Therapies [Privacy Notice](https://notts-talk.co.uk/privacy-policy/)"

  apiKeysMap.VHG_NOTTS = {
    title: "Limbic Referral Assistant",
    serviceName: "Nottingham and Nottinghamshire Talking Therapies",
    organisationName: "Nottingham and Nottinghamshire Talking Therapies",
    organisationTerms: vhgTerms,
    organisationPhoneNumbers: vhgPhoneNumbers,
    organisationGenericPhoneNumber: "0333 0153 494",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/nhs-logo.png",
    userMessageBackground: "#005EB8",
    fullscreen: false,
    faqLink: "https://limbic.ai/faq",
    eligibleIAPTIds: [IAPTIDs.VHG_NOTTS],
    iaptGPMap: iaptGPsMapVHG,
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_VHG,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsVHG),
    customClinicalPaths: [],
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsVHG)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsVHG)[0],
    freetextLimit: 500
  }
  apiKeysMap.VHG_NOTTS_FULLSCREEN = {
    ...apiKeysMap.VHG_NOTTS,
    fullscreen: true,
    directReferral: true,
    iaptIcons: ["https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/vhg-notts.png"]
  }
}

if (hasFF(FF.AC454_ESSEX)) {
  const essexTerms =
    "Therapy For You [Privacy Policy](https://www.therapyforyou.co.uk/privacy-policy)"
  const essexOrganisationPhoneNumbers =
    "Therapy For You (South East Essex): 01268 739128\n" +
    "Therapy For You (North East Essex): 01206 334001"

  apiKeysMap.ESSEX = {
    title: "Therapy For You Referral Assistant",
    serviceName: "Therapy For You",
    organisationName: "Therapy For You",
    organisationTerms: essexTerms,
    organisationPhoneNumbers: essexOrganisationPhoneNumbers,
    organisationGenericPhoneNumber: essexOrganisationPhoneNumbers,
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#c00b74",
    fullscreen: false,
    eligibleIAPTIds: [IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX, IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX],
    iaptGPMap: iaptGPMapEssex,
    iaptCCGMap: {
      [CCGCode.NE_ESSEX]: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
      [CCGCode.CASTLE_POINT_ROCHFORD]: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
      [CCGCode.SOUTHEND]: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX
    },
    discussionStepsOrder: stepsOrder_ESSEX,
    dialoguesMap: dialoguesMap_ESSEX,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsEssex),
    customClinicalPaths: [],
    complexComorbidPath,
    undeterminedPath,
    freetextLimit: 500
  }
  apiKeysMap.ESSEX_FULLSCREEN = {
    ...apiKeysMap.ESSEX,
    fullscreen: true,
    formLink: "https://www.therapyforyou.co.uk/ieso",
    directReferral: true
  }
  apiKeysMap.ESSEX_DEMO = {
    ...apiKeysMap.ESSEX,
    backendInstanceID: "ESSEX_E2E_DEMO",
    fullscreen: false,
    eligibleIAPTIds: [IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO, IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO],
    iaptGPMap: iaptGPMapEssexDemo,
    iaptCCGMap: {
      [CCGCode.NE_ESSEX]: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
      [CCGCode.CASTLE_POINT_ROCHFORD]: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
      [CCGCode.SOUTHEND]: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO
    }
  }
}

if (hasFF(FF.AC455_LINCS)) {
  const lincolnshirePhoneNumbers =
    "NHS Mental Health (Lincolnshire)\n\n" +
    "Lincolnshire Talking Therapies (Boston): +44 0303 123 4000\n" +
    "Lincolnshire Talking Therapies (Gainsborough): +44 0303 123 4000\n" +
    "Lincolnshire Talking Therapies (Grantham): +44 0303 123 4000\n" +
    "Lincolnshire Talking Therapies (Lincoln): +44 0303 123 4000\n" +
    "Lincolnshire Talking Therapies (Louth): +44 0303 123 4000\n" +
    "Lincolnshire Talking Therapies (Skegness): +44 0303 123 4000\n" +
    "Lincolnshire Talking Therapies (Sleaford): +44 0303 123 4000\n" +
    "Lincolnshire Talking Therapies (Spalding): +44 0303 123 4000\n" +
    "Lincolnshire Talking Therapies (Stamford): +44 0303 123 4000"

  const lincolnshireCrisisPhoneNumbers =
    "Local NHS Crisis Contacts\n\n" + "Lincolnshire Mental Health Helpline: 0800 001 4331" // eslint-disable-line

  const lincolnshireTerms =
    "Lincolnshire Talking Therapies [Terms of Service](https://www.lpft.nhs.uk/terms-use)\n" +
    "Lincolnshire Talking Therapies [Website Privacy Notice](https://www.lpft.nhs.uk/privacy-policy)"

  apiKeysMap.LINCOLNSHIRE = {
    backendInstanceID: "LINCS_MAIN",
    title: "Limbic Referral Assistant",
    serviceName: "Lincolnshire Talking Therapies",
    organisationName: "Lincolnshire Talking Therapies",
    organisationTerms: lincolnshireTerms,
    organisationPhoneNumbers: lincolnshirePhoneNumbers,
    crisisPhoneNumbers: lincolnshireCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0303 123 4000",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#01AEF0",
    fullscreen: false,
    eligibleIAPTIds: [
      IAPTIDs.LINCS_GAINSBOROUGH,
      IAPTIDs.LINCS_LOUTH,
      IAPTIDs.LINCS_LINCOLN,
      IAPTIDs.LINCS_SKEGNESS,
      IAPTIDs.LINCS_SLEAFORD,
      IAPTIDs.LINCS_BOSTON,
      IAPTIDs.LINCS_GRANTHAM,
      IAPTIDs.LINCS_SPALDING,
      IAPTIDs.LINCS_STAMFORD
    ],
    iaptGPMap: iaptGPMapLincolnshire,
    discussionStepsOrder: stepsOrder_LINCS,
    dialoguesMap: dialoguesMap_LINCOLNSHIRE,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsLincolnshire),
    customClinicalPaths: lincolnshireCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsLincolnshire)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsLincolnshire)[0],
    freetextLimit: 500,
    adsmEnabled: true
  }
  apiKeysMap.LINCOLNSHIRE_FULLSCREEN = {
    ...apiKeysMap.LINCOLNSHIRE,
    fullscreen: true,
    formLink: "https://gateway.mayden.co.uk/referral-v2/c2ce2fcb-3586-4e64-8405-deac6ce440b1",
    directReferral: true,
    iaptIcons: ["https://limbic-web-bot.s3.eu-west-2.amazonaws.com/lincolnshire/logo.png"]
  }
}

if (hasFF(FF.AC456_SABP)) {
  const sabpPhoneNumbers = ""
  const sabpPhoneTerms =
    "Mind Matters [Terms of Service](https://www.mindmattersnhs.co.uk/about/Confidentiality)"

  apiKeysMap.SABP = {
    title: "Limbic Referral Assistant",
    serviceName: "Mind Matters Surrey NHS",
    organisationName: "Mind Matters Surrey NHS",
    organisationTerms: sabpPhoneTerms,
    organisationPhoneNumbers: sabpPhoneNumbers,
    organisationGenericPhoneNumber: "0300 330 5450",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#6A6983",
    fullscreen: false,
    eligibleIAPTIds: [IAPTIDs.MIND_MATTERS_SAB],
    iaptGPMap: iaptGPMapSABP,
    discussionStepsOrder: stepsOrder_SABP,
    dialoguesMap: dialoguesMap_SABP,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsSABP),
    customClinicalPaths: [],
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsSABP)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsSABP)[0],
    freetextLimit: 500
  }
  apiKeysMap.SABP_FULLSCREEN = {
    ...apiKeysMap.SABP,
    fullscreen: true,
    formLink: "https://gateway.mayden.co.uk/referral-v2/cb5a6c97-0eea-48eb-9d31-aa0621081ada",
    directReferral: true,
    iaptIcons: []
  }
  apiKeysMap.SABP_V2 = {
    isDashboardService: true,
    formLink: "https://gateway.mayden.co.uk/referral-v2/cb5a6c97-0eea-48eb-9d31-aa0621081ada",
    eligibleIAPTIds: [IAPTIDs.MIND_MATTERS_SAB_V2],
    iaptGPMap: iaptGPMapSABP_v2,
    discussionStepsOrder: stepsOrder_SABP,
    dialoguesMap: dialoguesMap_SABP_V2,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsSABP),
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsSABP)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsSABP)[0],
    freetextLimit: 500
  }
}

if (hasFF(FF.AC457_GM_HUB)) {
  const gmHubTerms =
    "Greater Manchester Resilience Hub [Privacy Policy](https://www.penninecare.nhs.uk/yourinformation)"
  const gmHubEmails =
    process.env.REACT_APP_BACKEND_ENV === "production"
      ? ["GM.help@nhs.net"]
      : ["andreas+gmhub@limbic.ai", "ben+gmhub@limbic.ai", "zsuzsa+gmhub@limbic.ai"]

  apiKeysMap.GREATER_MANCHESTER_HUB = {
    backendInstanceID: "GMHUB_MAIN",
    title: "Limbic Referral Assistant",
    serviceName: "Greater Manchester Resilience service",
    organisationName: "Greater Manchester Resilience Hub",
    organisationTerms: gmHubTerms,
    organisationPhoneNumbers: "0333 009 5071",
    organisationGenericPhoneNumber: "0333 009 5071",
    emails: gmHubEmails,
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00B3A8",
    fullscreen: false,
    eligibleIAPTIds: [],
    discussionStepsOrder: stepsOrder_GM_HUB,
    dialoguesMap: dialoguesMap_GM_HUB,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    ageThreshold: 16
  }
  apiKeysMap.GREATER_MANCHESTER_HUB_FULLSCREEN = {
    ...apiKeysMap.GREATER_MANCHESTER_HUB,
    fullscreen: true,
    directReferral: true,
    iaptIcons: ["https://limbic-web-bot.s3.eu-west-2.amazonaws.com/gmhub/logo.png"]
  }
}

if (hasFF(FF.AC459_VITALITY)) {
  apiKeysMap.VITALITY = {
    backendInstanceID: "VITALITY_MAIN",
    title: "Limbic Referral Assistant",
    serviceName: "IPRS Health service",
    organisationName: "IPRS Health",
    organisationTerms: "",
    organisationPhoneNumbers: "0345 070 3040",
    organisationGenericPhoneNumber: "0345 070 3040",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vitality/logo-lb.png",
    userMessageBackground: "#6a6983",
    fullscreen: true,
    iaptIcons: [
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vitality/keeping-safe-logo.png",
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vitality/limbic-logo.png"
    ],
    enableParams: true,
    directReferral: true,
    eligibleIAPTIds: [],
    discussionStepsOrder: stepsOrder_VITALITY,
    dialoguesMap: dialogueMap_VITALITY,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    enableSOS: true
  }
}

if (hasFF(FF.AC461_MPFT)) {
  const MPFTPhoneNumbers = "0300 123 6020"
  const MPFTTerms = "MPFT [Privacy Notice](https://www.mpft.nhs.uk/about-us/information-governance)"

  apiKeysMap.MPFT = {
    title: "Limbic Referral Assistant",
    serviceName: "Shropshire, Telford & Wrekin NHS Talking Therapies",
    organisationName: "Shropshire, Telford & Wrekin NHS Talking Therapies",
    organisationTerms: MPFTTerms,
    organisationPhoneNumbers: MPFTPhoneNumbers,
    organisationGenericPhoneNumber: "0300 123 6020",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/mpft/logo.png",
    userMessageBackground: "#4d5961",
    fullscreen: false,
    eligibleIAPTIds: [IAPTIDs.SHROPSHIRE_TELFORD_WREKIN_MPFT],
    iaptGPMap: iaptGPMapMPFT,
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_MPFT,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    formLink: "https://gateway.mayden.co.uk/referral-v2/43c3838a-08b8-4cf7-8539-2009a89a327c",
    ageThreshold: 18
  }
}

export const MHMCrisisPhoneMapped = {
  [IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON]: "[0800 051 1508](tel:08000511508)",
  [IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON]: "[0800 145 6570](tel:08001456570)",
  [IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND]: "[0303 123 1146](tel:03031231146)"
}

export const MHMCrisisPhoneMappedText = {
  [IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON]:
    "If you need urgent mental health support, please call our 24/7 freephone crisis line on: [0800 051 1508](tel:08000511508)",
  [IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON]:
    "For urgent mental health support, please call our 24/7 freephone helpline: [0800 145 6570](tel:08001456570)",
  [IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND]:
    "If you need urgent mental health support, please call our 24/7 crisis team on: [0303 123 1146](tel:03031231146)"
}

if (hasFF(FF.AC462_MHM)) {
  const MHMTerms = "NHS Talking Therapies [Privacy Policy](https://www.mhm.org.uk/privacy-policy)"

  const MHMOrganisationPhoneNumbers =
    "Warrington: [01925 401 720](tel:01925401720)\nNorthumberland: [0300 3030 700](tel:03003030700)\nSefton: [0300 303 2708](tel:03003032708)"

  const MHMCrisisPhoneNumbers =
    "Sefton: [0800 145 6570](tel:08001456570)\nWarrington: [0800 051 1508](tel:08000511508)\nNorthumberland: [0303 123 1146](tel:03031231146)"

  apiKeysMap.MENTAL_HEALTH_MATTERS = {
    title: "Limbic Self Referral Assistant",
    serviceName: "NHS Talking Therapies",
    organisationName: "NHS Talking Therapies",
    organisationTerms: MHMTerms,
    organisationPhoneNumbers: MHMOrganisationPhoneNumbers,
    crisisPhoneNumbers: MHMCrisisPhoneNumbers,
    iaptCCGMap: {
      [CCGCode.WARRINGTON]: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
      [CCGCode.S_SEFTON]: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
      [CCGCode.NORTHUMBERLAND]: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND
    },
    iaptGPMap: iaptGPMapMHM,
    organisationGenericPhoneNumber: "0123 4567 890",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#b40378",
    fullscreen: false,
    faqLink: "https://limbic.ai/faq",
    eligibleIAPTIds: [
      IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
      IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
      IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON
    ],
    discussionStepsOrder: stepsOrder_MHM,
    dialoguesMap: dialoguesMap_MHM,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsMHM),
    customClinicalPaths: [],
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsMHM)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsMHM)[0],
    adsmEnabled: true,
    freetextLimit: 500
  }
  apiKeysMap.MENTAL_HEALTH_MATTERS_FULLSCREEN = {
    ...apiKeysMap.MENTAL_HEALTH_MATTERS,
    fullscreen: true,
    directReferral: true
  }
}

if (hasFF(FF.AC463_BRADFORD)) {
  apiKeysMap.BRADFORD = {
    isDashboardService: true,
    backendInstanceID: "BRADFORD_MAIN",
    eligibleIAPTIds: [IAPTIDs.BRADFORD],
    iaptCCGMap: {
      [CCGCode.BRADFORD_DISTRICT_CRAVEN]: IAPTIDs.BRADFORD,
      [CCGCode.BRADFORD_CITY]: IAPTIDs.BRADFORD,
      [CCGCode.BRADFORD_DISTRICTS]: IAPTIDs.BRADFORD
    },
    iaptGPMap: iaptGPMapBradford,
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    discussionStepsOrder: stepsOrder_BRADFORD,
    dialoguesMap: dialoguesMap_BRADFORD,
    ageThreshold: 18
  }
  apiKeysMap.BRADFORD_FULLSCREEN = { ...apiKeysMap.BRADFORD, fullscreen: true }
}

if (hasFF(FF.AC464_MIND)) {
  const mindPhoneNumbers = "[0300 123 3393](tel:03001233393)"
  const mindTerms = "Mind [Privacy Policy](https://www.mind.org.uk/legal-info/privacy-policy)"

  apiKeysMap.MIND = {
    title: "Limbic Referral Assistant",
    serviceName: "Mind",
    organisationName: "Mind's supported self-help",
    organisationTerms: mindTerms,
    organisationPhoneNumbers: mindPhoneNumbers,
    backendInstanceID: "MIND_MAIN",
    organisationGenericPhoneNumber: "0300 123 3393",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/mind/mind-logo.png",
    userMessageBackground: "#1300c1",
    fullscreen: false,
    discussionStepsOrder: stepsOrder_MIND,
    dialoguesMap: dialoguesMap_MIND,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    ageThreshold: 18,
    directSubmission: true
  }
  apiKeysMap.MIND_FULLSCREEN = { ...apiKeysMap.MIND, fullscreen: true, directReferral: true }
}

if (hasFF(FF.AC466_LWC)) {
  apiKeysMap.LIVING_WELL_CONSORTIUM = {
    isDashboardService: true,
    eligibleIAPTIds: [IAPTIDs.LWC_SERVICE_1],
    iaptGPMap: iaptGPsMapLWC,
    discussionStepsOrder: stepsOrder_LWC,
    dialoguesMap: dialoguesMap_LWC
  }
  apiKeysMap.LIVING_WELL_CONSORTIUM_FULLSCREEN = {
    ...apiKeysMap.LIVING_WELL_CONSORTIUM,
    fullscreen: true,
    directReferral: true
  }
}

if (hasFF(FF.AC467_CHESHIRE_WIRRAL)) {
  apiKeysMap.CHESHIRE_AND_WIRRAL = {
    isDashboardService: true,
    eligibleIAPTIds: [IAPTIDs.CW_CHE, IAPTIDs.CW_CHW],
    iaptGPMap: iaptGPMapCW,
    discussionStepsOrder: stepsOrder_CHESHIRE_WIRRAL,
    dialoguesMap: dialoguesMap_CHESHIRE_WIRRAL
  }
  apiKeysMap.CHESHIRE_AND_WIRRAL_FULLSCREEN = {
    ...apiKeysMap.CHESHIRE_AND_WIRRAL,
    fullscreen: true,
    directReferral: true
  }
}

if (hasFF(FF.AC468_OXFORD)) {
  apiKeysMap.OXFORD = {
    isDashboardService: true,
    eligibleIAPTIds: [IAPTIDs.OXFORDSHIRE],
    iaptCCGMap: {
      [CCGCode.OXFORDSHIRE]: IAPTIDs.OXFORDSHIRE
    },
    iaptGPMap: iaptGPMapOxford,
    discussionStepsOrder: stepsOrder_OXFORD_BUCKINGHAMSHIRE,
    dialoguesMap: dialoguesMap_OXFORD,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsOxford),
    organisationGenericPhoneNumber: "01865 901222",
    adsmEnabled: true,
    formLink: "https://www.iaptportal.co.uk/oxf.html"
  }
}

if (hasFF(FF.AC469_BUCKS)) {
  apiKeysMap.BUCKINGHAMSHIRE = {
    isDashboardService: true,
    iaptCCGMap: {
      [CCGCode.BUCKINGHAMSHIRE]: IAPTIDs.BUCKINGHAMSHIRE
    },
    eligibleIAPTIds: [IAPTIDs.BUCKINGHAMSHIRE],
    iaptGPMap: iaptGPMapBuckinghamshire,
    discussionStepsOrder: stepsOrder_OXFORD_BUCKINGHAMSHIRE,
    dialoguesMap: dialoguesMap_BUCKINGHSAMHIRE,
    organisationGenericPhoneNumber: "01865 901600",
    adsmEnabled: true,
    formLink: "https://www.iaptportal.co.uk/bucks.html"
  }
}

if (hasFF(FF.AC470_DORKING)) {
  apiKeysMap.DORKING = {
    isDashboardService: true,
    formLink: "https://gateway.mayden.co.uk/referral-v2/f50c0cfc-e5c1-4d49-8d52-35ac42e64bab",
    eligibleIAPTIds: [IAPTIDs.DORKING],
    iaptGPMap: iaptGPMapDorking,
    discussionStepsOrder: stepsOrder_DORKING,
    dialoguesMap: dialoguesMap_DORKING,
    freetextLimit: 500
  }
}

if (hasFF(FF.AC471_BEXLEY)) {
  apiKeysMap.BEXLEY = {
    isDashboardService: true,
    backendInstanceID: "BEXLEY_MAIN",
    eligibleIAPTIds: [IAPTIDs.BEXLEY],
    iaptCCGMap: {
      [CCGCode.BEXLEY]: IAPTIDs.BEXLEY
    },
    iaptGPMap: iaptGPMapBexley,
    discussionStepsOrder: stepsOrder_BEXLEY,
    dialoguesMap: dialoguesMap_BEXLEY,
    ageThreshold: 18
  }
}
