import { step } from "../../../backend/chatbot/decorators/step"
import type { IntroductionIAPTScriptState } from "./IntroductionIAPTDialogue"
import { IntroductionIAPTScript } from "./IntroductionIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"

export type TreatmentPaths = "Referral" | "SilverCloud"

interface State extends IntroductionIAPTScriptState {
  treatmentPath?: TreatmentPaths
}

export type IntroductionDorkingScriptState = State

export class IntroductionDorkingScript extends IntroductionIAPTScript {
  readonly name: string = "IntroductionDorkingScript"

  /* Script Steps */

  @step.logState
  greet(_d: IStepData<State>): IStepResult {
    const { directReferral } = this.rootStore.configStore
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: [
        "Hi there!",
        "I’m Limbic",
        `I'm a friendly robot assistant to help you access psychological support from ${organisationName}`
      ],
      nextStep: directReferral ? this.sayStaticReferralURL : this.askCanIHelp
    }
  }

  @step.logState
  sayStaticReferralURL(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "To refer yourself to our service, please use the Limbic Self-Referral Assistant. This is the easiest way to ensure our team receive your referral quickly"
      ),
      isStaticReferralURL: true,
      nextStep: this.end
    }
  }

  @step.logState
  askCanIHelp(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: "Can I help?",
      prompt: {
        id: this.getPromptId("askCanIHelp"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: `Yes, I'd like to get support from ${organisationName}`, value: true },
          { body: "No, I'm just browsing", value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleCanIHelp
    }
  }

  @step.logState
  handleCanIHelp(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askTreatmentPath }
    }
    return { nextStep: this.sayIfYouChangeYourMindWakeMeUp }
  }

  @step.logState
  askTreatmentPath(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Some people prefer the convenience of accessing Cognitive Behavioural Therapy (CBT) via our online platform, SilverCloud. You can work through your chosen programme at your own pace with regular reviews with a therapist. For more information or to sign up to this service, please visit our [website](https://dhctalkingtherapies.co.uk/computerbasedcbt/)",
        "If you would prefer to attend a group course/workshop or 1-2-1 therapy we can continue with your referral. If you would like further information on the groups we run, you can read about these on our [website](https://dhctalkingtherapies.co.uk/group-courses-workshops/)",
        "Which treatment path are you most interested in?"
      ],
      prompt: {
        id: this.getPromptId("askTreatmentPath"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: "Continue with self-referral for a group course/workshop or 1-2-1 talking therapy",
            value: "Referral"
          },
          {
            body: "Start SilverCloud now",
            value: "SilverCloud"
          }
        ]
      },
      nextStep: this.handleTreatmentPath
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, TreatmentPaths>, script: IntroductionDorkingScript) => {
    d.state.treatmentPath = d.response
    script.referralStore.setCustomField<State>("treatmentPath", d.response)
  })
  handleTreatmentPath(d: IStepData<State, TreatmentPaths>): IStepResult {
    if (d.response === "SilverCloud") {
      return { nextStep: this.signpostOnlineModules }
    }
    return { nextStep: this.end }
  }

  @step.logState
  signpostOnlineModules(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Please visit the link below to get started with your online modules",
        "Write 'Hello Limbic' at any time if you want to carry on with me instead"
      ],
      prompt: {
        id: this.getPromptId("signpostOnlineModules"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: "CBT via SilverCloud 👉",
            onPress: () => {
              const a = document.createElement("a")
              a.setAttribute("href", "https://dhctalkingtherapies.co.uk/computerbasedcbt/")
              a.setAttribute("target", "_blank")
              a.click()
            }
          }
        ],
        textPrompt: {
          placeholder: 'Type "Hello Limbic" to continue here',
          forceValue: true
        }
      },
      nextStep: this.handleTypeHeyLimbicToWakeMeUpWithCrisis
    }
  }

  /** Generic Handlers */
}

/* istanbul ignore next */
export default class IntroductionDorkingDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionDorking
  readonly name: string = "IntroductionDorkingDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IntroductionDorkingDialogue.id, new IntroductionDorkingScript(), state, snapshot)
  }
}
