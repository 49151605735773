import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = RiskPathwayScriptState

export type RiskPathwayMPFTScriptState = State

export class RiskPathwayMPFTScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayMPFTScript"

  /** Script Steps */
  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you able to keep yourself, and any dependants in your care, safe until your next appointment? This could be up to 2 weeks although likely to be sooner",
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until your next appointment? This could be up to 2 weeks although likely to be sooner",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>, script: RiskPathwayMPFTScript) => {
    d.state.canKeepSelfSafe = d.response
    script.referralStore.setCustomField<State>("canKeepSelfSafe", d.response)
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  async sayCrisis(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: [
        `Thanks for sharing ${name}, we know it's not always easy`,
        `However ${organisationName} does not provide urgent care`,
        "Sometimes people have thoughts of harming themselves or that life isn’t worth living. If you are concerned about your safety, it is important that you talk to someone, support is available"
      ],
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "If you need urgent support, please dial NHS 111 and select Option 2",
        "In medical emergency and life threatening situations only, please dial 999 or attend your local A&E department",
        `You can also contact the local Urgent NHS Mental Health Helpline on 0808 196 4501`,
        "Other helplines available:\n\n" +
          "You can contact Samaritans 24 hours a day, 365 days a year. You can call 116 123 (free from any phone) or email jo@samaritans.org\n" +
          "If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258. Shout offers a confidential 24/7 text service providing support if you are in need of immediate help"
      ],
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111 (Option 2), Samaritans (116 123)"
    )
    return {
      body: "Let's carry on with the mental health check in",
      nextStep: this.end
    }
  }

  @step.logState
  sayThanksForSharing(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const body = [`Thanks for sharing, ${name}`, "I've made a note of that"]
    return {
      body,
      prompt: {
        id: this.getPromptId("sayThanksForSharing"),
        type: "inlinePicker",
        choices: [{ body: "Thanks" }, { body: "Okay" }],
        isUndoAble: false
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    if (state.canKeepSelfSafe) return { nextStep: this.sayThanksForSharing }
    return { nextStep: this.sayCrisis }
  }
}

/* istanbul ignore next */
export default class RiskPathwayMPFTDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayMPFT
  readonly name: string = "RiskPathwayMPFTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayMPFTDialogue.id, new RiskPathwayMPFTScript(), state, snapshot)
  }
}
