import moment from "moment"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import SelfReferralIAPTScript from "./SelfReferralIAPTScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isValidPhoneNumber"
import { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import {
  nationalities,
  religions,
  ethnicities,
  exArmedForces,
  languages,
  sexualities,
  genders,
  disabilities,
  sameGenderAsBirth
} from "../../../config/referralForms/mind"
import { genderSameAsBirth } from "../../../config/referralForms/lincolnshire-form"
import {
  ARMED_FORCES_VIEWS_MIND,
  DISABILITY_VIEWS_MIND,
  ETHNICITY_VIEWS_MIND,
  GENDER_SAME_AS_BIRTH_VIEWS,
  GENDER_VIEWS_MIND,
  LANGUAGE_VIEWS_MIND,
  NATIONALITY_VIEWS_MIND,
  MindPastTreatments,
  MindPronouns,
  ReferralPayloadViews,
  RELIGION_VIEWS_MIND,
  SEXUALITY_VIEWS_MIND,
  MindTitles,
  MindCurrentSupports,
  MindContactOptions
} from "@limbic/types"
import { IStep } from "../../../backend/chatbot/models/IStep"
import { ICollectMainIssueSettings } from "../ad-hoc/CollectMainIssue/CollectMainIssueDialogue"
import {
  CollectGoalForTherapyScriptState,
  ICollectGoalForTherapySettings
} from "../ad-hoc/CollectGoalForTherapy/CollectGoalForTherapyDialogue"
import { ICollectPreferredCorrespondenceSettings } from "../ad-hoc/CollectPreferredCorrespondence/CollectPreferredCorrespondenceDialogue"
import {
  CollectGenderScriptState,
  ICollectGenderSettings
} from "../ad-hoc/CollectGender/CollectGenderDialogue"
import {
  CollectSexualityScriptState,
  ICollectSexualitySettings
} from "../ad-hoc/CollectSexuality/CollectSexualityDialogue"
import {
  CollectNationalityScriptState,
  ICollectNationalitySettings
} from "../ad-hoc/CollectNationality/CollectNationalityDialogue"
import { ICollectDisabilitiesSettings } from "../ad-hoc/CollectDisabilities/CollectDisabilitiesDialogue"
import { ICollectEmailSettings } from "../ad-hoc/CollectEmail/CollectEmailDialogue"
import { parsePhoneNumber } from "awesome-phonenumber"

export interface SelfReferralMindState extends SelfReferralIAPTScriptState {
  currentSupport?: MindCurrentSupports
  currentSupportDetails?: string
  preferredTitle?: MindTitles
  preferredPronouns?: MindPronouns
  nhsStaff?: boolean
  policeOrFireService?: boolean
  parentalStatus?: boolean
  previousMHCare?: MindPastTreatments
  previousMHCareDetails?: string
  referrerName?: string
  referrerEmail?: string
  isSelfReferrer?: boolean
}

type State = SelfReferralMindState
export type SelfReferralMindScriptState = State

export class SelfReferralMindScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralMindScript"

  /** Script Steps */

  @step.logState
  startSelfReferralPart1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.goToCollectMainIssue }
  }

  @step.logState
  askNHSMemberArmedForcesPoliceOrFireService(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Do any of the following apply to you?"),
      prompt: {
        id: this.getPromptId("askNHSmemberArmedForcesPoliceorFireService"),
        trackResponse: true,
        type: "inlinePickerMultiSelect",
        choices: [
          { body: this.t("NHS member of staff"), value: "NHS" },
          { body: this.t("Ex-member of the British Armed Forces"), value: "Yes - ex services" },
          {
            body: this.t("Immediate family member of the British Armed Forces"),
            value: "Dependant on ex-serving member"
          },
          { body: this.t("Police member"), value: "Police" },
          { body: this.t("Fire service member"), value: "Fire service" },
          {
            body: this.t("None"),
            value: "No",
            selectIndividually: true,
            backgroundColor: "#EC9CC8"
          },
          {
            body: this.t("Prefer not to say"),
            value: "Not stated (Person asked but declined to provide a response)",
            selectIndividually: true,
            backgroundColor: "#EC9CC8"
          }
        ]
      },
      nextStep: this.handleNHSMemberArmedForcesPoliceOrFireService
    }
  }

  @step.logState
  handleNHSMemberArmedForcesPoliceOrFireService(
    d: IStepData<
      State,
      Array<
        | "NHS"
        | "Yes - ex services"
        | "Dependant on ex-serving member"
        | "Police"
        | "Fire service"
        | "No"
        | "Not stated (Person asked but declined to provide a response)"
      >
    >
  ): IStepResult {
    d.state.nhsStaff = d.response.includes("NHS")
    d.state.policeOrFireService =
      d.response.includes("Police") || d.response.includes("Fire service")
    d.state.isExArmedForces = d.response.includes("Yes - ex services")
      ? "Yes - ex services"
      : d.response.includes("Dependant on ex-serving member")
        ? "Dependant on ex-serving member"
        : "No"

    if (d.response.includes("No")) {
      d.state.nhsStaff = false
      d.state.policeOrFireService = false
      d.state.isExArmedForces = "No"
    }

    if (d.response.includes("Not stated (Person asked but declined to provide a response)")) {
      d.state.isExArmedForces = "Not stated (Person asked but declined to provide a response)"
    }
    return { nextStep: this.goToCollectPhoneNumber }
  }

  @step.logState
  startDemographicQuestions(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Thank you",
        "We now need to ask you some demographic questions. We ask this in order to ensure we are equally supporting all members of our community"
      ]),
      nextStep: this.goToCollectGender
    }
  }

  @step.logState
  askParentalStatus(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Are you currently pregnant, or do you have a child under the age of 1?",
        "This includes if you are a father or co-parent"
      ]),
      prompt: {
        id: this.getPromptId("askPerinatal"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askParentalStatus"
      },
      nextStep: this.handleParentalStatus
    }
  }

  @step.logState
  handleParentalStatus(d: IStepData<State, boolean>): IStepResult {
    d.state.parentalStatus = d.response
    return { nextStep: this.askPreviousMHCare }
  }

  // TODO: use the preset flow for this once the updated version
  //       that allows for customisation is merged
  @step.logState
  askPreviousMHCare(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Have you previously accessed mental health support in the last 12 months?"),
      prompt: {
        id: this.getPromptId("askPreviousMentalHealthCare"),
        type: "inlinePicker",
        choices: [
          { body: this.t("No"), value: "No" },
          {
            body: this.t("In hospital treatment for mental health support"),
            value: "Hospital"
          },
          {
            body: this.t("Other mental health support"),
            value: "Other"
          }
        ]
      },
      nextStep: this.handlePreviousMHCare
    }
  }

  @step.logState
  handlePreviousMHCare(d: IStepData<State, MindPastTreatments>): IStepResult {
    d.state.previousMHCare = d.response
    if (d.response === "No") return { nextStep: this.showKeepingSafeInfo }
    return { nextStep: this.askPreviousMHCareDetails }
  }

  @step.logState
  askPreviousMHCareDetails(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Please provide details"),
      prompt: {
        id: this.getPromptId("askPreviousMHCareDetails"),
        trackResponse: true,
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePreviousMHCareDetailsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.previousMHCareDetails = d.response
  })
  @step.checkInputForCrisis({ getNextStep: (s: SelfReferralMindScript) => s.showKeepingSafeInfo })
  handlePreviousMHCareDetailsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.showKeepingSafeInfo }
  }

  @step.logState
  sayReferralSucceeded(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "And that's everything",
        "You've officially been referred to Mind's supported self-help",
        "Congratulations on taking this important step towards better mental health!"
      ]),
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [{ body: this.t("What happens next?") }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.end
    }
  }

  /* Generic Handlers */

  getGenders(state: State): string[] {
    return Object.keys(genders) as Array<string>
  }

  getGenderSameAsBirthValues(state: State): string[] {
    return Object.keys(sameGenderAsBirth) as Array<string>
  }

  getSexualities(state: State): string[] {
    return Object.keys(sexualities) as Array<string>
  }

  getEthnicities(state: State): string[] {
    return Object.keys(ethnicities) as Array<string>
  }

  getNationalities(state: State): string[] {
    return Object.keys(nationalities) as Array<string>
  }

  getDisabilities(state: State): string[] {
    return Object.keys(disabilities) as Array<string>
  }

  async getCollectMainIssueSettings(state: State): Promise<ICollectMainIssueSettings> {
    const name = this.getName(state)
    return {
      messages: {
        askMainIssue: this.t([
          "Thanks. You're doing really well",
          "What's the main issue that has brought you here today?",
          "(Please try to describe your thoughts, feelings, things that trouble you, and the impact this is having on your life in a few sentences)"
        ]),
        closingMessage: [
          this.t(
            "Thank you for sharing, I know that may have been difficult. You're making good progress {name}, let's continue with the referral",
            { name }
          )
        ]
      }
    }
  }

  async onCollectMainIssueEnded(state: State): Promise<IStep> {
    return this.goToCollectGoalForTherapy
  }

  async getCollectGoalForTherapySettings(state: State): Promise<ICollectGoalForTherapySettings> {
    return {
      messages: {
        askWhatIsYourGoal: this.t(["What would you like to achieve by accessing support?"])
      }
    }
  }

  async getCollectGoalForTherapyState(state: State): Promise<CollectGoalForTherapyScriptState> {
    return { forceGoalForTherapyValue: true }
  }

  async onCollectGoalForTherapyEnded(state: State): Promise<IStep> {
    return this.askNHSMemberArmedForcesPoliceOrFireService
  }

  async onCollectPhoneNumberEnded(state: State): Promise<IStep> {
    return this.goToCollectEmail
  }

  async getCollectEmailSettings(state: State): Promise<ICollectEmailSettings> {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      messages: {
        askDoYouWantToShareEmail: this.t(["Do you have an email address?"]),
        askEmailPermission: this.t(
          [
            "Do we have permission to send you an email to that address?",
            "(Please note: If you do not provide consent you will not be sent a confirmation email or booking reminders for any contact with {organisationName})"
          ],
          { organisationName }
        )
      },
      hasPreliminaryQuestion: true,
      shouldAskEmailPermission: true,
      customisableOptions: {
        askDoYouWantToShareEmail: [
          { body: this.t("Yes, I have an email"), value: true },
          { body: this.t("No, I don't have an email"), value: false }
        ]
      }
    }
  }

  async onCollectEmailEnded(state: State): Promise<IStep> {
    return this.goToCollectPreferredCorrespondence
  }

  async getCollectPreferredCorrespondenceSettings(
    state: State
  ): Promise<ICollectPreferredCorrespondenceSettings> {
    return {
      messages: {
        askPreferredContactMethod: this.t([
          "And what is your preferred method of contact for appointment booking and correspondence?"
        ])
      },
      options: ["Telephone", "E-mail", "Text"]
    }
  }

  async onCollectPreferredCorrespondenceEnded(state: State): Promise<IStep> {
    return this.goToCheckPostCodeForAddressLookup
  }

  async onCollectPostcodeAndAddressEnded(state: State): Promise<IStep> {
    return this.startDemographicQuestions
  }

  async getCollectGenderSettings(state: State): Promise<ICollectGenderSettings> {
    const genders = this.getGenders(state)
    return {
      optionsGender: genders.filter(g => g !== "Other").map(g => ({ body: this.t(g), value: g })),
      optionsGenderSameAsBirth: this.getGenderSameAsBirthValues(state).map(g => ({
        body: g,
        value: g
      }))
    }
  }

  async getCollectGenderState(state: State): Promise<CollectGenderScriptState> {
    return { withTextPrompt: true }
  }

  async onCollectGenderEnded(state: State): Promise<IStep> {
    return this.goToCollectSexuality
  }

  async getCollectSexualitySettings(state: State): Promise<ICollectSexualitySettings> {
    const sexualities = this.getSexualities(state)
    return {
      options: sexualities.map(s => ({ body: this.t(s), value: s })),
      messages: { askSexuality: this.t(["What is your sexual orientation?"]) }
    }
  }

  async getCollectSexualityState(state: State): Promise<CollectSexualityScriptState> {
    return { withTextPrompt: true }
  }

  async onCollectSexualityEnded(state: State): Promise<IStep> {
    return this.goToCollectEthnicity
  }

  async onCollectEthnicityEnded(state: State): Promise<IStep> {
    return this.goToCollectNationality
  }

  async getCollectNationalitySettings(state: State): Promise<ICollectNationalitySettings> {
    const nationalities = this.getNationalities(state)
    return {
      // This is temporary should use the actual value when dashboard is done
      options: nationalities.filter(n => n !== "Other").map(n => ({ body: this.t(n), value: n }))
    }
  }

  async getCollectNationalityState(state: State): Promise<CollectNationalityScriptState> {
    return { withTextPrompt: true }
  }

  async onCollectNationalityEnded(state: State): Promise<IStep> {
    return this.goToCollectDisabilities
  }

  async getCollectDisabilitiesSettings(state: State): Promise<ICollectDisabilitiesSettings> {
    return {
      choicesMap: this.getDisabilities(state).map(d => ({ body: this.t(d), value: d })),
      shouldAskDisabilityStatus: true,
      multiSelectDisabilities: true
    }
  }

  async onCollectDisabilitiesEnded(state: State): Promise<IStep> {
    return this.askParentalStatus
  }

  async getReferralPayload(state: State): Promise<ReferralPayloadViews> {
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile
    const parsed = parsePhoneNumber(state.phoneNumber || "0", { regionCode: "GB" })

    return {
      instanceID: "MIND_MAIN",
      serviceApiKey: "mind",
      nameFirst: this.getName(state),
      nameLast: this.getLastName(state),
      nameFirstPreferred: state.preferredName,
      title: state.preferredTitle,
      pronouns: state.preferredPronouns,
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || state.address,
        county: state.county || state.address || "n/a",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      phoneHome: isValidLandline
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      email: state.email,
      consentEmail: state.canSendEmail,
      consentDataShare: true,
      consentDataStore: true,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      disability: this.getDisability(state),
      language: this.getLanguage(state),
      sexuality: this.getSexuality(state),
      gender: this.getGender(state),
      genderSameAsBirthAssigned: this.getGenderSameAsBirthAssigned(state),
      ethnicity: this.getEthnicity(state),
      nationality: this.getNationality(state),
      armedForces: this.getExArmedForces(state),
      problemInOwnWords: state.mainIssue,
      treatmentExpectation: state.therapyGoal,
      questionnaires: this.getQuestionnairesPayload(state),
      consentResearch: state.consentResearch,
      // MindContactOptions is a subset of CorrespondencePreferred
      correspondencePreferred: state.preferredContactMethod as MindContactOptions,
      policeOrFireService: state.policeOrFireService,
      requiresUrgentSupport: state.requiresUrgentSupport,
      nhsStaff: state.nhsStaff,
      currentSupport: state.currentSupport,
      currentSupportDetails: state.currentSupportDetails,
      pastTreatment: state.previousMHCare,
      pastTreatmentDetails: state.previousMHCareDetails,
      isSelfReferrer: state.isSelfReferrer,
      ...(state.referrerName &&
        state.referrerEmail && {
          referrer: { name: state.referrerName, email: state.referrerEmail }
        }),
      genderOther: state.genderOther,
      sexualityOther: state.sexualityOther,
      nationalityOther: state.nationalityOther,
      perinatalStatus: state.parentalStatus
    }
  }

  getLanguage(state: State): LANGUAGE_VIEWS_MIND | undefined {
    return languages[state.primaryLanguage || "English"]
  }

  getEthnicity(state: State): ETHNICITY_VIEWS_MIND | undefined {
    return ethnicities[state.ethnicity || "Not Stated"]
  }

  getExArmedForces(state: State): ARMED_FORCES_VIEWS_MIND | undefined {
    return exArmedForces[
      state.isExArmedForces || "Not stated (Person asked but declined to provide a response)"
    ]
  }

  getReligion(state: State): RELIGION_VIEWS_MIND | undefined {
    return religions[state.religion || "Patient Religion Unknown"]
  }

  getSexuality(state: State): SEXUALITY_VIEWS_MIND | undefined {
    return sexualities[state.sexuality || "Do not wish to say"]
  }

  getNationality(state: State): NATIONALITY_VIEWS_MIND | undefined {
    return nationalities[state.nationality || "Other"]
  }

  getGender(state: State): GENDER_VIEWS_MIND | undefined {
    return genders[state.gender || "I don't want to say"]
  }

  getGenderSameAsBirthAssigned(state: State): GENDER_SAME_AS_BIRTH_VIEWS | undefined {
    return genderSameAsBirth[state.sameGenderAsBirth || "I don't want to say"]
  }

  getDisability(state: State): DISABILITY_VIEWS_MIND[] | undefined {
    return state.disabilities ? state.disabilities.map(d => disabilities[d]) : ["NONE"]
  }
}

/* istanbul ignore next */
export default class SelfReferralMindDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralMind
  readonly name: string = "SelfReferralMindDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralMindDialogue.id, new SelfReferralMindScript(), state, snapshot)
  }
}
