import moment from "moment"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { AssessmentWithADSMScript, AssessmentWithADSMScriptState } from "./AssessmentWithADSMScript"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"

type State = AssessmentWithADSMScriptState

export class AssessmentVHGScript extends AssessmentWithADSMScript {
  readonly name: string = "AssessmentVHGScript"

  /** Generic Handlers */

  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReadyForWASAS }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }

  getPersonalInfoHTML(state: State): string {
    // prettier-ignore
    return ([] as Array<string | false | undefined>)
        .concat(
            `<b>Name:</b> ${state.username}<br/>`,
            !!state.birthday && `<b>Date of Birth:</b> ${moment(state.birthday).format("DD/MM/YYYY")}<br/>`,
            !!state.birthday && `<b>Underaged:</b> ${!!state.isUnderAged}<br/>`,
            `<b>Postcode:</b> ${state.userPostcode?.postcode || "N/A"}<br/>`
        )
        .filter(Boolean)
        .join("\n")
  }

  getClinicalNotesHTML(state: State): string {
    return ""
  }

  getQuestionnairesInfoHTML(state: State): string {
    return ""
  }
}

/* istanbul ignore next */
export default class AssessmentVHGDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentVHG
  readonly name: string = "AssessmentVHGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentVHGDialogue.id, new AssessmentVHGScript(), state, snapshot)
  }
}
