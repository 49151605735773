import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { IDefaultChatFlowMessagesSubmitReferral, ReferralPayload } from "@limbic/types"
import { TrackingEvents } from "../../../../models/Constants"
import moment from "moment"
import { ReferralPayloadBuilder } from "../../../../models/PayloadBuilder/ReferralPayloadBuilder"

export type ISubmitReferralSettings = {
  messages?: IDefaultChatFlowMessagesSubmitReferral
  payloadMap?: Record<string, Record<string, any>>
  questionsToSkip?: string[]
}

type State = BaseScriptState
export type SubmitReferralScriptState = State

export class SubmitReferralScript extends BaseScript<State> {
  readonly name: string = "SubmitReferralScript"
  protected messages: IDefaultChatFlowMessagesSubmitReferral | undefined
  protected payloadMap: Record<string, Record<string, any>>
  protected questionsToSkip: string[] | undefined
  constructor(settings: ISubmitReferralSettings | undefined = {}) {
    super()
    this.messages = settings?.messages ?? {}
    this.payloadMap = settings.payloadMap ?? {}
    this.questionsToSkip = settings.questionsToSkip ?? []
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.doReferralSubmission }
  }

  @step.logState
  async doReferralSubmission(d: IStepData<State>): Promise<IStepResult> {
    const referralSubmitted = await this.onSubmitReferralData(d.state)
    if (!referralSubmitted) {
      d.state.referralSubmitted = false
      d.state.referralSubmissionFailed = true
      this.setPeople({ referralSubmissionFailed: true })
      return { nextStep: this.sayReferralFailed }
    }
    const age = this.getUserAge(d.state)
    if (age) await this.rootStore.dataPointsStore.sendDataPoints(age)
    d.state.referralSubmitted = true
    d.state.referralSubmissionFailed = false
    if (this.clinicalStore.isRisk) await this.onRiskReferralFinished(d.state)
    this.setPeople({ referralSubmitted: true })
    return { nextStep: this.sayReferralSucceeded }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.sayReferralSucceeded ?? [
          "And that's everything",
          "You've officially been referred to {iaptName}",
          "Congratulations on taking this important step towards better mental health!"
        ],
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [{ body: "What happens next?" }]
      },
      nextStep: this.end
    }
  }

  @step.logState
  sayReferralFailed(d: IStepData<State>): IStepResult {
    const url = d.state.iapt?.referralForm?.form_url
    const body = [
      "Oops... I'm really sorry about this, but it seems like something has gone wrong when trying to submit your data to {iaptName}",
      "I've notified my creators of this issue",
      url
        ? "If you don't wish to wait, you can manually refer yourself by following this link [here]({url})"
        : undefined
    ].filter(Boolean)

    return {
      body: this.t(this.messages?.sayReferralFailed ?? body, { ...this.getContext(d.state), url }),
      prompt: {
        id: this.getPromptId("sayReferralFailed"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.goToGoodbye
    }
  }

  async getReferralPayload(state: State): Promise<Partial<ReferralPayload>> {
    return new ReferralPayloadBuilder()
      .withTransformMap(this.rootStore.configStore.backendMapping?.transformMap)
      .withTargetKeys(this.rootStore.configStore.backendMapping?.targetKeys)
      .withContext({ state, customFields: this.referralStore.customFields })
      .withData({
        serviceApiKey: this.rootStore.configStore.dashboardServiceKey,
        consentDataShare: true,
        consentDataStore: true,
        consentResearch: state.consentResearch,
        dob: moment(state.birthday).format("YYYY-MM-DD"),
        title: state.title ?? state.nameTitle,
        nhsNumber: state.nhsNumber,
        pronounsPreferred: state.preferredPronouns,
        nameFirst: this.getFirstName(state),
        nameLast: this.getLastName(state),
        nameFirstAlias: state.preferredName || undefined,
        gpCodeNACS: state.odsGP?.id ?? state.gp?.nacsCode,
        gpName: state.odsGP?.name ?? state.gp?.name,
        output: this.referralStore.referralType,
        referralSourceDetails: state.whereDidYouHearAboutService || undefined,
        riskLevel: this.clinicalStore.riskLevel,
        riskLevelReason: this.clinicalStore.riskLevelReason,
        triggerWords: this.clinicalStore.triggerWords,
        clinicalNotes: this.referralStore.clinicalNotes
      })
      .withChatBotFlow(this.rootStore.configStore.dialogueFlows)
      .withCustomTransforms()
      .build()
  }

  /** Generic handlers */

  async onSubmitReferralData(state: State): Promise<boolean> {
    try {
      const payload = await this.getReferralPayload(state)

      if (this.referralStore.hasReferral) await this.referralStore.updateReferral(payload)
      else await this.referralStore.createReferral(payload)

      this.referralStore.setShouldHavePatientIdAndInstanceId(true)
      state.patientId = this.referralStore.patientId
      state.signupCode = this.referralStore.signupCode
      this.track(TrackingEvents.SELF_REFERRAL_SUBMITTED)
    } catch (e) {
      this.referralStore.setShouldHavePatientIdAndInstanceId(false)
      this.logException(e, "onSubmitReferralData")
      return false
    }
    return true
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async onRiskReferralFinished(state: State): Promise<void> {}

  getContext(state: State): Record<string, any> {
    return {
      ...this.rootStore.configStore,
      name: this.getName(state),
      iaptName: this.getIAPTName(state) ?? this.rootStore.configStore.organisationName
    }
  }
}

export default class SubmitReferralDialogue extends AdHocDialogue<State, SubmitReferralScript> {
  static id = DialogueIDs.SubmitReferral
  readonly name: string = "SubmitReferralDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ISubmitReferralSettings
  ) {
    super(
      SubmitReferralDialogue.id,
      new SubmitReferralScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
