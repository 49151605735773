import React from "react"
import { observer } from "mobx-react"
import "./Sidebar.scss"
import { useConfigStore } from "../../contexts/RootStoreContext"
import { sidebarsMap } from "./sidebarsMap"
import NHSComplianceCard from "./components/NHSComplianceCard"
import HIPPAComplianceCard from "./components/HIPPAComplianceCard"

export interface Props {
  visible?: boolean
}

function updateLinksToNewTab(htmlString: string): string {
  const tempDiv = document.createElement("div")
  tempDiv.innerHTML = htmlString

  const links = tempDiv.querySelectorAll("a")
  links.forEach(link => {
    link.setAttribute("target", "_blank")
    link.setAttribute("rel", "noopener noreferrer")
  })

  return tempDiv.innerHTML
}

function Sidebar(props: Props): JSX.Element | null {
  const config = useConfigStore()
  const { visible } = props
  if (!visible) {
    return null
  }

  const customBigBot = config.customBigBot ?? {}

  const CustomSidebar = sidebarsMap[config.key]
  if (CustomSidebar) return <CustomSidebar {...props} />

  return (
    <div className="lb-sidebar-container">
      <article className="lb-sidebar-card">
        <div className="lb-header">
          <img src={config.logo} className="lb-header-img" alt="" />
          <h3 className="subtitle">
            {customBigBot.sidebarTitle ?? `Refer yourself to ${config.organisationName}`}
          </h3>
        </div>
        {customBigBot.sidebarText ? (
          <div
            dangerouslySetInnerHTML={{ __html: updateLinksToNewTab(customBigBot.sidebarText) }}
          />
        ) : (
          <p>
            We are your local NHS talking therapies service. It’s a free service. We are open and
            ready to support you. We offer a range of short-term psychological therapies to adults.
          </p>
        )}
        {customBigBot.sidebarIAPTIcon ? (
          <img className="nhs-logo" src={customBigBot.sidebarIAPTIcon} alt="NHS Logo" />
        ) : (
          config.iaptIcons?.map?.(link => (
            <img key={link} className="nhs-logo" src={link} alt={link} />
          ))
        )}
      </article>
      <div className="lb-sidebar-spacer" />
      <NHSComplianceCard />
      <HIPPAComplianceCard />
    </div>
  )
}

export default observer(Sidebar)
