import NetworkError from "../../../models/NetworkError"
import Logger from "../../../utils/Logger"
import IPostcode, { PostcodeStatus } from "../../../models/IPostcode"
import delay from "../../../utils/delay"
import { isOnline } from "../../../utils/isOnline"
import { Errors, isError } from "../../../utils/error"

const BASE_URL = "https://api.postcodes.io/postcodes"
const TOTAL_RETRIES = 3

const headers = new Headers()
headers.set("Content-Type", "application/json")

export async function getPostCodeDetails(
  postCode?: string
): Promise<[IPostcode | undefined, PostcodeStatus]> {
  const [postcode, error] = await get(`${BASE_URL}/${postCode}`)
  return [postcode ? serverPostcodeToIPostcode(postcode) : undefined, error]
}

export async function get(url: string, retry = 0): Promise<[any, PostcodeStatus]> {
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) return [undefined, PostcodeStatus.NoInternetConnection]

    const result = await fetch(url, { headers })
    const data = await result.json()
    if (data?.error) {
      throw new NetworkError(data?.status, data?.error)
    }
    return [data.result, PostcodeStatus.Success]
  } catch (e) {
    Logger.getInstance().breadcrumb({
      message: "getPostCodeDetails failed",
      data: { url, retry, error: e.message }
    })

    if (isError(e, Errors.INVALID_POSTCODE)) return [undefined, PostcodeStatus.InvalidPostcode]
    if (isError(e, Errors.POSTCODE_NOT_FOUND)) return [undefined, PostcodeStatus.PostcodeNotFound]

    Logger.getInstance().exception(e.message, "getPostCodeDetails failed")

    if (retry < TOTAL_RETRIES) {
      await delay(2 * retry || 1.5)
      return await get(url, retry + 1)
    } else {
      return [undefined, PostcodeStatus.RequestFailed]
    }
  }
}

interface IServerPostcode {
  postcode: string
  longitude: number
  latitude: number
  ccg: string
  codes: {
    ccg_id: string
  }
  country?: string
}

function serverPostcodeToIPostcode(serverPostcode: IServerPostcode): IPostcode {
  return {
    postcode: serverPostcode.postcode,
    longitude: serverPostcode.longitude,
    latitude: serverPostcode.latitude,
    ccg: serverPostcode.ccg,
    ccgId: serverPostcode.codes.ccg_id,
    ...(serverPostcode?.country && { country: serverPostcode.country })
  }
}
