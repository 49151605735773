import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { TrackingEvents } from "../../../../models/Constants"
import {
  IDefaultChatFlowMessagesServiceSearch,
  IDefaultChatFlowSettingsServiceSearch,
  EligibilityPresetFlowResults
} from "@limbic/types"
import { PostcodeStatus } from "../../../../models/IPostcode"
import { getPostCodeDetails } from "../../../../backend/api/external/postcodes"
import { IIAPTService } from "../../../../models/IIAPTService"
import { ISelectable } from "@limbic/types"
import { IGPService } from "../../../../models/IGPService"
import getIAPTById from "../../../../utils/getIAPTById"
import { INHSStatus } from "../../../../models/INHS"
import {
  getGPServicesByName,
  getGPServicesByPostcode,
  getIAPTServicesByCCG
} from "../../../../backend/api/external/nhs"
import getIsEligibleGPForIAPT from "../../../../utils/isEligibleForIAPT"
import customIAPTSHaveError from "../../../../utils/customIAPTSHaveError"

export type IServiceSearchSettings = IDefaultChatFlowSettingsServiceSearch & {
  messages?: IDefaultChatFlowMessagesServiceSearch
}

export interface State extends BaseScriptState {
  retryPostcodeTimes?: number
  retryGPTimes?: number
  retryPostcodeGP?: string
  userDoesntKnowGPName?: boolean
  eligibleIAPTs?: IIAPTService[]
  gpNameEntered?: string
}

export type ServiceSearchScriptState = State

export class ServiceSearchScript extends BaseScript<State> {
  readonly name: string = "ServiceSearchScript"
  protected readonly messagesServiceSearch: IDefaultChatFlowMessagesServiceSearch | undefined
  protected readonly enableReferAnyway?: boolean
  protected readonly retryPostcodeTimes?: number
  protected readonly retryGPTimes?: number

  constructor(settings?: IServiceSearchSettings | undefined) {
    super()
    this.messagesServiceSearch = settings?.messages ?? {}
    this.retryPostcodeTimes = settings?.retryPostcodeTimes ?? 3
    this.retryGPTimes = settings?.retryGPTimes ?? 3
    this.enableReferAnyway = settings?.enableReferAnyway
  }

  /** Abstract Optional Step Handlers */

  async onPostcodeOfUserSuccessful?(state: State): Promise<IStepResult | void>

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    return { nextStep: this.startEligibilityCheck }
  }

  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askYourAreaOrGPPostCode }
  }

  @step.logState
  askYourAreaOrGPPostCode(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        this.messagesServiceSearch?.askYourAreaOrGPPostCode ?? [
          `So ${name}, in order to find the right health service for you, I first need to locate your registered GP`,
          "If you want to give me your post code, I can look for GP clinics in your area",
          "Alternatively, if you know the name/address of your GP clinic, we can go from there",
          "Which would you prefer?"
        ],
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("postCodeOrDetails"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Help me find GPs in my area", value: 1 },
          { body: "I can tell you my GP's details", value: 2 }
        ]
      },
      nextStep: this.handleYourAreaOrGPPostCode
    }
  }

  @step.logStateAndResponse
  handleYourAreaOrGPPostCode(d: IStepData<State, 1 | 2>): IStepResult {
    if (d.response === 1) {
      return { body: "Happy to help!", nextStep: this.askPostCodeOfUser }
    }
    return { nextStep: this.askDoYouKnowThePostCodeOfGP }
  }

  @step.logState
  @step.setState<State>({ retryPostcodeTimes: 0 } as Partial<State>)
  askPostCodeOfUser(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messagesServiceSearch?.askPostCodeOfUser ?? "Please type your postcode below",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askPostCodeOfUser"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePostCodeOfUserWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: ServiceSearchScript) => s.returnToAkPostCodeOfUser
  })
  async handlePostCodeOfUserWithCrisis(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.postcodeEntered = d.response || d.state.retryPostcode
    d.state.retryPostcodeTimes ??= 0
    d.state.retryPostcode = d.state.postcodeEntered

    const [postcode, postcodeStatus] = await getPostCodeDetails(d.response || d.state.retryPostcode)

    if (postcodeStatus === PostcodeStatus.NoInternetConnection) {
      return { nextStep: this.askRetryInternetConnection }
    }
    if (postcodeStatus === PostcodeStatus.Success) {
      d.state.userPostcode = postcode
      const result = await this.onPostcodeOfUserSuccessful?.(d.state)
      if (result) return result
      return { nextStep: this.askSelectGPFromUserPostcode }
    }
    const isInvalidPostcode = postcodeStatus === PostcodeStatus.InvalidPostcode
    const isNotFoundPostcode = postcodeStatus === PostcodeStatus.PostcodeNotFound
    if (isInvalidPostcode || isNotFoundPostcode) {
      const body = isInvalidPostcode
        ? "Hmmm, this doesn't seem to be a valid UK postcode"
        : "Hmmm, unfortunately I can't find this postcode"
      return {
        body,
        nextStep: this.askDidYouTypeThePostCodeCorrectly
      }
    }
    if (
      postcodeStatus === PostcodeStatus.RequestFailed &&
      d.state.retryPostcodeTimes < (this.retryPostcodeTimes ?? 3)
    ) {
      d.state.retryPostcodeTimes++
      return { nextStep: this.askPostcodeRetry }
    }

    d.state.retryPostcodeTimes = 0
    return {
      body: [
        "Oh dear, for some reason I can't find anything using your postcode. Sorry about that.",
        "Don't worry if your postcode is correct, I can help you find your GP another way"
      ],
      nextStep: this.askDoYouKnowThePostCodeOfGP
    }
  }

  @step.logState
  askRetryInternetConnection(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, It looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("askRetryConnection"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.handleAskPostcodeRetry
    }
  }

  @step.logState
  askDidYouTypeThePostCodeCorrectly(_d: IStepData<State>): IStepResult {
    return {
      body: "Could you do me a favour and double check you typed it in correctly?",
      prompt: {
        id: this.getPromptId("askDidYouTypeThePostCodeCorrectly"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "It's correct", value: true },
          { body: "Oops, let me re-type it", value: false }
        ]
      },
      nextStep: this.handleDidYouTypeThePostCodeCorrectly
    }
  }

  @step.logStateAndResponse
  async handleDidYouTypeThePostCodeCorrectly(d: IStepData<State, boolean>): Promise<IStepResult> {
    if (d.response) {
      this.track(TrackingEvents.INVALID_POSTCODE, { postcode: d.state.postcodeEntered })
      return {
        body: [
          "Oh dear, for some reason I couldn't find anything using your postcode. Sorry about that.",
          "Don't worry if your postcode is correct, I can help you find your GP another way"
        ],
        nextStep: this.askDoYouKnowThePostCodeOfGP
      }
    }
    d.state.retryPostcode = undefined
    const name = this.getName(d.state)
    return { body: `No worries ${name}`, nextStep: this.askPostCodeOfUser }
  }

  @step
  returnToAkPostCodeOfUser(_d: IStepData<State>): IStepResult {
    return {
      body: "So let's find your GP",
      nextStep: this.askPostCodeOfUser
    }
  }

  @step.logState
  askPostcodeRetry(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, it looks like something went wrong while looking up GPs",
      prompt: {
        id: this.getPromptId("askPostcodeRetry"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Try again", value: false },
          { body: "Oops, let me re-type the postcode", value: true }
        ],
        isUndoAble: false
      },
      nextStep: this.handleAskPostcodeRetry
    }
  }

  @step.logStateAndResponse
  handleAskPostcodeRetry(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.track(TrackingEvents.RE_ENTER_POSTCODE)
      return { nextStep: this.askPostCodeOfUser }
    }
    this.track(TrackingEvents.TRY_AGAIN_POSTCODE)
    return { nextStep: this.handlePostCodeOfUserWithCrisis }
  }

  @step.logState
  askDoYouKnowThePostCodeOfGP(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you happen to know the postcode of your GP clinic?",
      prompt: {
        id: this.getPromptId("askDoYouKnowThePostCodeOfGP"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleDoYouKnowThePostCodeOfGP
    }
  }

  @step.logState
  handleDoYouKnowThePostCodeOfGP(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { body: "Great!", nextStep: this.askGPPostCode }
    }
    return { nextStep: this.askDoYouKnowTheNameOfGP }
  }

  @step.logState
  askDoYouKnowTheNameOfGP(_d: IStepData<State>): IStepResult {
    return {
      body: "What about their name?",
      prompt: {
        id: this.getPromptId("askDoYouKnowTheNameOfGP"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes, I know their name", value: true },
          { body: "No, I don't know their name", value: false }
        ]
      },
      nextStep: this.handleDoYouKnowTheNameOfGP
    }
  }

  @step.logState
  handleDoYouKnowTheNameOfGP(d: IStepData<State, boolean>): IStepResult {
    d.state.userDoesntKnowGPName = !d.response
    this.track(TrackingEvents.DO_U_KNOW_GP_NAME, { body: d.response ? "Yes" : "No" })
    if (d.response) {
      return { body: "Great", nextStep: this.askGPName }
    }
    return { body: "Hmmmm...", nextStep: this.sayItsImportantToFindGP }
  }

  @step.logState
  askGPPostCode(_d: IStepData<State>): IStepResult {
    return {
      body: "Please type the postcode below and I'll try to find them for you",
      prompt: {
        id: this.getPromptId("askGPPostCode"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleGPPostCodeWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: ServiceSearchScript) => s.returnToAskGPPostCode
  })
  @step.startTyping
  async handleGPPostCodeWithCrisis(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.postcodeEntered = d.state.retryPostcodeGP || d.response
    if (d.state.retryGPTimes === undefined) d.state.retryGPTimes = 0

    d.state.retryPostcodeGP = d.state.postcodeEntered

    const [postcode, postcodeStatus] = await getPostCodeDetails(
      d.response || d.state.retryPostcodeGP
    )

    if (postcodeStatus === PostcodeStatus.NoInternetConnection) {
      return { nextStep: this.askRetryInternetConnectionGP }
    }

    const isInvalidPostcode = postcodeStatus === PostcodeStatus.InvalidPostcode
    const isNotFoundPostcode = postcodeStatus === PostcodeStatus.PostcodeNotFound

    if (isInvalidPostcode || isNotFoundPostcode) {
      const body = isInvalidPostcode
        ? "Hmmm, this doesn't seem to be a valid UK postcode"
        : "Hmmm, unfortunately I can't find this postcode"
      return {
        body,
        nextStep: this.askDidYouTypeThePostCodeCorrectlyGP
      }
    }

    if (
      postcodeStatus === PostcodeStatus.RequestFailed &&
      d.state.retryGPTimes < (this.retryGPTimes ?? 3)
    ) {
      d.state.retryGPTimes = d.state.retryGPTimes + 1
      return { nextStep: this.askPostcodeRetryGP }
    }

    if (postcode) {
      d.state.gpPostcode = postcode
      return { nextStep: this.askSelectGPFromGPPostcode }
    }
    return { nextStep: this.sayIDidntFindGP }
  }

  @step
  returnToAskGPPostCode(_d: IStepData<State>): IStepResult {
    return {
      body: "So let's find your GP",
      nextStep: this.askGPPostCode
    }
  }

  @step.logState
  askRetryInternetConnectionGP(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, It looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("askRetryInternetConnectionGP"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.handleAskPostcodeRetry
    }
  }

  @step.logState
  askDidYouTypeThePostCodeCorrectlyGP(_d: IStepData<State>): IStepResult {
    return {
      body: "Could you do me a favour and double check you typed it in correctly?",
      prompt: {
        id: this.getPromptId("askDidYouTypeThePostCodeCorrectlyGP"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "It's correct", value: true },
          { body: "Oops, let me re-type it", value: false }
        ]
      },
      nextStep: this.handleDidYouTypeThePostCodeCorrectlyGP
    }
  }

  @step.logStateAndResponse
  handleDidYouTypeThePostCodeCorrectlyGP(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.track(TrackingEvents.INVALID_POSTCODE, { postcode: d.state.postcodeEntered })
      return {
        body: [
          "Oh dear, for some reason I couldn't find anything using that postcode. Sorry about that.",
          "Don't worry if the postcode is correct, I can help you find your GP another way"
        ],
        nextStep: this.askDoYouKnowTheNameOfGP
      }
    }
    d.state.retryPostcodeGP = undefined
    const name = this.getName(d.state)
    return { body: `No worries ${name}`, nextStep: this.askGPPostCode }
  }

  @step.logState
  askPostcodeRetryGP(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, it looks like something went wrong while looking up GPs",
      prompt: {
        id: this.getPromptId("askPostcodeRetryGP"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Try again", value: false },
          { body: "Oops, let me re-type the postcode", value: true }
        ],
        isUndoAble: false
      },
      nextStep: this.handleAskPostcodeRetryGP
    }
  }

  @step.logStateAndResponse
  handleAskPostcodeRetryGP(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.track(TrackingEvents.RE_ENTER_POSTCODE)
      return { nextStep: this.askGPPostCode }
    }
    this.track(TrackingEvents.TRY_AGAIN_POSTCODE)
    return { nextStep: this.handleGPPostCodeWithCrisis }
  }

  @step.logState
  sayIDidntFindGP(d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.NO_GP_FOUND, {
      postcodeSearch: d.state.postcodeEntered,
      gpNameSearch: d.state.gpNameEntered,
      userPostcode: d.state.userPostcode?.postcode ?? "N/A",
      gpPostcode: d.state.gpPostcode?.postcode ?? "N/A"
    })
    const name = this.getName(d.state)
    return {
      body: `Hmmmm... I haven't been able to find your GP ${name}`,
      nextStep: this.sayItsImportantToFindGP
    }
  }

  @step.logState
  sayItsImportantToFindGP(d: IStepData<State>): IStepResult {
    const eligibleIAPTs = this.getEligibleIAPTSByAgeThreshold(d.state)
    const shouldProceedWithReferral = this.enableReferAnyway && eligibleIAPTs.length
    return {
      body: "It's important that we identify your GP in order to find the right mental health service for you",
      nextStep: shouldProceedWithReferral
        ? this.askWantMeToReferYouAnyway
        : this.sayWithoutGPICannotReferYou
    }
  }

  @step.logState
  sayWithoutGPICannotReferYou(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: `Without it, I cannot refer you to ${organisationName}`,
      nextStep: this.closeWithCallIntoService
    }
  }

  @step.logState
  closeWithCallIntoService(d: IStepData<State>): IStepResult {
    this.setUserNeedsToCallIn(d.state)
    this.setEligibility(d.state, false)
    return { nextStep: this.checkEligibility }
  }

  @step
  checkEligibility(d: IStepData<State>): IStepResult {
    const isEligible = this.getIsEligible(d.state)
    this.setEligibility(d.state, isEligible)

    const needsToSelfReferManually = this.getNeedsToSelfReferManually(d.state)
    this.setSignpostToManualSelfReferral(d.state, needsToSelfReferManually)

    switch (true) {
      case d.state.isUnderAged:
        d.state.presetFlowResult = EligibilityPresetFlowResults.UNDERAGE
        break
      case d.state.isEligible:
        d.state.presetFlowResult = EligibilityPresetFlowResults.ELIGIBLE
        break
      default:
        d.state.presetFlowResult = EligibilityPresetFlowResults.INELIGIBLE
        break
    }
    return { nextStep: this.end }
  }

  @step.logState
  askWantMeToReferYouAnyway(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t(
        this.messagesServiceSearch?.askWantMeToReferYouAnyway ?? [
          "But that's okay 😊",
          `I can still refer you to ${organisationName}`,
          "Would you like me to do that for you?"
        ],
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askWantMeToReferYouAnyway"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleWantMeToReferYouAnyway
    }
  }

  @step.logState
  handleWantMeToReferYouAnyway(d: IStepData<State, boolean>): IStepResult {
    if (!d.response) {
      return {
        body: "Okay",
        nextStep: this.closeWithCallIntoService
      }
    }
    if (d.state.gpNameEntered) {
      return { nextStep: this.askVerifyGPNameForCustomGP }
    }
    if (d.state.userDoesntKnowGPName) {
      return { nextStep: this.selectCustomGP }
    }
    return { nextStep: this.askGPNameForCustomGP }
  }

  @step.logState
  askGPNameForCustomGP(_d: IStepData<State>): IStepResult {
    return {
      body: "Okay, so what's the name of your GP?",
      prompt: {
        id: this.getPromptId("askGPNameForCustomGP"),
        trackResponse: false,
        type: "inlinePicker",
        choices: [{ body: "I don't know" }],
        textPrompt: {
          forceValue: true
        }
      },
      nextStep: this.handleGPNameForCustomGP
    }
  }

  @step.logState
  handleGPNameForCustomGP(d: IStepData<State, "I don't know" | string>): IStepResult {
    if (d.response === "I don't know") {
      d.state.userDoesntKnowGPName = true
      return { nextStep: this.selectCustomGP }
    }
    d.state.gpNameEntered = d.response
    return { nextStep: this.selectCustomGPByNameEntered }
  }

  @step.logState
  selectCustomGPByNameEntered(d: IStepData<State>): IStepResult {
    const customGP = this.getCustomGP()
    const name = d.state.gpNameEntered ?? "unknown"
    this.setGP(d.state, { ...customGP, name, formattedName: name })
    return { nextStep: this.askSelectIAPTServiceManually }
  }

  @step.logState
  selectCustomGP(d: IStepData<State>): IStepResult {
    this.setGP(d.state, this.getCustomGP())
    return { nextStep: this.askSelectIAPTServiceManually }
  }

  @step.logState
  askSelectIAPTServiceManually(d: IStepData<State>): IStepResult {
    const eligibleIAPTs = this.getEligibleIAPTSByAgeThreshold(d.state)
    return {
      body: "And which service would you like to be referred into?",
      prompt: {
        id: this.getPromptId("askSelectIAPTServiceManually"),
        trackResponse: true,
        type: "inlinePicker",
        choices: (
          eligibleIAPTs.map(iapt => ({
            body: iapt.formattedName,
            value: iapt
          })) as ISelectable<any>[]
        ) //
          .concat({
            body: "Actually, I want to speak to a human",
            value: "speakToHuman",
            backgroundColor: "#EC9CC8"
          })
      },
      nextStep: this.handleSelectIAPTServiceManually
    }
  }

  @step.logState
  async handleSelectIAPTServiceManually(
    d: IStepData<State, IIAPTService | "speakToHuman">
  ): Promise<IStepResult> {
    if (d.response === "speakToHuman") {
      return {
        body: "Okay",
        nextStep: this.closeWithCallIntoService
      }
    }
    this.setIAPT(d.state, d.response, true)
    this.setIAPTSuggestions(d.state, [])

    return { nextStep: this.checkEligibility }
  }

  @step.logState
  async askSelectGPFromGPPostcode(d: IStepData<State>): Promise<IStepResult> {
    const gpServices = await getGPServicesByPostcode(d.state.gpPostcode)
    if (gpServices.requestStatus === INHSStatus.NoInternetConnection) {
      return { nextStep: this.askRetryInternetConnectionGPfromGPPostcode }
    }

    if (gpServices.requestStatus === INHSStatus.RequestFailed) {
      return { nextStep: this.askRetrySelectGPFromGPPostcode }
    }

    if (gpServices.requestStatus === INHSStatus.NoPostcodeAvailable) {
      return {
        body: "Hmmm, something went wrong with the postcode search",
        nextStep: this.returnToAkPostCodeOfUser
      }
    }

    if (!gpServices?.data.length) {
      return { nextStep: this.sayIDidntFindGP }
    }
    return {
      body: [
        "I've found a few GPs close to that postcode",
        "Are you registered with any of the following? (Please select)"
      ],
      prompt: {
        id: this.getPromptId("askSelectGPFromGPPostcode"),
        type: "inlinePicker",
        choices: (
          gpServices.data.map(gp => ({
            body: gp.formattedName,
            value: gp
          })) as ISelectable<any>[]
        ) //
          .concat(
            { body: "My GP is not on this list", value: "notListed", backgroundColor: "#EC9CC8" },
            { body: "I'm not sure", value: "notSure", backgroundColor: "#EC9CC8" }
          )
      },
      nextStep: this.handleSelectGPFromGPPostcode
    }
  }

  @step.logStateAndResponse
  handleSelectGPFromGPPostcode(
    d: IStepData<State, "notListed" | "notSure" | IGPService>
  ): IStepResult {
    if (["notListed", "notSure"].includes(d.response as string)) {
      return { body: "Hmmmm...", nextStep: this.sayItsImportantToFindGP }
    }
    this.setGP(d.state, d.response as IGPService)
    return { nextStep: this.selectIAPTServiceByGP }
  }

  @step
  askRetrySelectGPFromGPPostcode(_d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.ASK_RETRY_SOMETHING_WRONG_SELECT_GP_FROM_GP_POSTCODE)
    return {
      body: "Hmmm, it looks like something went wrong while looking up GPs",
      prompt: {
        id: this.getPromptId("askRetrySelectGPFromGPPostcode"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.handleRetrySelectGPFromGPPostcode
    }
  }

  @step.logStateAndResponse
  async handleRetrySelectGPFromGPPostcode(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.askSelectGPFromGPPostcode }
  }

  @step.logState
  askVerifyGPNameForCustomGP(d: IStepData<State>): IStepResult {
    // sanity check
    if (!d.state.gpNameEntered) {
      return { nextStep: this.askGPNameForCustomGP }
    }
    return {
      body: [
        `So earlier you said your GP was ${d.state.gpNameEntered}`,
        "Is that correct or would you like to re-type it?"
      ],
      prompt: {
        id: this.getPromptId("askVerifyGPNameForCustomGP"),
        type: "inlinePicker",
        choices: [
          { body: "That's correct", value: true },
          { body: "Let me re-type it", value: false }
        ]
      },
      nextStep: this.handleVerifyGPNameForCustomGP
    }
  }

  @step.logState
  handleVerifyGPNameForCustomGP(d: IStepData<State, boolean>): IStepResult {
    if (!d.response) {
      return { nextStep: this.askGPNameForCustomGP }
    }
    return { nextStep: this.selectCustomGPByNameEntered }
  }

  @step.logState
  askRetryInternetConnectionGPfromGPPostcode(_d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.ASK_RETRY_CONNECTION_GP_FROM_GP_POSTCODE)
    return {
      body: "Hmmm, It looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("askRetryInternetConnectionGPfromGPPostcode"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.askSelectGPFromGPPostcode
    }
  }

  @step.logState
  askGPName(_d: IStepData<State>): IStepResult {
    return {
      body: "Please type the name of your GP clinic and I'll try to find them for you",
      prompt: {
        id: this.getPromptId("askGPName"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleGPNameWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.gpNameEntered = d.response
  })
  @step.checkInputForCrisis({
    getNextStep: (s: ServiceSearchScript) => s.askSelectGPByName
  })
  async handleGPNameWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.askSelectGPByName }
  }

  @step.logState
  async askSelectGPByName(d: IStepData<State>): Promise<IStepResult> {
    const gpServices = await getGPServicesByName(d.state.gpNameEntered)
    if (!gpServices?.data?.length) {
      return { nextStep: this.sayIDidntFindGP }
    }
    return {
      // TODO: Custom messages
      body: [
        "I've found a few GPs matching the name you typed",
        "Are you registered with any of the following? (Please select)"
      ],
      prompt: {
        id: this.getPromptId("askSelectGPByName"),
        type: "inlinePicker",
        choices: (
          gpServices.data.map(gp => ({
            body: gp.formattedName,
            value: gp
          })) as ISelectable<any>[]
        ) //
          .concat(
            { body: "My GP is not on this list", value: "notListed", backgroundColor: "#EC9CC8" },
            { body: "I'm not sure", value: "notSure", backgroundColor: "#EC9CC8" }
          )
      },
      nextStep: this.handleSelectGPByName
    }
  }

  @step.logStateAndResponse
  handleSelectGPByName(d: IStepData<State, "notListed" | "notSure" | IGPService>): IStepResult {
    const body =
      d.response === "notListed"
        ? "My GP is not on this list"
        : d.response === "notSure"
          ? "I'm not sure"
          : "GP selected"
    this.track(TrackingEvents.SELECT_GP_BY_NAME, { body })
    if (["notListed", "notSure"].includes(d.response as string)) {
      return { body: "Hmmmm...", nextStep: this.sayItsImportantToFindGP }
    }
    this.setGP(d.state, d.response as IGPService)
    return { nextStep: this.selectIAPTServiceByGP }
  }

  @step
  async selectIAPTServiceByGP(d: IStepData<State>): Promise<IStepResult> {
    const nextStep = this.sayICouldntFindIAPTsForYourGP
    try {
      const gp = d.state.gp
      if (!gp?.postcode || !gp?.ccg) {
        this.track(TrackingEvents.NO_VALID_GP_FOUND, {
          gp: gp?.name ?? "N/A",
          gpID: gp?.id ?? "N/A",
          gpPostcode: gp?.postcode ?? "N/A",
          gpCCG: gp?.ccg ?? "N/A",
          isCustom: gp?.isCustom
        })
        this.logBreadcrumb("selectIAPTServiceByGP failed", d.state, { gp })
        this.logMessage("No valid GP with postcode and CCG found")
        return { nextStep }
      }
      const [postcode, postcodeStatus] = await getPostCodeDetails(gp?.postcode)

      if (postcodeStatus === PostcodeStatus.NoInternetConnection) {
        return { nextStep: this.askRetryInternetConnectionSelectIAPTServiceByGP }
      }

      // 📝 This should never be the case since the Postcode is from the retrieved GP so
      //    they should always be valid -> from: "await getPostCodeDetails(gp?.postcode)"
      //    Keeping this as a fail-safe just in case
      if (postcodeStatus === PostcodeStatus.InvalidPostcode) {
        return {
          body: ["Hmmm, this doesn't seem to be a valid UK postcode", "Let's try again"],
          nextStep: this.askSelectGPFromUserPostcode
        }
      }

      if (postcodeStatus === PostcodeStatus.RequestFailed) {
        return { nextStep: this.askRetrySelectIAPTServiceByGP }
      }

      const ccg = gp?.ccg.id
      const lon = postcode?.longitude
      const lat = postcode?.latitude
      const results = await getIAPTServicesByCCG(ccg, lon, lat)

      if (results.requestStatus === INHSStatus.RequestFailed) {
        return { nextStep: this.askRetrySelectIAPTServiceByGP }
      }

      if (results.requestStatus === INHSStatus.NoInternetConnection) {
        return { nextStep: this.askRetryInternetConnectionSelectIAPTServiceByGP }
      }

      if (!results.data.length) {
        this.track(TrackingEvents.NO_IAPTS_FOUND, {
          postcodeSearch: d.state.postcodeEntered,
          gpNameSearch: d.state.gpNameEntered,
          userPostcode: d.state.userPostcode?.postcode ?? "N/A",
          gpPostcode: d.state.gpPostcode?.postcode ?? "N/A",
          gp: gp?.formattedName ?? "N/A"
        })
        this.logBreadcrumb("No IAPT services found", d.state)
        this.logMessage("No IAPT services found")
        return { nextStep }
      }

      const eligibleIAPTIds = this.rootStore.configStore.eligibleIAPTIds
      const iapt = results.data.find(i => eligibleIAPTIds.includes(i.id))
      const suggestions = results.data.filter(i => !eligibleIAPTIds.includes(i.id)).slice(0, 3)
      if (!iapt) {
        this.track(TrackingEvents.NO_ELIGIBLE_IAPT_FOUND, {
          postcodeSearch: d.state.postcodeEntered,
          gpNameSearch: d.state.gpNameEntered,
          userPostcode: d.state.userPostcode?.postcode ?? "N/A",
          gpPostcode: d.state.gpPostcode?.postcode ?? "N/A",
          gp: gp?.formattedName ?? "N/A"
        })
      }
      this.setIAPT(d.state, iapt)
      this.setIAPTSuggestions(d.state, suggestions)
      const isEligibleGPForIAPT = getIsEligibleGPForIAPT(iapt, gp)
      if (iapt && !isEligibleGPForIAPT) {
        this.track(TrackingEvents.INELIGIBLE_GP_MATCHING_IAPT, {
          gp: gp?.formattedName,
          iapt: iapt.formattedName
        })
      }
    } catch (e) {
      this.logBreadcrumb("selectIAPTServiceByGP failed", undefined, { error: e.message })
      this.logException(e, "selectIAPTServiceByGP")
      return { nextStep }
    }
    return { nextStep: this.checkUnderAgedForIAPT }
  }

  @step.logState
  sayICouldntFindIAPTsForYourGP(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: `Hmm, unfortunately it doesn't look like you can access ${organisationName} with your GP`,
      nextStep: this.closeWithCallIntoService
    }
  }

  @step.logState
  checkUnderAgedForIAPT(d: IStepData<State>): IStepResult {
    const isUnderAged = this.getIsUnderagedForIAPT(d.state, d.state.iapt)
    this.setUnderAged(d.state, isUnderAged)
    return { nextStep: this.checkEligibility }
  }

  @step.logState
  askRetryInternetConnectionSelectIAPTServiceByGP(_d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.ASK_RETRY_CONNECTION_SELECT_IAPT_SERVICE_BY_GP)
    return {
      body: "Hmmm, It looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("askRetryInternetConnectionSelectIAPTServiceByGP"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.selectIAPTServiceByGP
    }
  }

  @step.logState
  async askSelectGPFromUserPostcode(d: IStepData<State>): Promise<IStepResult> {
    const gpServices = await getGPServicesByPostcode(d.state.userPostcode)
    if (gpServices.requestStatus === INHSStatus.NoInternetConnection) {
      return { nextStep: this.askRetryInternetConnectionGPFromUserPostcode }
    }

    if (gpServices.requestStatus === INHSStatus.RequestFailed) {
      return { nextStep: this.askRetrySelectGPFromUserPostcode }
    }

    if (gpServices.requestStatus === INHSStatus.NoPostcodeAvailable) {
      return {
        body: "Hmmm, something went wrong with the postcode search",
        nextStep: this.returnToAkPostCodeOfUser
      }
    }

    if (!gpServices?.data.length) {
      return {
        body: ["Hmmm...", "I can't find any GP services near you"],
        nextStep: this.askDoYouKnowThePostCodeOfGP
      }
    }
    return {
      body: [
        "I've found a few GPs in your area",
        "Are you registered with any of the following? (Please select)"
      ],
      prompt: {
        id: this.getPromptId("askSelectGPFromUserPostcode"),
        type: "inlinePicker",
        choices: (
          gpServices.data.map(gp => ({
            body: gp.formattedName,
            value: gp
          })) as ISelectable[]
        ) //
          .concat(
            { body: "My GP is not on this list", value: "notListed", backgroundColor: "#EC9CC8" },
            { body: "I'm not sure", value: "notSure", backgroundColor: "#EC9CC8" }
          )
      },
      nextStep: this.handleSelectGPFromUserPostcode
    }
  }

  @step.logStateAndResponse
  handleSelectGPFromUserPostcode(
    d: IStepData<State, "notListed" | "notSure" | IGPService>
  ): IStepResult {
    const body =
      d.response === "notListed"
        ? "My GP is not on this list"
        : d.response === "notSure"
          ? "I'm not sure"
          : "GP selected"
    this.track(TrackingEvents.SELECT_GP_BY_POSTCODE, { body })
    if (d.response === "notListed" || d.response === "notSure") {
      const body = d.response === "notListed" ? "No problem" : "That's okay"
      return { body, nextStep: this.askDoYouKnowThePostCodeOfGP }
    }
    this.setGP(d.state, d.response)
    return { nextStep: this.selectIAPTServiceByGP }
  }

  @step
  askRetrySelectGPFromUserPostcode(_d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.ASK_RETRY_SOMETHING_WRONG_SELECT_GP_FROM_USER_POSTCODE)
    return {
      body: "Hmmm, it looks like something went wrong while looking up GPs",
      prompt: {
        id: this.getPromptId("askRetrySelectGPFromUserPostcode"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.handleRetrySelectGPFromUserPostcode
    }
  }

  @step.logStateAndResponse
  async handleRetrySelectGPFromUserPostcode(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.askSelectGPFromUserPostcode }
  }

  @step.logState
  askRetryInternetConnectionGPFromUserPostcode(_d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.ASK_RETRY_CONNECTION_GP_FROM_USER_POSTCODE)
    return {
      body: "Hmmm, It looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("askRetryInternetConnectionGPFromUserPostcode"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.askSelectGPFromUserPostcode
    }
  }

  @step
  askRetrySelectIAPTServiceByGP(_d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.ASK_RETRY_SOMETHING_WRONG_SELECT_IAPT_SERVICE_BY_GP)
    return {
      body: "Hmmm, it looks like something went wrong while looking up GPs",
      prompt: {
        id: this.getPromptId("askRetrySelectIAPTServiceByGP"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.handleRetrySelectIAPTServiceByGP
    }
  }

  @step.logStateAndResponse
  async handleRetrySelectIAPTServiceByGP(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.selectIAPTServiceByGP }
  }

  /** Generic Handlers */

  getIsUnderagedForIAPT(state: State, iapt?: IIAPTService): boolean {
    if (!iapt) return false
    const ageThreshold = iapt.ageThreshold || 18
    return this.getUserAge(state) < ageThreshold
  }

  getIsEligibleForIAPT(state: State, iapt?: IIAPTService): boolean {
    if (!iapt) return false
    const isUnderaged = this.getIsUnderagedForIAPT(state, iapt)
    return !isUnderaged
  }

  getIAPTsByGPCode(_state: State, code?: string): IIAPTService[] {
    if (code) {
      const iaptGPMap = this.rootStore.configStore.iaptGPMap ?? {}
      const gpCodes = Object.keys(iaptGPMap)
      const gpCode = gpCodes.find(c => code.includes(c))
      // 👆 we do this so that we get G82634 even when the GP is G82634001
      if (gpCode) {
        const customIAPTS = this.rootStore.configStore.customIAPTS
        const iaptID = iaptGPMap[gpCode]
        if (iaptID) {
          const dashboardServiceKey = this.rootStore.configStore.dashboardServiceKey

          if (
            customIAPTSHaveError(
              dashboardServiceKey,
              customIAPTS,
              this.rootStore.configStore.eligibleIAPTIds
            )
          ) {
            this.logException(
              new Error("No dashboard customIAPTS available"),
              dashboardServiceKey ?? ""
            )
          }

          return [iaptID].map(id => getIAPTById(id, customIAPTS)).filter(Boolean) as IIAPTService[]
        }
      }
    }
    return []
  }

  getIAPTsByCCGCodes(state: State, ccgs?: string[]): IIAPTService[] {
    if (!ccgs?.length) return []
    const iaptCCGMap = this.rootStore.configStore.iaptCCGMap ?? {}
    const iaptIDs = new Set<string>()
    for (let i = 0, { length } = ccgs; i < length; i++) {
      const ccg = ccgs[i]
      const id = iaptCCGMap[ccg]
      if (id) iaptIDs.add(id)
    }
    const customIAPTS = this.rootStore.configStore.customIAPTS

    const dashboardServiceKey = this.rootStore.configStore.dashboardServiceKey

    if (
      customIAPTSHaveError(
        dashboardServiceKey,
        customIAPTS,
        this.rootStore.configStore.eligibleIAPTIds
      )
    ) {
      this.logException(new Error("No dashboard customIAPTS available"), dashboardServiceKey ?? "")
    }

    return [...iaptIDs].map(id => getIAPTById(id, customIAPTS)).filter(Boolean) as IIAPTService[]
  }

  getIsEligible(state: State): boolean {
    const { username, iapt, isUnderAged, isEligible } = state
    if (isEligible != null) return isEligible

    return !!username && !isUnderAged && !!iapt
  }

  getNeedsToSelfReferManually(state: State): boolean {
    const { gp, odsGP, signPostToManualReferral, iapt } = state
    if (signPostToManualReferral === true) return true

    const isEligible = this.getIsEligible(state)
    return isEligible && !(gp || odsGP) && !!iapt?.referralForm?.form_url
  }
}

export default class ServiceSearchDialogue extends AdHocDialogue<State, ServiceSearchScript> {
  static id = DialogueIDs.ServiceSearch
  readonly name: string = "ServiceSearchDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: IServiceSearchSettings
  ) {
    super(
      ServiceSearchDialogue.id,
      new ServiceSearchScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
