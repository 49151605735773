import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

interface State extends CrisisScriptState {
  requiresUrgentSupport?: boolean
}
export type CrisisMPFTScriptState = State

export class CrisisMPFTScript extends CrisisScript {
  readonly name: string = "CrisisMPFTScript"

  /** Script Steps */

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const name = this.getName(d.state)
    return {
      body: [
        `Thanks for sharing ${name}. I know it's not always easy`,
        `However ${organisationName} does not provide urgent care`,
        "Sometimes people have thoughts of harming themselves or that life isn’t worth living. If you are concerned about your safety, it is important that you talk to someone, support is available"
      ],
      prompt: {
        id: this.getPromptId("saySorryToHear"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "If you need urgent support, please dial NHS 111 and select Option 2",
        "In medical emergency and life threatening situations only, please dial 999 or attend your local A&E department",
        `You can also contact the local Urgent NHS Mental Health Helpline on 0808 196 4501`,
        "Other helplines available:\n\n" +
          "You can contact Samaritans 24 hours a day, 365 days a year. You can call 116 123 (free from any phone) or email jo@samaritans.org\n" +
          "If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258. Shout offers a confidential 24/7 text service providing support if you are in need of immediate help"
      ],
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111 (Option 2), Samaritans (116 123), SHOUT (85258)"
    )
    return {
      body: [
        "To recap:\n\n" +
          `We'll continue with the referral, however ${organisationName} does not provide urgent care. If you require immediate help, please contact one of the numbers listed above`,
        "Let's carry on with the mental health check in"
      ],
      nextStep: this.end
    }
  }

  @step.logState
  saySorryForTheMisunderstanding(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Okay",
        "My creators have taught me to listen carefully for specific words or phrases",
        "In case you need help",
        "Sorry for the misunderstanding",
        "Ok, let's carry on with the mental health check in"
      ],
      nextStep: this.end
    }
  }
}

/* istanbul ignore next */
export default class CrisisMPFTDialogue extends AdHocDialogue<State, CrisisMPFTScript> {
  static id = DialogueIDs.CrisisMPFT
  readonly name: string = "CrisisMPFTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisMPFTDialogue.id, new CrisisMPFTScript(), state, snapshot)
  }
}
