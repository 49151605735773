import { IGPService } from "../../../models/IGPService"
import encodeURLParams from "../../../utils/encodeURLParams"
import { IIAPTService } from "../../../models/IIAPTService"
import Logger from "../../../utils/Logger"
import IPostcode from "../../../models/IPostcode"
import { INHSStatus } from "../../../models/INHS"
import { deprecatedIAPTs } from "../../../config/deprecatedIAPTs"
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber"
import getIAPTById from "../../../utils/getIAPTById"
import delay from "../../../utils/delay"
import NetworkError from "../../../models/NetworkError"
import errorMessage from "../../../utils/parseErrorMessage"
import { isOnline } from "../../../utils/isOnline"
import { ICCG } from "../../../models/ICCG"
import { IIAPTBotService } from "@limbic/types"

const TOTAL_RETRIES = 3
const API_URL = "https://api.nhs.uk/service-search"
const SEARCH_API = "/search"
const SUBSCRIPTION_KEY = "08eec77818f24aac9ee37599896cc916"

const headers = new Headers()
headers.set("subscription-key", SUBSCRIPTION_KEY)
headers.set("Content-Type", "application/json")
headers.set("Accept", "application/json")

interface IServerODSService {
  Name: string
  OrgId: { extension: string }
  Status: string
  GeoLoc: {
    Location: {
      AddrLn1: string
      AddrLn2: string
      AddrLn3: string
      Town: string
      County: string
      PostCode: string
      Country: string
      UPRN: number
    }
  }
  Contacts?: { Contact: Array<{ type: string; value: string }> }
}

export interface IODSService {
  name: string
  nacsCode: string
  status: string
  address: string
  postcode: string
  contacts?: string[]
}

export async function getServiceByODSCode(odsCode?: string): Promise<IODSService | undefined> {
  if (!odsCode) return
  const url = `https://directory.spineservices.nhs.uk/ORD/2-0-0/organisations/${odsCode}`
  const [result] = await get(url)
  return serverODSServiceToODSService(result)
}

function serverODSServiceToODSService(s?: IServerODSService): IODSService | undefined {
  if (!s) return
  const {
    Contacts,
    GeoLoc: { Location },
    Name,
    OrgId,
    Status
  } = s
  return {
    name: Name,
    nacsCode: OrgId.extension,
    status: Status,
    address: `${Location.AddrLn1} ${Location.AddrLn2}`,
    postcode: Location.PostCode,
    contacts: Contacts?.Contact.map(i => i.value)
  }
}

export async function getCCGBYODSCode(odsCode: string): Promise<ICCG | undefined> {
  const payload = {
    filter: `NACSCode eq '${odsCode}'`,
    select: "OrganisationID,OrganisationName,Postcode"
  }
  const params = encodeURLParams({ "api-version": 1 })
  const url = `${API_URL}${SEARCH_API}?${params}`
  const [result] = await post(url, payload)
  const item = result?.[0]
  if (item) {
    return {
      id: item.OrganisationID,
      name: item.OrganisationName,
      code: odsCode
    }
  }
}

export async function getGPServicesByNameRaw(
  name?: string,
  limit = 25,
  skip = 0
): Promise<{ count?: number; value: IGPService[]; requestStatus?: string }> {
  if (name == null) {
    return { value: [] }
  }
  const payload = {
    filter: "OrganisationType eq 'GP'",
    search: name
      .split(" ")
      .map(s => `${s}*`)
      .join(" "),
    searchMode: "any",
    select: "OrganisationID,OrganisationName,PIMSCode,NACSCode,Postcode,CCG,Contacts,City,County",
    top: limit,
    skip: skip,
    count: true
  }
  const params = encodeURLParams({ "api-version": 1 })
  const url = `${API_URL}${SEARCH_API}?${params}`
  const [result, resultStatus] = await post(url, payload, true)
  return {
    count: result?.["@odata.count"],
    value: result.value.map(serverGPServiceToGPService),
    requestStatus: resultStatus
  }
}

export async function getGPServicesByName(
  name?: string,
  limit = 25
): Promise<{ data: IGPService[]; requestStatus?: string }> {
  if (!name) {
    return {
      data: []
    }
  }
  const payload = {
    filter: "OrganisationType eq 'GP'",
    searchFields: "OrganisationName,OrganisationAliases,Address1,Address2,Address3,Postcode",
    search: name
      .split(" ")
      .map(s => `${s}*`)
      .join(" "),
    searchMode: "all",
    select:
      "OrganisationID,OrganisationName,PIMSCode,NACSCode,Postcode,CCG,Contacts,City,County,Address1,Address2",
    top: limit,
    skip: 0,
    count: true
  }
  const params = encodeURLParams({ "api-version": 1 })
  const url = `${API_URL}${SEARCH_API}?${params}`
  const [services, requestStatus] = await post(url, payload)
  return {
    data: services.map(s => serverGPServiceToGPService(s)),
    requestStatus
  }
}

export async function getGPServicesByPostcode(
  p?: IPostcode,
  limit = 25
): Promise<{ data: IGPService[]; requestStatus?: string }> {
  if (!p?.postcode) {
    return {
      data: [],
      requestStatus: INHSStatus.NoPostcodeAvailable
    }
  }
  const payload = {
    filter: "OrganisationType eq 'GP'",
    select: "OrganisationID,OrganisationName,PIMSCode,NACSCode,Postcode,CCG,Contacts,City,County",
    searchFields: "Postcode",
    search: p.postcode
      .split(" ")
      .map(s => `${s}*`)
      .join(" "),
    searchMode: "any",
    orderby: `geo.distance(Geocode, geography'Point(${p.longitude || 0} ${p.latitude || 0})')`,
    top: limit,
    skip: 0,
    count: true
  }
  const params = encodeURLParams({ "api-version": 1 })
  const url = `${API_URL}${SEARCH_API}?${params}`
  const [services, requestStatus] = await post(url, payload)

  if (requestStatus !== INHSStatus.Success) {
    return {
      data: [],
      requestStatus
    }
  }

  return {
    data: services.map(s => serverGPServiceToGPService(s)),
    requestStatus
  }
}

export async function getIAPTServicesBYODSCCG(
  odsCode: string,
  lon?: number,
  lat?: number,
  customIAPTS?: IIAPTBotService[]
): Promise<{ data: IIAPTService[]; requestStatus?: string }> {
  try {
    const ccg = await getCCGBYODSCode(odsCode)
    if (!ccg) return { data: [], requestStatus: INHSStatus.NoCCGAvailable }
    return getIAPTServicesByCCG(ccg.id, lon, lat, customIAPTS) // 👈 not awaiting on purpose
  } catch (e) {
    return { data: [], requestStatus: INHSStatus.RequestFailed }
  }
}

export async function getIAPTServicesByCCG(
  ccg?: string,
  lon?: number,
  lat?: number,
  customIAPTS?: IIAPTBotService[]
): Promise<{ data: IIAPTService[]; requestStatus?: string }> {
  if (!ccg) {
    return {
      data: [],
      requestStatus: INHSStatus.NoCCGAvailable
    }
  }

  const payload = {
    // SRV0339 is the service code for IAPT
    filter: `ServiceCodesProvided/any(c:search.in(c, 'SRV0339')) and OrganisationTypeID ne 'TRU' and search.ismatch('${ccg}', 'RelatedIAPTCCGs', 'full', 'all')`,
    select: "OrganisationID,OrganisationName,Postcode,Contacts",
    orderby: `geo.distance(Geocode, geography'Point(${lon || 0} ${lat || 0})')`
  }
  const params = encodeURLParams({ "api-version": 1 })
  const url = `${API_URL}${SEARCH_API}?${params}`
  const [services, requestStatus] = await post(url, payload)

  if (requestStatus !== INHSStatus.Success) {
    return {
      data: [],
      requestStatus
    }
  }
  return {
    data: services
      .map(s => serverIAPTServiceToIAPTService(s, customIAPTS))
      .filter((i: IIAPTService) => !deprecatedIAPTs.includes(i.name)),
    requestStatus
  }
}

// TODO: - Tighten typechecking for return values
export async function get(path: string, retry = 0): Promise<[any, INHSStatus]> {
  const headers = { ContentType: "application/json" }
  const hasConnection = await isOnline()
  if (!hasConnection) {
    return [undefined, INHSStatus.NoInternetConnection]
  }
  try {
    const response = await fetch(path, { headers })

    if (!response.ok) {
      const text = await response.text()
      const message = errorMessage(response, { message: text })
      throw new NetworkError(String(response.status), message)
    }
    const data = await response.json()
    return [data.value, INHSStatus.Success]
  } catch (error) {
    Logger.getInstance().exception(error, "nhs get failed")
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("nhs get retry")
      await delay(2)
      return await get(path, retry + 1)
    }
    return [undefined, INHSStatus.RequestFailed]
  }
}

// TODO: - Tighten typechecking for return values
export async function post(
  path: string,
  payload: Record<string, any>,
  raw?: boolean,
  retry = 0
): Promise<[any, INHSStatus]> {
  const hasConnection = await isOnline()
  if (!hasConnection) return [undefined, INHSStatus.NoInternetConnection]

  const method = "POST"
  const body = JSON.stringify(payload)
  try {
    const response = await fetch(path, { body, method, headers })

    if (!response.ok) {
      const text = await response.text()
      const message = errorMessage(response, { message: text })
      throw new NetworkError(String(response.status), message)
    }

    const data = await response.json()
    if (raw) return data
    return [data.value, INHSStatus.Success]
  } catch (error) {
    Logger.getInstance().exception(error, "nhs post failed")

    if (retry < TOTAL_RETRIES) {
      await delay(2)
      return await post(path, payload, raw, retry + 1)
    }
    return [undefined, INHSStatus.RequestFailed]
  }
}

interface IServerGPService {
  OrganisationID: string
  OrganisationName: string
  City: string
  County: string
  PIMSCode: string
  NACSCode: string
  Postcode: string
  CCG: [string, string] // [id, name]
  Contacts: string // JSON array of objects
  Address1: string
  Address2: string
}

function serverGPServiceToGPService(s: IServerGPService): IGPService {
  const {
    OrganisationID,
    OrganisationName,
    PIMSCode,
    NACSCode,
    Postcode,
    CCG,
    Contacts,
    City,
    County,
    Address1,
    Address2
  } = s
  let phoneContact
  try {
    const contacts = JSON.parse(Contacts || "[]")
    phoneContact = contacts?.find?.(i => i.OrganisationContactMethodType === "Telephone")
  } catch (e) {
    Logger.getInstance().exception(e, "serverGPServiceToGPService failed")
  }

  const cityCounty = City || County ? ` (${City ?? ""} ${County ?? ""})` : ""

  return {
    id: OrganisationID,
    name: OrganisationName,
    formattedName: `${OrganisationName}${cityCounty}`,
    pimsCode: PIMSCode,
    nacsCode: NACSCode,
    postcode: Postcode,
    ccg: {
      id: CCG?.[0],
      name: CCG?.[1],
      code: "" // the NHS API does not return this code. Adding an empty string because eventually we weill not use this API anymore
    },
    phoneNumber: formatPhoneNumber(phoneContact?.OrganisationContactValue),
    address1: Address1,
    address2: Address2
  }
}

interface IServerIAPTService {
  OrganisationID: string
  OrganisationName: string
  Postcode: string
  Contacts: string // JSON array of objects
}

function serverIAPTServiceToIAPTService(
  s: IServerIAPTService,
  customIAPTs?: IIAPTBotService[]
): IIAPTService {
  const { OrganisationID, OrganisationName, Postcode, Contacts } = s
  let phoneContact
  try {
    const contacts = JSON.parse(Contacts || "[]")
    phoneContact = contacts?.find?.(i => i.OrganisationContactMethodType === "Telephone")
  } catch (e) {
    Logger.getInstance().exception(e, "serverIAPTServiceToIAPTService failed")
  }

  const iapt = getIAPTById(OrganisationID, customIAPTs)
  return {
    ...(iapt ?? {}),
    id: OrganisationID,
    name: iapt?.name || OrganisationName,
    formattedName: iapt?.formattedName ?? OrganisationName,
    postcode: iapt?.postcode ?? Postcode,
    phoneNumber: iapt?.phoneNumber ?? formatPhoneNumber(phoneContact?.OrganisationContactValue)
  }
}
