import { observer } from "mobx-react"
import React from "react"
import { useApplicationStore } from "../../../contexts/RootStoreContext"
import { FF } from "../../../../featureFlags"

function HIPPAComplianceCard() {
  const app = useApplicationStore()
  if (!app.getFeatureFlag(FF.AC434_HIPPA_SIDEBAR)) return null
  return (
    <article data-testid="hippa_compliance_card" className="lb-sidebar-card full-height">
      <p>
        {app.t(
          "Limbic is designed to meet the rigorous security standards of HIPAA, ensuring the confidentiality, integrity, and availability of protected health information (PHI)."
        )}
      </p>
    </article>
  )
}

export default observer(HIPPAComplianceCard)
