import React from "react"
import { observer } from "mobx-react"
import "./Sidebar.scss"
import { useConfigStore } from "../../contexts/RootStoreContext"

export interface Props {
  visible?: boolean
}

function Sidebar(props: Props): JSX.Element | null {
  const config = useConfigStore()
  const { visible } = props
  if (!visible) {
    return null
  }

  return (
    <div className="lb-sidebar-container">
      <article className="lb-sidebar-card">
        <div className="lb-header">
          <img src={config.logo} className="lb-header-img" alt="" />
          <h3 className="subtitle">Sign up for supported self-help</h3>
        </div>
        <p>
          <b className="lb-bold-text">
            Supported self-help is Mind’s free, 6-week guided programme. We give you the materials
            to understand and manage your feelings. And we call you regularly to give you support.
          </b>
        </p>
        <p>
          Supported self-help can help people with mild to moderate mental health needs. It is not
          an emergency service. If you need help now, please visit our{" "}
          <a
            href="https://www.mind.org.uk/need-urgent-help/using-this-tool/"
            rel="noreferrer"
            target="_blank">
            'Get help now'
          </a>{" "}
          page.
        </p>
        <p>
          Please read the following criteria before making your referral:
          <ul>
            <li>
              You'll need to live in Lancashire, Tameside & Glossop or Teesside and be 18 and over
            </li>
            <li>
              It doesn't matter if you’re on a waiting list for mental health support. But if you
              are currently receiving another form of support such as counselling, please complete
              your treatment first and then come back to us if you’d still like some support.
            </li>
          </ul>
        </p>
        <p>
          For more information head{" "}
          <a href="https://www.mind.org.uk/supported-self-help/" rel="noreferrer" target="_blank">
            here
          </a>
          .
        </p>
        {config.iaptIcons?.map?.(link => (
          <img key={link} className="nhs-logo" src={link} alt={link} />
        ))}
      </article>
      <div className="lb-sidebar-spacer" />
      <article className="lb-sidebar-card">
        <p>
          Limbic helps people access psychological support quicker and easier. Your data is secured
          according to the highest NHS standards.
        </p>
        <img
          className="badges"
          src="https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/data-pics.png"
          alt={"Badges"}
        />
      </article>
    </div>
  )
}

export default observer(Sidebar)
