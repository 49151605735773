import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = RiskPathwayScriptState

export type RiskPathwayGMHubScriptState = State

export class RiskPathwayGMHubScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayGMHubScript"

  /** Script Steps */
  async start(d: IStepData<State>): Promise<IStepResult<State>> {
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    return { nextStep: this.step1 }
  }

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Are you able to keep yourself, and any dependants in your care, safe until the service contacts you (within 5 working days time)?"
      ),
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until the service contacts you (within 5 working days)?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canKeepSelfSafe = d.response
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const serviceName = this.rootStore.configStore.serviceName

    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However, this is not an emergency response service",
          "There are alternative services that are better placed to support you",
          "If you are concerned about your safety, it is important that you talk to someone, support is available"
        ],
        { name, serviceName }
      ),
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }],
        dataPointsName: "sayCrisis"
      },
      nextStep: this.sayInstructions
    }
  }

  async sayInstructions(_d: IStepData<State>): Promise<IStepResult> {
    return {
      body: this.t([
        "You can call the NHS helpline on 111 and select the option for mental health support. This helpline is open 24 hours a day, 7 days a week",
        "If you feel there is an immediate risk of you harming yourself or attempting to end your life, please call 999 immediately.",
        "If you are experiencing a mental health crisis please to go the [NHS Getting Help page](https://www.nhs.uk/mental-health/feelings-symptoms-behaviours/behaviours/help-for-suicidal-thoughts/)",
        "You can also contact Papyrus on 0800 0684141 (call), 07860 039967 (text) or [pat@papyrus-uk.org](pat@papyrus-uk.org) if you're under 35",
        "One of {serviceName}'s team will contact you within 5 working days to check that you have referred yourself in to one of the crisis support services"
      ]),
      prompt: {
        id: this.getPromptId("sayInstructions"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.handleSayAdditionalInstructions
    }
  }

  @step.logState
  async handleSayAdditionalInstructions(_d: IStepData<State>): Promise<IStepResult> {
    this.referralStore.setCustomField("crisisLinksShared", "NHS Getting Help and Papyrus")
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }
}

export default class RiskPathwayGMHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayGMHub
  readonly name: string = "RiskPathwayGMHubDialogue"

  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayGMHubDialogue.id, new RiskPathwayGMHubScript(), state, snapshot)
  }
}
