import { IAppointment } from "@limbic/types"
import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import { isOnline } from "../../../utils/isOnline"
import { IAppointmentStatus } from "../../../models/IAppointment"
import client from "./_client"

const TOTAL_RETRIES = 3

export async function getAppointments(
  signupCode: string,
  botApiKey: string,
  version: "draft" | "published",
  pageNumber = 1, // starts at 1,
  pageSize = 20,
  retry = 0,
  duration = 60
): Promise<[IAppointment[] | undefined, IAppointmentStatus]> {
  const params = {
    botApiKey,
    version,
    signupCode,
    pageNumber: pageNumber.toString(),
    pageSize: pageSize.toString(),
    duration: duration.toString()
  }

  try {
    const hasConnection = await isOnline()
    if (!hasConnection) return [undefined, IAppointmentStatus.NoInternetConnection]

    const data = await client.get(`/v1/appointment/calendar-slots`, params)
    return [data, IAppointmentStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "getPCMISAppointments fetch failed")

    if (retry < TOTAL_RETRIES) {
      logLongJSON(`getPCMISAppointments query for retry ${retry}`, JSON.stringify(params))
      Logger.getInstance().message("getPCMISAppointments retry")

      await delay(1)
      return await getAppointments(
        signupCode,
        botApiKey,
        version,
        pageNumber,
        pageSize,
        retry + 1,
        duration
      )
    }

    return [undefined, IAppointmentStatus.RequestFailed]
  }
}

export async function bookAppointmentSlot(
  signupCode: string,
  botAPIKey: string,
  version: "draft" | "published",
  calendarSlotId: string,
  retry = 0
): Promise<[boolean, IAppointmentStatus]> {
  const body = { signupCode, botAPIKey, calendarSlotId, version }
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) return [false, IAppointmentStatus.NoInternetConnection]

    const data = await client.post("/v1/appointment/book-slot", body)
    return [data, IAppointmentStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "bookPCMISAppointmentSlot fetch failed")

    if (retry < TOTAL_RETRIES) {
      logLongJSON(`bookPCMISAppointmentSlot body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("bookPCMISAppointmentSlot retry")

      await delay(1)
      return await bookAppointmentSlot(signupCode, botAPIKey, version, calendarSlotId, retry + 1)
    }

    return [false, IAppointmentStatus.RequestFailed]
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
