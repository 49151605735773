import React, { useCallback, useEffect, useRef, useState } from "react"
import classnames from "classnames"
import Select, { OptionsOrGroups, SingleValue, StylesConfig } from "react-select"
import AsyncCreatableSelect from "react-select/async-creatable"
import { useApplicationStore, useConfigStore } from "../../../contexts/RootStoreContext"
import TextInput from "../TextInput"
import InlineButton from "../../InlineButton/InlineButton"
import {
  COUNTRIES_MAP,
  IUSAddress,
  US_STATES_AND_TERRITORIES,
  USAddressSchema
} from "@limbic/types"
import { IAutocompletePrediction } from "@limbic/types/dist/utils/googlePlaces"
import styles from "./USAddressInput.module.scss"
import { useAddressAutoComplete } from "../../../hooks/useAddressAutoComplete"
import { usePlaceDetails } from "../../../hooks/usePlaceDetails"

const STATE_OPTIONS = Object.keys(US_STATES_AND_TERRITORIES).map(key => ({
  value: key,
  label: `${US_STATES_AND_TERRITORIES[key]} (${key})`
})) as OptionsOrGroups<any, any>
const COUNTRY = "US" as keyof typeof COUNTRIES_MAP

const customComponents = {
  DropdownIndicator: () => null,
  IndicatorSeparator: () => null
}

interface IProps {
  onSubmit?: (address: IUSAddress) => void
}

interface AddressState {
  address1: string
  address2?: string
  city: string
  state: keyof typeof US_STATES_AND_TERRITORIES | undefined
  zipcode: string
}

export default function USAddressInput(props: IProps): JSX.Element {
  const config = useConfigStore()
  const app = useApplicationStore()
  const [addressState, setAddressState] = useState<AddressState>({
    address1: "",
    address2: "",
    city: "",
    state: undefined,
    zipcode: ""
  })
  const [errors, setErrors] = useState<Partial<AddressState>>({})
  const [selectedAddress, setSelectedAddress] =
    useState<SingleValue<{ value: string; label: string } | undefined>>(null)
  const [inputValue, setInputValue] = useState("")
  const [isLoadingPlaceDetails, setIsLoadingPlaceDetails] = useState(false)
  const address1Ref = useRef<any>(null)

  const { fetchAddresses } = useAddressAutoComplete()
  const { fetchPlaceDetails } = usePlaceDetails()

  const isActive =
    !!addressState.address1 && !!addressState.city && !!addressState.state && !!addressState.zipcode

  useEffect(() => {
    address1Ref.current?.focus()
  }, [])

  const onSubmit = useCallback(() => {
    const fullAddress = {
      ...addressState,
      country: COUNTRY,
      consentMail: false
    }

    const result = USAddressSchema.safeParse(fullAddress)
    if (!result.success) {
      const newErrors: Partial<AddressState> = {}
      result.error.errors.forEach(e => {
        newErrors[e.path[0]] = e.message
      })
      setErrors(newErrors)
      return
    }

    setErrors({})
    props.onSubmit?.(fullAddress)
  }, [addressState, props])

  const loadAddressOptions = useCallback(
    async (inputValue: string) => {
      if (inputValue.length <= 3) return []
      try {
        const result = await fetchAddresses(inputValue, [COUNTRY])
        const addressOptions = result.addresses?.map((address: IAutocompletePrediction) => ({
          value: address.place_id,
          label: address.description
        })) as OptionsOrGroups<any, any>

        return addressOptions ?? []
      } catch (error) {
        console.error("Error fetching addresses:", error)
      }

      return []
    },
    [fetchAddresses]
  )

  const fillAddress = (address: Partial<IUSAddress>) => {
    setAddressState(prevState => ({
      ...prevState,
      ...address
    }))
    setInputValue(address.address1 ?? inputValue)
    setSelectedAddress({
      value: address.address1 ?? inputValue,
      label: address.address1 ?? inputValue
    })
  }

  const resetErrors = useCallback(() => {
    setErrors({})
  }, [])

  const resetState = useCallback(() => {
    setAddressState({
      address1: "",
      address2: "",
      city: "",
      state: undefined,
      zipcode: ""
    })
    setInputValue("")
    setSelectedAddress(null)
    setIsLoadingPlaceDetails(false)
    resetErrors()
    setTimeout(() => address1Ref.current?.focus(), 0)
  }, [resetErrors])

  const handleOnAddressSelect = async (
    selectedOption: SingleValue<{ value: string; label: string }>
  ) => {
    if (selectedOption == null) {
      resetState()
      return
    }
    setSelectedAddress(selectedOption)
    setIsLoadingPlaceDetails(true)
    if (selectedOption.value === selectedOption.label) {
      resetState()
      setInputValue(selectedOption.label)
      setAddressState(prevState => ({ ...prevState, address1: selectedOption.label }))
      setIsLoadingPlaceDetails(false)
      return
    }

    const { addressDetails, error } = await fetchPlaceDetails(selectedOption.value)
    if (addressDetails && !error) {
      fillAddress(addressDetails)
    } else {
      const addressComponents = selectedOption?.label.split(", ") ?? []
      const addressDetails = {
        address1: addressComponents[0] ?? undefined,
        city: addressComponents[1] ?? undefined,
        state:
          (addressComponents[2].split(" ")[0] as keyof typeof US_STATES_AND_TERRITORIES) ??
          undefined,
        zipcode: addressComponents[2].split(" ")[1] ?? undefined
      }
      fillAddress(addressDetails)
    }
    setIsLoadingPlaceDetails(false)
  }

  const handleInputChange = async (newValue: string, actionMeta: any) => {
    if (actionMeta.action === "input-change") {
      setInputValue(newValue)
      setAddressState(prevState => ({ ...prevState, address1: newValue }))
      if (newValue === "") {
        resetState()
      }
    }
  }

  const submitButtonCSS = classnames(styles.lbUserInputAddressSubmitButton, { isActive })
  const value = inputValue.length
    ? selectedAddress || { label: inputValue, value: inputValue }
    : undefined

  return (
    <div className={styles.lbUserInputAddressContainer} data-testid="address-input">
      {isLoadingPlaceDetails && <div className={styles.loadingOverlay}>Loading...</div>}
      <ErrorInfo error={errors.address1} />
      <AsyncCreatableSelect
        key={selectedAddress?.value + "address1"}
        ref={address1Ref}
        components={customComponents}
        value={value}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleOnAddressSelect}
        loadOptions={loadAddressOptions}
        isValidNewOption={(inputValue, selectOptions) =>
          !!inputValue && !selectOptions.some(option => option.value === inputValue)
        }
        getNewOptionData={undefined}
        formatCreateLabel={() => null}
        allowCreateWhileLoading
        defaultOptions={false}
        placeholder={"Address 1 *"}
        isClearable
        menuPlacement={"auto"}
        styles={selectStyles}
        noOptionsMessage={() => null}
      />

      <TextInput
        key={addressState.address2 + "address2"}
        value={addressState.address2}
        borderless
        autoFocus={true}
        placeholder={"Address 2"}
        clearOnSubmit={false}
        multiline={false}
        autoCorrect={"none"}
        autoComplete={"address2"}
        onChangeText={value =>
          setAddressState(prevState => ({
            ...prevState,
            address2: value
          }))
        }
        onSubmit={onSubmit}
        showOnSubmitButton={false}
      />
      <ErrorInfo error={errors.city} />
      <TextInput
        key={addressState.city + "city"}
        value={addressState.city}
        borderless
        autoFocus={true}
        hasError={!!errors.city}
        placeholder={"City *"}
        clearOnSubmit={false}
        multiline={false}
        autoCorrect={"none"}
        autoComplete={"city"}
        onChangeText={value => setAddressState(prevState => ({ ...prevState, city: value || "" }))}
        onSubmit={onSubmit}
        showOnSubmitButton={false}
      />

      <ErrorInfo error={errors.state} />
      <ErrorInfo error={errors.zipcode} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: "2" }} data-testid="state-select">
          <Select
            options={STATE_OPTIONS}
            isSearchable
            placeholder={"State *"}
            value={
              addressState.state
                ? STATE_OPTIONS.find(option => option.value === addressState.state)
                : null
            }
            onChange={option =>
              setAddressState(prevState => ({ ...prevState, state: option?.value }))
            }
            menuPlacement={"auto"}
            styles={selectStyles}
            id="state-select"
          />
        </div>
        <div style={{ flex: "1", alignItems: "flex-start" }}>
          <TextInput
            key={addressState.zipcode + "zipcode"}
            value={addressState.zipcode}
            borderless
            autoFocus={true}
            hasError={!!errors.zipcode}
            placeholder={"Zipcode *"}
            clearOnSubmit={false}
            multiline={false}
            autoCorrect={"none"}
            autoComplete={"zipcode"}
            onChangeText={value =>
              setAddressState(prevState => ({ ...prevState, zipcode: value || "" }))
            }
            onSubmit={onSubmit}
            showOnSubmitButton={false}
          />
        </div>
      </div>
      <InlineButton
        fullWidth
        disabled={!isActive}
        style={{ backgroundColor: config.userMessageBackground }}
        btn={{ body: app.t("Submit") }}
        onSelect={onSubmit}
        buttonClassName={submitButtonCSS}
      />
    </div>
  )
}

interface IErrorInfoProps {
  error?: string
}

function ErrorInfo(props: IErrorInfoProps) {
  const { error } = props
  if (!error) return null
  return (
    <div className="lb-user-input-address-error-container">
      <span className="lb-user-input-address-error">{error}</span>
    </div>
  )
}

const selectStyles: StylesConfig<any, false, any> | undefined = {
  control: base => ({
    ...base,
    borderRadius: 22,
    padding: 4,
    border: 0,
    minHeight: "51px",
    fontFamily: "Aeroport, Roboto, sans-serif",
    fontWeight: 300
  }),
  container: base => ({
    ...base,
    padding: "16px 16px"
  }),
  menu: base => ({
    ...base,
    borderRadius: 22,
    width: "auto",
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    borderRadius: 22,
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: 300
  }),
  input: base => ({
    ...base,
    fontFamily: "Aeroport, Roboto, sans-serif",
    fontWeight: 300
  }),
  placeholder: base => ({
    ...base,
    fontFamily: "Aeroport, Roboto, sans-serif",
    fontWeight: 300,
    color: "#8F9395"
  })
}
