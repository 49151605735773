import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { MHMCrisisPhoneMappedText } from "../../../config/apiKeysMap"

type State = RiskPathwayScriptState

export type RiskPathwayMHMScriptState = State

export class RiskPathwayMHMScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayMHMScript"

  /** Script Steps */
  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Are you able to keep yourself, and any dependants in your care, safe until your appointment? This could be up 6 weeks, although likely to be sooner"
      ),
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until your appointment? This could be up 6 weeks, although likely to be sooner",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>, script: RiskPathwayMHMScript) => {
    d.state.canKeepSelfSafe = d.response
    script.referralStore.setCustomField<State>("canKeepSelfSafe", d.response)
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  async sayCrisis(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const specificPhoneNumber = this.t(
      d.state.iapt?.id ? [MHMCrisisPhoneMappedText[d.state.iapt?.id]] : []
    )

    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However, {organisationName} does not provide urgent care",
          "If you need urgent support, please dial NHS 111 and select Option 2",
          "In medical emergency and life threatening situations only, please dial 999 or attend your local A&E department",
          "Other helplines available:",
          "You can contact Samaritans 24 hours a day, 365 days a year",
          "You can call 116 123 (free from any phone) or email jo@samaritans.org",
          "If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258",
          "[Shout](https://giveusashout.org/) offers a confidential 24/7 text service providing support if you are in need of immediate help",
          ...specificPhoneNumber,
          "We’ll continue with the referral, however {organisationName} does not provide urgent care. If you require immediate help, please contact one of the numbers listed above"
        ],
        { name, organisationName }
      ),
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }],
        dataPointsName: "sayCrisis"
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }
}

/* istanbul ignore next */
export default class RiskPathwayMHMDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayMHM
  readonly name: string = "RiskPathwayMHMDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayMHMDialogue.id, new RiskPathwayMHMScript(), state, snapshot)
  }
}
