import { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import AssessmentIAPTScript from "../../assessment/AssessmentIAPTScript"
import dialoguesRegistry from "../../../dialoguesRegistry"
import { SurveyScriptState } from "../../../createSurveyDialogue"
import {
  IDefaultChatFlowMessagesAministerCSSRS,
  IDefaultChatFlowSettingsAdministerCSSRS
} from "@limbic/types"

export interface IAdministerCSSRSSettings extends IDefaultChatFlowSettingsAdministerCSSRS {
  messages?: IDefaultChatFlowMessagesAministerCSSRS
}

export type ICSSRSSurveySettings = Pick<
  IAdministerCSSRSSettings,
  "shouldSkipQuestion7" | "messages"
>

type State = BaseScriptState

export type AdministerCSSRSScriptState = State

export class AdministerCSSRSScript extends AssessmentIAPTScript {
  readonly name: string = "AdministerCSSRSScript"
  protected messages: IDefaultChatFlowMessagesAministerCSSRS
  protected shouldSkipQuestion7: boolean
  protected shouldUpdateReferral: boolean

  constructor(settings: IAdministerCSSRSSettings | undefined = {}) {
    super()
    this.messages = settings?.messages ?? {}
    this.shouldSkipQuestion7 = settings?.shouldSkipQuestion7 ?? false
    this.shouldUpdateReferral = settings?.shouldUpdateReferral ?? true
  }

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.goToCSSRSSurvey }
  }

  @step.logState
  goToCSSRSSurvey(d: IStepData<State>): IStepResult {
    const CSSRSDialogue = dialoguesRegistry.get(DialogueIDs.CSSRS)
    const cssrsSurveySettings: ICSSRSSurveySettings = {
      messages: this.messages,
      shouldSkipQuestion7: this.shouldSkipQuestion7
    }
    return {
      nextDialogue: new CSSRSDialogue({ ...d.state }, undefined, cssrsSurveySettings),
      nextStep: this.handleCSSRSSurvey
    }
  }

  @step.logStateAndResponse
  async handleCSSRSSurvey(d: IStepData<State, undefined, SurveyScriptState>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handleCSSRSResponses?.(d.state)

    return { nextStep: this.end }
  }

  /** Generic Handlers */
  handleCSSRSResponses(state: State): void {
    this.sendCSSRS(state)
  }
}

export default class AdministerCSSRSDialogue extends AdHocDialogue<State, AdministerCSSRSScript> {
  static id = DialogueIDs.AdministerCSSRS
  readonly name: string = "AdministerCSSRSDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: IAdministerCSSRSSettings
  ) {
    super(
      AdministerCSSRSDialogue.id,
      new AdministerCSSRSScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
