import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

interface State extends AssessmentPitchIAPTScriptState {
  mainIssue?: string
}

export type AssessmentPitchBradfordState = State

export class AssessmentPitchBradfordScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchBradfordScript"

  /** Script Steps */

  @step.logState
  checkForNeedToBeCalled(_d: IStepData<State>): IStepResult {
    return { nextStep: this.sayWeCanSpeedUpReferral }
  }

  @step.logState
  sayWeCanSpeedUpReferral(d: IStepData<State>): IStepResult {
    return {
      body: [
        "I'm now going to ask you a few questions to measure symptoms of common mental health issues",
        "And I will use the results to match you with the most appropriate therapy"
      ],
      prompt: {
        id: this.getPromptId("sayWeCanSpeedUpReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.end
    }
  }

  @step.logState
  saySomeoneFromAdminWillContact(d: IStepData<State>): IStepResult {
    return {
      body: "Someone from our admin team will be in touch within 1-2 working days to book you in for an assessment with one of our clinicians",
      prompt: {
        id: this.getPromptId("saySomeoneFromAdminWillContact"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Looking forward to it" }, { body: "Okay" }]
      },
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */
}

/* istanbul ignore next */
export default class AssessmentPitchBradfordDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchBradford
  readonly name: string = "AssessmentPitchBradfordDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchBradfordDialogue.id, new AssessmentPitchBradfordScript(), state, snapshot)
  }
}
