import { isOnline } from "../../../utils/isOnline"
import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import type { IGPServiceODS } from "../../../models/IGPService"
import client from "./_client"

export enum ODSFindRequestStatus {
  NO_INTERNET = "NO_INTERNET",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED"
}

const TOTAL_RETRIES = 2

export async function getODSGPDetails(
  id: string,
  retry = 0
): Promise<[IGPServiceODS | undefined, ODSFindRequestStatus]> {
  if (!id) return [undefined, ODSFindRequestStatus.FAILED]

  try {
    if (!(await isOnline())) return [undefined, ODSFindRequestStatus.NO_INTERNET]

    const data = await client.get(`/v1/ods/gp/${id}`)
    return [data, ODSFindRequestStatus.SUCCESS]
  } catch (e) {
    Logger.getInstance().exception(e, "getODSGPDetails fetch failed")
    if (retry < TOTAL_RETRIES) {
      const message = `getODSGPDetails param for retry ${retry}`
      Logger.getInstance().breadcrumb({ message, data: { id } })
      Logger.getInstance().message("getODSGPDetails retry")
      await delay(1)
      return await getODSGPDetails(id, retry + 1)
    }

    return [undefined, ODSFindRequestStatus.FAILED]
  }
}
