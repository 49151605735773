import moment from "moment"
import {
  ALCOHOL_FREQUENCIES,
  ALCOHOL_QUANTITIES,
  CorrespondencePreferred,
  CovidStatus,
  DiscussionSteps,
  EligibilityPresetFlowResults,
  IDefaultChatFlowSettingsCheckCovidAndDetails,
  IDefaultChatFlowSettingsCheckPostCodeFromAddressLookup,
  ITreatmentOption,
  IUSAddress,
  QUESTIONNAIRE,
  ReferralPayload
} from "@limbic/types"
import Script, { ScriptState } from "../backend/chatbot/Script"
import { step } from "../backend/chatbot/decorators/step"
import {
  AssessmentCallReason,
  ReferralType,
  RiskLevel,
  RiskLevelReason,
  TrackingEvents
} from "../models/Constants"
import getIAPTById from "../utils/getIAPTById"
import isNullOrUndefined from "../utils/isNullOrUndefined"
import invariant from "../utils/invariant"
import IName from "../models/IName"
import IPostcode from "../models/IPostcode"
import { IIAPTService } from "../models/IIAPTService"
import { IGPService, IGPServiceODS } from "../models/IGPService"
import { IPersistableSurveyResponse } from "../models/ISurvey"
import { IPersistableUserResponse } from "../models/IUserResponse"
import { Translator } from "../i18n/Translator"
import type RootStore from "../app/stores/rootStore"
import type ClinicalStore from "../app/stores/clinicalStore"
import type DiscussionStore from "../app/stores/discussionStore"
import type ReferralStore from "../app/stores/referralStore"
import type WellbeingHubStore from "../app/stores/wellbeingHubStore"
import type { IStep, IStepData, IStepResult } from "../backend/chatbot/models/IStep"
import type { IDialoguesRegistry } from "./dialoguesRegistry"
import { Severity } from "../models/Logger/Severity"
import {
  CollectMainIssueScriptState,
  ICollectMainIssueSettings
} from "./dialogues/ad-hoc/CollectMainIssue/CollectMainIssueDialogue"
import {
  CollectGoalForTherapyScriptState,
  ICollectGoalForTherapySettings
} from "./dialogues/ad-hoc/CollectGoalForTherapy/CollectGoalForTherapyDialogue"
import {
  CollectNHSNumberScriptState,
  ICollectNHSNumberSettings
} from "./dialogues/ad-hoc/CollectNHSNumber/CollectNHSNumberDialogue"
import { ICollectADHDSettings } from "./dialogues/ad-hoc/CollectADHD/CollectADHDDialogue"
import { ICollectASDSettings } from "./dialogues/ad-hoc/CollectASD/CollectASDDialogue"
import { ICollectPreferredCorrespondenceSettings } from "./dialogues/ad-hoc/CollectPreferredCorrespondence/CollectPreferredCorrespondenceDialogue"
import { DialogueIDs } from "./DialogueIDs"
import {
  CollectNationalityScriptState,
  ICollectNationalitySettings
} from "./dialogues/ad-hoc/CollectNationality/CollectNationalityDialogue"
import { ICollectEthnicitySettings } from "./dialogues/ad-hoc/CollectEthnicity/CollectEthnicityDialogue"
import { ICollectCurrentMHTreatmentSettings } from "./dialogues/ad-hoc/CollectCurrentMHTreatmentDetails/CollectCurrentMHTreatmentDetailsDialogue"
import {
  CollectGenderScriptState,
  ICollectGenderSettings
} from "./dialogues/ad-hoc/CollectGender/CollectGenderDialogue"
import {
  CollectSexualityScriptState,
  ICollectSexualitySettings
} from "./dialogues/ad-hoc/CollectSexuality/CollectSexualityDialogue"
import { ICollectDisabilitiesSettings } from "./dialogues/ad-hoc/CollectDisabilities/CollectDisabilitiesDialogue"
import {
  CollectLanguageAndInterpreterScriptState,
  ICollectLanguageAndInterpreterSettings
} from "./dialogues/ad-hoc/CollectLanguageAndInterpreter/CollectLanguageAndInterpreterDialogue"
import { ICollectLongTermMedicalConditionSettings } from "./dialogues/ad-hoc/CollectLongTermMedicalConditionDetails/CollectLongTermMedicalConditionDetailsDialogue"
import { CollectMedicationAndSubstancesDetailsScriptState } from "./dialogues/ad-hoc/CollectMedicationAndSubstancesDetails/CollectMedicationAndSubstancesDetailsDialogue"
import { ICollectReligionSettings } from "./dialogues/ad-hoc/CollectReligion/CollectReligionDialogue"
import { ICollectPhoneNumberSettings } from "./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberDialogue"
import { ICollectAlcoholConsumptionSettings } from "./dialogues/ad-hoc/CollectAlcoholConsumption/CollectAlcoholConsumptionDialogue"
import { ICollectSubstancesSettings } from "./dialogues/ad-hoc/CollectSubstances/CollectSubstancesDialogue"
import { ValueOf } from "../models/ValueOf"
import { ICollectEmailSettings } from "./dialogues/ad-hoc/CollectEmail/CollectEmailDialogue"
import passthroughTranslationFn from "../utils/passthroughTranslationFn"
import customIAPTSHaveError from "../utils/customIAPTSHaveError"
import setIneligibleUser from "../backend/api/limbic/setIneligibleUser"
import IAddress from "../models/IAddress"
import { FF } from "../featureFlags"
import { ICollectSMISettings } from "./dialogues/ad-hoc/CollectSMI/CollectSMIDialogue"
import stepResultBodyToLLMMessage from "../utils/stepResultBodyToLLMMessage"

export interface BaseScriptState extends ScriptState {
  name?: IName
  spineName?: IName
  nhsNumber?: string
  gp?: IGPService
  odsGP?: IGPServiceODS
  iapt?: IIAPTService
  iaptManuallySelected?: boolean
  iaptSuggestions?: IIAPTService[]
  userPostcode?: IPostcode
  customUserPostcode?: string
  isCustomPostcode?: boolean
  gpPostcode?: IPostcode
  postcodeEntered?: string
  addressLookupCounter?: number
  postcodeLookupCounter?: number
  hideEarlierYouSaid?: boolean
  retryPostcode?: string
  invalidPostcodeEntered?: string
  startWithAskPostcode?: boolean
  birthday?: number
  isUnderAged?: boolean
  isEligible?: boolean
  isMaybeEligible?: boolean
  signPostToManualReferral?: boolean
  needsToCall?: boolean
  patientId?: string
  signupCode?: string
  phoneNumber?: string
  canSendTextMessagesToPhoneNumber?: boolean
  canLeaveVoicemailToPhoneNumber?: boolean
  canCallPhoneNumber?: boolean
  email?: string
  canSendEmail?: boolean
  addressHome?: IAddress | IUSAddress
  address?: string
  address2?: string
  street?: string
  city?: string
  county?: string
  state?: string
  zipcode?: number
  canSendMailToAddress?: boolean
  preferredContactMethod?: CorrespondencePreferred
  ethnicity?: string
  nationality?: string
  nationalityOther?: string
  gender?: string
  genderOther?: string
  sameGenderAsBirth?: string
  spineGender?: string
  spineLanguage?: string
  spineInterpreterRequired?: boolean
  primaryLanguage?: string
  perinatalStatus?: string
  isExArmedForces?: string
  religion?: string
  title?: any
  preferredPronouns?: any
  sexuality?: string
  sexualityOther?: string
  disabilityStatus?: boolean
  disability?: string
  disabilities?: string[]
  longTermMedicalCondition?: string[]
  longTermConditionOther?: string
  ltcAffectsMood?: boolean
  ltcMoodImpact?: "little" | "somewhat" | "very"
  ltcManagement?: "little" | "fairly" | "very"
  covidStatus?: CovidStatus
  covidDate?: string
  hasLongCovid?: boolean
  isReferralForLongCovid?: boolean
  alcohol?: boolean
  alcoholFrequency?: ValueOf<typeof ALCOHOL_FREQUENCIES>
  alcoholQuantity?: ValueOf<typeof ALCOHOL_QUANTITIES>
  alcoholImpactsLife?: boolean
  substances?: boolean
  substancesForMood?: boolean
  substancesAreMedications?: boolean
  substancesInfo?: string
  substancesImpactOnLife?: boolean
  otherSubstances?: boolean
  substancesOverTheCounter?: boolean
  takingPrescribedMedication?: boolean
  prescribedMedications?: string
  medicationWithinDoseRange?: "Yes" | "No" | "Not sure"
  medicationInfo?: string
  needsAssessmentCall?: boolean
  assessmentCallReason?: AssessmentCallReason
  hasCurrentSupport?: boolean
  priorMHTreatment?: boolean
  priorMHTreatmentDetails?: string
  currentMHTreatment?: boolean
  currentMHTreatmentDetails?: string[]
  improvementSuggestion?: string
  isHelpful?: "Yes" | "No" | "Needed more"
  referralSubmitted?: boolean
  referralSubmissionFailed?: boolean
  userInput?: string
  userInputExplanation?: string
  whereDidYouHearAboutService?: string
  canKeepSelfSafe?: boolean
  php9q9Score?: number
  phq9Responses?: IPersistableSurveyResponse[]
  phq2Responses?: IPersistableSurveyResponse[]
  bdi2Responses?: IPersistableSurveyResponse[]
  ham_dResponses?: IPersistableSurveyResponse[]
  pswqResponses?: IPersistableSurveyResponse[]
  auditResponses?: IPersistableSurveyResponse[]
  drugsAndSmokingResponses?: IPersistableSurveyResponse[]
  itqResponses?: IPersistableSurveyResponse[]
  irqaResponses?: IPersistableSurveyResponse[]
  gad7Responses?: IPersistableSurveyResponse[]
  gad2Responses?: IPersistableSurveyResponse[]
  ham_aResponses?: IPersistableSurveyResponse[]
  wsasResponses?: IPersistableSurveyResponse[]
  pdssResponses?: IPersistableSurveyResponse[]
  phobiaScaleResponses?: IPersistableSurveyResponse[]
  employmentStatusResponses?: IPersistableSurveyResponse[]
  medicationResponses?: IPersistableSurveyResponse[]
  accommodationResponses?: IPersistableSurveyResponse[]
  pcl5Responses?: IPersistableSurveyResponse[]
  spinResponses?: IPersistableSurveyResponse[]
  ociResponses?: IPersistableSurveyResponse[]
  scoffResponses?: IPersistableSurveyResponse[]
  shai18Responses?: IPersistableSurveyResponse[]
  specificPhobiaResponses?: IPersistableSurveyResponse[]
  riskPathwayResponses?: IPersistableUserResponse[]
  smiResponses?: IPersistableSurveyResponse[]
  cssrsResponses?: IPersistableUserResponse[]
  experiencingCrisisAndSuicidalThoughts?: boolean
  consentADSM?: boolean
  readTheInformationSheetADSM?: boolean
  agreeToWithdrawByEmailADSM?: boolean
  agreeReferralDataToBeUsedADSM?: boolean
  wishToParticipateInTheProjectADSM?: boolean
  consentDataUsedInOtherProjectsADSM?: boolean
  consentParticipationInProjectADSM?: boolean
  agreeTerms?: boolean
  agreeDetailsShared?: boolean
  consentResearch?: boolean
  mainIssue?: string
  therapyGoal?: string
  hasADHD?: boolean
  hasASD?: boolean
  requiresInterpreter?: boolean
  interpreterLanguage?: string
  ableToCommunicateInEnglish?: boolean
  requiresUrgentSupport?: boolean
  presetFlowResult?: EligibilityPresetFlowResults
  isPhoneNumberAvailable?: boolean
}

export default abstract class BaseScript<State extends BaseScriptState> extends Script<State> {
  static _rootStore: RootStore
  static _translator: Translator | undefined
  _rootStore?: RootStore
  _translator?: Translator

  /** Static Methods */

  static setRootStore(rootStore: RootStore): void {
    invariant(rootStore, `BaseScript needs a _rootStore property.[${rootStore}]`)
    this._rootStore = rootStore
  }

  static setTranslator(t: Translator): void {
    invariant(t, `BaseScript cannot set _translator to [${t}]`)
    this._translator = t
  }

  static get rootStore(): RootStore {
    invariant(
      this._rootStore,
      `BaseScript needs a static _rootStore property. [${this._rootStore}]`
    )
    return this._rootStore
  }

  static get translator(): Translator | undefined {
    return this._translator
  }

  setRootStore(rootStore: RootStore): void {
    invariant(rootStore, `BaseScript needs a _rootStore property.[${rootStore}]`)
    invariant(
      process.env.NODE_ENV === "test",
      "non-static setRootStore of BaseScript is only for testing"
    )
    this._rootStore = rootStore
  }

  setTranslator(t: Translator): void {
    invariant(t, `BaseScript cannot set _translator to [${t}]`)
    invariant(
      process.env.NODE_ENV === "test",
      "non-static setTranslator of BaseScript is only for testing"
    )
    this._translator = t
  }
  /** Optional Abstract Generic Handlers */

  getReferralTypeForRisk?(state: State): string | undefined
  getCustomReferralType?(state: State): string | undefined

  /** Overrides */

  getName(state: State): string {
    if (state.preferredName) return state.preferredName
    if (state.name) return this.getFirstName(state)
    return super.getName(state)
  }

  getFirstName(state: State): string {
    if (state.name) return state.name.firstName
    return super.getName(state)
  }

  getLastName(state: State): string {
    if (state.name) return state.name.lastName
    return super.getLastName(state)
  }

  async onHandleTriggerWords(
    state: State,
    triggerWords: string[],
    input?: string,
    nextStep?: IStep<State>
  ): Promise<IStepResult | void> {
    this.clinicalStore.setTriggerWords(triggerWords)
    this.clinicalStore.setTriggerWordsPhrase(input)
  }

  getCrisisDialogue(_state: State): IDialoguesRegistry[keyof IDialoguesRegistry] | undefined {
    return this.discussionStore.getDialogueClass(DiscussionSteps.Crisis)
  }

  startTyping(): void {
    this.rootStore.chatStore.startBotTyping()
  }

  stopTyping(): void {
    this.rootStore.chatStore.stopBotTyping()
  }

  /** Script Steps */

  @step.logState
  goToGoodbye(d: IStepData<State>): IStepResult {
    const GoodbyeDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.Goodbye)
    const nextDialogue = GoodbyeDialogue //
      ? new GoodbyeDialogue({ ...d.state })
      : undefined
    return {
      nextDialogue,
      nextStep: this.end,
      clearStack: true
    }
  }

  /** Default Chat Flow Steps */

  // prettier-ignore
  getCollectPhoneNumberSettings?(state: State): Promise<ICollectPhoneNumberSettings | void>
  onCollectPhoneNumberEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectPhoneNumber(d: IStepData<State>): Promise<IStepResult> {
    const CollectPhoneNumberDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectPhoneNumber
    ) as IDialoguesRegistry[DialogueIDs.CollectPhoneNumber] | undefined
    const settings = (await this.getCollectPhoneNumberSettings?.(d.state)) ?? {}
    const nextDialogue = CollectPhoneNumberDialogue
      ? new CollectPhoneNumberDialogue({ ...d.state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectPhoneNumberEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  // prettier-ignore
  getCollectPostcodeAndAddressSettings?(state: State): Promise<IDefaultChatFlowSettingsCheckPostCodeFromAddressLookup | void>
  onCollectPostcodeAndAddressEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCheckPostCodeForAddressLookup(d: IStepData<State>): Promise<IStepResult> {
    // prettier-ignore
    const CheckPostCodeFromAddressLookupDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.CheckPostCodeFromAddressLookup)
    const settings = (await this.getCollectPostcodeAndAddressSettings?.(d.state)) ?? {}
    const nextDialogue = CheckPostCodeFromAddressLookupDialogue
      ? new CheckPostCodeFromAddressLookupDialogue({ ...d.state, ...settings })
      : undefined
    const nextStep = (await this.onCollectPostcodeAndAddressEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  // prettier-ignore
  getCollectAlcoholConsumptionSettings?(state: State): Promise<ICollectAlcoholConsumptionSettings | void>
  onCollectAlcoholConsumptionEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectAlcoholConsumption(d: IStepData<State>): Promise<IStepResult> {
    const CollectAlcoholConsumptionDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectAlcoholConsumption
    ) as IDialoguesRegistry[DialogueIDs.CollectAlcoholConsumption] | undefined
    const settings = (await this.getCollectAlcoholConsumptionSettings?.(d.state)) ?? {}
    const nextDialogue = CollectAlcoholConsumptionDialogue
      ? new CollectAlcoholConsumptionDialogue({ ...d.state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectAlcoholConsumptionEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  // prettier-ignore
  getCheckCovidAndDetailsSettings?(state: State): Promise<IDefaultChatFlowSettingsCheckCovidAndDetails | void>
  onCheckCovidDetailsEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCheckCovidAndDetails(d: IStepData<State>): Promise<IStepResult> {
    const CheckCovidAndDetailsDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CheckCovidAndDetails
    )
    const settings = (await this.getCheckCovidAndDetailsSettings?.(d.state)) ?? {}
    const nextDialogue = CheckCovidAndDetailsDialogue
      ? new CheckCovidAndDetailsDialogue({ ...d.state, ...settings })
      : undefined

    const nextStep = (await this.onCheckCovidDetailsEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  getCollectMainIssueSettings?(state: State): Promise<ICollectMainIssueSettings | void>
  // TODO: settings and state must be cleaned up from all preset flows
  // prettier-ignore
  getCollectMainIssueState?(state: State): Promise<CollectMainIssueScriptState | void>
  onCollectMainIssueEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectMainIssue(d: IStepData<State>): Promise<IStepResult> {
    const CollectMainIssueDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectMainIssue
    ) as IDialoguesRegistry[DialogueIDs.CollectMainIssue] | undefined
    const settings = (await this.getCollectMainIssueSettings?.(d.state)) ?? {}
    const state = (await this.getCollectMainIssueState?.(d.state)) ?? {}
    const nextDialogue = CollectMainIssueDialogue
      ? new CollectMainIssueDialogue({ ...d.state, ...state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectMainIssueEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  getCollectGoalForTherapySettings?(state: State): Promise<ICollectGoalForTherapySettings | void>
  // TODO: settings and state must be cleaned up from all preset flows
  getCollectGoalForTherapyState?(state: State): Promise<CollectGoalForTherapyScriptState | void>
  onCollectGoalForTherapyEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectGoalForTherapy(d: IStepData<State>): Promise<IStepResult> {
    // prettier-ignore
    const CollectGoalForTherapyDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.CollectGoalForTherapy)
    const settings = (await this.getCollectGoalForTherapySettings?.(d.state)) ?? {}
    const state = (await this.getCollectGoalForTherapyState?.(d.state)) ?? {}
    const nextDialogue = CollectGoalForTherapyDialogue
      ? new CollectGoalForTherapyDialogue({ ...d.state, ...state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectGoalForTherapyEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  getCollectNHSNumberSettings?(state: State): Promise<ICollectNHSNumberSettings | void>
  // TODO: settings and state must be cleaned up from all preset flows
  getCollectNHSNumberState?(state: State): Promise<CollectNHSNumberScriptState | void>
  onCollectNHSNumberEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectNHSNumber(d: IStepData<State>): Promise<IStepResult> {
    const CollectNHSNumberDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectNHSNumber
    ) as IDialoguesRegistry[DialogueIDs.CollectNHSNumber] | undefined
    const settings = (await this.getCollectNHSNumberSettings?.(d.state)) ?? {}
    const state = (await this.getCollectNHSNumberState?.(d.state)) ?? {}
    const nextDialogue = CollectNHSNumberDialogue
      ? new CollectNHSNumberDialogue({ ...d.state, ...state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectNHSNumberEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  getCollectADHDSettings?(state: State): Promise<ICollectADHDSettings | void>
  onCollectADHDEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectADHD(d: IStepData<State>): Promise<IStepResult> {
    const CollectADHDDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.CollectADHD)
    const settings = (await this.getCollectADHDSettings?.(d.state)) ?? {}
    const nextDialogue = CollectADHDDialogue
      ? new CollectADHDDialogue({ ...d.state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectADHDEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  getCollectASDSettings?(state: State): Promise<ICollectASDSettings | void>
  onCollectASDEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectASD(d: IStepData<State>): Promise<IStepResult> {
    const CollectASDDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.CollectASD)
    const settings = (await this.getCollectASDSettings?.(d.state)) ?? {}
    const nextDialogue = CollectASDDialogue
      ? new CollectASDDialogue({ ...d.state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectASDEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  // prettier-ignore
  getCollectPreferredCorrespondenceSettings?(state: State): Promise<ICollectPreferredCorrespondenceSettings | void>
  onCollectPreferredCorrespondenceEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectPreferredCorrespondence(d: IStepData<State>): Promise<IStepResult> {
    const CollectPreferredCorrespondenceDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectPreferredCorrespondence
    ) as IDialoguesRegistry[DialogueIDs.CollectPreferredCorrespondence] | undefined
    const settings = (await this.getCollectPreferredCorrespondenceSettings?.(d.state)) ?? {}
    const nextDialogue = CollectPreferredCorrespondenceDialogue
      ? new CollectPreferredCorrespondenceDialogue({ ...d.state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectPreferredCorrespondenceEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  getCollectNationalitySettings?(state: State): Promise<ICollectNationalitySettings | void>
  getCollectNationalityState?(state: State): Promise<CollectNationalityScriptState | void>
  onCollectNationalityEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectNationality(d: IStepData<State>): Promise<IStepResult> {
    const CollectNationality = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectNationality
    ) as IDialoguesRegistry[DialogueIDs.CollectNationality]
    const settings = (await this.getCollectNationalitySettings?.(d.state)) ?? {}
    const state = (await this.getCollectNationalityState?.(d.state)) ?? {}
    const nextDialogue = CollectNationality
      ? new CollectNationality({ ...d.state, ...state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectNationalityEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  getCollectEthnicitySettings?(state: State): Promise<ICollectEthnicitySettings | void>
  onCollectEthnicityEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectEthnicity(d: IStepData<State>): Promise<IStepResult> {
    const settings = (await this.getCollectEthnicitySettings?.(d.state)) ?? {}
    const CollectEthnicityDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectEthnicity
    ) as IDialoguesRegistry[DialogueIDs.CollectEthnicity]
    const nextDialogue = CollectEthnicityDialogue
      ? new CollectEthnicityDialogue({ ...d.state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectEthnicityEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  getCollectReligionSettings?(state: State): Promise<ICollectReligionSettings | void>
  onCollectReligionEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectReligion(d: IStepData<State>): Promise<IStepResult> {
    const settings = (await this.getCollectReligionSettings?.(d.state)) ?? {}
    const CollectReligionDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectReligion
    ) as IDialoguesRegistry[DialogueIDs.CollectReligion]
    const nextDialogue = CollectReligionDialogue
      ? new CollectReligionDialogue({ ...d.state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectReligionEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  // prettier-ignore
  getCollectCurrentMHTreatmentSettings?(state: State): Promise<ICollectCurrentMHTreatmentSettings | void>
  onCollectCurrentMHTreatmentEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectCurrentMHTreatment(d: IStepData<State>): Promise<IStepResult> {
    const CollectCurrentMHTreatmentDetailsDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectCurrentMHTreatmentDetails
    ) as IDialoguesRegistry[DialogueIDs.CollectCurrentMHTreatmentDetails]
    const settings = (await this.getCollectCurrentMHTreatmentSettings?.(d.state)) ?? {}
    const nextDialogue = CollectCurrentMHTreatmentDetailsDialogue
      ? new CollectCurrentMHTreatmentDetailsDialogue({ ...d.state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectCurrentMHTreatmentEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  onCollectPriorMHTreatmentEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectPriorMHTreatmentDetails(d: IStepData<State>): Promise<IStepResult> {
    // prettier-ignore
    const CollectPriorMHTreatmentDetailsDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.CollectPriorMHTreatmentDetails)
    const nextDialogue = CollectPriorMHTreatmentDetailsDialogue
      ? new CollectPriorMHTreatmentDetailsDialogue({ ...d.state })
      : undefined
    const nextStep = (await this.onCollectPriorMHTreatmentEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  getCollectGenderSettings?(state: State): Promise<ICollectGenderSettings | void>
  // TODO: settings and state must be cleaned up from all preset flows
  getCollectGenderState?(state: State): Promise<CollectGenderScriptState | void>
  onCollectGenderEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectGender(d: IStepData<State>): Promise<IStepResult> {
    const CollectGenderDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectGender
    )
    const state = (await this.getCollectGenderState?.(d.state)) ?? {}
    const settings = (await this.getCollectGenderSettings?.(d.state)) ?? {}
    const nextDialogue = CollectGenderDialogue
      ? new CollectGenderDialogue({ ...d.state, ...state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectGenderEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  getCollectSexualitySettings?(state: State): Promise<ICollectSexualitySettings | void>
  getCollectSexualityState?(state: State): Promise<CollectSexualityScriptState | void>
  onCollectSexualityEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectSexuality(d: IStepData<State>): Promise<IStepResult> {
    const CollectSexualityDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectSexuality
    ) as IDialoguesRegistry[DiscussionSteps.CollectSexuality]
    const state = (await this.getCollectSexualityState?.(d.state)) ?? {}
    const settings = (await this.getCollectSexualitySettings?.(d.state)) ?? {}
    const nextDialogue = CollectSexualityDialogue
      ? new CollectSexualityDialogue({ ...d.state, ...state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectSexualityEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  getCollectDisabilitiesSettings?(state: State): Promise<ICollectDisabilitiesSettings | void>
  onCollectDisabilitiesEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectDisabilities(d: IStepData<State>): Promise<IStepResult> {
    const CollectDisabilitiesDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectDisabilities
    ) as IDialoguesRegistry[DialogueIDs.CollectDisabilities]
    const settings = (await this.getCollectDisabilitiesSettings?.(d.state)) ?? {}
    const nextDialogue = CollectDisabilitiesDialogue
      ? new CollectDisabilitiesDialogue({ ...d.state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectDisabilitiesEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  // prettier-ignore
  getCollectLanguageAndInterpreterSettings?(state: State): Promise<ICollectLanguageAndInterpreterSettings | void>
  // TODO: settings and state must be cleaned up from all preset flows
  // prettier-ignore
  getCollectLanguageAndInterpreterState?(state: State): Promise<CollectLanguageAndInterpreterScriptState | void>
  onCollectLanguageAndInterpreterEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectLanguageAndInterpreter(d: IStepData<State>): Promise<IStepResult> {
    const CollectLanguageAndInterpreterDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectLanguageAndInterpreter
    ) as IDialoguesRegistry[DialogueIDs.CollectLanguageAndInterpreter]
    const state = (await this.getCollectLanguageAndInterpreterState?.(d.state)) ?? {}
    const settings = (await this.getCollectLanguageAndInterpreterSettings?.(d.state)) ?? {}
    const nextDialogue = CollectLanguageAndInterpreterDialogue
      ? new CollectLanguageAndInterpreterDialogue({ ...d.state, ...state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectLanguageAndInterpreterEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  getCollectEmailSettings?(state: State): Promise<ICollectEmailSettings | void>
  onCollectEmailEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectEmail(d: IStepData<State>): Promise<IStepResult> {
    const CollectEmailDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectEmail
    ) as IDialoguesRegistry[DialogueIDs.CollectEmail]
    const settings = (await this.getCollectEmailSettings?.(d.state)) ?? {}
    const nextDialogue = CollectEmailDialogue
      ? new CollectEmailDialogue({ ...d.state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectEmailEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  // prettier-ignore
  getCollectLongTermMedicalConditionSettings?(state: State): Promise<ICollectLongTermMedicalConditionSettings | void>
  onCollectLongTermMedicalConditionEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectLongTermMedicalConditionDetails(d: IStepData<State>): Promise<IStepResult> {
    const CollectLongTermMedicalConditionDetailsDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectLongTermMedicalConditionDetails
    )
    const settings = (await this.getCollectLongTermMedicalConditionSettings?.(d.state)) ?? {}
    const nextDialogue = CollectLongTermMedicalConditionDetailsDialogue
      ? new CollectLongTermMedicalConditionDetailsDialogue({ ...d.state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectLongTermMedicalConditionEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  // prettier-ignore
  getCollectSubstancesSettings?(state: State): Promise<ICollectSubstancesSettings>
  onCollectSubstancesEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectSubstances(d: IStepData<State>): Promise<IStepResult> {
    const CollectSubstancesDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectSubstances
    ) as IDialoguesRegistry[DialogueIDs.CollectSubstances]
    const settings = (await this.getCollectSubstancesSettings?.(d.state)) ?? {}
    const nextDialogue = CollectSubstancesDialogue
      ? new CollectSubstancesDialogue({ ...d.state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectSubstancesEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  // prettier-ignore
  getCollectMedicationAndSubstancesState?(state: State): Promise<CollectMedicationAndSubstancesDetailsScriptState>
  onCollectMedicationAndSubstancesEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectMedicationAndSubstances(d: IStepData<State>): Promise<IStepResult> {
    const CollectMedicationAndSubstancesDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectLongTermMedicalConditionDetails
    ) as IDialoguesRegistry[DialogueIDs.CollectMedicationAndSubstancesDetails]
    const state = (await this.getCollectMedicationAndSubstancesState?.(d.state)) ?? {}
    const nextDialogue = CollectMedicationAndSubstancesDialogue
      ? new CollectMedicationAndSubstancesDialogue({ ...d.state, ...state }, undefined)
      : undefined
    const nextStep = (await this.onCollectMedicationAndSubstancesEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  getCollectSMISettings?(state: State): Promise<ICollectSMISettings>
  onCollectSMIEnded?(state: State): Promise<IStep | void>

  @step.logState
  async goToCollectSMI(d: IStepData<State>): Promise<IStepResult> {
    const CollectSMIDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectSMI
    ) as IDialoguesRegistry[DialogueIDs.CollectSMI]
    const settings = (await this.getCollectSMISettings?.(d.state)) ?? {}
    const nextDialogue = CollectSMIDialogue
      ? new CollectSMIDialogue({ ...d.state }, undefined, settings)
      : undefined
    const nextStep = (await this.onCollectSMIEnded?.(d.state)) ?? this.end
    return { nextDialogue, nextStep }
  }

  /** Generic Handlers */

  getFullName(state: State): string {
    if (!state.name) return state.username || ""
    const { firstName, lastName, middleNames } = state.name
    return `${firstName ?? ""}${middleNames ? ` ${middleNames}` : ""} ${lastName ?? ""}`.trim()
  }

  getPromptId(id: string): string {
    invariant(id, "You need to provide a prompt id")
    return `${this.name}.${id}`
  }

  setUnderAged(state: State, isUnderAged: boolean): void {
    state.isUnderAged = isUnderAged
    this.setPeople({ isUnderAged })
    this.logBreadcrumb("setUnderAged", state, { isUnderAged })
    this.logMessage("setUnderAged")
  }

  setEligibility(state: State, isEligible: boolean): void {
    state.isEligible = isEligible
    this.setPeople({ isEligible })
    this.track(TrackingEvents.ELIGIBILITY_DETERMINED, { isEligible })
    this.logBreadcrumb(TrackingEvents.ELIGIBILITY_DETERMINED, state, { isEligible })
    this.logMessage(TrackingEvents.ELIGIBILITY_DETERMINED)
    if (!state.isEligible) this.setPeople({ gpInfo: state.gp, odsGPInfo: state.odsGP })
  }

  setSignpostToManualSelfReferral(state: State, signPostToManualReferral: boolean): void {
    state.signPostToManualReferral = signPostToManualReferral
    this.setPeople({ signPostToManualReferral })
    this.track(TrackingEvents.SIGNPOST_MANUAL_REFERRAL, { signPostToManualReferral })
    this.logBreadcrumb(TrackingEvents.SIGNPOST_MANUAL_REFERRAL, state, { signPostToManualReferral })
    this.logMessage(TrackingEvents.SIGNPOST_MANUAL_REFERRAL)
  }

  setUserDoesNotNeedToCallIn(state: State): void {
    state.needsToCall = false
    this.setPeople({ needsToCall: false })
    this.track(TrackingEvents.USER_DOES_NOT_NEED_TO_CALL)
    this.logBreadcrumb(TrackingEvents.USER_DOES_NOT_NEED_TO_CALL, state, { needsToCall: false })
    this.logMessage(TrackingEvents.USER_DOES_NOT_NEED_TO_CALL)
  }

  setUserNeedsToCallIn(state: State): void {
    state.needsToCall = true
    this.setPeople({ needsToCall: true })
    this.track(TrackingEvents.USER_NEEDS_TO_CALL)
    this.logBreadcrumb(TrackingEvents.USER_NEEDS_TO_CALL, state, { needsToCall: true })
    this.logMessage(TrackingEvents.USER_NEEDS_TO_CALL)
  }

  setGP(state: State, gp?: IGPService): void {
    state.gp = gp
  }

  setODSGP(state: State, gp?: IGPServiceODS): void {
    state.odsGP = gp
  }

  setIAPT(state: State, iapt?: IIAPTService, iaptManuallySelected?: boolean): void {
    state.iapt = iapt
    state.iaptManuallySelected = !!iaptManuallySelected
    if (iapt?.backendInstanceID) this.referralStore.setInstanceID(iapt.backendInstanceID)
    const data = {
      iapt: iapt?.name ? `${iapt?.name} (${iapt?.id})` : undefined,
      iaptManuallySelected: !!iaptManuallySelected
    }
    this.setPeople(data)
    this.track(TrackingEvents.IAPT_DETERMINED, data)
    this.logBreadcrumb(TrackingEvents.IAPT_DETERMINED, state, data)
    this.logMessage(TrackingEvents.IAPT_DETERMINED)
  }

  setIAPTSuggestions(state: State, iaptSuggestions?: IIAPTService[]): void {
    state.iaptSuggestions = iaptSuggestions
    const data = { iaptSuggestions: iaptSuggestions?.map(i => `${i.name} (${i.id}`) }
    this.setPeople(data)
    this.track(TrackingEvents.EXTERNAL_IAPTS_DETERMINED, data)
    this.logBreadcrumb(TrackingEvents.EXTERNAL_IAPTS_DETERMINED, state, data)
    this.logMessage(TrackingEvents.EXTERNAL_IAPTS_DETERMINED)
  }

  setRiskLevelLow(state: State): void {
    const riskLevel = RiskLevel.Low
    this.clinicalStore.setRiskLevel(riskLevel)
    this.clinicalStore.setRiskLevelReason()
    const data = { riskLevel }
    this.setPeople(data)
    this.logBreadcrumb("setRiskLevelLow", state, data)
    this.logMessage("setRiskLevelLow")
  }

  setRiskLevelModerate(state: State, reason?: string): void {
    const riskLevel = RiskLevel.Moderate
    const riskLevelReason = reason
    this.clinicalStore.setRiskLevel(riskLevel)
    this.clinicalStore.setRiskLevelReason(riskLevelReason)
    if (riskLevelReason === RiskLevelReason.CRISIS_DETECTION) {
      this.clinicalStore.setIsCrisisFromTriggerWords(true)
    }
    const data = { riskLevel, riskLevelReason }
    this.setPeople(data)
    this.logBreadcrumb("setRiskLevelModerate", state, data)
    this.logMessage("setRiskLevelModerate")
  }

  setRiskLevelHigh(state: State, reason?: string): void {
    const riskLevel = RiskLevel.High
    const riskLevelReason = reason
    this.clinicalStore.setRiskLevel(riskLevel)
    this.clinicalStore.setRiskLevelReason(riskLevelReason)
    if (riskLevelReason === RiskLevelReason.CRISIS_DETECTION) {
      this.clinicalStore.setIsCrisisFromTriggerWords(true)
    }
    const data = { riskLevel, riskLevelReason }
    this.setPeople(data)
    this.logBreadcrumb("setRiskLevelHigh", state, data)
    this.logMessage("setRiskLevelHigh")
  }

  setTreatment(state: State, t?: ITreatmentOption): void {
    const treatment = t?.formattedName
    this.setPeople({ treatment })
    if (treatment) {
      this.track(TrackingEvents.TREATMENT_DETERMINED, { treatment })
      this.logBreadcrumb(TrackingEvents.TREATMENT_DETERMINED, undefined, { treatment })
    }
    this.logBreadcrumb("setTreatment", state, { treatment })
    this.logMessage("setTreatment")
  }

  setTreatmentSuggested(state: State, t: ITreatmentOption): void {
    const treatment = t?.formattedName
    const key = `${treatment} Suggested`
    this.setPeople({ [key]: true })
    this.track(key, { treatment })
    this.logBreadcrumb(key)
    this.logMessage(key)
  }

  setTreatmentAccepted(state: State, treatment: ITreatmentOption, accepted: boolean): void {
    const declinedTreatments = this.clinicalStore.getDeclinedTreatments()
    const key = `${treatment.formattedName} Accepted`
    this.setPeople({ [key]: accepted, declinedTreatments })
    const event = accepted
      ? `${treatment.formattedName} Accepted`
      : `${treatment.formattedName} Declined`
    this.track(event, { treatment, accepted, declinedTreatments })
    this.logBreadcrumb(event, undefined, { accepted, declinedTreatments })
    this.logMessage(event)
  }

  setCrisisDetected(state: State): void {
    const crisisTriggerWords = this.clinicalStore.triggerWords
    const data = { crisisDetected: true, crisisTriggerWords }
    this.setPeople(data)
    this.track(TrackingEvents.CRISIS_DETECTED, data)
    this.logBreadcrumb(TrackingEvents.CRISIS_DETECTED, state, data)
    this.logMessage(TrackingEvents.CRISIS_DETECTED)
  }

  setCrisisDetectionCorrect(state: State, correct?: boolean): void {
    const crisisTriggerWords = this.clinicalStore.triggerWords
    const data = { crisisTriggerWords, crisisDetectionCorrect: !!correct }
    this.setPeople(data)
    const event = correct
      ? TrackingEvents.CRISIS_DETECTION_CORRECT
      : TrackingEvents.CRISIS_DETECTION_WRONG
    this.track(event, data)
    this.logBreadcrumb(TrackingEvents.CRISIS_DETECTED, state)
    this.logMessage(event)
  }

  updateReferralType(state: State, ref?: ReferralType): void {
    const patientId = this.referralStore.patientId
    if (!patientId) return
    const clinicalPath = this.clinicalStore.clinicalPath
    const riskLevel = this.clinicalStore.riskLevel
    const { needsAssessmentCall, assessmentCallReason } = state

    const data = { clinicalPath, riskLevel, needsAssessmentCall, assessmentCallReason }
    const referralType = ref ?? this.getReferralType(state)
    const message = `Updating Referral Type to ${referralType}`
    this.logBreadcrumb(message, undefined, { ref, ...data, patientId })

    this.referralStore.setReferralType(referralType)
    void this.referralStore.updateReferralType()
    this.setPeople({ referralType, output: referralType })
    this.logBreadcrumb("UPDATE REFERRAL TYPE", state, { referralType })
    this.logMessage("UPDATE REFERRAL TYPE")
  }

  getReferralType(state: State): string {
    const riskReferral = this.getReferralTypeForRisk?.(state)
    if (riskReferral) return riskReferral

    const customReferral = this.getCustomReferralType?.(state)
    if (customReferral) return customReferral

    const clinicalPathReferral = this.getReferralTypeForClinicalPath(state)
    if (clinicalPathReferral) return clinicalPathReferral

    return ReferralType.ASSESSMENT_CALL_REQUIRED
  }

  getReferralTypeForClinicalPath(_state: State): string | undefined {
    const clinicalPath = this.clinicalStore.clinicalPath
    if (!clinicalPath) return ReferralType.SELF_REFERRAL

    const treatment = clinicalPath.treatments?.find(t => t.accepted)
    const declinedTreatments = clinicalPath.treatments?.find(t => t.declined)
    if (!treatment && declinedTreatments && clinicalPath.declinedTreatmentsReferralType) {
      return clinicalPath.declinedTreatmentsReferralType
    }
    if (treatment?.referralType) {
      return treatment.referralType
    }
    if (clinicalPath.defaultReferralType) {
      return clinicalPath.defaultReferralType
    }
  }

  getIAPTName(state: State): string | undefined {
    // I'm not sure if clinicName is actually needed generally.
    // Whoever you are, if you are here because you're trying to
    // remove it and this is the last place you see it being used
    // then feel free to karate chop it out of existence.
    // However, if you see this comment, and you know why clinicName
    // exists, then please kaboom this comment and add one to explain
    return state.iapt?.formattedName || state.iapt?.name || state.iapt?.clinicName
  }

  getUserAge(state: State): number {
    const now = moment()
    const birthday = state.birthday || now
    return now.diff(birthday, "years", true)
  }

  getIAPTServiceAgeThreshold(state: State): number {
    if (state.iapt?.ageThreshold != null) {
      return state.iapt.ageThreshold
    }
    const eligibleIAPTIds = this.rootStore.configStore.eligibleIAPTIds
    const customIAPTS = this.rootStore.configStore.customIAPTS
    const dashboardServiceKey = this.rootStore.configStore.dashboardServiceKey

    if (
      customIAPTSHaveError(
        dashboardServiceKey,
        customIAPTS,
        this.rootStore.configStore.eligibleIAPTIds
      )
    ) {
      this.logException(new Error("No dashboard customIAPTS available"), dashboardServiceKey ?? "")
    }

    const ageThresholds = eligibleIAPTIds
      ?.map(id => getIAPTById(id, customIAPTS))
      .filter(Boolean)
      .map(iapt => iapt?.ageThreshold ?? 18) ?? [18]
    if (!ageThresholds.length) {
      return 18
    }
    return Math.min.apply(null, ageThresholds)
  }

  getEligibleIAPTSByAgeThreshold(state: State): IIAPTService[] {
    const age = this.getUserAge(state)
    const eligibleIAPTIds = this.rootStore.configStore.eligibleIAPTIds
    const customIAPTS = this.rootStore.configStore.customIAPTS
    const dashboardServiceKey = this.rootStore.configStore.dashboardServiceKey

    if (
      customIAPTSHaveError(
        dashboardServiceKey,
        customIAPTS,
        this.rootStore.configStore.eligibleIAPTIds
      )
    ) {
      this.logException(new Error("No dashboard customIAPTS available"), dashboardServiceKey ?? "")
    }

    return eligibleIAPTIds
      .map(id => getIAPTById(id, customIAPTS))
      .filter(Boolean)
      .filter(iapt => Number(iapt!.ageThreshold) <= age) as IIAPTService[]
  }

  getCustomGP(): IGPService {
    return {
      id: "unknown ID",
      name: "unknown GP",
      pimsCode: "unknown",
      nacsCode: "unknown",
      formattedName: "unknown GP",
      postcode: "unknown postcode",
      ccg: {
        id: "unknown",
        name: "unknown CCG",
        code: "unknown"
      },
      isCustom: true
    }
  }

  getQuestionnairesPayload(state: State): NonNullable<ReferralPayload["questionnaires"]> {
    const phq9 = [...(state.phq9Responses || []), ...(state.riskPathwayResponses || [])].length
      ? {
          total: this.getPHQ9Total(state),
          responses: [...(state.phq9Responses || []), ...(state.riskPathwayResponses || [])],
          responsesDisplay: ""
        }
      : undefined

    const phq2 = state.phq2Responses?.length
      ? {
          total: this.getPHQ2Total(state),
          responses: state.phq2Responses || [],
          responsesDisplay: ""
        }
      : undefined

    const audit = state.auditResponses?.length
      ? {
          total: this.getAuditTotal(state),
          responses: state.auditResponses || [],
          responsesDisplay: ""
        }
      : undefined

    const drugsAndSmoking = state.drugsAndSmokingResponses?.length
      ? {
          total: this.getDrugsAndSmokingTotal(state),
          responses: state.drugsAndSmokingResponses || [],
          responsesDisplay: ""
        }
      : undefined

    const itq = state.itqResponses?.length
      ? {
          total: this.getITQ(state),
          responses: state.itqResponses || [],
          responsesDisplay: ""
        }
      : undefined

    // There is no scoring for IRQ-A
    const irqa = state.irqaResponses?.length
      ? {
          responses: state.irqaResponses || [],
          responsesDisplay: ""
        }
      : undefined

    const gad7 = state.gad7Responses?.length
      ? {
          total: this.getGAD7Total(state),
          responses: state.gad7Responses || [],
          responsesDisplay: ""
        }
      : undefined

    const gad2 = state.gad2Responses?.length
      ? {
          total: this.getGAD2Total(state),
          responses: state.gad2Responses || [],
          responsesDisplay: ""
        }
      : undefined

    const phobia = state.phobiaScaleResponses?.length
      ? {
          total: this.getPhobiaScaleTotal(state),
          responses: state.phobiaScaleResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const employment = state.employmentStatusResponses?.length
      ? {
          responses: state.employmentStatusResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const wsas = state.wsasResponses?.length
      ? {
          total: this.getWSASTotal(state),
          responses: state.wsasResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const medication = state.medicationResponses?.length
      ? {
          responses: state.medicationResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const accommodation = state.accommodationResponses?.length
      ? {
          responses: state.accommodationResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const pcl5 = state.pcl5Responses?.length
      ? {
          total: this.getPCL5Total(state),
          responses: state.pcl5Responses || [],
          responsesDisplay: ""
        }
      : undefined
    const spin = state.spinResponses?.length
      ? {
          total: this.getSPINTotal(state),
          responses: state.spinResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const pdss = state.pdssResponses?.length
      ? {
          total: this.getPDSSTotal(state),
          responses: state.pdssResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const specificPhobia = state.specificPhobiaResponses?.length
      ? {
          total: this.getSpecificPhobiaTotal(state),
          responses: state.specificPhobiaResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const oci = state.ociResponses?.length
      ? {
          total: this.getOCITotal(state),
          responses: state.ociResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const scoff = state.scoffResponses?.length
      ? {
          total: this.getSCOFFTotal(state),
          responses: state.scoffResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const shai18 = state.shai18Responses?.length
      ? {
          total: this.getSHAI18Total(state),
          responses: state.shai18Responses || [],
          responsesDisplay: ""
        }
      : undefined
    const bdi2 = state.bdi2Responses?.length
      ? {
          total: this.getBDI2Total(state),
          responses: state.bdi2Responses || [],
          responsesDisplay: ""
        }
      : undefined
    const pswq = state.pswqResponses?.length
      ? {
          total: this.getPSWQTotal(state),
          responses: state.pswqResponses || [],
          responsesDisplay: ""
        }
      : undefined

    const smi = state.smiResponses?.length
      ? {
          total: this.getSMITotal(state),
          responses: state.smiResponses || [],
          responsesDisplay: ""
        }
      : undefined

    // There is no scoring for CSSRS
    const cssrs = state.cssrsResponses?.length
      ? {
          responses: state.cssrsResponses || [],
          responsesDisplay: ""
        }
      : undefined

    return {
      ...(phq9 ? { [QUESTIONNAIRE.PHQ9]: phq9 } : {}),
      ...(phq2 ? { [QUESTIONNAIRE.PHQ2]: phq2 } : {}),
      ...(audit ? { [QUESTIONNAIRE.AUDIT]: audit } : {}),
      ...(drugsAndSmoking ? { [QUESTIONNAIRE.DRUGS_SMOKING_PCMIS]: drugsAndSmoking } : {}),
      ...(itq ? { [QUESTIONNAIRE.ITQ]: itq } : {}),
      ...(irqa ? { [QUESTIONNAIRE.IRQ_MAN_PCMIS]: irqa } : {}),
      ...(gad7 ? { [QUESTIONNAIRE.GAD7]: gad7 } : {}),
      ...(gad2 ? { [QUESTIONNAIRE.GAD2]: gad2 } : {}),
      ...(phobia ? { [QUESTIONNAIRE.PHOBIA]: phobia } : {}),
      ...(employment ? { [QUESTIONNAIRE.EMPLOYMENT]: employment } : {}),
      ...(wsas ? { [QUESTIONNAIRE.WSAS]: wsas } : {}),
      ...(medication ? { [QUESTIONNAIRE.MEDICATION]: medication } : {}),
      ...(accommodation ? { [QUESTIONNAIRE.ACCOMMODATION]: accommodation } : {}),
      ...(pcl5 ? { [QUESTIONNAIRE.PCL5]: pcl5 } : {}),
      ...(pdss ? { [QUESTIONNAIRE.PDSS]: pdss } : {}),
      ...(spin ? { [QUESTIONNAIRE.SPIN]: spin } : {}),
      ...(specificPhobia ? { [QUESTIONNAIRE.SPECIFIC_PHOBIA]: specificPhobia } : {}),
      ...(oci ? { [QUESTIONNAIRE.OCI]: oci } : {}),
      ...(scoff ? { [QUESTIONNAIRE.SCOFF]: scoff } : {}),
      ...(shai18 ? { [QUESTIONNAIRE.SHAI]: shai18 } : {}),
      ...(bdi2 ? { [QUESTIONNAIRE.BDI2]: bdi2 } : {}),
      ...(pswq ? { [QUESTIONNAIRE.PSWQ]: pswq } : {}),
      ...(smi ? { [QUESTIONNAIRE.SMI]: smi } : {}),
      ...(cssrs ? { [QUESTIONNAIRE.CSSRS]: cssrs } : {})
    }
  }

  sendPHQ9(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore
      .updateReferral({ questionnaires })
      .then(() => this.referralStore.setPHQ9HasBeenSent(true))
  }

  sendPHQ2(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendAudit(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendDrugsAndSmoking(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendITQ(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendIRQA(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendGAD7(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendGAD2(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendHAM_A(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendPhobiaScale(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendBDI2(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendHAM_D(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendPSWQ(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendIAPTEmploymentStatus(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendWSAS(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendIAPTMedication(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendPCL5(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendOCI(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendSCOFF(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendPDSS(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendSpecificPhobia(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendSPIN(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendSHAI18(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  getPHQ2Total(state: State): number | undefined {
    return state.phq2Responses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  sendCSSRS(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  getPHQ9Total(state: State): number | undefined {
    return state.phq9Responses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  sendSMI(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  getBDI2Total(state: State): number | undefined {
    return state.bdi2Responses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getHAM_DTotal(state: State): number | undefined {
    return state.ham_dResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getAuditTotal(state: State): number | undefined {
    return state.auditResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getDrugsAndSmokingTotal(state: State): number | undefined {
    return state.drugsAndSmokingResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getITQ(state: State): number | undefined {
    return state.itqResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getIRQA(state: State): number | undefined {
    return state.irqaResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getGAD2Total(state: State): number | undefined {
    return state.gad2Responses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getGAD7Total(state: State): number | undefined {
    return state.gad7Responses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getHAM_ATotal(state: State): number | undefined {
    return state.ham_aResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getPSWQTotal(state: State): number | undefined {
    return state.pswqResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getPhobiaScaleTotal(state: State): number | undefined {
    return state.phobiaScaleResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getSocialPhobiaScore(state: State): number | undefined {
    return state.phobiaScaleResponses?.[0]?.points
  }

  getPanicPhobiaScore(state: State): number | undefined {
    return state.phobiaScaleResponses?.[1]?.points
  }

  getSpecificPhobiaScore(state: State): number | undefined {
    return state.phobiaScaleResponses?.[2]?.points
  }

  getWSASTotal(state: State): number | undefined {
    return state.wsasResponses?.reduce(
      (t, c, i, a) =>
        t +
        (!i && isNullOrUndefined(c.points)
          ? // If it's the 1st Q and doesn't have points (N/A was selected)
            // then count into the total score the 1st Q's points as equal
            // to the average of the points the rest of the Qs have
            a.reduce((t, c) => t + (c.points || 0), 0) / (a.length - 1)
          : // Otherwise, just count up all the answer points
            c.points || 0),
      0
    )
  }

  getPCL5Total(state: State): number | undefined {
    return state.pcl5Responses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getOCITotal(state: State): number | undefined {
    return state.ociResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getSCOFFTotal(state: State): number | undefined {
    return state.scoffResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getPDSSTotal(state: State): number | undefined {
    return state.pdssResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getSpecificPhobiaTotal(state: State): number | undefined {
    return state.specificPhobiaResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getSPINTotal(state: State): number | undefined {
    return state.spinResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getSHAI18Total(state: State): number | undefined {
    return state.shai18Responses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getSMITotal(state: State): number | undefined {
    return state.smiResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getTitleHTML(_state: State): string | undefined {
    return undefined
  }

  getReferralSubmittedIndicatorHTML(state: State): string | undefined {
    if (!state.referralSubmitted) {
      return "<p>Risk was detected before a referral was created.<br/><i>It’s possible that the user exited the chatbot before a referral was created and therefore it might not show up in the patient management system</i></p>"
    }
  }

  getPersonalInfoHTML(state: State): string {
    const { alcohol, alcoholQuantity, alcoholFrequency } = state
    const alcoholFreq = alcohol ? `(${alcoholQuantity} units ${alcoholFrequency})` : ""

    // prettier-ignore
    return ([] as Array<string | false | undefined>).concat(
      `<b>Name:</b> ${state.username}<br/>`,
      !!state.birthday && `<b>Date of Birth:</b> ${moment(state.birthday).format("DD/MM/YYYY")}<br/>`,
      !!state.birthday && `<b>Underaged:</b> ${!!state.isUnderAged}<br/>`,
      `<b>Postcode:</b> ${(state.isCustomPostcode ? state.customUserPostcode : state.userPostcode?.postcode) ?? "N/A"}<br/>`,
      `<b>PhoneNumber:</b> ${state.phoneNumber}<br/>`,
      state.canSendTextMessagesToPhoneNumber != null && `<b>Permission for SMS:</b> ${state.canSendTextMessagesToPhoneNumber}<br/>`,
      state.canLeaveVoicemailToPhoneNumber != null && `<b>Permission for Voicemail:</b> ${state.canLeaveVoicemailToPhoneNumber}<br/>`,
      `<b>Email:</b> ${state.email || "N/A"}<br/>`,
      state.canSendEmail != null && `<b>Permission to Send Email:</b> ${state.canSendEmail}<br/>`,
      state.address != null && `<b>Address:</b> ${state.address} ${state.address2}<br/>`,
      state.city != null && `<b>City:</b> ${state.city}<br/>`,
      state.state != null && `<b>State:</b> ${state.state}<br/>`,
      state.zipcode != null && `<b>Zipcode:</b> ${state.zipcode}<br/>`,
      state.county != null && `<b>County:</b> ${state.county}<br/>`,
      state.ethnicity != null && `<b>Ethnicity:</b> ${state.ethnicity}<br/>`,
      state.religion != null && `<b>Religion:</b> ${state.religion}<br/>`,
      state.gender != null && `<b>Gender:</b> ${state.gender}<br/>`,
      state.perinatalStatus != null && `<b>Perinatal Status:</b> ${state.perinatalStatus}<br/>`,
      state.isExArmedForces != null && `<b>Is Ex Armed Forces:</b> ${state.isExArmedForces}<br/>`,
      state.sexuality != null && `<b>Sexuality:</b> ${state.sexuality}<br/>`,
      state.longTermMedicalCondition != null && `<b>LTC:</b> ${state.longTermMedicalCondition?.join(", ")}<br/>`,
      state.substances != null && `<b>Using Substances:</b> ${state.substances}<br/>`,
      state.substancesAreMedications != null && `<b>Substances Are Medications:</b> ${state.substancesAreMedications}<br/>`,
      state.substancesInfo != null && `<b>Info About Substances:</b> ${state.substancesInfo}<br/>`,
      state.medicationWithinDoseRange != null && `<b>Medications are within recommended dose range:</b> ${state.medicationWithinDoseRange}<br/>`,
      state.alcohol != null && `<b>Alcohol:</b> ${state.alcohol} ${alcoholFreq}<br/>`,
      state.hasCurrentSupport != null && `<b>3rd Party Therapy:</b> ${state.hasCurrentSupport}<br/>`,
      state.signupCode != null && `<b>Limbic Referral ID:</b> ${state.signupCode}<br/>`,
    ).filter(Boolean).join("\n")
  }

  getServicesInfoHMTL(state: State): string {
    const strings = [] as string[]
    const gp = state.gp
    if (gp?.name) {
      // prettier-ignore
      strings.push(`<b>GP:</b> ${gp?.formattedName} (${gp?.postcode}, ${gp?.ccg.name})<br/>`)
    }
    const iapt = state.iapt
    if (iapt?.name) {
      strings.push(`<b>Talking Therapy:</b> ${iapt?.name} (${iapt?.postcode})<br/>`)
    }
    const iaptSuggestions = state.iaptSuggestions
    if (iaptSuggestions?.length) {
      // prettier-ignore
      strings.push(`<b>Other Talking Therapies Suggested:</b> ${!iapt && iaptSuggestions?.map(i => i.name).join(", ")}<br/>`)
    }
    if (!strings.length) return ""
    return `
    <h3>Services</h3>
    <p>
        ${strings.join("\n")}
    </p>
    `
  }

  getClinicalNotesHTML(_state: State): string {
    const clinicalPath = this.clinicalStore.clinicalPath
    const primaries = this.clinicalStore.primaryProblems
    const primariesTitle = primaries.length
      ? "<b>Primary Problem Categories</b>"
      : "<b>Primary Problem Categories:</b> None"
    const primaryProblems = primaries.length
      ? `<ul>${primaries.map(i => `<li>${i}</li>`).join("\n")}</ul>`
      : ""

    const secondaries = this.clinicalStore.secondaryProblems
    const secondariesTitle = primaries.length
      ? "<b>Secondary Problem Categories</b>"
      : "<b>Secondary Problem Categories:</b> None"
    const secondaryProblems = primaries.length
      ? `<ul>${secondaries.map(i => `<li>${i}</li>`).join("\n")}</ul>`
      : ""

    const flags = this.clinicalStore.flags
    const flagsTitle = flags.length ? "<b>Problem flags</b>" : "<b>Problem flags:</b> None"
    const clinicalFlags = flags.length
      ? `<ul>${flags.map(i => `<li>${i}</li>`).join("\n")}</ul>`
      : ""

    const clinicalNotes = this.referralStore.clinicalNotes
    const notes = clinicalNotes.map(note => `<li>${note}</li>`).join("\n")
    return `
    <h3>Clinical Group</h3>
    <p>${clinicalPath?.clinicalGroup}</p>

    ${primariesTitle}
    ${primaryProblems}

    ${secondariesTitle}
    ${secondaryProblems}

    ${flagsTitle}
    ${clinicalFlags}

    <b>Clinical Notes</b>
    <ul>${notes}</ul>
    `
  }

  getQuestionnairesInfoHTML(state: State): string {
    return `
    <b>Questionnaires</b>
    <div>
    <b>PHQ-9 (${this.getPHQ9Total(state)}):</b>
    <ul>
      ${[...(state.phq9Responses || []), ...(state.riskPathwayResponses || [])]
        ?.map?.((q: any) =>
          `<i>${q.title}:</i> <b>${q.answer} (${q.points})</b>` //
            .replace(/^\d.\s+/, "")
            .replace(/\n+/g, "\n          ")
        )
        ?.map(i => `<li>${i}</li>`)
        ?.join("")}
    </ul>

    <b>GAD-7 (${this.getGAD7Total(state)}):</b>
    <ul>
      ${state.gad7Responses
        ?.map?.(q =>
          `<i>${q.title}:</i> <b>${q.answer} (${q.points})</b>` //
            .replace(/^\d.\s+/, "")
            .replace(/\n+/g, "\n          ")
        )
        ?.map(i => `<li>${i}</li>`)
        ?.join("")}
    </ul>

    <b>Phobia Scale (${this.getPhobiaScaleTotal(state)}):</b>
    <ul>
      ${state.phobiaScaleResponses
        ?.map?.(q =>
          `<i>${q.title}:</i> <b>${q.answer} (${q.points})</b>` //
            .replace(/^\d.\s+/, "")
            .replace(/\n+/g, "\n          ")
        )
        ?.map(i => `<li>${i}</li>`)
        ?.join("")}
    </ul>

    <b>Employment:</b>
    <ul>
      ${state.employmentStatusResponses
        ?.map?.(q =>
          `<i>${q.title}:</i> <b>${q.answer}</b>` //
            .replace(/^\d.\s+/, "")
            .replace(/\n+/g, "\n          ")
        )
        ?.map(i => `<li>${i}</li>`)
        ?.join("")}
    </ul>
      
    <b>WSAS (${this.getWSASTotal(state)}):</b>
    <ul>
      ${state.wsasResponses
        ?.map?.(q =>
          `<i>${q.title}:</i> <b>${q.answer} (${q.points})</b>` //
            .replace(/^\d.\s+/, "")
            .replace(/\n+/g, "\n          ")
        )
        ?.map(i => `<li>${i}</li>`)
        ?.join("")}
    </ul>

    <b>Medication:</b>
    <ul>
      ${state.medicationResponses
        ?.map?.(q =>
          `<i>${q.title}:</i> <b>${q.answer}</b>` //
            .replace(/^\d.\s+/, "")
            .replace(/\n+/g, "\n          ")
        )
        ?.map(i => `<li>${i}</li>`)
        ?.join("")}
    </ul>
    </div>

    <b>Accommodation:</b>
    <ul>
      ${state.accommodationResponses
        ?.map?.(q =>
          `<i>${q.title}:</i> <b>${q.answer}</b>` //
            .replace(/^\d.\s+/, "")
            .replace(/\n+/g, "\n          ")
        )
        ?.map(i => `<li>${i}</li>`)
        ?.join("")}
    </ul>
    </div>
    `
  }

  getAdditionalInfoHTML(state: State): string {
    const triggerWords = this.clinicalStore.triggerWords?.length
      ? `<b>Crisis Trigger Words:</b> ${this.clinicalStore.triggerWords?.join(", ")}<br/>`
      : ""
    const triggerWordsPhrase =
      this.clinicalStore.triggerWords?.length && this.clinicalStore.triggerWordsPhrase //
        ? `<b>User Input With Trigger Words:</b> ${this.clinicalStore.triggerWordsPhrase}<br/>`
        : ""
    const treatment = this.clinicalStore.getAcceptedTreatment()
    const declinedTreatments = this.clinicalStore.getDeclinedTreatments()
    return `
    <b>Treatment:</b> ${treatment?.name}<br/>
    <b>Declined Treatments:</b> ${declinedTreatments.map(t => t.name).join(", ")}<br/>
    <b>Admin team should call:</b> ${!!state.needsAssessmentCall}<br/>
    <b>Reason why admin team should call:</b> ${state.assessmentCallReason}<br/>
    ${triggerWords}
    ${triggerWordsPhrase}
    <b>Was the bot helpful:</b> ${state.isHelpful}<br/>
    <b>Improvement Suggestion:</b> ${state.improvementSuggestion}<br/>
    `
  }

  getEmailHTMLStyle(): string {
    return `
    <style>
        #outlook a {
          padding: 0;
        }

        body {
          width: 100% !important;
          -webkit-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
          margin: 0;
          padding: 0;
          font-family: sans-serif;
        }

        .ExternalClass {
          width: 100%;
        }
        .ExternalClass,
        .ExternalClass p,
        .ExternalClass span,
        .ExternalClass font,
        .ExternalClass td,
        .ExternalClass div {
          line-height: 100%;
        }

        #backgroundTable {
          margin: 0;
          padding: 0;
          width: 100% !important;
          line-height: 100% !important;
        }

        #contentTable {
          margin: 64px auto;
          max-width: 800px !important;
          line-height: 100% !important;
        }

        img {
          outline: none;
          text-decoration: none;
          -ms-interpolation-mode: bicubic;
        }

        a img {
          border: none;
        }
        .image_fix {
          display: block;
        }
        p {
          margin: 1em 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: black !important;
        }
        h1 a,
        h2 a,
        h3 a,
        h4 a,
        h5 a,
        h6 a {
          color: blue !important;
        }
        h1 a:active,
        h2 a:active,
        h3 a:active,
        h4 a:active,
        h5 a:active,
        h6 a:active {
          color: red !important;
        }
        h1 a:visited,
        h2 a:visited,
        h3 a:visited,
        h4 a:visited,
        h5 a:visited,
        h6 a:visited {
          color: purple !important;
        }

        table td {
          padding-left: 24px;
          padding-right: 24px;
          border-collapse: collapse;
          line-height: 24px;
        }

        table {
          border-collapse: collapse;
          mso-table-lspace: 0;
          mso-table-rspace: 0;
        }

        a {
          color: orange;
        }
        h1,
        h2,
        h3,
        a {
          color: #375491 !important;
        }
        h1 {
          font-size: 1.8rem;
          text-align: center;
          font-weight: normal;
        }
        .red {
          color: red;
        }

        .blue {
          color: #375491 !important;
          font-weight: bold;
        }

        .big {
          font-size: 21px;
          font-weight: bold;
        }

        .image_fix {
          margin-left: auto;
          margin-right: auto;
        }
      </style>
    `
  }

  // TODO: This and the createBookingEmail need to be merged
  createReferralEmail(state: State, isCrisis = false): string {
    let referralType = this.referralStore.referralType
    if (isCrisis) referralType = ReferralType.LIMBIC_RISK_PATIENT
    // prettier-ignore
    return `
    <html lang='en'>
      <head>
      <title>Limbic Self Referral Asssistant</title>
      ${this.getEmailHTMLStyle()}
      </head>
      <body>
        <h1>${referralType}</h1>
        <h3>${state.username}</h3>
        ${this.getTitleHTML(state) ?? ""}
        <p>${this.getReferralSubmittedIndicatorHTML(state) ?? ""}</p>
        <hr/>
        <h3>Personal Info</h3>
        <p>
          ${this.getPersonalInfoHTML(state)}
        </p>
        ${this.getServicesInfoHMTL(state)}
        ${this.getClinicalNotesHTML(state)}
        ${this.getQuestionnairesInfoHTML(state)}
        <p>
          ${this.getAdditionalInfoHTML(state)}
        </p>
      </body>
    </html>
    `
      .replace(/undefined/gi, "-")
      .replace(/true/gi, "Yes")
      .replace(/false/gi, "No")
  }

  // TODO: This and the createReferralEmail need to be merged
  createBookingEmail(state: State, isCrisis = false, type: string): string {
    const jobCategory = this.referralStore.getCustomField("jobCategory")
    let referralType = this.referralStore.referralType
    if (isCrisis) referralType = ReferralType.LIMBIC_RISK_PATIENT
    // prettier-ignore
    return `
    <html lang='en'>
      <head>
      <title>Limbic Self Referral Asssistant</title>
      ${this.getEmailHTMLStyle()}
      </head>
      <body>
        <h1><b>${state.username}</b> ${type}</h1>
        ${this.getTitleHTML(state)}
        <p>${this.getReferralSubmittedIndicatorHTML(state) ?? ""}</p>
        <hr/>
        <h3>${referralType}</h3>
        <hr/>
        <h3>Personal Info</h3>
        <p>
          ${this.getPersonalInfoHTML(state)}
        </p>
        ${jobCategory ? `<p><b>Job Category:</b> ${jobCategory}</p>` : ""}
        ${this.getServicesInfoHMTL(state)}
        ${this.getClinicalNotesHTML(state)}
        ${this.getQuestionnairesInfoHTML(state)}
      </body>
    </html>
    `
      .replace(/undefined/gi, "-")
      .replace(/true/gi, "Yes")
      .replace(/false/gi, "No")
  }

  getStateToHTML(state: State): string {
    const output = JSON.parse(
      JSON.stringify({
        clinical: this.clinicalStore,
        questionnaires: this.getQuestionnairesPayload(state),
        referral: this.referralStore,
        chatbotState: state
      })
    )
    const table = objectToTable(output, 0, ["referralForm", "Responses"])
    return `
      <html lang='en'>
        <head>
            <title>Limbic Self Referral Asssistant</title>
            ${this.getEmailHTMLStyle()}
        </head>
        <body>
            <h1><b>${state.username}</b></h1>
            ${table}
        </body>
      </html>
    `.replace(/undefined/gi, "-")
  }

  /**
   * Setting a main getContext here that includes most used properties
   * just in case one is not set in an extended script
   */
  getContext(state: State): Record<string, any> {
    return {
      ...this.rootStore.configStore,
      name: this.getName(state),
      iaptName: this.getIAPTName(state),
      primaryLanguage: state.primaryLanguage,
      language: state.primaryLanguage
    }
  }

  async trackUserAsIneligible(
    state: State,
    reason: string,
    location?: "database" | "mixpanel"
  ): Promise<void> {
    try {
      if (this.referralStore.hasIneligibilityReasonBeenTracked) return

      const dob = state.birthday ? moment(state.birthday).format("YYYY-MM-DD") : undefined

      if (!location || location === "database") {
        const ACCESS_BOT_API_KEY =
          process.env.REACT_APP_LIMBIC_ACCESS_BOT_API_KEY || this.rootStore.configStore.key

        // No need to await this, we can just fire and forget
        void setIneligibleUser({
          service: ACCESS_BOT_API_KEY,
          reason,
          dob,
          postcode: state.userPostcode?.postcode
        })
      }

      if (!location || location === "mixpanel") {
        this.setPeople({
          ineligible: true,
          ineligibilityReason: reason,
          dob,
          postcode: state.userPostcode?.postcode
        })
        this.track(TrackingEvents.INELIGIBLE, { reason })
      }

      this.referralStore.setHasIneligibilityReasonBeenTracked(true)
    } catch (e) {
      this.logException(e, "trackUserAsIneligible", Severity.Error)
    }
  }

  async appendQuestionToLLMThread(body: IStepResult["body"]): Promise<void> {
    if (!this.getFeatureFlag(FF.AC_899_LLM_RESPONSES)) return

    const message = stepResultBodyToLLMMessage(body)
    await this.rootStore.llmStore.addMessage(`#### QUESTION ####\n${message}`)
  }

  async getLLMResponse(userInput?: unknown): Promise<string[] | undefined> {
    if (!this.getFeatureFlag(FF.AC_899_LLM_RESPONSES)) return

    const message = typeof userInput === "string" ? userInput : JSON.stringify(userInput)
    const reply = message ? `#### REPLY ####\n${message}` : undefined
    this.startTyping()
    return await this.rootStore.llmStore.getLLMResponse(reply)
  }

  getFeatureFlag(key: FF): boolean {
    return this.rootStore.applicationStore.getFeatureFlag(key)
  }

  /** Getters / Setters */

  get rootStore(): RootStore {
    if (this._rootStore) return this._rootStore
    return (this.constructor as any).rootStore
  }

  get discussionStore(): DiscussionStore {
    return this.rootStore.discussionStore
  }

  get clinicalStore(): ClinicalStore {
    return this.rootStore.clinicalStore
  }

  get referralStore(): ReferralStore {
    return this.rootStore.referralStore
  }

  get wellbeingHubStore(): WellbeingHubStore {
    return this.rootStore.wellbeingHubStore
  }

  get t(): Translator["get"] {
    if (this._translator) return this._translator.get
    return (this.constructor as any).translator?.get ?? passthroughTranslationFn
  }
}

function objectToTable(obj: Record<string, any>, level = 0, ignoreKeys: string[] = []): string {
  const isArray = Array.isArray(obj)
  let html = `<table border="1" style="border-collapse: collapse;">`

  for (const key in obj) {
    if (ignoreKeys.includes(key)) continue
    const value = obj[key]
    html += `<tr><td style="padding: 5px; border: 1px solid black;">`

    if (level > 0) html += "<strong>"
    if (!isArray) html += `${key}: `
    if (level > 0) html += "</strong>"
    html += `</td><td style="padding: 5px; border: 1px solid black;">`

    if (typeof value === "object" && value != null) {
      html += objectToTable(value, level + 1, ignoreKeys)
    } else html += value

    html += `</td></tr>`
  }

  html += "</table>"

  return html
}
