import ISurvey, { SurveyType } from "../../models/ISurvey"
import createSurveyDialogue from "../createSurveyDialogue"
import { DialogueIDs } from "../DialogueIDs"

const PHQ2: ISurvey = {
  startingQuestion: [
    "In the past two weeks, how often have you been bothered by the following problems?"
  ],
  questions: [
    {
      id: "1",
      questionName: "PHQ2 - step1",
      question: "1. Little interest or pleasure in doing things",
      type: "inline",
      progress: 0.5,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "Several days" },
        { points: 2, answer: "More than half the days" },
        { points: 3, answer: "Nearly every day" }
      ]
    },
    {
      id: "2",
      questionName: "PHQ2 - step2",
      question: "2. Feeling down, depressed, or hopeless",
      type: "inline",
      progress: 1,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "Several days" },
        { points: 2, answer: "More than half the days" },
        { points: 3, answer: "Nearly every day" }
      ]
    }
  ]
}

export default createSurveyDialogue(DialogueIDs.PHQ2, PHQ2, SurveyType.PHQ2)
