import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import { isOnline } from "../../../utils/isOnline"
import {
  IAppointmentBookResponse,
  IAppointmentMind,
  IAppointmentResponse,
  IAppointmentStatus,
  ITimeslot
} from "../../../models/IAppointmentMind"
import moment from "moment"
import client from "./_client"

const TOTAL_RETRIES = 3
const BASE_PATH = "/v1/views/sessions"

export async function getMindAppointments(
  language: string,
  region: string,
  retry = 0
): Promise<[IAppointmentMind[] | undefined, IAppointmentStatus]> {
  let body = {}
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) return [undefined, IAppointmentStatus.NoInternetConnection]

    const timeStart = moment().add(2, "hours")
    const timeEnd = moment().add(4, "weeks")
    const remainderStart = 30 - (timeStart.minute() % 30)
    const remainderEnd = 30 - (timeEnd.minute() % 30)
    const timeStartRounded = moment(timeStart).add(remainderStart, "minutes").toISOString()
    const timeEndRounded = moment(timeEnd).add(remainderEnd, "minutes").toISOString()
    const appointmentTimes = { startDate: timeStartRounded, endDate: timeEndRounded }

    body = { instanceID: "MIND_MAIN", language, region, ...appointmentTimes }
    const json = await client.post(`${BASE_PATH}/region/list`, body)
    const data = client.extractData(json)
    const parsedData = parseAppointments(data)

    return [parsedData, IAppointmentStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "getAppointments fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`getAppointments body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("getAppointments retry")
      await delay(1)
      return await getMindAppointments(language, region, retry + 1)
    }
    return [undefined, IAppointmentStatus.RequestFailed]
  }
}

export async function reserveMindAppointment(
  sessionID: string,
  patientID: string,
  retry = 0
): Promise<[IAppointmentBookResponse | undefined, IAppointmentStatus]> {
  let body = {}
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) return [undefined, IAppointmentStatus.NoInternetConnection]

    body = { instanceID: "MIND_MAIN", patientID, sessionID }
    const json = await client.post(`${BASE_PATH}/book`, body)
    const data = client.extractData(json)

    return [data, IAppointmentStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "getAppointments fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`getAppointments body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("getAppointments retry")
      await delay(1)
      return await reserveMindAppointment(sessionID, patientID, retry + 1)
    }
    return [undefined, IAppointmentStatus.RequestFailed]
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}

function parseAppointments(data: any[]): IAppointmentMind[] {
  data.sort((a, b) => new Date(a.startdate).getTime() - new Date(b.startdate).getTime())

  const map = data.reduce(
    (acc, timeslot) => {
      const date = moment.utc(timeslot.startdate).format("DD MM YYYY")
      const formattedTimeslot: ITimeslot = {
        sessionID: timeslot.localsessionid,
        date,
        startTime: timeslot.starttime,
        endTime: calculateEndTime(timeslot.starttime, timeslot.duration),
        agencyName: timeslot.agencyname
      }
      acc[date] = acc[date] ? [...acc[date], formattedTimeslot] : [formattedTimeslot]
      return acc
    },
    {} as Record<string, IAppointmentResponse[]>
  )

  return Object.keys(map).map(date => ({ date, timeslots: map[date] }))
}

function calculateEndTime(starttime, duration) {
  const [startHours, startMinutes] = starttime.split(":").map(Number)
  const [durationHours, durationMinutes] = duration.split(":").map(Number)

  const startDate = new Date()
  startDate.setHours(startHours, startMinutes)

  const endDate = new Date(startDate.getTime())
  endDate.setHours(endDate.getHours() + durationHours)
  endDate.setMinutes(endDate.getMinutes() + durationMinutes)

  const endHours = endDate.getHours().toString().padStart(2, "0")
  const endMinutes = endDate.getMinutes().toString().padStart(2, "0")

  return `${endHours}:${endMinutes}`
}
