import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { PermissionsIAPTScript } from "./PermissionsIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { PermissionsScriptState } from "./PermissionsScript"

type State = PermissionsScriptState
export type PermissionsEveryturnScriptState = State

export class PermissionsEveryturnScript extends PermissionsIAPTScript {
  readonly name: string = "PermissionsEveryturnScript"

  /** Script Steps */
  @step.logState
  showTOSLinks(_d: IStepData<State>): IStepResult {
    const organisationTerms = this.t(this.rootStore.configStore.organisationTerms ?? "")
    return {
      body: this.t(
        [
          "You can find all the details here:\n\n{organisationTerms}\n" +
            "Limbic [Terms of Service](https://www.limbic.ai/terms-of-use)\n" +
            "Limbic [Privacy Policy](https://www.limbic.ai/privacy)\n"
        ],
        { organisationTerms }
      ),
      nextStep: this.promptIUnderstandTerms
    }
  }
}

/* istanbul ignore next */
export default class PermissionsEveryturnDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsEveryturn
  readonly name: string = "PermissionsEveryturnDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsEveryturnDialogue.id, new PermissionsEveryturnScript(), state, snapshot)
  }
}
