import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class EmailPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_EMAIL> {
  chatFlow = ChatFlowsEnum.COLLECT_EMAIL

  /** Overrides */

  getDefaultPayload(): Partial<ReferralPayload> {
    return {
      email: this.ctx.state?.email,
      consentEmail: this.ctx.state?.canSendEmail
    } as Partial<ReferralPayload>
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_EMAIL] = EmailPayloadBuilder
