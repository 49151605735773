import { observer } from "mobx-react"
import React from "react"
import { useApplicationStore } from "../../../contexts/RootStoreContext"
import { FF } from "../../../../featureFlags"

function NHSComplianceCard() {
  const app = useApplicationStore()
  if (!app.getFeatureFlag(FF.AC433_NHS_SIDEBAR)) return null
  return (
    <article data-testid="nhs_compliance_card" className="lb-sidebar-card full-height">
      <p>
        {app.t(
          "Limbic helps people access psychological support quicker and easier. Your data is secured according to the highest NHS standards."
        )}
      </p>
      <img
        className="badges"
        src="https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/data-pics.png"
        alt={"Badges"}
      />
    </article>
  )
}

export default observer(NHSComplianceCard)
