import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IAPTIDs } from "../../../models/IIAPTService"

type State = CrisisScriptState

export type CrisisOxfordBucksScriptState = State
export class CrisisOxfordBucksScript extends CrisisScript {
  readonly name: string = "CrisisOxfordBucksScript"

  /** Script Steps */

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const serviceName =
      this.rootStore.configStore.serviceName ?? this.rootStore.configStore.organisationName
    const name = this.getName(d.state)
    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        `However ${serviceName} does not provide urgent care`
      ],
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(d: IStepData<State>): IStepResult {
    const serviceName =
      this.rootStore.configStore.serviceName ?? this.rootStore.configStore.organisationName

    const serviceSpecificText =
      d.state.iapt?.id === IAPTIDs.OXFORDSHIRE
        ? [
            "You could also contact Safe Haven. This is a non-clinical, face-to-face safe space to get short-term support in mental health crisis when other services are not available. It is open Friday to Monday 6pm-10pm",
            "Oxford: 01865 903 037 / Banbury: 01295 270 004"
          ]
        : [
            "You can also contact Bucks Safe Haven that offers a safe and supportive alternative to A&E for adults (aged 18+) who are experiencing a mental health crisis. For High Wycombe (open 7 nights a week) contact 01494 218098 and for Aylesbury (open Sunday, Monday, Tuesday and Wednesday, contact 01296 453017"
          ]

    return {
      body: [
        "If you need urgent support, please dial NHS 111 and select Option 2",
        "In medical emergency and life threatening situations only, please dial 999 or attend your local A&E department",
        ...serviceSpecificText,
        "Other helplines available:",
        "You can contact Samaritans 24 hours a day, 365 days a year. You can call 116 123 (free from any phone) or email [jo@samaritans.org](jo@samaritans.org)",
        "If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258. Shout offers a confidential 24/7 text service providing support if you are in need of immediate help",
        "To recap:",
        `We'll continue with the referral, however ${serviceName} does not provide urgent care. If you require immediate help, please contact one of the numbers listed above`
      ],
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(d: IStepData<State>): IStepResult {
    const data =
      d.state.iapt?.id === IAPTIDs.OXFORDSHIRE
        ? "999, NHS 111 Option 2, Samaritans, Oxford: 01865 903 037 / Banbury: 01295 270 004"
        : "999, NHS 111 Option 2, Samaritans, Bucks Safe Haven, 85258"

    this.referralStore.setCustomField("crisisNumbersShared", data)
    return {
      body: "Let's carry on with your referral",
      nextStep: this.end
    }
  }

  @step.logState
  saySorryForTheMisunderstanding(_d: IStepData<State>): IStepResult {
    return {
      body: ["Thank you for sharing this information", "Let's carry on with your referral"],
      nextStep: this.end
    }
  }
}

/* istanbul ignore next */
export default class CrisisOxfordBucksDialogue extends AdHocDialogue<
  State,
  CrisisOxfordBucksScript
> {
  static id = DialogueIDs.CrisisOxfordBucks
  readonly name: string = "CrisisOxfordBucksDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisOxfordBucksDialogue.id, new CrisisOxfordBucksScript(), state, snapshot)
  }
}
