import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class LTCPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.ASK_LONG_TERM_MEDICAL_CONDITIONS> {
  chatFlow = ChatFlowsEnum.ASK_LONG_TERM_MEDICAL_CONDITIONS

  /** Overrides */

  getDefaultPayload(): Partial<ReferralPayload> {
    const impactMap = {
      little: "LITTLE",
      somewhat: "SOMEWHAT",
      very: "VERY"
    }

    const management = {
      little: "LITTLE",
      fairly: "SOMEWHAT",
      very: "VERY"
    }

    const payload: Partial<ReferralPayload> = {
      ltc: this.ctx.state?.longTermMedicalCondition?.map(ltc => {
        // we need to do this because the LTC preset flow is being
        // used both by current services which use the user-friendly
        // format to save the value in the state, and by dashboard
        // services which just save the value as is in uppercase.
        // But because "no" and "other" are being handled by the
        // preset flow itself, we have to make sure we won't use
        // the lower case values that the preset flow uses.
        // TODO: once all services are in the dashboard, or once
        //       no local, hardcoded service uses the LTC preset
        //       flow, make the preset flow use the uppercase values
        //       for the none and other identifiers and remove the
        //       below transformation
        return ltc.toUpperCase()
      }),
      ltcAffectMood: this.ctx.state?.ltcAffectsMood,
      ltcMoodImpact: impactMap[this.ctx.state?.ltcMoodImpact],
      ltcManagement: management[this.ctx.state?.ltcManagement]
    }

    if (this.ctx.state?.longTermConditionOther) {
      payload.ltcOtherDetails = this.ctx.state?.longTermConditionOther
    }

    return payload
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.ASK_LONG_TERM_MEDICAL_CONDITIONS] = LTCPayloadBuilder
