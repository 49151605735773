import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class SexualityPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_SEXUALITY> {
  chatFlow = ChatFlowsEnum.COLLECT_SEXUALITY

  /** Overrides */

  getDefaultPayload(): Partial<ReferralPayload> {
    const payload: Partial<ReferralPayload> = {
      sexuality: this.ctx.state?.sexuality
    }

    if (this.ctx.state?.sexualityOther) {
      payload.sexualityOther = this.ctx.state?.sexualityOther
    }

    return payload
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_SEXUALITY] = SexualityPayloadBuilder
