import BaseScript, { BaseScriptState } from "./BaseScript"
import Dialogue, { IDialogueSnapshot } from "../backend/chatbot/Dialogue"
import { step } from "../backend/chatbot/decorators/step"
import isNullOrUndefined from "../utils/isNullOrUndefined"
import invariant from "../utils/invariant"
import { SurveyType } from "../models/ISurvey"
import type IPrompt from "../backend/chatbot/models/IPrompt"
import type { IStep, IStepData, IStepResult } from "../backend/chatbot/models/IStep"
import type ISurvey from "../models/ISurvey"
import type {
  IPersistableSurveyResponse,
  ISurveyChoices,
  ISurveyQuestion,
  ISurveyResponse
} from "../models/ISurvey"

export interface SurveyScriptState extends BaseScriptState {
  nextProgress?: number
}

type State = SurveyScriptState

export class SurveyScript<State extends SurveyScriptState> extends BaseScript<State> {
  protected survey: ISurvey
  protected surveyType: SurveyType
  readonly name: string;

  [key: `step${number}`]: IStep<State> | undefined
  [key: `handleStep${number}`]: IStep<State> | undefined

  constructor(name: string, survey: ISurvey, surveyType: SurveyType) {
    super()
    this.name = name
    this.survey = survey
    this.surveyType = surveyType
    this.setupSurveySteps()
  }

  /** Script Steps */

  @step
  start(_d: IStepData<State>): IStepResult<State> {
    this.timeEvent(`${this.name} Finished`)
    return { nextStep: this.sayExplanation }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.track(`${this.name} Finished`)
    return super.end(d)
  }

  @step
  sayExplanation(d: IStepData<State>): IStepResult<State> {
    let body
    let prompt
    if (this.survey?.explanation) {
      const name = this.getName(d.state)
      body = this.t(this.survey.explanation, { name })
      prompt = {
        id: this.getPromptId("sayExplanation"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      } as IPrompt
    }
    return { body, prompt, nextStep: this.startQuestionnaire }
  }

  @step
  startQuestionnaire(_d: IStepData<State>): IStepResult {
    if (this.step0) {
      return { nextStep: this.step0 }
    }
    return { nextStep: this.sayEndingMessage }
  }

  @step
  sayEndingMessage(d: IStepData<State>): IStepResult {
    const { endingMessage } = this.survey
    const name = this.getName(d.state)
    return {
      body: endingMessage ? this.t(endingMessage, { name }) : undefined,
      nextStep: this.end
    }
  }

  @step
  handleLast(d: IStepData<State>): IStepResult {
    const lastQ = this.survey.questions[this.survey.questions.length - 1]
    if (!isNullOrUndefined(lastQ?.progress)) {
      this.rootStore.applicationStore.setCurrentProgress(lastQ.progress)
    }
    if (this.isResponse(d.response)) {
      const item = { id: lastQ.id, ...d.response }
      this.saveResponse(item, d.state)
    }
    return { nextStep: this.sayEndingMessage }
  }

  /** Generic Handlers */

  setupSurveySteps(): void {
    this.logBreadcrumb("setupSurveySteps")
    if (!this.survey) {
      return
    }

    const survey = this.survey
    const questions = survey?.questions || []
    for (let i = 0, { length } = questions; i < length; i++) {
      const id = `step${i}`
      const nextId = `step${i + 1}`
      const question = questions[i]
      const prevQuestion = questions[i - 1]
      const isLast = i === questions.length - 1
      this[id] = function (this: SurveyScript<State>, d: IStepData<State, ISurveyResponse>) {
        if (!isNullOrUndefined(prevQuestion?.progress)) {
          this.rootStore.applicationStore.setCurrentProgress(prevQuestion.progress)
        }
        this.logBreadcrumb(id)
        if (this.isResponse(d.response)) {
          const item = { id: prevQuestion!.id, ...d.response }
          this.saveResponse(item, d.state)
        }
        let body = [] as string[]
        if (i === 0 && survey.startingQuestion) {
          body = body.concat(survey.startingQuestion)
        }
        body = body.concat(question.question)
        const name = this.getName(d.state)
        body = this.t(body, { name })
        const promptObject = this.extractQuestionPrompt(question, id)
        const prompt = promptObject ? this.getPrompt(d.state, promptObject) : undefined
        const nextStep = isLast ? this.handleLast : this[nextId]

        return { body, prompt, nextStep }
      } as IStep<State>
      // Make sure the step has a name for hydration purposes
      Object.defineProperty(this[id], "name", { value: id, writable: false })
      Object.defineProperty(this[id], "stepName", { value: id, writable: false })
    }
  }

  isResponse(input: unknown): input is ISurveyResponse {
    if (!input || typeof input !== "object") {
      return false
    }
    const keys = Object.keys(input!)
    return keys.includes("title") && keys.includes("answer")
  }

  extractQuestionPrompt(question: ISurveyQuestion, promptId: string): IPrompt | undefined {
    const id = this.getPromptId(promptId)
    if (question.type === "inline") {
      if (!(question.answers && question.answers.length) && !question.textPrompt) {
        return
      }
      return {
        id,
        trackResponse: true,
        type: "inlinePicker",
        choices: question.answers.map((item: ISurveyChoices) => ({
          body: this.t(item.answer),
          value: {
            // TODO: Maybe its better to do: question.question.join(" ")
            title: typeof question.question === "string" ? question.question : question.question[0],
            answer: item.answer,
            points: item.points,
            subscales: question.subscales
          }
        })),
        textPrompt: question.textPrompt,
        dataPointsName: question.questionName
      }
    }

    if (question.type === "slider") {
      return {
        id,
        type: "slider",
        // TODO: Maybe its better to do: question.question.join(" ")
        title: typeof question.question === "string" ? question.question : question.question[0],
        min: question.min,
        max: question.max,
        labels: this.t(question.labels),
        notApplicable: question.notApplicable,
        dataPointsName: question.questionName
      }
    }
  }

  saveResponse<T extends IPersistableSurveyResponse>(item: T, state: State): void {
    const key = (
      {
        [SurveyType.PHQ9]: "phq9Responses",
        [SurveyType.PHQ2]: "phq2Responses",
        [SurveyType.HAM_D]: "ham_dResponses",
        [SurveyType.Audit]: "auditResponses",
        [SurveyType.DrugsAndSmoking]: "drugsAndSmokingResponses",
        [SurveyType.ITQ]: "itqResponses",
        [SurveyType.IRQA]: "irqaResponses",
        [SurveyType.GAD7]: "gad7Responses",
        [SurveyType.GAD2]: "gad2Responses",
        [SurveyType.HAM_A]: "ham_aResponses",
        [SurveyType.BDI2]: "bdi2Responses",
        [SurveyType.PSWQ]: "pswqResponses",
        [SurveyType.IAPTPhobiaScale]: "phobiaScaleResponses",
        [SurveyType.IAPTEmploymentStatus]: "employmentStatusResponses",
        [SurveyType.IAPTWorkAndSocialAdjustment]: "wsasResponses",
        [SurveyType.IAPTMedication]: "medicationResponses",
        [SurveyType.IAPTAccommodation]: "accommodationResponses",
        [SurveyType.PCL5]: "pcl5Responses",
        [SurveyType.PDSS]: "pdssResponses",
        [SurveyType.SPIN]: "spinResponses",
        [SurveyType.OCI]: "ociResponses",
        [SurveyType.SCOFF]: "scoffResponses",
        [SurveyType.SHAI18]: "shai18Responses",
        [SurveyType.SpecificPhobia]: "specificPhobiaResponses"
      } as Record<SurveyType, keyof State>
    )[this.surveyType]
    invariant<string>(
      key,
      `Could not find responses key for survey ${this.surveyType}. [${String(key)}]`
    )
    super.saveResponse(item, state, key)
  }
}

interface DialogueClass {
  id: string
  new (state: State, snapshot?: IDialogueSnapshot<State>): Dialogue<State>
}

export default function createSurveyDialogue(
  identifier: string,
  survey: ISurvey<any>,
  type: SurveyType
): DialogueClass {
  const scriptName = `${identifier} Script`
  return class extends Dialogue<State> {
    static id = identifier
    readonly name: string = `${identifier} Dialogue`
    readonly type = "Survey"
    constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
      super(identifier, new SurveyScript(scriptName, survey, type), state, snapshot)
    }
  }
}
