import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class EthnicityPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_ETHNICITY> {
  chatFlow = ChatFlowsEnum.COLLECT_ETHNICITY

  /** Overrides */

  getDefaultPayload(): Partial<ReferralPayload> {
    return { ethnicity: this.ctx.state?.ethnicity } as Partial<ReferralPayload>
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_ETHNICITY] = EthnicityPayloadBuilder
