import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { RiskLevel, RiskLevelReason } from "../../../models/Constants"
import CrisisDetector from "../../../models/CrisisDetector"

type State = CrisisScriptState
export type CrisisEveryturnState = State
export class CrisisEveryturnScript extends CrisisScript {
  readonly name: string = "CrisisEveryturnScript"

  /** Script Steps */

  @step
  /**
   * Extending due to a special request to mark all users
   * with crisis word at least moderate risk-level.
   */
  async handleAreYouInCrisis(d: IStepData<State, boolean>): Promise<IStepResult> {
    this.clinicalStore.setIsCrisis(d.response)
    this.setCrisisDetectionCorrect(d.state, d.response)

    d.response
      ? this.setRiskLevelHigh(d.state, RiskLevelReason.CRISIS_DETECTION)
      : this.setRiskLevelModerate(d.state, RiskLevelReason.CRISIS_DETECTION)

    this.referralStore
      .updateReferral({
        riskLevel: d.response ? this.clinicalStore.riskLevel : RiskLevel.Moderate,
        riskLevelReason: this.clinicalStore.riskLevelReason ?? "",
        triggerWords: this.clinicalStore.triggerWords
      })
      .catch(e => this.logException(e, "handleAreYouInCrisis -> updateReferral"))

    this.updateReferralType(d.state)

    const result = await this.onHandleAreYouInCrisis?.(d.state)
    if (result) return result

    if (!d.response && d.state.disableDetectionIfWrong) {
      CrisisDetector.getInstance().disableCrisisDetectionForNextInput()
    }
    return {
      nextStep: d.response //
        ? this.saySorryToHear
        : this.saySorryForTheMisunderstanding
    }
  }

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const crisisPhoneNumbers = this.rootStore.configStore.crisisPhoneNumbers
    const serviceName = this.rootStore.configStore.serviceName
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However {serviceName} does not provide urgent care",
          "In medical emergency and life threatening situations only, please dial [999](tel:999) or attend your local A&E department",
          "Or you can call the NHS helpline on 111 and select the option for mental health support. This helpline is open 24 hours a day, 7 days a week",
          "You can visit this [link](https://www.insightiapt.org/need-urgent-help/) to find out more about the options you have",
          "Alternatively, you can call our services if it's not a life-threatening situation on the following numbers:\n\n{crisisPhoneNumbers}",
          "Other helplines available:\n\nYou can contact Samaritans 24 hours a day, 365 days a year\nYou can call [116 123](tel:116123) (free from any phone) or email jo@samaritans.org\nIf you would prefer not to talk but want some mental health support, you could text SHOUT to 85258\nShout offers a confidential 24/7 text service providing support if you are in need of immediate help"
        ],
        { serviceName, name, crisisPhoneNumbers }
      ),
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }]
      },
      nextStep: this.handleSorryToHear
    }
  }

  @step.logState
  handleSorryToHear(d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, 111 and crisis numbers from services"
    )
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: d.state.referralSubmitted
        ? undefined
        : this.t(
            [
              "Just to recap:\n\nWe’ll continue with the referral, however {serviceName} does not provide urgent care. If you require immediate help, please contact one of the numbers listed above",
              "Let’s carry on with your referral"
            ],
            { serviceName }
          ),
      nextStep: d.state.referralSubmitted ? this.sayTheyWillCall : this.end
    }
  }

  sayTheyWillCall(d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: this.t(
        "One of {serviceName}'s clinical team will contact you on {phoneNumber} within one working day to check that you have referred yourself in to one of the crisis support services",
        { serviceName, phoneNumber: d.state.phoneNumber }
      ),
      nextStep: this.end
    }
  }

  /* Generic Handlers */

  async onHandlePhoneNumber(_state: State): Promise<IStepResult | void> {
    return { body: this.t("Thank you"), nextStep: this.sayTheyWillCall }
  }
}

/* istanbul ignore next */
export default class CrisisEveryturnDialogue extends AdHocDialogue<State, CrisisEveryturnScript> {
  static id = DialogueIDs.CrisisEveryturn
  readonly name: string = "CrisisEveryturnDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisEveryturnDialogue.id, new CrisisEveryturnScript(), state, snapshot)
  }
}
