import { ClinicalFlags, clinicalFlagsMap, disordersMap, ProblemCategories } from "@limbic/types"
import { IUserClinicalPathDescriptor } from "../models/IClinicalPath"
import { getIntersection } from "./array"
import Logger from "./Logger"

export enum InvalidClinicalPathReason {
  NoPrimarySecondaries = "Secondaries without primaries",
  MultipleBoth = "Primaries AND secondaries with multiple items",
  MultipleSecondaryNoPrimary = "Multiple secondaries must have exactly one secondary",
  DuplicatePrimary = "Duplicate Primary",
  DuplicateSecondary = "Duplicate Secondary",
  DuplicateFlag = "Duplicate Flag",
  IntersectingLists = "Intersecting Lists",
  IntersectingListDepression = "Intersecting List (Depression)",
  IntersectingListGAD = "Intersecting List (Gad)",
  IntersectingListPhobia = "Intersecting List (Phobia)",
  IntersectingListSocialPhobia = "Intersecting List (Social Phobia)",
  IntersectingListAgoraphobia = "Intersecting List (Agoraphobia)",
  IntersectingListAnxietyExtra = "Intersecting List (HEALTH/OCD/PTSD)",
  IntersectingListPanic = "Intersecting List (Panic)",
  IntersectingListWorry = "Intersecting List (Worry)",
  IntersectingListWSAS = "Intersecting List (WSAS)",
  IntersectingListEatingDisorder = "Intersecting List (Eating Disorder)",
  GADSecondaryMultiplePrimaries = "GAD as secondary can't have multiple primaries",
  Unknown = "Unknown"
}

export interface ClinicalPathValidation {
  isValid: boolean
  reason?: InvalidClinicalPathReason[]
}

export default function validateClinicalPath(
  input: IUserClinicalPathDescriptor
): ClinicalPathValidation {
  const reason: InvalidClinicalPathReason[] = []
  try {
    const { flags, primaryProblems: primaries, secondaryProblems: secondaries } = input
    const primariesSet = new Set<ProblemCategories>(primaries)
    const secondariesSet = new Set<ProblemCategories>(secondaries)
    const flagsSet = new Set<ClinicalFlags>(flags)
    const all = [...primaries, ...secondaries]

    // You can't have an empty primary while the secondary has stuff.
    // You're either below caseness so both are empty, or you have at
    // least one primary problem
    if (!primariesSet.size && secondariesSet.size) {
      reason.push(InvalidClinicalPathReason.NoPrimarySecondaries)
    }

    // You can't have both lists with more than one item
    // If you scored above caseness for both PHQ9 and GAD7
    // and you went through the anxiety path, it means you added
    // depression as the only secondary and added a lot of anxiety
    // disorders as primary.
    // If you then select one of the multiple
    // primaries as the most affecting etc, then that becomes the
    // only primary and the rest all become secondary.
    // If you went through the depression path, it means you added
    // GAD as the only secondary and depression as the only primary
    // which means there can never be a case where both lists have
    // multiple disorders
    if (primariesSet.size > 1 && secondariesSet.size > 1) {
      reason.push(InvalidClinicalPathReason.MultipleBoth)
    }

    // If you have more than 1 secondary, then you must have exactly 1 primary
    if (secondariesSet.size > 1 && primariesSet.size !== 1)
      reason.push(InvalidClinicalPathReason.MultipleSecondaryNoPrimary)

    // Duplicates
    if (primariesSet.size !== primaries.length) {
      reason.push(InvalidClinicalPathReason.DuplicatePrimary)
    }
    if (secondariesSet.size !== secondaries.length) {
      reason.push(InvalidClinicalPathReason.DuplicateSecondary)
    }
    if (flagsSet.size !== flags.length) {
      reason.push(InvalidClinicalPathReason.DuplicateFlag)
    }

    // Intersecting lists
    if (primaries.find(i => secondariesSet.has(i))) {
      reason.push(InvalidClinicalPathReason.IntersectingLists)
    }
    if (secondaries.find(i => primariesSet.has(i))) {
      reason.push(InvalidClinicalPathReason.IntersectingLists)
    }

    // there can only be one item from each of these lists
    if (getIntersection(disordersMap.depression, [...new Set(all)]).length > 1) {
      reason.push(InvalidClinicalPathReason.IntersectingListDepression)
    }
    if (getIntersection(disordersMap.anxiety.gad, [...new Set(all)]).length > 1) {
      reason.push(InvalidClinicalPathReason.IntersectingListGAD)
    }
    if (getIntersection(disordersMap.anxiety.phobia, [...new Set(all)]).length > 1) {
      reason.push(InvalidClinicalPathReason.IntersectingListPhobia)
    }
    if (getIntersection(disordersMap.anxiety.socialPhobia, [...new Set(all)]).length > 1) {
      reason.push(InvalidClinicalPathReason.IntersectingListSocialPhobia)
    }
    if (getIntersection(disordersMap.anxiety.agoraPhobia, [...new Set(all)]).length > 1) {
      reason.push(InvalidClinicalPathReason.IntersectingListAgoraphobia)
    }
    if (getIntersection(disordersMap.anxiety.panic, [...new Set(all)]).length > 1) {
      reason.push(InvalidClinicalPathReason.IntersectingListPanic)
    }
    if (getIntersection(disordersMap.anxiety.worry, [...new Set(all)]).length > 1) {
      reason.push(InvalidClinicalPathReason.IntersectingListWorry)
    }
    if (getIntersection(disordersMap.anxietyExtra, [...new Set(all)]).length > 1) {
      reason.push(InvalidClinicalPathReason.IntersectingListAnxietyExtra)
    }
    if (getIntersection(disordersMap.eatingDisorder, [...new Set(all)]).length > 1) {
      reason.push(InvalidClinicalPathReason.IntersectingListEatingDisorder)
    }
    if (getIntersection(clinicalFlagsMap.wsas, [...new Set(flags)]).length > 1) {
      reason.push(InvalidClinicalPathReason.IntersectingListWSAS)
    }

    // GAD as secondary means there can only be one primary because either
    // you selected depression and put GAD as the only secondary, or you
    // selected GAD which puts GAD as primary, and then you selected one of
    // the anxiety disorders as primary which changed the GAD to secondary
    const isSecondaryGAD = !!secondaries.find(i => disordersMap.anxiety.gad.includes(i))
    if (isSecondaryGAD && primariesSet.size > 1) {
      reason.push(InvalidClinicalPathReason.GADSecondaryMultiplePrimaries)
    }

    // 💡Leaving these rules as comments here for historical reasons,
    // so that we know what we did and why we changed it.
    // Deprecated: - Any anxiety-specific disorder can't exist without GAD.
    //             👆This is no longer valid due to the ADSM assessment
    //             flow because the ADSM model can administer anxiety
    //             questionnaires even without GAD.
    // Deprecated: - Depression can't be part of multiple primaries
    //             - Depression as primary can't have multiple secondaries
    //             - Depression as primary must have GAD as the only secondary
    //             👆This is no longer valid because we do the ADSM triage
    //              even when you only have depression which means you can
    //              end up with multiple primaries that include both depresssion
    //              and ADSM disorders.
  } catch (e) {
    Logger.getInstance().exception(e, "validateClinicalPath")
    reason.push(InvalidClinicalPathReason.Unknown)
  }

  return {
    isValid: !reason.length,
    reason: [...new Set(reason)]
  }
}
