import AssessmentScript, { AssessmentScriptState } from "./AssessmentScript"

type State = AssessmentScriptState
export type AssessmentIAPTScriptState = State

// this basically exists only because some non-iapt
// services like K&M CCG (actually only them), only
// get an email without us saving a referral on our
// side so these senders would just fail. Normally we
// should bring all services to create referrals in
// our system which means we would always need to send
// the questionnaires which means we would just move
// these to the AssessmentScript and remove this file
// entirely

export default class AssessmentIAPTScript<
  State extends AssessmentIAPTScriptState = AssessmentIAPTScriptState
> extends AssessmentScript {
  readonly name: string = "AssessmentIAPTScript"

  /** Generic Handlers */

  handlePHQ9Responses(state: State): void {
    if (!this.referralStore.phq9HasBeenSent) {
      this.sendPHQ9(state)
    }
  }

  handlePHQ2Responses(state: State): void {
    this.sendPHQ2(state)
  }

  handleBDI2Responses(state: State): void {
    this.sendBDI2(state)
  }

  handleHAM_DResponses(state: State): void {
    this.sendHAM_D(state)
  }

  handleHAM_AResponses(state: State): void {
    this.sendHAM_A(state)
  }

  handlePSWQResponses(state: State): void {
    this.sendPSWQ(state)
  }

  handleGAD7Responses(state: State): void {
    this.sendGAD7(state)
  }

  handleGAD2Responses(state: State): void {
    this.sendGAD2(state)
  }

  handlePhobiaScaleResponses(state: State): void {
    this.sendPhobiaScale(state)
  }

  handleIAPTEmploymentStatusResponses(state: State): void {
    this.sendIAPTEmploymentStatus(state)
  }

  handleWSASResponses(state: State): void {
    this.sendWSAS(state)
  }

  handleIAPTMedicationResponses(state: State): void {
    this.sendIAPTMedication(state)
  }
}
