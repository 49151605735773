import React from "react"
import "./DeviceLabel.scss"
import Logger from "../../../utils/Logger"

export default function DeviceLabel(): React.ReactElement | null {
  const imgUrl = process.env.REACT_APP_DEVICE_LABEL
  if (!imgUrl) {
    Logger.getInstance().exception(new Error("Device label image URL is not set"))
    return null
  }
  const openDeviceLabel = () => window.open(imgUrl, "_blank")

  return (
    <div
      id="device-label"
      className="lb-device-label-box"
      onClick={openDeviceLabel}
      title="Device label">
      <img src={imgUrl} className="lb-device-label" alt="Limbic Access device label" />
    </div>
  )
}
