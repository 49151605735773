import z from "zod"

export enum FF {
  AC432_MD2_IFU = "AC432_MD2_IFU",
  AC433_NHS_SIDEBAR = "AC433_NHS_SIDEBAR",
  AC434_HIPPA_SIDEBAR = "AC434_HIPPA_SIDEBAR",
  AC448_ADSM_ML_V2 = "AC448_ADSM_ML_V2",
  AC450_WSAS_IN_ASSESSMENT = "AC450_WSAS_IN_ASSESSMENT",
  AC451_EVERYTURN = "AC451_EVERYTURN",
  AC453_VHG = "AC453_VHG",
  AC454_ESSEX = "AC454_ESSEX",
  AC455_LINCS = "AC455_LINCS",
  AC456_SABP = "AC456_SABP",
  AC457_GM_HUB = "AC457_GM_HUB",
  AC459_VITALITY = "AC459_VITALITY",
  AC461_MPFT = "AC461_MPFT",
  AC462_MHM = "AC462_MHM",
  AC463_BRADFORD = "AC463_BRADFORD",
  AC464_MIND = "AC464_MIND",
  AC466_LWC = "AC466_LWC",
  AC467_CHESHIRE_WIRRAL = "AC467_CHESHIRE_WIRRAL",
  AC468_OXFORD = "AC468_OXFORD",
  AC469_BUCKS = "AC469_BUCKS",
  AC470_DORKING = "AC470_DORKING",
  AC471_BEXLEY = "AC471_BEXLEY",
  AC_899_LLM_RESPONSES = "AC_899_LLM_RESPONSES",
  AC_900_STREAMING_RESPONSES = "AC_900_STREAMING_RESPONSES",
  AC_901_SMI_MODULE = "AC_901_SMI_MODULE",
  AC_902_CUSTOMISABLE_ASSESSMENT = "AC_902_CUSTOMISABLE_ASSESSMENT"
}

const schema: Record<FF, z.ZodBoolean> = {
  [FF.AC432_MD2_IFU]: z.boolean(),
  [FF.AC433_NHS_SIDEBAR]: z.boolean(),
  [FF.AC434_HIPPA_SIDEBAR]: z.boolean(),
  [FF.AC448_ADSM_ML_V2]: z.boolean(),
  [FF.AC450_WSAS_IN_ASSESSMENT]: z.boolean(),
  [FF.AC451_EVERYTURN]: z.boolean(),
  [FF.AC453_VHG]: z.boolean(),
  [FF.AC454_ESSEX]: z.boolean(),
  [FF.AC455_LINCS]: z.boolean(),
  [FF.AC456_SABP]: z.boolean(),
  [FF.AC457_GM_HUB]: z.boolean(),
  [FF.AC459_VITALITY]: z.boolean(),
  [FF.AC461_MPFT]: z.boolean(),
  [FF.AC462_MHM]: z.boolean(),
  [FF.AC463_BRADFORD]: z.boolean(),
  [FF.AC464_MIND]: z.boolean(),
  [FF.AC466_LWC]: z.boolean(),
  [FF.AC467_CHESHIRE_WIRRAL]: z.boolean(),
  [FF.AC468_OXFORD]: z.boolean(),
  [FF.AC469_BUCKS]: z.boolean(),
  [FF.AC470_DORKING]: z.boolean(),
  [FF.AC471_BEXLEY]: z.boolean(),
  [FF.AC_899_LLM_RESPONSES]: z.boolean(),
  [FF.AC_900_STREAMING_RESPONSES]: z.boolean(),
  [FF.AC_901_SMI_MODULE]: z.boolean(),
  [FF.AC_902_CUSTOMISABLE_ASSESSMENT]: z.boolean()
}

export const featureFlagsGuard = z.object(schema).strict()
export type FeatureFlags = z.infer<typeof featureFlagsGuard>

const regionFlags = require("./region-flags.json") ?? {}
const localFlags: Record<FF, boolean> = {
  [FF.AC432_MD2_IFU]: false,
  [FF.AC433_NHS_SIDEBAR]: false,
  [FF.AC434_HIPPA_SIDEBAR]: false,
  [FF.AC448_ADSM_ML_V2]: false,
  [FF.AC450_WSAS_IN_ASSESSMENT]: false,
  [FF.AC451_EVERYTURN]: false,
  [FF.AC453_VHG]: false,
  [FF.AC454_ESSEX]: false,
  [FF.AC455_LINCS]: false,
  [FF.AC456_SABP]: false,
  [FF.AC457_GM_HUB]: false,
  [FF.AC459_VITALITY]: false,
  [FF.AC461_MPFT]: false,
  [FF.AC462_MHM]: false,
  [FF.AC463_BRADFORD]: false,
  [FF.AC464_MIND]: false,
  [FF.AC466_LWC]: false,
  [FF.AC467_CHESHIRE_WIRRAL]: false,
  [FF.AC468_OXFORD]: false,
  [FF.AC469_BUCKS]: false,
  [FF.AC470_DORKING]: false,
  [FF.AC471_BEXLEY]: false,
  [FF.AC_899_LLM_RESPONSES]: false,
  [FF.AC_900_STREAMING_RESPONSES]: false,
  [FF.AC_901_SMI_MODULE]: false,
  [FF.AC_902_CUSTOMISABLE_ASSESSMENT]: false
}

const featureFlags = featureFlagsGuard.parse({
  ...localFlags,
  ...regionFlags
})

export function hasFF(key: FF): boolean {
  // this happens on purpose so we can mock the function in tests
  // with different combinations of flags
  // noinspection PointlessBooleanExpressionJS
  return getAllFeatureFlags()[key] === true
}

export function getAllFeatureFlags(): FeatureFlags {
  // sanity check to ensure that the flags are not mutated
  const copy = featureFlagsGuard.parse(JSON.parse(JSON.stringify(featureFlags)))
  Object.freeze(copy)
  return copy
}
