import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = AssessmentPitchIAPTScriptState

export class AssessmentPitchEveryturnScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchEveryturnScript"

  /** Script Steps */

  @step.logState
  checkForNeedToBeCalled(d: IStepData<State>): IStepResult {
    return { nextStep: this.askWannaDoQuestionnaires }
  }

  @step.logState
  askWannaDoQuestionnaires(d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "I'm now going to ask you a few questions to measure symptoms of common mental health issues",
        "And I will use the results to match you with the best therapist for your needs"
      ]),
      prompt: {
        id: this.getPromptId("askWannaDoQuestionnaires"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWannaDoQuestionnaires
    }
  }
}

/* istanbul ignore next */
export default class AssessmentPitchEveryturnDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchEveryturn
  readonly name: string = "AssessmentPitchEveryturnDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      AssessmentPitchEveryturnDialogue.id,
      new AssessmentPitchEveryturnScript(),
      state,
      snapshot
    )
  }
}
