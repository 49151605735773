import { IStepResult, StepResultBodyType } from "../backend/chatbot/models/IStep"

export default function stepResultBodyToLLMMessage(body: IStepResult["body"]): string {
  const messages = Array.isArray(body) ? body : [body]
  return messages.reduce((m: string, current: StepResultBodyType) => {
    const result = m.length ? `${m.trim()}\n` : m
    if (typeof current === "string" && current.length) return `${result}${current}`
    else if (typeof current === "object") return `${result}MEDIA: ${JSON.stringify(current)}`
    return result
  }, "")
}
