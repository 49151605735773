import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { PHQ9Script } from "./PHQ9"
import type { PHQ9ScriptState } from "./PHQ9"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { RiskLevelReason } from "../../../models/Constants"
import { step } from "../../../backend/chatbot/decorators/step"
import {
  ChatFlowsEnum,
  DiscussionSteps,
  IDefaultChatFlowMessagesRiskPathway,
  RISK_PATHWAY_MESSAGE_MAP,
  RiskPathwayType
} from "@limbic/types"
import { IRiskPathwaySettings } from "../../dialogues/riskPathway/RiskPathwayDynamicDialogue"

type State = PHQ9ScriptState

export class PHQ9DynamicScript extends PHQ9Script {
  readonly name: string = "PHQ9DynamicScript"

  @step.logState
  async goToRiskPathwayDynamic(d: IStepData<State>): Promise<IStepResult> {
    const RiskPathwayDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.RiskPathway)
    const settings = (await this.getRiskPathwaySettings?.(d.state)) ?? {}

    const nextDialogue = RiskPathwayDialogue //
      ? new RiskPathwayDialogue({ ...d.state }, undefined, settings)
      : undefined
    return {
      nextDialogue,
      nextStep: this.handleRiskPathway
    }
  }

  /** Generic Handlers */
  async onHandleRiskQ9Score(state: State): Promise<IStepResult | void> {
    this.setCrisisDetected(state)
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    const isRisk = this.clinicalStore.isRisk
    if (!isRisk && state.php9q9Score === 3) {
      this.setRiskLevelHigh(state, RiskLevelReason.PHQ9_Q9)
    }
    return { nextStep: this.goToRiskPathwayDynamic }
  }

  async getRiskPathwaySettings(_state: State): Promise<IRiskPathwaySettings> {
    const assessmentFlow = this.rootStore.configStore.dialogueFlows?.[DiscussionSteps.Assessment]
    const assessmentStep = assessmentFlow?.find(
      step => step.chatFlow === ChatFlowsEnum.ASSESSMENT_AND_TREATMENTS
    )

    const messages: IDefaultChatFlowMessagesRiskPathway = {}
    RISK_PATHWAY_MESSAGE_MAP.forEach(key => {
      if (assessmentStep?.settings?.messages) {
        messages[key] = assessmentStep?.settings?.messages[key]
      }
    })

    return {
      messages,
      crisisNumbersShared: assessmentStep?.settings?.assessmentAndTreatments?.crisisNumbersShared,
      riskPathwayType:
        assessmentStep?.settings?.assessmentAndTreatments?.riskPathwayType ??
        RiskPathwayType.DEFAULT
    }
  }
}

export default class PHQ9DynamicDialogue extends Dialogue<State> {
  static id = DialogueIDs.PHQ9Dynamic
  readonly name: string = "survey:PHQ9_Dynamic Dialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PHQ9DynamicDialogue.id, new PHQ9DynamicScript(), state, snapshot)
  }
}
