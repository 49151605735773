import { IKeyMetaData, IKeyTransform, IKeyTransformMap, KeyType } from "@limbic/types"
import Logger from "../../utils/Logger"
import { upsertPath } from "../../utils/object"

export abstract class PayloadBuilder<T extends Record<string, any>> {
  _payload: T
  ctx: Record<string, any>
  transformMap: IKeyTransformMap
  targetKeys: Record<string, IKeyMetaData>

  /**
   * Returns the transformed payload. This needs to be implemented
   * by the child class because each payload builder has its own
   * logic for transforming the payload
   */
  getTransformedPayload?(): Partial<T>

  /**
   * Returns the transformed keys. Implement this method if you
   * want to declare which keys in the default payload should be
   * removed from the final payload because they were transformed
   * and their values were saved in a different key
   * @param transformedPayload {Record<string, any>?} the transformed payload
   */
  getTransformedKeys?(transformedPayload?: Record<string, any>): Array<keyof T>

  /**
   * Returns the default payload of the current builder. This needs
   * to be implemented by the child class because each payload builder
   * has its own default payload
   */
  getDefaultPayload?(): Partial<T>

  constructor() {
    this._payload = {} as T
    this.ctx = {}
    this.transformMap = {}
    this.targetKeys = {}
  }

  /**
   * Sets the context of the payload builder. Use it
   * to pass the ctx.state object from the chatbot
   * to the payload builder or to pass any other
   * context that is needed to build the payload
   * @param ctx {Record<string, any>} the context to set
   */
  withContext(ctx: Record<string, any>): this {
    this.ctx = { ...this.ctx, ...ctx }
    return this
  }

  /**
   * Sets the transform map of the payload builder. Use it
   * to pass the transform rules for each key in the payload
   * @param transformMap {IKeyTransformMap} the transform map to set
   */
  withTransformMap(transformMap?: IKeyTransformMap): this {
    this.transformMap = transformMap ?? {}
    return this
  }

  /**
   * Sets the target keys of the payload builder. Use it
   * to pass the meta-data of each key in the payload so
   * that the payload builder can know how to transform
   * values based on meta-data like the key's type or
   * allowed values
   * @param targetKeys
   */
  withTargetKeys(targetKeys?: Record<string, IKeyMetaData>): this {
    this.targetKeys = targetKeys ?? {}
    return this
  }

  /**
   * Sets data in the payload of the payload builder. Use it
   * to pass any data you want to be set in the payload directly
   * without any further transformation
   * @param data
   */
  withData(data?: Partial<T>): this {
    if (data && typeof data === "object") {
      for (const [key, value] of Object.entries(data)) {
        upsertPath(this._payload, key, value)
      }
    }
    return this
  }

  /**
   * Sets values in the payload based on transforms that were defined
   * by the user and not automatically inferred by the chatbot flow
   * in the dashboard
   */
  withCustomTransforms(): this {
    // prettier-ignore
    const customTransforms: IKeyTransform[] = Object.values(this.transformMap).filter(t => t.isCustomTransform)
    customTransforms.forEach(t => {
      const sourceValue = this.ctx[t.contextKey ?? "state"]?.[t.sourceKey]
      this.withData(this.getTransformedKeyValue(t.sourceKey, sourceValue) as T)
    })
    return this
  }

  /**
   * Configures a payload builder with the current builder's
   * transform map, target keys, and context and uses it to
   * build a payload that is then set in the current builder
   * @param builder
   */
  withBuilder(builder: PayloadBuilder<T>): this {
    this.withData(
      builder
        .withTransformMap(this.transformMap)
        .withTargetKeys(this.targetKeys)
        .withContext(this.ctx)
        .build()
    )
    return this
  }

  /**
   * Gets the transformed value of a key from the context. Ideally
   * you should not need to override this method, but if you do, consider
   * doing in a compositional way, by calling super.getTransformedKeyValue()
   * and then adding your own logic on top of it so that the root logic is
   * not lost
   * @param sourceKey {string} the key to get the transformed value for
   * @param sourceValue {any?} the value to transform
   */
  getTransformedKeyValue(
    sourceKey: string,
    sourceValue?: unknown
  ): Record<string, any> | undefined {
    if (sourceValue == null) return

    const transform = this.transformMap[sourceKey]
    const targetKey = transform?.targetKey
    if (!targetKey) return

    const metaData = this.targetKeys[targetKey]
    if (!metaData) return
    return this.processTransformation(transform, metaData, sourceKey, targetKey, sourceValue)
  }

  /**
   * Processes a transformation by checking if the source value
   * is mapped to a target value, and if it is, then it returns
   * the target value. If the source value is not mapped, then
   * it checks if the source value satisfies the allowed values
   * constraint of the target key, and if it does, then it returns
   * the source value. If the source value is not mapped, and it
   * doesn't satisfy the allowed values constraint, then it logs
   * an error
   * @param transform {IKeyTransform} the transform to process
   * @param metaData {IKeyMetaData} the meta-data of the target key
   * @param sourceKey {string} the source key
   * @param targetKey {string} the target key
   * @param sourceValue {unknown} the source value to be transformed
   * @private
   */
  protected processTransformation(
    transform: IKeyTransform,
    metaData: IKeyMetaData,
    sourceKey: string,
    targetKey: string,
    sourceValue: unknown
  ): Record<string, any> | undefined {
    const valuesMap = transform.valuesMap
    const allowedValues = metaData.allowedValues
    const targetType = metaData?.type
    const valueType = transform.type
    const isTargetText = targetType === KeyType.Text
    const isTargetNumber = targetType === KeyType.Number
    const isTargetBoolean = targetType === KeyType.Boolean
    const isTargetTextList = targetType === KeyType.TextList
    const isTargetNumberList = targetType === KeyType.NumberList
    const isTargetBooleanList = targetType === KeyType.BooleanList
    const isTargetObject = targetType === KeyType.Object
    const isValueText = valueType === KeyType.Text
    const isValueNumber = valueType === KeyType.Number
    const isValueBoolean = valueType === KeyType.Boolean
    const isValueArray = Array.isArray(sourceValue)
    const isValueTextList = valueType === KeyType.TextList || (isValueArray && isValueText)
    const isValueNumberList = valueType === KeyType.NumberList || (isValueArray && isValueNumber)
    const isValueBooleanList = valueType === KeyType.BooleanList || (isValueArray && isValueBoolean)
    const isValueObject = valueType === KeyType.Object

    const isTargetSingle = isTargetText || isTargetNumber || isTargetBoolean
    const isTargetList = isTargetTextList || isTargetNumberList || isTargetBooleanList
    const isValueSingle = isValueText || isValueNumber || isValueBoolean
    const isValueList = isValueTextList || isValueNumberList || isValueBooleanList

    const mappedValue = valuesMap?.[String(sourceValue)]
    // If a mapped value is found, then no need to transform anything
    // we already know it's a match so just need to use it either as
    // is or as a single value array and call it a day (if a mapped
    // value exists at this point, it means the source value is not
    // a list, so we don't care about that scenario yet)
    if (mappedValue != null) {
      if (isTargetSingle) return { [targetKey]: mappedValue }
      if (isTargetList) return { [targetKey]: [mappedValue] }
      if (isTargetObject) return { [targetKey]: mappedValue }
    }

    // If the source value is a list, then we need to check each
    // element in the list to see if it's mapped, and if it is,
    // then we need to replace it with the mapped value. If the
    // target is also a list, then use the mapped values as is,
    // but if the target is a single value key, then use the first
    // mapped value and ignore the rest
    if (isValueList && valuesMap) {
      const mappedValues = (sourceValue as (string | number | boolean)[])
        .map(v => {
          const mappedValue = valuesMap[String(v)]
          if (mappedValue != null) return mappedValue

          // If the source value is a text list and a value doesn't map,
          // then maybe the value is a free text value (aka an inline
          // multi picker prompt that also has a free text option), so if
          // there is a __freeTextValue__ indicator in the valuesMap, we
          // should use that
          if (isValueTextList) {
            const freeTextValue = valuesMap.__inlineFreeTextValue__
            if (freeTextValue != null) return freeTextValue
          }

          this.handleInvalidTransformation(transform, metaData, sourceKey, targetKey, sourceValue)
          return undefined
        })
        .filter(Boolean)

      if (mappedValues.length && isTargetList) return { [targetKey]: mappedValues }
      if (mappedValues.length && isTargetSingle) return { [targetKey]: mappedValues[0] }
    }

    // If we haven't found a mapped value yet and the value is text
    // and the valuesMap also has a freeTextValue indicator, it could
    // very well mean that the value is a free text and that's why no
    // value in the valuesMap matches it so might as well just use that
    const freeTextValue = valuesMap?.__inlineFreeTextValue__
    if (isValueText && freeTextValue != null) {
      if (isTargetSingle) return { [targetKey]: freeTextValue }
      if (isTargetList) return { [targetKey]: [freeTextValue] }
    }

    // If there's no valuesMap, or if we haven't found a match yet,
    // it means we have nothing to map the source value to, so we
    // either need to save it as is, or skip it if it can't be used
    // as is for the given target
    if (!valuesMap || mappedValue == null) {
      switch (true) {
        // - If the source satisfies the allowedValues constraint,
        //   then just save the value as is, since there's no valuesMap
        //   to check for transformations
        // - If the source and target are both boolean, save the
        //   value as is, since there's no valuesMap to check for
        //   transformations
        // - If the source and target are both boolean lists, save
        //   the value as is, since there's no valuesMap to check
        //   for transformations
        // - If the source and target are both text, without any
        //   allowedValues constraint, then save the value as is
        // - If the source and target are both numbers, without any
        //   allowedValues constraint, then save the value as is
        // - If both are text lists without the allowedValues constraint,
        //   then just save the value as is, since there's no valuesMap
        //   to check for transformations
        // - If both are number lists without the allowedValues constraint,
        //   then just save the value as is, since there's no valuesMap
        //   to check for transformation
        case allowedValues?.includes(sourceValue as string):
        case isTargetBoolean && isValueBoolean:
        case isTargetBooleanList && isValueBooleanList:
        case isValueText && isTargetText && !allowedValues?.length:
        case isValueNumber && isTargetNumber && !allowedValues?.length:
        case isValueTextList && isTargetTextList && !allowedValues?.length:
        case isValueNumberList && isTargetNumberList && !allowedValues?.length:
          return { [targetKey]: sourceValue }
        // if the value is a number but is set to be saved in a
        // text target that doesn't have an allowedValues constraint,
        // then might as well just convert the number to a string
        case isValueNumber && isTargetText && !allowedValues?.length:
          return { [targetKey]: `${sourceValue}` }
        // If the value is a number list but is set to be saved in a text
        // list target that doesn't have an allowedValues constraint, then
        // might as well just convert the numbers to strings
        case isValueNumberList && isTargetTextList && !allowedValues?.length:
          return { [targetKey]: (sourceValue as number[]).map(String) }
        // If the value is a boolean but is set to be saved in a
        // text target that doesn't have an allowedValues constraint,
        // then might as well just convert the boolean to Yes/No
        case isValueBoolean && isTargetText && !allowedValues?.length:
          return { [targetKey]: sourceValue ? "Yes" : "No" }
        // If the value is a boolean list but is set to be saved in a text list
        // target that doesn't have an allowedValues constraint, then might as
        // well just convert the boolean values to Yes/No values
        case isValueBooleanList && isTargetTextList && !allowedValues?.length:
          return { [targetKey]: (sourceValue as boolean[]).filter(v => (v ? "Yes" : "No")) }
        case isValueObject && isTargetObject:
          return { [targetKey]: sourceValue }
      }

      // If the value and the target are both text or list, and there's an
      // allowedValues constraint, then we need to make sure the elements
      // in the target value are filtered so that only allowed values
      // are set in the payload
      if (isValueList && isTargetList && allowedValues?.length) {
        const correctValues = (sourceValue as (string | number | boolean)[]).filter(v => {
          return allowedValues?.includes(v)
        })
        if (correctValues.length) return { [targetKey]: correctValues }
      }

      // if the value is single but the target a list, and there's no
      // allowedValues constraint, then just save the value as a single
      // element array
      if (isValueSingle && isTargetList && !allowedValues?.length) {
        return { [targetKey]: [sourceValue] }
      }

      // If the value is a list but the target is single, and there's
      // no allowedValues constraint, then just save the first element
      // of the list as the value
      if (isValueList && isTargetSingle && !allowedValues?.length) {
        return { [targetKey]: (sourceValue as any[])[0] }
      }

      this.handleInvalidTransformation(transform, metaData, sourceKey, targetKey, sourceValue)
    }
  }

  /**
   * Handles an invalid transformation by logging an error
   * @param transform {IKeyTransform} the transform that was attempted
   * @param metaData {IKeyMetaData} the meta-data of the target key
   * @param sourceKey {string} the source key
   * @param targetKey {string} the target key
   * @param sourceValue {unknown} the source value that was attempted to be transformed
   */
  handleInvalidTransformation(
    transform?: IKeyTransform,
    metaData?: IKeyMetaData,
    sourceKey?: string,
    targetKey?: string,
    sourceValue?: unknown
  ): void {
    const message = "Invalid transformation attempted"
    const e = new Error(message)
    Logger.getInstance().breadcrumb({ message, data: { transform, metaData, sourceValue } })
    Logger.getInstance().exception(e, `${transform?.context} (${sourceKey}-->${targetKey})`)
  }

  /**
   * Builds the payload by combining the default payload with the
   * transformed payload. It also filters out the default payload
   * keys that were transformed so that they don't appear twice
   * in the final payload. It also makes sure the returned payload
   * can be serialisable. Ideally you should not need to override
   * this method, but if you do, consider doing in a compositional
   * way, by calling super.build() and then adding your own logic
   * on top of it so that the root logic is not lost
   */
  build(): T {
    try {
      const transformedPayload = this.getTransformedPayload?.() ?? {}
      const transformedKeys = this.getTransformedKeys?.(transformedPayload) ?? []
      // use the transformed keys to filter out the default payload keys
      const defaultPayload = Object.entries(this.getDefaultPayload?.() ?? {}).reduce(
        (payload, [key, value]) => {
          if (transformedKeys.includes(key)) return payload
          return { ...payload, [key]: value }
        },
        {}
      )
      this.withData({ ...defaultPayload, ...transformedPayload })
      return JSON.parse(JSON.stringify(this.payload)) as T
    } catch (e) {
      Logger.getInstance().breadcrumb({
        message: "Error building payload",
        data: JSON.parse(JSON.stringify(this))
      })
      Logger.getInstance().exception(e, "Error building payload")
      throw e
    }
  }

  /** Getters / Setters */

  get payload(): T {
    return this._payload
  }
}
