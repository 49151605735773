import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IAPTIDs } from "../../../models/IIAPTService"
import { DialogueIDs } from "../../DialogueIDs"
import { ADSMConsentScript, ADSMConsentScriptState } from "./ADSMConsentDialogue"

type State = ADSMConsentScriptState
export type ADSMConsentEveryturnDialogueState = State

export class ADSMConsentEveryturnScript extends ADSMConsentScript {
  readonly name: string = "ADSMConsentEveryturnScript"

  /** Script Steps */
  @step.logState
  start(d: IStepData<State>): IStepResult {
    const disableADSMEveryturnIAPTIDs = [
      IAPTIDs.EVERYTURN_KENT,
      IAPTIDs.EVERYTURN_KENT_DEMO,
      IAPTIDs.EVERYTURN_MEDWAY,
      IAPTIDs.EVERYTURN_MEDWAY_DEMO,
      IAPTIDs.EVERYTURN_NOTTS
    ]

    const adsmEnabled =
      this.rootStore.configStore.adsmEnabled &&
      !disableADSMEveryturnIAPTIDs.includes(d.state.iapt?.id as IAPTIDs)

    if (adsmEnabled && this.referralStore.isMainSpokenLanguageEnglish) {
      return { nextStep: this.intro }
    }
    this.clinicalStore.disableADSM()
    return { nextStep: this.end }
  }

  /** Generic Handlers */
}

/* istanbul ignore next */
export default class ADSMConsentEveryturnDialogue extends Dialogue<State> {
  static id = DialogueIDs.ADSMConsentEveryturn
  readonly name: string = "ADSMConsentEveryturnDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(ADSMConsentEveryturnDialogue.id, new ADSMConsentEveryturnScript(), state, snapshot)
  }
}
