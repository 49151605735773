import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { DialogueIDs } from "../../DialogueIDs"
import {
  EligibilityCheckWithPDSScript,
  EligibilityCheckWithPDSScriptState
} from "./EligibilityCheckWithPDSScript"
import ISelectable from "../../../models/ISelectable"
import { TrackingEvents } from "../../../models/Constants"
import { IneligibilityReason } from "@limbic/types"

interface State extends EligibilityCheckWithPDSScriptState {
  waitlistSupport?: boolean
  mainProblemAlcoholOrDrugs?: boolean
  learningDisability?: boolean
}

export type EligibilityCheckCWScriptState = State

export class EligibilityCheckCWScript extends EligibilityCheckWithPDSScript {
  readonly name: string = "EligibilityCheckCWScript"
  readonly FAILED_ATTEMPTS_THRESHOLD: number = 3

  /** Script Steps */

  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askRequiresUrgentSupport }
  }

  @step.logState
  askRequiresUrgentSupport(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you at immediate risk of harming yourself?",
      prompt: {
        id: this.getPromptId("askRequiresUrgentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleRequiresUrgentSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: EligibilityCheckCWScript) => {
    d.state.requiresUrgentSupport = d.response
    script.referralStore.setCustomField<State>("requiresUrgentSupport", d.response)
  })
  handleRequiresUrgentSupport(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.clinicalStore.setRequiresUrgentSupport(true)
      this.trackUserAsIneligible(d.state, IneligibilityReason.REQUIRES_URGENT_SUPPORT)
      this.setRiskLevelHigh(d.state, "User said they require urgent support")
    }
    return {
      nextStep: d.response //
        ? this.sayCrisis
        : this.askCurrentSupport
    }
  }

  @step.logState
  sayCrisis(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: this.t(
        [
          "Thank you for sharing this information",
          "However, {organisationName} cannot provide support to people who are in crisis or unable to keep themselves safe",
          "If you feel unable to keep yourself safe and require support, please call NHS 111 and select the mental health option to talk to a mental health professional. They will be able to support you to get the help/advice you need. The service is open 24 hours a day, seven days a week",
          "You should still call 999 or go to A&E if you have an immediate, life-threatening emergency requiring mental or physical health assistance",
          "Other helplines available:\n\n" +
            "<b>Samaritans:</b> Call 116 12 for free (open 24 hours, 365 days a year)\n" +
            "<b>CALM:</b> Call 0800 58 58 58 (5pm - midnight, 7 days a week)\n" +
            "<b>Shout:</b> Text 'SHOUT' to 85258 to start a conversation with a trained volunteer, who will provide free confidential support (open 24 hours a day, seven days a week)",
          "You can refer yourself back to {organisationName} when you are no longer in crisis"
        ],
        { organisationName }
      ),
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleSayCrisis
    }
  }

  @step.logState
  handleSayCrisis(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, Local (0800 145 6485), Samaritans (116 123), SHOUT (85258)"
    )
    return { nextStep: this.goToGoodbye }
  }

  @step.logState
  askCurrentSupport(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently receiving support from a mental health service, have a personality disorder diagnosis or are looking for a medication review or diagnosis?",
      prompt: {
        id: this.getPromptId("askCurrentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleCurrentSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, s: EligibilityCheckCWScript) => {
    d.state.hasCurrentSupport = d.response
    s.setPeople({
      hasCurrentSupport: d.state.hasCurrentSupport
    })
  })
  handleCurrentSupport(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.setEligibility(d.state, false)
      this.trackUserAsIneligible(d.state, IneligibilityReason.CURRENTLY_UNDER_CARE)
      this.track(TrackingEvents.INELIGIBLE_CURRENT_SUPPORT)
    }
    return { nextStep: d.response ? this.goToSignpostCurrentSupport : this.askWaitlistSupport }
  }

  @step.logState
  askWaitlistSupport(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently on a waiting list for another mental health service or receiving therapy with another service?",
      prompt: {
        id: this.getPromptId("askWaitlistSupport"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleWaitlistSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, s: EligibilityCheckCWScript) => {
    d.state.waitlistSupport = d.response
    s.setPeople({
      waitlistSupport: d.state.waitlistSupport
    })
  })
  handleWaitlistSupport(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.setEligibility(d.state, false)
      this.trackUserAsIneligible(d.state, IneligibilityReason.CURRENTLY_ON_WAIT_LIST)
      this.track(TrackingEvents.INELIGIBLE_WAITLIST_SUPPORT)
    }
    return {
      nextStep: d.response
        ? this.goToSignpostWaitlistSupport
        : this.askIsMainProblemAboutAlcoholOrDrugs
    }
  }

  @step.logState
  askIsMainProblemAboutAlcoholOrDrugs(_d: IStepData<State>): IStepResult {
    return {
      body: "Is the main issue you're referring for in relation to drugs or alcohol?",
      prompt: {
        id: this.getPromptId("askIsMainProblemAboutAlcoholOrDrugs"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askIsMainProblemAboutAlcoholOrDrugs"
      },
      nextStep: this.handleIsMainProblemAboutAlcoholOrDrugs
    }
  }

  @step.logStateAndResponse
  async handleIsMainProblemAboutAlcoholOrDrugs(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.mainProblemAlcoholOrDrugs = d.response
    if (d.response) {
      this.setEligibility(d.state, false)
      this.trackUserAsIneligible(d.state, IneligibilityReason.MAIN_PROBLEM_IS_DRUGS_OR_ALCOHOL)
      this.track(TrackingEvents.INELIGIBLE_DRUG_OR_ALCOHOL)
    }
    return {
      nextStep: d.response
        ? this.goToSignpostMainProblemAboutAlcoholOrDrugs
        : this.askLearningDisability
    }
  }

  @step.logState
  askLearningDisability(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you under the care of a learning disabilities service?",
      prompt: {
        id: this.getPromptId("askLearningDisability"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askLearningDisability"
      },
      nextStep: this.handleLearningDisability
    }
  }

  @step.logStateAndResponse
  async handleLearningDisability(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.learningDisability = d.response
    if (d.response) {
      this.setEligibility(d.state, false)
      this.trackUserAsIneligible(d.state, IneligibilityReason.LEARNING_DISABILITY_CARE)
      this.track(TrackingEvents.INELIGIBLE_LEARNING_DISABILITY)
    }
    return { nextStep: d.response ? this.goToSignpostLearningDisability : this.askPostCodeOfUser }
  }

  @step.logState
  askSelectIAPTServiceManually(d: IStepData<State>): IStepResult {
    const eligibleIAPTs = this.getEligibleIAPTSByAgeThreshold(d.state)
    return {
      body: "And which service would you like to be referred into?",
      prompt: {
        id: this.getPromptId("askSelectIAPTServiceManually"),
        trackResponse: true,
        type: "inlinePicker",
        choices: (
          eligibleIAPTs.map(iapt => ({
            body: `${iapt.formattedName} ${
              iapt.id === "7560833"
                ? "(North East CW)"
                : iapt.id === "7757974"
                  ? "(South East CW)"
                  : ""
            }`,
            value: iapt
          })) as ISelectable<any>[]
        ) //
          .concat({
            body: "Actually, I want to speak to a human",
            value: "speakToHuman",
            backgroundColor: "#EC9CC8"
          })
      },
      nextStep: this.handleSelectIAPTServiceManually
    }
  }

  @step.logState
  goToSignpostCurrentSupport(_d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.CURRENT_SUPPORT_SIGNPOST)

    return {
      body: [
        "Talking Therapies staff are trained to deliver guided self-help and short term psychological therapies for people suffering with anxiety and depression only",
        "Please speak to your GP first who will be best placed to discuss and support your onward care needs"
      ],
      prompt: {
        id: this.getPromptId("goToSignpostCurrentSupport"),
        type: "inlinePicker",
        choices: [{ body: "Understood" }, { body: "Okay" }],
        isUndoAble: true
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  goToSignpostMainProblemAboutAlcoholOrDrugs(_d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.DRUG_ALCOHOL_SIGNPOST)
    return {
      body: [
        "Please contact the drug and alcohol service on\n" +
          "New Beginnings Chester: 0300 303 4549\n" +
          "Ellesmere Port: 0300 303 4550\n" +
          "Central Cheshire Northwich: 0300 303 4548\n" +
          "Crewe CGL: 01625 464995\n" +
          "They will be best placed to support your onwards care needs"
      ],
      prompt: {
        id: this.getPromptId("goToSignpostMainProblemAboutAlcoholOrDrugs"),
        type: "inlinePicker",
        choices: [{ body: "Understood" }, { body: "Okay" }],
        isUndoAble: true
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  goToSignpostLearningDisability(_d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.LEARNING_DISABILITY_SIGNPOST)
    return {
      body: ["Please discuss your needs with the Learning Disabilities team"],
      prompt: {
        id: this.getPromptId("goToSignpostLearningDisability"),
        type: "inlinePicker",
        choices: [{ body: "Understood" }, { body: "Okay" }],
        isUndoAble: true
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  goToSignpostWaitlistSupport(_d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.WAITLIST_SUPPORT_SIGNPOST)
    return {
      body: [
        "Please contact the service you are waiting for. They will be best placed to support your onwards care needs"
      ],
      prompt: {
        id: this.getPromptId("goToSignpostWaitlistSupport"),
        type: "inlinePicker",
        choices: [{ body: "Understood" }, { body: "Okay" }],
        isUndoAble: true
      },
      nextStep: this.goToGoodbye
    }
  }
}

/* istanbul ignore next */
export default class EligibilityCheckCWDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckCW
  readonly name: string = "EligibilityCheckCWDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckCWDialogue.id, new EligibilityCheckCWScript(), state, snapshot)
  }
}
