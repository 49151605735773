import { IneligibilityReason } from "@limbic/types"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { TrackingEvents } from "../../../models/Constants"
import { DialogueIDs } from "../../DialogueIDs"
import {
  EligibilityCheckWithPDSScript,
  EligibilityCheckWithPDSScriptState
} from "./EligibilityCheckWithPDSScript"

type BradfordCurrentMHTreatments =
  | "No"
  | "Currently receiving a talking therapy"
  | "Currently on the waiting list for another mental health service"

interface State extends EligibilityCheckWithPDSScriptState {
  nhsStaff?: boolean
  seriousMentalIllness?: boolean
}

export type EligibilityCheckBradfordScriptState = State

export class EligibilityCheckBradfordScript extends EligibilityCheckWithPDSScript {
  readonly name: string = "EligibilityCheckBradfordScript"
  readonly FAILED_ATTEMPTS_THRESHOLD: number = 3

  /** Script Steps */

  @step.logState
  startEligibilityCheck(d: IStepData<State>): IStepResult {
    return { nextStep: this.askAreYouInCrisis }
  }

  @step.logState
  askSeriousMentalIllness(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Do you have a diagnosis of a serious mental illness e.g. Psychosis, Bi-Polar, Personality Disorder, and is this what you require support with?"
      ),
      prompt: {
        id: this.getPromptId("askSeriousMentalIllness"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askSeriousMentalIllness"
      },
      nextStep: this.handleSeriousMentalIllness
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, BradfordCurrentMHTreatments>, script: EligibilityCheckBradfordScript) => {
      d.state.currentMHTreatment = d.response !== "No"
      script.referralStore.setCustomField<State>("currentMHTreatment", d.state.currentMHTreatment)
    }
  )
  handleSeriousMentalIllness(d: IStepData<State, boolean>): IStepResult {
    d.state.seriousMentalIllness = d.response
    if (d.response) {
      this.trackUserAsIneligible(d.state, IneligibilityReason.SERIOUS_MENTAL_ILLNESS)
      return { nextStep: this.goToSeriousMentalIllnessSignposting }
    }
    return { nextStep: this.askCurrentMHTreatment }
  }

  @step.logState
  goToSeriousMentalIllnessSignposting(d: IStepData<State>): IStepResult {
    const organisationName =
      this.getIAPTName(d.state) ?? this.rootStore.configStore.organisationName

    this.track(TrackingEvents.SERIOUS_MENTAL_ILLNESS)
    return {
      body: this.t(
        [
          "{organisationName} treat common mental health problems. Please contact your GP who can direct you further to the most appropriate service",
          "You can also find additional support through the Healthy Minds website [here](https://www.healthyminds.services/)"
        ],
        { organisationName }
      ),
      prompt: {
        id: this.getPromptId("goToSeriousMentalIllnessSignposting"),
        type: "inlinePicker",
        choices: [{ body: "Understood" }, { body: "Okay" }],
        isUndoAble: true
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  askCurrentMHTreatment(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently receiving any kind of talking therapy or on the waiting list for another mental health service?",
      prompt: {
        id: this.getPromptId("askCurrentMHTreatment"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "No", value: "No" },
          {
            body: "Currently receiving a talking therapy",
            value: "Currently receiving a talking therapy"
          },
          {
            body: "Currently on the waiting list for another mental health service",
            value: "Currently on the waiting list for another mental health service"
          }
        ],
        dataPointsName: "askCurrentSupport"
      },
      nextStep: this.handleCurrentMHTreatment
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, BradfordCurrentMHTreatments>, script: EligibilityCheckBradfordScript) => {
      d.state.currentMHTreatment = d.response !== "No"
      script.referralStore.setCustomField<State>("currentMHTreatment", d.state.currentMHTreatment)
    }
  )
  handleCurrentMHTreatment(d: IStepData<State, BradfordCurrentMHTreatments>): IStepResult {
    if (d.response === "No") {
      return { nextStep: this.askExArmedForces }
    }
    return { nextStep: this.askCurrentMHTreatmentDetails }
  }

  @step.logState
  askCurrentMHTreatmentDetails(_d: IStepData<State>): IStepResult {
    return {
      body: "Please give details about what kind of therapy you are having, or what service you're waiting for",
      prompt: {
        id: this.getPromptId("askCurrentMHTreatmentDetails"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleCurrentMHTreatmentDetailsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: EligibilityCheckBradfordScript) => {
    d.state.currentMHTreatmentDetails = [d.response]
    script.referralStore.setCustomField<State>(
      "currentMHTreatmentDetails",
      d.state.currentMHTreatmentDetails
    )
  })
  @step.checkInputForCrisis({
    getNextStep: (s: EligibilityCheckBradfordScript) => s.askExArmedForces
  })
  handleCurrentMHTreatmentDetailsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askExArmedForces }
  }

  @step.logState
  askNHSStaff(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you an Bradford District Care NHS FT member of staff?",
      prompt: {
        id: this.getPromptId("askNHSStaff"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askNHSStaff"
      },
      nextStep: this.handleNHSStaff
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: EligibilityCheckBradfordScript) => {
    d.state.nhsStaff = d.response
    script.referralStore.setCustomField<State>("nhsStaff", d.response)
  })
  handleNHSStaff(_d: IStepData<State, boolean>): IStepResult {
    return {
      nextStep: this.sayIntroToSpineSearch
    }
  }

  @step
  askExArmedForces(d: IStepData<State>): IStepResult {
    const exArmedForcesOptions = this.getExArmedForcesValues(d.state)
    if (!exArmedForcesOptions?.length) {
      this.logBreadcrumb("EX ARMED FORCES OPTIONS NOT FOUND", d.state, { exArmedForcesOptions })
      this.logMessage("EX ARMED FORCES OPTIONS NOT FOUND")
      return { nextStep: this.askNHSStaff }
    }
    return {
      body: "Have you ever served in the British Armed Forces?",
      prompt: {
        id: this.getPromptId("askExArmedForces"),
        trackResponse: true,
        type: "inlinePicker",
        choices: exArmedForcesOptions.map(g => ({ body: g, value: g })),
        dataPointsName: "askExArmedForces"
      },
      nextStep: this.handleExArmedForces
    }
  }

  @step
  async handleExArmedForces(d: IStepData<State, string>): Promise<IStepResult> {
    this.setPeople({ isExArmedForces: d.response })
    d.state.isExArmedForces = d.response
    return { nextStep: this.askNHSStaff }
  }

  @step.logState
  askAreYouInCrisis(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently at risk of harming yourself?",
      prompt: {
        id: this.getPromptId("askAreYouInCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askAreYouInCrisis"
      },
      nextStep: this.handleAreYouInCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canKeepSelfSafe = d.response
  })
  handleAreYouInCrisis(d: IStepData<State, boolean>): IStepResult {
    this.trackUserAsIneligible(d.state, IneligibilityReason.REQUIRES_URGENT_SUPPORT)
    return {
      nextStep: d.response //
        ? this.sayCrisis
        : this.continueEligibilityCheck
    }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const name = this.getName(d.state)
    return {
      body: [
        `Sorry to hear that ${name}`,
        `However ${organisationName} does not provide urgent care`
      ],
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "You can call First Response, our 24/7 crisis service at 0800 9521181",
        "If you need urgent support, please dial NHS 111 and select Option 2",
        "In medical emergency and life threatening situations only, please dial 999, or attend your local A&E department",
        "Other helplines available:\n" +
          "You can contact Samaritans 24 hours a day, 365 days a year. You can call 116 123 (free from any phone) or email jo@samaritans.org\n" +
          "If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258. Shout offers a confidential 24/7 service providing support if you are in need of immediate help"
      ],
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "NHS 111, 999, First Response (0800 9521181), Samaritans, Shout"
    )
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: `Thank you for reaching out. You can refer yourself back into ${organisationName} when you are no longer in crisis`,
      nextStep: this.end,
      clearStack: true
    }
  }

  @step.logState
  continueEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askPostCodeOfUser }
  }

  @step.logState
  sayCouldntFindYouAndCallIntoService(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: `I cannot find your details. Please call ${organisationName} on 01274 221 234 to continue the referral`,
      clearStack: true,
      nextStep: this.end
    }
  }

  @step.logState
  sayICouldntFindYourGP(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(["Found you!", "Hmm, however it looks like I wasn't able to find your GP"]),
      nextStep: this.sayItsImportantToFindGP
    }
  }

  @step.logState
  sayItsImportantToFindGP(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "It's important that we identify your GP in order to find the right mental health service for you"
      ),
      nextStep: this.sayWithoutGPICannotReferYou
    }
  }

  /** Generic Handlers */

  async onFailedSpineSearchCountReached(state: State): Promise<IStepResult> {
    return { nextStep: this.sayCouldntFindYouAndCallIntoService }
  }

  async onPostcodeOfUserSuccessful(_state: State): Promise<IStepResult> {
    return { nextStep: this.askSeriousMentalIllness }
  }

  getExArmedForcesValues(_state: State): string[] {
    return ["Yes - ex-services", "No", "Dependant of an ex-serving member", "Not Stated", "Unknown"]
  }
}

/* istanbul ignore next */
export default class EligibilityCheckBradfordDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckBradford
  readonly name: string = "EligibilityCheckBradfordDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      EligibilityCheckBradfordDialogue.id,
      new EligibilityCheckBradfordScript(),
      state,
      snapshot
    )
  }
}
