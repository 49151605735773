import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"
import { AssessmentWithADSMScript, AssessmentWithADSMScriptState } from "./AssessmentWithADSMScript"

type State = AssessmentWithADSMScriptState
export type AssessmentADSMEveryturnScriptState = State

export class AssessmentADSMEveryturnScript extends AssessmentWithADSMScript {
  readonly name: string = "AssessmentADSMEveryturnScript"

  /** Generic Handlers */

  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReadyForWASAS }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }
}

/* istanbul ignore next */
export default class AssessmentADSMEveryturnDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentADSMEveryturn
  readonly name: string = "AssessmentADSMEveryturnDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentADSMEveryturnDialogue.id, new AssessmentADSMEveryturnScript(), state, snapshot)
  }
}
