import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"
import invariant from "../../../../utils/invariant"

export default class AlcoholPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION> {
  chatFlow = ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION

  /** Overrides */

  getTransformedKeys(transformedPayload?: Record<string, any>): string[] {
    const alcoholKey = this.settings?.alcoholKey
    const alcoholFrequencyKey = this.settings?.alcoholFrequencyKey
    const alcoholQuantityKey = this.settings?.alcoholQuantityKey
    const alcoholImpactsLifeKey = this.settings?.alcoholImpactsLifeKey
    const result: string[] = []

    if (alcoholKey! in (transformedPayload ?? {})) result.push("alcohol")
    if (alcoholFrequencyKey! in (transformedPayload ?? {})) result.push("alcoholFrequency")
    if (alcoholQuantityKey! in (transformedPayload ?? {})) result.push("alcoholQuantity")
    if (alcoholImpactsLifeKey! in (transformedPayload ?? {})) result.push("alcoholImpactsLife")

    return result
  }

  getDefaultPayload(): Partial<ReferralPayload> {
    const alcoholKey = this.getSourceKey("alcoholKey")
    const alcoholFrequencyKey = this.getSourceKey("alcoholFrequencyKey")
    const alcoholQuantityKey = this.getSourceKey("alcoholQuantityKey")
    const alcoholImpactsLifeKey = this.getSourceKey("alcoholImpactsLifeKey")
    invariant(alcoholKey, "couldn't get the mapping key alcoholKey")
    invariant(alcoholFrequencyKey, "couldn't get the mapping key alcoholFrequencyKey")
    invariant(alcoholQuantityKey, "couldn't get the mapping key alcoholQuantityKey")
    invariant(alcoholImpactsLifeKey, "couldn't get the mapping key alcoholImpactsLifeKey")

    const alcohol = this.ctx.state?.[alcoholKey]
    const alcoholFrequency = this.ctx.state?.[alcoholFrequencyKey]
    const alcoholQuantity = this.ctx.state?.[alcoholQuantityKey]
    const alcoholImpactsLife = this.ctx.state?.[alcoholImpactsLifeKey]

    return {
      alcohol,
      alcoholFrequency,
      alcoholQuantity,
      alcoholImpactsLife
    }
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION] = AlcoholPayloadBuilder
