/* eslint-disable @typescript-eslint/no-empty-function */
import moment from "moment"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import SelfReferralIAPTScript from "./SelfReferralIAPTScript"
import invariant from "../../../utils/invariant"
import {
  ARMED_FORCES_MAYDEN_ESSEX,
  DISABILITY_MAYDEN_ESSEX,
  ETHNICITY_MAYDEN_ESSEX,
  GENDER_MAYDEN_ESSEX,
  GenderBirthAssigned,
  LANGUAGE_MAYDEN_ESSEX,
  LIMBIC_IMPACT_LEVEL,
  LTC_MAYDEN_ESSEX,
  NATIONALITY_MAYDEN,
  PERINATAL_MAYDEN_ESSEX,
  ReferralPayloadMaydenEssex,
  RELIGION_MAYDEN_ESSEX,
  SEXUALITY_MAYDEN_ESSEX
} from "@limbic/types"
import { TrackingEvents } from "../../../models/Constants"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isValidPhoneNumber"
import { IStep, IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import {
  disabilities,
  ethnicities,
  exArmedForces,
  genders,
  languages,
  ltcs,
  nationalities,
  perinatalStatuses,
  religions,
  sameGenderAsBirth,
  sexualities
} from "../../../config/referralForms/essex-form"
import { ICollectMainIssueSettings } from "../ad-hoc/CollectMainIssue/CollectMainIssueDialogue"
import {
  CollectGenderScriptState,
  ICollectGenderSettings
} from "../ad-hoc/CollectGender/CollectGenderDialogue"
import { ICollectDisabilitiesSettings } from "../ad-hoc/CollectDisabilities/CollectDisabilitiesDialogue"
import { ICollectReligionSettings } from "../ad-hoc/CollectReligion/CollectReligionDialogue"
import { ICollectEmailSettings } from "../ad-hoc/CollectEmail/CollectEmailDialogue"
import { parsePhoneNumber } from "awesome-phonenumber"

interface State extends SelfReferralIAPTScriptState {
  gpConsent?: boolean
  mainIssue?: string
  sameGenderAsBirth?: string
  relationshipStatus?: string
  currentSupportDetails?: string
  isTakingMedication?: boolean
  disabilities?: string[]
  consentDataShareGPAndNHS?: boolean
  groupInterest?: boolean
  employmentInterest?: boolean
  employmentStatus?: string
  statutorySickPay?: boolean
  consentXyla?: boolean
}

export type SelfReferralEssexState = State

export class SelfReferralEssexScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralEssexScript"

  /** Script Steps */

  @step.logState
  startSelfReferralPart2(_d: IStepData<State>): IStepResult {
    return { nextStep: this.goToCollectMainIssue }
  }

  @step.logState
  askPerinatal(d: IStepData<State>): IStepResult {
    const perinatalStatuses = this.getPerinatalStatuses(d.state)
    if (!perinatalStatuses?.length) {
      this.logBreadcrumb("PERINATAL STATUSES NOT FOUND", d.state, { perinatalStatuses })
      this.logMessage("PERINATAL STATUSES NOT FOUND")
      return { nextStep: this.askExArmedForces }
    }
    return {
      body: this.t(
        "Do any of the following statements about pregnancy or young children apply to you?"
      ),
      prompt: {
        id: this.getPromptId("askPerinatal"),
        trackResponse: true,
        type: "inlinePicker",
        choices: perinatalStatuses.map(g => ({ body: this.t(g), value: g })),
        dataPointsName: "askPerinatal"
      },
      nextStep: this.handlePerinatal
    }
  }

  @step.logState
  askMedication(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are you currently taking any prescribed medication for Mental Health?"),
      prompt: {
        id: this.getPromptId("askMedication"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askMedication"
      },
      nextStep: this.handleMedication
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralEssexScript) => {
    d.state.isTakingMedication = d.response
    script.referralStore.setCustomField<State>("isTakingMedication", d.response)
  })
  handleMedication(d: IStepData<State, boolean>): IStepResult {
    return { nextStep: d.response ? this.askMedicationInfo : this.askCurrentSupport }
  }

  @step.logState
  askMedicationInfo(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Could you let me know the details of the medication you've been prescribed?"),
      prompt: {
        id: this.getPromptId("askMedicationInfo"),
        trackResponse: false,
        type: "text",
        forceValue: false,
        cancelLabel: "skip",
        cancelIsEmptySubmit: true,
        dataPointsName: "askMedicationInfo"
      },
      nextStep: this.handleMedicationInfoWithCrisis
    }
  }

  @step.logState
  askCurrentSupport(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are you currently receiving any other form of mental health support?"),
      prompt: {
        id: this.getPromptId("askCurrentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askCurrentSupport"
      },
      nextStep: this.handleCurrentSupport
    }
  }

  @step.logState
  askCurrentSupportDetails(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Please select from the following"),
      prompt: {
        id: this.getPromptId("askCurrentSupportDetails"),
        type: "inlinePicker",
        choices: [
          { body: this.t("GP only"), value: "GP only" },
          { body: this.t("Consultant Psychiatrist only"), value: "Consultant Psychiatrist only" },
          { body: this.t("Care co-ordinated by CMHT"), value: "Care co-ordinated by CMHT" },
          { body: this.t("Voluntary services support"), value: "Voluntary services support" },
          { body: this.t("Other talking therapy"), value: "Other talking therapy" }
        ],
        dataPointsName: "askCurrentSupportDetails"
      },
      nextStep: this.handleCurrentSupportDetails
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralEssexScript) => {
    d.state.currentSupportDetails = d.response
    script.referralStore.setCustomField<State>("currentSupportDetails", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralEssexScript) => s.askCurrentSupportDetails
  })
  handleCurrentSupportDetails(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askEmploymentStatus }
  }

  @step.logState
  askEmploymentStatus(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Please indicate which of the following options best describes your current employment status"
      ),
      prompt: {
        id: this.getPromptId("askEmploymentStatus"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Employed (Full Time)") },
          { body: this.t("Employed (Part Time)") },
          { body: this.t("Employed (Self)") },
          { body: this.t("Unemployed (Seeking Work)") },
          { body: this.t("Unemployed") },
          { body: this.t("Benefits") },
          { body: this.t("Student (Full Time") },
          { body: this.t("Student (Part Time)") },
          { body: this.t("Homemaker") },
          { body: this.t("Retired") }
        ]
      },
      nextStep: this.handleEmploymentStatus
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, _script: SelfReferralEssexScript) => {
    d.state.employmentStatus = d.response
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralEssexScript) => s.askEmploymentStatus
  })
  handleEmploymentStatus(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askStatutorySickPay }
  }

  @step.logState
  askStatutorySickPay(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are you currently receiving Statutory Sick Pay?"),
      prompt: {
        id: this.getPromptId("askStatutorySickPay"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleStatutorySickPay
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, _script: SelfReferralEssexScript) => {
    d.state.statutorySickPay = d.response
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralEssexScript) => s.askStatutorySickPay
  })
  handleStatutorySickPay(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.goToCollectGoalForTherapy }
  }

  @step.logState
  askConsentForXyla(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "We work closely with our partner Xyla to ensure we can offer assessment appointments as soon as possible",
        "Xyla are able to offer a wider selection of days and times to book your assessment",
        "If suitable, do you consent to be contacted by and assessed through our partner Xyla?"
      ]),
      prompt: {
        id: this.getPromptId("askConsentForXyla"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleConsentForXyla
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, _script: SelfReferralEssexScript) => {
    d.state.consentXyla = d.response
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralEssexScript) => s.askConsentForXyla
  })
  handleConsentForXyla(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.showKeepingSafeInfo }
  }

  /** Generic Handlers */

  async onSubmitReferralData(state: State): Promise<boolean> {
    try {
      const payload = await this.getReferralPayload(state)
      await this.referralStore.createReferral(payload)
      this.referralStore.setShouldHavePatientIdAndInstanceId(true)
      state.patientId = this.referralStore.patientId
      state.signupCode = this.referralStore.signupCode
      this.track(TrackingEvents.SELF_REFERRAL_SUBMITTED)
    } catch (e) {
      this.referralStore.setShouldHavePatientIdAndInstanceId(false)
      this.logException(e, "onSubmitReferralData")
      return false
    }

    return true
  }

  async getReferralPayload(state: State): Promise<ReferralPayloadMaydenEssex> {
    const instanceID = state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile
    const parsed = parsePhoneNumber(state.phoneNumber || "0", { regionCode: "GB" })

    return {
      instanceID,
      serviceApiKey: "essex",
      nhsNumber: state.nhsNumber,
      nameFirst: this.getFirstName(state),
      nameLast: this.getLastName(state),
      nameFirstPreferred: this.getName(state),
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually City and County maybe undefined
        // The backend submission then fails
        // Setting them to "unknown" if undefined
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode,
        consentMail: !!state.canSendMailToAddress
      },
      phoneHome: isValidLandline
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      email: state.email,
      consentEmail: state.canSendEmail,
      problemInOwnWords: state.mainIssue,
      gender: this.getGender(state),
      genderSameAsBirthAssigned: this.getGenderSameAsBirth(state),
      disability: this.getDisability(state),
      adhd: state.hasADHD,
      asd: state.hasASD,
      perinatal: this.getPerinatal(state),
      language: this.getLanguage(state),
      interpreter: state.requiresInterpreter,
      interpreterLanguage: this.getInterpreterLanguage(state),
      title: state.nameTitle,
      ethnicity: this.getEthnicity(state),
      nationality: this.getNationality(state),
      armedForces: this.getArmedForce(state),
      sexuality: this.getSexuality(state),
      religion: this.getReligion(state),
      ltc: this.getLTC(state),
      ltcOtherDetails: state.longTermConditionOther,
      ltcAffectMood: state.ltcAffectsMood,
      ltcMoodImpact: this.getLTCMoodImpact(state),
      ltcManagement: this.getLTCManagement(state),
      substances: state.substances,
      substancesAreMedication: state.substancesAreMedications,
      substancesInfo:
        !state.substancesAreMedications && state.substancesInfo
          ? [state.substancesInfo]
          : undefined,
      // TODO: isTakingMedication needs to be handled
      // There's no property right now to send it
      // medication:
      //   state.substancesAreMedications && state.medicationInfo //
      //     ? [state.medicationInfo]
      //     : undefined,
      // medicationWithinDosage: state.isTakingMedication,
      medicationWithinDosage: state.substancesAreMedications
        ? !!state.medicationWithinDoseRange
        : undefined,
      medication: state.isTakingMedication ? [state.medicationInfo!] : undefined,
      consentDataShare: state.consentDataShareGPAndNHS,
      consentDataStore: true,
      consentResearch: state.consentResearch,
      // When spine it's "odsGP" not "gp" 👇
      gpCodeNACS: state.odsGP?.id ?? state.gp?.nacsCode,
      gpName: state.odsGP?.name ?? state.gp?.name,
      alcohol: state.alcohol,
      alcoholFrequency: state.alcoholFrequency,
      alcoholQuantity: state.alcoholQuantity,
      currentSupport: state.hasCurrentSupport,
      currentSupportDetailsOther: state.currentSupportDetails || undefined,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason ?? "",
      triggerWords: this.clinicalStore.triggerWords,
      clinicalNotes: this.referralStore.clinicalNotes,
      treatmentExpectation: state.therapyGoal,
      employmentStatus: state.employmentStatus,
      statutorySickPay: state.statutorySickPay,
      therapyPreference: [
        ...(state.groupInterest ? ["group"] : []),
        ...(state.employmentInterest ? ["employment"] : [])
      ],
      consentXyla: state.consentXyla
    }
  }

  async getCollectEmailSettings(state: State): Promise<ICollectEmailSettings> {
    return {
      messages: {
        askDoYouWantToShareEmail: this.t(["Do you have an email address?"]),
        askEmailPermission: this.t([
          "Do you allow us to contact you over email (including unencrypted emails when required)?"
        ])
      },
      shouldAskEmailPermission: true,
      hasPreliminaryQuestion: true,
      customisableOptions: {
        askDoYouWantToShareEmail: [
          { body: this.t("Yes, I have an email"), value: true },
          { body: this.t("No, I don't have an email"), value: false }
        ]
      }
    }
  }

  async onCollectEmailEnded(state: State): Promise<IStep> {
    return this.goToCheckPostCodeForAddressLookup
  }

  async getCollectMainIssueSettings(state: State): Promise<ICollectMainIssueSettings> {
    const name = this.getName(state)
    return {
      messages: {
        askMainIssue: this.t(
          [
            "Brilliant",
            "So {name}, please could you describe the main concern or problem that brought you here today (be sure to include specific feelings, behaviours, or thoughts that are bothering you)"
          ],
          { name }
        ),
        closingMessage: this.t(["Thanks for sharing {name}"], { name })
      }
    }
  }

  async onCollectMainIssueEnded(state: State): Promise<IStep> {
    return this.goToCollectGender
  }

  async getCollectReligionSettings(state: State): Promise<ICollectReligionSettings> {
    const optionsReligions = this.getReligions(state).map(g => ({ body: g, value: g }))

    return {
      options: optionsReligions
    }
  }

  async getCollectGenderSettings(state: State): Promise<ICollectGenderSettings> {
    const optionsGender = this.getGenders(state).map(g => ({ body: this.t(g), value: g }))
    const optionsGenderSameAsBirth = this.getGenderSameAsBirthValues(state) //
      .map(g => ({ body: this.t(g), value: g }))
    // This is temporary - to be replaced by actual values when we have the dashboard
    return { optionsGender, optionsGenderSameAsBirth }
  }

  async getCollectGenderState(state: State): Promise<CollectGenderScriptState> {
    return { skipSameGenderAsBirth: false }
  }

  async onCollectGenderEnded(state: State): Promise<IStep> {
    return this.goToCollectSexuality
  }

  async onCollectSexualityEnded(state: State): Promise<IStep> {
    return this.goToCollectEthnicity
  }

  async onCollectEthnicityEnded(state: State): Promise<IStep> {
    return this.goToCollectNationality
  }

  async onCollectNationalityEnded(state: State): Promise<IStep> {
    return this.goToCollectLanguageAndInterpreter
  }

  async onCollectLanguageAndInterpreterEnded(state: State): Promise<IStep> {
    return this.goToCollectReligion
  }

  async onCollectReligionEnded(_state: State): Promise<IStep> {
    return this.askPerinatal
  }

  async onHandlePerinatal(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askExArmedForces }
  }

  async onHandleExArmedForces(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.goToCollectLongTermMedicalConditionDetails }
  }

  async onCollectLongTermMedicalConditionEnded(state: State): Promise<IStep> {
    return this.goToCollectDisabilities
  }

  async getCollectDisabilitiesSettings(state: State): Promise<ICollectDisabilitiesSettings> {
    return {
      choicesMap: this.getDisabilities(state).map(d => ({ body: this.t(d), value: d })),
      shouldAskDisabilityStatus: false
    }
  }

  async getCollectNationalitySettings(state: State): Promise<ICollectDisabilitiesSettings> {
    const nationalities = this.getNationalities(state)
    return {
      options: nationalities.map(n => ({ body: this.t(n), value: n }))
    }
  }

  async onCollectDisabilitiesEnded(state: State): Promise<IStep> {
    return this.goToCollectASD
  }

  async onCollectADHDEnded(state: State): Promise<IStep> {
    return this.goToCollectAlcoholConsumption
  }

  async onHandleSubstances(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: state.substances //
        ? this.askSubstancesOrigin
        : this.askMedication
    }
  }

  async onHandleSubstancesInfo(_state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.askMedication
    }
  }

  async onHandleMedicationWithinDoseRange(_state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.askMedication
    }
  }

  async onHandleCurrentSupport(state: State): Promise<IStepResult<State>> {
    if (state.hasCurrentSupport) {
      return {
        nextStep: this.askCurrentSupportDetails
      }
    }
    return {
      nextStep: this.askEmploymentStatus
    }
  }

  async onCollectGoalForTherapyEnded(state: State): Promise<IStep> {
    return this.askConsentForXyla
  }

  getGender(state: State): GENDER_MAYDEN_ESSEX {
    const gender = genders[state.gender!]
    return gender ?? "UNKNOWN"
  }

  getGenderSameAsBirth(state: State): GenderBirthAssigned {
    const genderSameAsBirth = sameGenderAsBirth[state.sameGenderAsBirth!]
    return genderSameAsBirth ?? "UNKNOWN"
  }

  getSexuality(state: State): SEXUALITY_MAYDEN_ESSEX {
    const sexuality = sexualities[state.sexuality!]
    return sexuality ?? "UNKNOWN"
  }

  getEthnicity(state: State): ETHNICITY_MAYDEN_ESSEX {
    const ethnicity = ethnicities[state.ethnicity!]
    return ethnicity ?? "UNKNOWN"
  }

  getLanguage(state: State): LANGUAGE_MAYDEN_ESSEX | undefined {
    return languages[state.primaryLanguage!] ?? "NOT_ANSWERED"
  }

  getInterpreterLanguage(state: State): LANGUAGE_MAYDEN_ESSEX | undefined {
    return languages[state.interpreterLanguage!] ?? "NOT_ANSWERED"
  }

  getReligion(state: State): RELIGION_MAYDEN_ESSEX {
    const religion = religions[state.religion!]
    return religion ?? "NOT_ANSWERED"
  }

  getPerinatal(state: State): PERINATAL_MAYDEN_ESSEX {
    const perinatal = perinatalStatuses[state.perinatalStatus!]
    return perinatal ?? "NONE"
  }

  getArmedForce(state: State): ARMED_FORCES_MAYDEN_ESSEX {
    const armedForces = exArmedForces[state.isExArmedForces!]
    return armedForces ?? "UNKNOWN"
  }

  getLTC(state: State): LTC_MAYDEN_ESSEX[] | undefined {
    const ltc = state.longTermMedicalCondition?.map(i => ltcs[i]).filter(Boolean)
    return ltc?.length ? ltc : undefined
  }

  getLTCMoodImpact(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      somewhat: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcMoodImpact!]
  }

  getLTCManagement(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      fairly: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcManagement!]
  }

  getDisability(state: State): DISABILITY_MAYDEN_ESSEX[] {
    if (!state.disabilities?.length) {
      this.logBreadcrumb("getDisability without answer", state)
      this.logMessage("getDisability without answer")
    }

    return state.disabilities
      ? state.disabilities?.map(disability => disabilities[disability]).filter(Boolean)
      : ["NONE"]
  }

  getNationality(state: State): NATIONALITY_MAYDEN | undefined {
    return nationalities[state.nationality || "Other"]
  }
}

export default class SelfReferralEssexDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralEssex
  readonly name: string = "SelfReferralEssexDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralEssexDialogue.id, new SelfReferralEssexScript(), state, snapshot)
  }
}
