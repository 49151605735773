import _isOnline from "is-online"

// this function is a wrapper around the is-online package
// so that 1) we can mock it in our tests and 2) we can catch
// any errors that might be thrown by the package for example
// known issues with requests made by the browser like Load Failed
//
// Might not be really needed but since we also get the ability
// to mock it in tests, it doesn't hurt to have it
export async function isOnline(): Promise<boolean> {
  return await _isOnline().catch(() => false)
}
