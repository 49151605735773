import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class DisabilityPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_DISABILITY> {
  chatFlow = ChatFlowsEnum.COLLECT_DISABILITY

  /** Overrides */

  getDefaultPayload(): Partial<ReferralPayload> {
    const payload: Partial<ReferralPayload> = {
      disability: this.ctx.state?.disability || this.ctx.state?.disabilities
    }

    if (this.ctx.state?.disabilityOther) {
      payload.disabilityOther = this.ctx.state?.disabilityOther
    }

    return payload
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_DISABILITY] = DisabilityPayloadBuilder
