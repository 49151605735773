import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class MainIssuePayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_MAIN_ISSUE> {
  chatFlow = ChatFlowsEnum.COLLECT_MAIN_ISSUE

  /** Overrides */

  getDefaultPayload(): Partial<ReferralPayload> {
    return { problemInOwnWords: this.ctx.state?.mainIssue } as Partial<ReferralPayload>
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_MAIN_ISSUE] = MainIssuePayloadBuilder
