import ISurvey, { SurveyType } from "../../models/ISurvey"
import createSurveyDialogue from "../createSurveyDialogue"
import { DialogueIDs } from "../DialogueIDs"

const SCOFF: ISurvey = {
  questions: [
    {
      id: "1",
      questionName: "SCOFF - step 1",
      question: "1. Do you make yourself Sick because you feel uncomfortably full?",
      type: "inline",
      progress: 0.2,
      answers: [
        { points: 1, answer: "Yes" },
        { points: 0, answer: "No" }
      ]
    },
    {
      id: "2",
      questionName: "SCOFF - step 2",
      question: "2. Do you worry you have lost Control over how much you eat?",
      type: "inline",
      progress: 0.4,
      answers: [
        { points: 1, answer: "Yes" },
        { points: 0, answer: "No" }
      ]
    },
    {
      id: "3",
      questionName: "SCOFF - step 3",
      question: "3. Have you recently lost more than One stone (6.35 kg) in a three-month period?",
      type: "inline",
      progress: 0.6,
      answers: [
        { points: 1, answer: "Yes" },
        { points: 0, answer: "No" }
      ]
    },
    {
      id: "4",
      questionName: "SCOFF - step 4",
      question: "4. Do you believe yourself to be Fat when others say you are too thin?",
      type: "inline",
      progress: 0.8,
      answers: [
        { points: 1, answer: "Yes" },
        { points: 0, answer: "No" }
      ]
    },
    {
      id: "5",
      questionName: "SCOFF - step 5",
      question: "5. Would you say Food dominates your life?",
      type: "inline",
      progress: 1,
      answers: [
        { points: 1, answer: "Yes" },
        { points: 0, answer: "No" }
      ]
    }
  ]
}

export default createSurveyDialogue(DialogueIDs.SCOFF, SCOFF, SurveyType.SCOFF)
