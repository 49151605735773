import * as self from "./address"
import { API_KEY } from "../../../config/config"
import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import { isOnline } from "../../../utils/isOnline"
import {
  COUNTRIES_MAP,
  IUSAddress,
  US_STATES_AND_TERRITORIES,
  IAutocompletePrediction
} from "@limbic/types"
import invariant from "../../../utils/invariant"
import client from "./_client"

type GeocoderAddressComponent = google.maps.GeocoderAddressComponent
type PlaceResult = google.maps.places.PlaceResult

export enum AddressAPIsRequestStatus {
  Success = "SUCCESS",
  RequestFailed = "REQUEST_FAILED",
  Aborted = "REQUEST_ABORTED",
  NoInternetConnection = "NO_INTERNET_CONNECTION"
}

const TOTAL_RETRIES = 2

const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

export async function getAddressAutocomplete(
  query: string,
  abortController = new AbortController(),
  retry = 0
): Promise<[IAutocompletePrediction[] | undefined, AddressAPIsRequestStatus]> {
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) return [undefined, AddressAPIsRequestStatus.NoInternetConnection]

    const signal = abortController.signal
    const data = await client.get(
      `/v1/addressAutoComplete/predictions/${query}`,
      undefined,
      undefined,
      signal
    )

    return [data.predictions, AddressAPIsRequestStatus.Success]
  } catch (e) {
    if (e.name === "AbortError") {
      Logger.getInstance().breadcrumb({
        message: "getAddressAutocomplete fetch aborted",
        data: { query }
      })
      return [undefined, AddressAPIsRequestStatus.Aborted]
    }
    Logger.getInstance().breadcrumb({
      message: "getAddressAutocomplete fetch failed",
      data: { query }
    })
    Logger.getInstance().exception(e, "getAddressAutocomplete fetch failed")
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("getAddressAutocomplete retry")
      await delay(1)
      return await self.getAddressAutocomplete(query, abortController, retry + 1)
    }

    return [undefined, AddressAPIsRequestStatus.RequestFailed]
  }
}

export async function getPlaceDetails(
  placeID: string,
  abortController = new AbortController(),
  retry = 0
): Promise<[IUSAddress | undefined, AddressAPIsRequestStatus]> {
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) return [undefined, AddressAPIsRequestStatus.NoInternetConnection]

    const signal = abortController.signal
    const data = await client.get(
      `/v1/addressAutoComplete/placeDetails/${placeID}`,
      undefined,
      undefined,
      signal
    )

    return [googlePlaceResultToIUSAddress(data.results?.[0]), AddressAPIsRequestStatus.Success]
  } catch (e) {
    if (e.name === "AbortError") {
      Logger.getInstance().breadcrumb({
        message: "getPlaceDetails fetch aborted",
        data: { placeID }
      })
      return [undefined, AddressAPIsRequestStatus.Aborted]
    }
    Logger.getInstance().breadcrumb({
      message: "getPlaceDetails fetch failed",
      data: { placeID }
    })
    Logger.getInstance().exception(e, "getPlaceDetails fetch failed")
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("getPlaceDetails retry")
      await delay(1)
      return await self.getPlaceDetails(placeID, abortController, retry + 1)
    }

    return [undefined, AddressAPIsRequestStatus.RequestFailed]
  }
}

const REQUIRED_ADDRESS_COMPONENTS = [
  "street_number",
  "route",
  "locality",
  "administrative_area_level_1",
  "postal_code",
  "country"
]

export function googlePlaceResultToIUSAddress(placeResult?: PlaceResult): IUSAddress | undefined {
  invariant(placeResult, "placeResult not provided")
  const components = placeResult.address_components ?? []
  const componentTypes = new Set(components.map((c: GeocoderAddressComponent) => c.types).flat())
  const missingComps = REQUIRED_ADDRESS_COMPONENTS.filter(req => !componentTypes.has(req))
  invariant(!missingComps.length, `Missing address components: ${missingComps.join(", ")}`)

  const address: Partial<IUSAddress> = {}

  components.forEach(component => {
    switch (component.types?.[0]) {
      case "street_number":
        address["address1"] = component.long_name ?? ""
        break
      case "route":
        address["address1"] = `${address["address1"]} ${component.long_name}` ?? ""
        break
      case "locality":
        address["city"] = component.long_name ?? ""
        break
      case "administrative_area_level_1":
        // intentionally not typed to US_STATES_AND_TERRITORIES to allow for non-US addresses
        address["state"] = component.short_name as keyof typeof US_STATES_AND_TERRITORIES
        break
      case "postal_code":
        address["zipcode"] = component.short_name
        break
      case "country":
        address["country"] = component.short_name as keyof typeof COUNTRIES_MAP
        break
    }
  })

  return { ...address, consentMail: false }
}
