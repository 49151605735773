import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IDefaultChatFlowMessagesRiskPathway, RiskPathwayType } from "@limbic/types"
import invariant from "../../../../src/utils/invariant"
import { IPersistableSurveyResponse } from "../../../models/ISurvey"
import dialoguesRegistry from "../../dialoguesRegistry"
import { SurveyScriptState } from "../../createSurveyDialogue"

interface ICanKeepSelfSafeResponse {
  id: string
  title: string
  responseKey: keyof State
}

const CAN_KEEP_SELF_SAFE_RESPONSE_VALUES: ICanKeepSelfSafeResponse = {
  id: "10",
  title:
    "Are you able to keep yourself, and any dependants in your care, safe until your appointment?",
  responseKey: "riskPathwayResponses"
}

const botConfigVersion = process.env.REACT_APP_BOT_VERSION ?? "draft"
invariant(
  ["published", "draft"].includes(botConfigVersion),
  "REACT_APP_BOT_VERSION must be 'draft' or 'published'"
)

export type IRiskPathwaySettings = {
  crisisNumbersShared?: string
  riskPathwayType?: RiskPathwayType
  messages?: IDefaultChatFlowMessagesRiskPathway
}

type State = RiskPathwayScriptState

export type RiskPathwayDynamicScriptState = State

export class RiskPathwayDynamicScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayDynamicScript"

  onHandleCSSRSResponses?(state: State): Promise<IStepResult | void>

  protected crisisNumbersShared: string | undefined
  protected riskPathwayType: RiskPathwayType
  protected messages: IDefaultChatFlowMessagesRiskPathway | undefined

  constructor(settings: IRiskPathwaySettings | undefined = {}) {
    super()
    this.crisisNumbersShared = settings.crisisNumbersShared
    this.riskPathwayType = settings.riskPathwayType ?? RiskPathwayType.DEFAULT
    this.messages = settings?.messages ?? {}
  }

  /** Script Steps */

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    const shouldGoToCSSRS = this.riskPathwayType === RiskPathwayType.CSSRS
    return { nextStep: shouldGoToCSSRS ? this.goToCSSRSSurvey : this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.askCanYouKeepYourselfSafe ??
          "Are you able to keep yourself, and any dependants in your care, safe until your appointment?",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    CAN_KEEP_SELF_SAFE_RESPONSE_VALUES.id,
    CAN_KEEP_SELF_SAFE_RESPONSE_VALUES.title,
    CAN_KEEP_SELF_SAFE_RESPONSE_VALUES.responseKey,
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>, script: RiskPathwayDynamicScript) => {
    d.state.canKeepSelfSafe = d.response
    script.referralStore.setCustomField<State>("canKeepSelfSafe", d.response)
  })
  async handleCanYouKeepYourselfSafe(d: IStepData<State>): Promise<IStepResult> {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.sayCrisisRiskPathway ?? [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However, this is not an emergency response service",
          "I'll make sure to notify {serviceName} of this in the referral"
        ],
        this.getContext(d.state)
      ),
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messages?.sayCrisisNumbersRiskPathway ?? [
          "To get more appropriate help, you can call NHS 111 and selection Option 2",
          "If you need urgent, life threatening medical help please call 999",
          "Alternatively, you can also call Samaritans on 116 123"
        ],
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      this.crisisNumbersShared ?? "999, NHS 111 and Samaritans (116 123)"
    )
    return {
      body: this.t(
        this.messages?.sayLetsCarryOn ?? "Ok, let's carry on with the mental health check in",
        this.getContext(d.state)
      ),
      nextStep: this.closeDialogue
    }
  }

  @step.logState
  goToCSSRSSurvey(d: IStepData<State>): IStepResult {
    // we don't need to pass settings here because the assessment
    // preset flow that wil have the risk pathway type as a setting
    // will not include settings to customise the CSSRS survey
    const CSSRSDialogue = dialoguesRegistry.get(DialogueIDs.CSSRS)
    return {
      nextDialogue: new CSSRSDialogue({ ...d.state }),
      nextStep: this.handleCSSRSSurvey
    }
  }

  @step.logStateAndResponse
  async handleCSSRSSurvey(d: IStepData<State, undefined, SurveyScriptState>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handleCSSRSResponses?.(d.state)
    const result = await this.onHandleCSSRSResponses?.(d.state)
    if (result) return result

    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }

  handleCSSRSResponses(state: State): void {
    this.sendCSSRS(state)
  }

  getContext(state: State): Record<string, any> {
    return {
      ...this.rootStore.configStore,
      name: this.getName(state),
      iaptName: this.getIAPTName(state)
    }
  }

  saveResponse<T extends IPersistableSurveyResponse>(item: T, state: State): void {
    const newItem = { ...item }
    /**
     * With the item.id check we ensure that the correct question is being modified
     * (in case we add more questions in the future)
     */
    if (
      item.id === CAN_KEEP_SELF_SAFE_RESPONSE_VALUES?.id &&
      this.messages?.askCanYouKeepYourselfSafe
    ) {
      const title =
        this.messages?.askCanYouKeepYourselfSafe?.join(", ") ||
        CAN_KEEP_SELF_SAFE_RESPONSE_VALUES.title
      newItem.title = title as string
    }
    super.saveResponse(newItem, state, CAN_KEEP_SELF_SAFE_RESPONSE_VALUES.responseKey)
  }
}

export default class RiskPathwayDynamicDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayDynamic
  readonly name: string = "RiskPathwayDynamicDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>, settings?: IRiskPathwaySettings) {
    super(
      RiskPathwayDynamicDialogue.id,
      new RiskPathwayDynamicScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
