import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import AssessmentIAPTScript from "./AssessmentIAPTScript"
import type { AssessmentIAPTScriptState } from "./AssessmentIAPTScript"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"

type State = AssessmentIAPTScriptState

export class AssessmentDemoScript extends AssessmentIAPTScript {
  readonly name: string = "AssessmentDemoScript"

  /** Generic Handlers */

  async onFinishAssessment(state: State): Promise<IStepResult> {
    return { nextStep: this.end }
  }
}

/* istanbul ignore next */
export default class AssessmentDemoDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentDemo
  readonly name: string = "AssessmentDemoDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentDemoDialogue.id, new AssessmentDemoScript(), state, snapshot)
  }
}
