import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import { isOnline } from "../../../utils/isOnline"
import { SubmitReferralStatus } from "../../../models/SubmitReferral"
import client from "./_client"

const TOTAL_RETRIES = 3
const PATH = "/v1/insight/referral/submit"
const DEMO_PATH = "/v1/insight/demo/referral/submit"

export async function submitInsightReferral(
  isDemo: boolean,
  patientId: string,
  retry = 0
): Promise<[boolean, SubmitReferralStatus]> {
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) return [false, SubmitReferralStatus.NoInternetConnection]

    const headers = client.headers({ "x-api-key": client.getTimedResourceAPIKey(patientId) })
    const url = isDemo ? DEMO_PATH : PATH
    const data = await client.post(url, undefined, undefined, headers)

    const isSubmitSuccessful = data?.status === "SUCCESS"
    return [isSubmitSuccessful, SubmitReferralStatus.Success]
  } catch (e) {
    Logger.getInstance().breadcrumb({
      message: "submitInsightReferral fetch failed",
      data: { id: patientId }
    })
    Logger.getInstance().exception(e, "submitInsightReferral fetch failed")
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("submitInsightReferral retry")
      await delay(1)
      return await submitInsightReferral(isDemo, patientId, retry + 1)
    }
    return [false, SubmitReferralStatus.RequestFailed]
  }
}
