import type { ITreatmentOption } from "@limbic/types"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import {
  TreatmentOptionsIAPTScript,
  TreatmentOptionsIAPTScriptState
} from "./TreatmentOptionsIAPTDialogue"
import { InsightTreatmentOptions, TrackingEvents } from "../../../models/Constants"
import { step } from "../../../backend/chatbot/decorators/step"
import formatUnicorn from "../../../utils/formatUnicorn"
import {
  getInsightAppointments,
  reserveAppointment
} from "../../../backend/api/limbic/insightAppointments"
import { IAppointmentStatus } from "../../../models/IAppointment"
import { submitInsightReferral } from "../../../backend/api/limbic/submitInsightReferral"
import type { IStep, IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IAPTIDs } from "../../../models/IIAPTService"

interface WindowWithLimbicNameSpace extends Window {
  APPOINTMENT_BOOKING: string
}

declare let window: WindowWithLimbicNameSpace

window.APPOINTMENT_BOOKING = String(process.env.REACT_APP_APPOINTMENT_BOOKING ?? "enabled")

const ENABLE_APPOINTMENTS = window.APPOINTMENT_BOOKING !== "disabled"
const DISABLED_APPOINTMENT_BOOKING = [IAPTIDs.INSIGHT_DERBY, IAPTIDs.EVERYTURN_DERBY]

interface State extends TreatmentOptionsIAPTScriptState {
  appointmentID?: string
  appointment?: string
  tempAppointment?: string
  retryBookAppointment?: number
  retryReserveAppointment?: number
}

export type TreatmentOptionsInsightScriptState = State

// TODO: clean this dialogue up
//       - treatment options order is IESO, webinar, cCBT, GSH
//       - if user is shown the webinar option, they can't decline it
//       - if user declines cCBT then they should book an 1:1 appointment
//         and the guided self-help option should be auto-selected
//       - IESO and webinar options have their own eligibility criteria.
//         IESO is only for Kent and Medway users, and webinar is only
//         for users who have an email, agreed to be contacted by email,
//         and don't require an interpreter

export class TreatmentOptionsInsightScript extends TreatmentOptionsIAPTScript {
  readonly name: string = "TreatmentOptionsInsightScript"

  /** Script Steps */

  @step.logState
  suggestWebinar(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const serviceName = this.rootStore.configStore.serviceName
    const currentIndex = d.state.currentTreatmentOptionIndex!
    const treatmentOption = this.rootStore.clinicalStore.treatmentOptions[currentIndex]
    const treatmentOptionName = treatmentOption.formattedName
    const isFirst = d.state.totalTreatmentsOffered === 0
    const ctx = { name, organisationName, iaptName }
    const explainer = (treatmentOption.explainer || []).map(i => formatUnicorn(i, ctx))
    const text = isFirst
      ? "Based on your answers to the assessment questions, we feel that the best place to start your psychological journey with {serviceName} is by attending a {treatmentOptionName}"
      : "The alternative treatment option for you is the {treatmentOptionName}"

    const canBookAppointment =
      ENABLE_APPOINTMENTS &&
      d.state.patientId &&
      !DISABLED_APPOINTMENT_BOOKING.includes(d.state.iapt?.id as any)
    return {
      body: this.t(
        [
          text,
          ...explainer,
          !canBookAppointment &&
            "One of {serviceName}'s practitioners will call you to discuss this. The {serviceName} team will be in touch to organise a time and date for this call"
        ].filter(Boolean),
        { serviceName, treatmentOptionName }
      ),
      prompt: {
        id: this.getPromptId(`suggestCurrentTreatmentOption-${treatmentOption.name}`),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: true },
          { body: this.t("Can you explain more?"), value: "explain" }
        ]
      },
      nextStep: this.handleCurrentTreatmentOption
    }
  }

  @step.logState
  suggestDigitalTherapyOr1to1(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const serviceName = this.rootStore.configStore.serviceName
    const currentIndex = d.state.currentTreatmentOptionIndex!
    const treatmentOption = this.rootStore.clinicalStore.treatmentOptions[currentIndex]
    const treatmentOptionName = treatmentOption.formattedName
    const isFirst = d.state.totalTreatmentsOffered === 0
    const ctx = { name, organisationName, iaptName }
    const explainer = (treatmentOption.explainer || []).map(i => formatUnicorn(i, ctx))
    const text = isFirst
      ? "Based on your answers to the assessment questions, we feel that the best place to start your psychological journey is either using {serviceName}'s {treatmentOptionName} platform or attending some 1:1 sessions with one of {serviceName}'s' 'Psychological Wellbeing Practitioners'"
      : "The alternative treatment option for you is either using {serviceName}'s {treatmentOptionName} platform or attending some 1:1 sessions with one of {serviceName}'s' 'Psychological Wellbeing Practitioners'"

    return {
      body: this.t(
        // prettier-ignore
        [text, ...explainer, "Would you like to go ahead with the {treatmentOptionName}?"].filter(Boolean),
        { serviceName, treatmentOptionName }
      ),
      prompt: {
        id: this.getPromptId(`suggestCurrentTreatmentOption-${treatmentOption.name}`),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false },
          { body: this.t("Can you explain more?"), value: "explain" }
        ]
      },
      nextStep: this.handleCurrentTreatmentOption
    }
  }

  @step.logState
  async suggestCurrentTreatmentOption(d: IStepData<State>): Promise<IStepResult> {
    const currentIndex = d.state.currentTreatmentOptionIndex!
    const treatmentOption = this.rootStore.clinicalStore.treatmentOptions[currentIndex]

    const isWebinar = treatmentOption?.name === "webinar"
    if (isWebinar) return { nextStep: this.suggestWebinar }

    const isDigitalTherapy = treatmentOption?.name === "ccbt"
    if (isDigitalTherapy) return { nextStep: this.suggestDigitalTherapyOr1to1 }

    return super.suggestCurrentTreatmentOption(d)
  }

  @step.logState
  @step.startTyping
  @step.delay(3)
  async sayAcceptedTreatmentOption(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const currentIndex = d.state.currentTreatmentOptionIndex!
    const current = this.rootStore.clinicalStore.treatmentOptions[currentIndex]
    const isWebinar = current.name === "webinar"
    const isIESO = current.name === "ieso"

    this.rootStore.clinicalStore.acceptTreatment(current)
    this.setTreatmentAccepted(d.state, current, true)
    this.setTreatment(d.state, current)
    this.updateReferralType(d.state)
    this.referralStore.addClinicalNote(
      `Assigned Treatment: ${
        isIESO
          ? InsightTreatmentOptions.IESO
          : isWebinar
            ? InsightTreatmentOptions.WEBINAR
            : InsightTreatmentOptions.DIGITAL_THERAPY
      }`
    )
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName

    const canBookAppointment =
      ENABLE_APPOINTMENTS &&
      !isIESO &&
      !DISABLED_APPOINTMENT_BOOKING.includes(d.state.iapt?.id as any)
    if (canBookAppointment) {
      this.referralStore.setIdleSubmissionActive(false)
      const isDemo = this.referralStore.instanceID === "INSIGHT_E2E_DEMO"
      const [isSubmitSuccessful] = await submitInsightReferral(isDemo, d.state.patientId || "")
      if (isSubmitSuccessful) {
        return {
          body: this.t([!isWebinar ? "Ok, great" : undefined].filter(Boolean)),
          nextStep: this.bookAppointment
        }
      }
      // if the submission failed, then reactivate the idle submission
      this.referralStore.setIdleSubmissionActive(true)
    }
    return {
      body: this.t(
        [
          !isWebinar ? "Great - it's really good you're open to trying this, {name}" : undefined,
          ...(current.acceptedResponse ?? [])
        ],
        { name, organisationName, iaptName }
      ),
      nextStep: this.end
    }
  }

  @step.logState
  @step.startTyping
  @step.delay(3)
  async sayDeclinedTreatmentOption(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const currentIndex = d.state.currentTreatmentOptionIndex!
    const current = this.rootStore.clinicalStore.treatmentOptions[currentIndex]
    this.rootStore.clinicalStore.declineTreatment(current)

    // INSIGHT has requested if the user rejects digital therapy to book 1:1 therapy
    // Index 3 is the self-help therapy
    if (currentIndex === 2) {
      const alternativeTreatment = this.rootStore.clinicalStore.treatmentOptions[3]
      this.rootStore.clinicalStore.acceptTreatment(alternativeTreatment)
      this.setTreatmentAccepted(d.state, alternativeTreatment, true)
      this.setTreatment(d.state, alternativeTreatment)
      this.referralStore.addClinicalNote(`Assigned Treatment: ${InsightTreatmentOptions.GSH}`)
    }
    this.updateReferralType(d.state)
    const serviceName = this.rootStore.configStore.serviceName
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName

    const acceptedTreatment = this.rootStore.clinicalStore.getAcceptedTreatment()
    const isIESO = acceptedTreatment?.name === "ieso" || current.name === "ieso"
    const canBookAppointment =
      ENABLE_APPOINTMENTS &&
      !isIESO &&
      !DISABLED_APPOINTMENT_BOOKING.includes(d.state.iapt?.id as any)

    if (canBookAppointment) {
      const isDemo = this.referralStore.instanceID === "INSIGHT_E2E_DEMO"
      const [isSubmitSuccessful] = await submitInsightReferral(isDemo, d.state.patientId || "")
      if (isSubmitSuccessful) {
        this.referralStore.setIdleSubmissionActive(false)
        return {
          body: this.t(
            [
              "That's okay {name}",
              "One of {serviceName}'s practitioners will speak to you to discuss the most appropriate treatment option for you"
            ],
            { name, serviceName }
          ),
          nextStep: this.bookAppointment
        }
      }
      // if the submission failed, then reactivate the idle submission
      this.referralStore.setIdleSubmissionActive(false)
    }

    if (currentIndex === 2) {
      return {
        body: this.t(["That's okay {name}", ...(current.declinedResponse ?? [])], {
          name,
          organisationName,
          iaptName
        }),
        nextStep: this.end
      }
    }

    return super.sayDeclinedTreatmentOption(d)
  }

  @step.logState
  async bookAppointment(d: IStepData<State>): Promise<IStepResult> {
    if (d.state.retryReserveAppointment === undefined) d.state.retryReserveAppointment = 0
    const isDemo = this.referralStore.instanceID === "INSIGHT_E2E_DEMO"
    const [appointments, appointmentsStatus] = await getInsightAppointments(
      isDemo,
      d.state.patientId || ""
    )
    const serviceName = this.rootStore.configStore.serviceName

    if (appointmentsStatus === IAppointmentStatus.NoInternetConnection) {
      return { nextStep: this.sayNoInternetConnectionGetAppointments }
    }

    if (appointmentsStatus === IAppointmentStatus.RequestFailed) {
      return {
        nextStep: this.saySomethingWentWrongRetrievingAppointments
      }
    }

    if (appointments?.length === 0 && appointmentsStatus === IAppointmentStatus.Success) {
      return {
        body: this.t(
          "One of {serviceName}'s practitioners will call you to discuss this. The {serviceName} team will be in touch to organise a time and date for this call",
          { serviceName }
        ),
        nextStep: this.end
      }
    }

    this.track(TrackingEvents.APPOINTMENTS_PROVIDED)

    return {
      body: this.t(
        "Please choose a date and time that works best for you to have a 15 minute call with one of our team"
      ),
      prompt: {
        id: this.getPromptId("bookAppointment"),
        type: "appointmentLegacy",
        appointments: appointments || [],
        isUndoAble: true
      },
      nextStep: this.confirmAppointment
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.tempAppointment = d.response
  })
  async confirmAppointment(d: IStepData<State, string>): Promise<IStepResult> {
    return {
      body: this.t([
        "You have selected the following date and time",
        d.response.split("_")[1],
        "Is this correct?"
      ]),
      prompt: {
        id: this.getPromptId("confirmAppointment"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No, let me change it"), value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleAppointment
    }
  }

  @step.logState
  async handleAppointment(d: IStepData<State, string>): Promise<IStepResult> {
    if (d.response) {
      const [_id, appointment] = d.state.tempAppointment?.split("_") || []
      d.state.appointmentID = _id
      const isDemo = this.referralStore.instanceID === "INSIGHT_E2E_DEMO"
      const [reservedAppointment, reservedAppointmentStatus] = await reserveAppointment(
        isDemo,
        d.state.patientId || "",
        d.state.appointmentID || _id || ""
      )

      if (reservedAppointmentStatus === IAppointmentStatus.NoInternetConnection) {
        return { nextStep: this.sayNoInternetConnectionReserveAppointment }
      }

      if (reservedAppointment) {
        d.state.appointment = appointment
        this.track(TrackingEvents.APPOINTMENT_BOOKED)
        this.referralStore.addClinicalNote(`Appointment: ${appointment}`)
        this.rootStore.clearUndoHistory()
        return { nextStep: this.end }
      }
      if (!reservedAppointment || reservedAppointmentStatus === IAppointmentStatus.RequestFailed) {
        this.track(TrackingEvents.APPOINTMENT_BOOKING_FAILED)
        d.state.tempAppointment = undefined
        if (d.state.retryReserveAppointment === undefined) d.state.retryReserveAppointment = 0
        d.state.retryReserveAppointment = d.state.retryReserveAppointment + 1
        if (d.state.retryReserveAppointment > 2) {
          return { nextStep: this.sayAppointmentBookingFailed }
        }
      }
      return {
        body: this.t([
          "Hmmm... something went wrong while booking your appointment",
          "Please try again"
        ]),
        nextStep: this.bookAppointment
      }
    }
    d.state.tempAppointment = undefined
    return { nextStep: this.bookAppointment }
  }

  @step.logState
  sayAppointmentBookingFailed(d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "Sorry {name}, we're encountering a persistent issue when trying to confirm your appointment booking",
          "Your referral has been submitted successfully, so we'll ask one of the {serviceName} team to give you a call to organise your appointment"
        ],
        { name, serviceName }
      ),
      nextStep: this.end
    }
  }

  @step.logState
  saySomethingWentWrongRetrievingAppointments(d: IStepData<State>): IStepResult {
    if (d.state.retryBookAppointment === undefined) d.state.retryBookAppointment = 0
    d.state.retryBookAppointment = d.state.retryBookAppointment + 1

    if (d.state.retryBookAppointment === 2) {
      const serviceName = this.rootStore.configStore.serviceName
      const name = this.getName(d.state)
      return {
        body: this.t(
          [
            "Sorry {name}, we're encountering a persistent issue when trying to load the available appointments",
            "One of {serviceName}'s practitioners will call you to discuss this. The {serviceName} team will be in touch to organise a time and date for this call"
          ],
          { name, serviceName }
        ),
        nextStep: this.end
      }
    }
    return {
      body: this.t([
        "Hmmm... something went wrong while loading the available appointments",
        "Please try again"
      ]),
      prompt: {
        id: this.getPromptId("saySomethingWentWrongRetrievingAppointments"),
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }]
      },
      nextStep: this.bookAppointment
    }
  }

  @step.logState
  sayNoInternetConnectionGetAppointments(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Hmmm, it looks like you're not connected to the internet"),
      prompt: {
        id: this.getPromptId("sayNoInternetConnectionGetAppointments"),
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }],
        isUndoAble: false
      },
      nextStep: this.bookAppointment
    }
  }

  @step.logState
  sayNoInternetConnectionReserveAppointment(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Hmmm, it looks like you're not connected to the internet"),
      prompt: {
        id: this.getPromptId("sayNoInternetConnectionGetAppointments"),
        type: "inlinePicker",
        choices: [{ body: this.t("Try again") }],
        isUndoAble: false
      },
      nextStep: this.handleAppointment
    }
  }

  @step.logState
  @step.startTyping
  async bookAppointmentBelowCaseness(d: IStepData<State>): Promise<IStepResult> {
    this.referralStore.setIdleSubmissionActive(false)
    const isDemo = this.referralStore.instanceID === "INSIGHT_E2E_DEMO"
    const [isSubmitSuccessful] = await submitInsightReferral(isDemo, d.state.patientId || "")
    if (isSubmitSuccessful) {
      const serviceName = this.rootStore.configStore.serviceName
      return {
        body: this.t(
          "As a next step, one of {serviceName}'s practitioners will speak to you to discuss this",
          { serviceName }
        ),
        nextStep: this.bookAppointment
      }
    }
    // if the submission failed, then reactivate the idle submission
    this.referralStore.setIdleSubmissionActive(true)
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  getIsValidTreatmentOption(state: State, treatmentOption?: ITreatmentOption): boolean {
    const isValid = super.getIsValidTreatmentOption(state, treatmentOption)
    if (!isValid) return false
    const isAtRisk = this.clinicalStore.isRisk
    if (isAtRisk) return false
    // prettier-ignore
    const isAllowedTreatments = state.php9q9Score! === 0 || (state.php9q9Score === 1 && state.canKeepSelfSafe)
    if (!isAllowedTreatments) return false

    if (treatmentOption?.name === "webinar") {
      return !!state.email && !!state.canSendEmail && !state.requiresInterpreter
    }
    if (treatmentOption?.name === "ieso") {
      return [IAPTIDs.INSIGHT_KENT, IAPTIDs.INSIGHT_MEDWAY].includes(state.iapt?.id as any)
    }
    return true
  }

  getBelowCasenessStep(state: State): IStep<State> | undefined {
    const canBookAppointment =
      ENABLE_APPOINTMENTS && !DISABLED_APPOINTMENT_BOOKING.includes(state.iapt?.id as any)

    if (canBookAppointment && !this.clinicalStore.isRisk) {
      return this.bookAppointmentBelowCaseness
    }
    return this.end
  }
}

/* istanbul ignore next */
export default class TreatmentOptionsInsightDialogue extends Dialogue<State> {
  static id = DialogueIDs.TreatmentOptionsInsight
  readonly name: string = "TreatmentOptionsInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(TreatmentOptionsInsightDialogue.id, new TreatmentOptionsInsightScript(), state, snapshot)
  }
}
