import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = RiskPathwayScriptState
export type RiskPathwayLWCScriptState = State

export class RiskPathwayLWCScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayLWCScript"

  /** Script Steps */

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you able to keep yourself, and any dependants in your care, safe until your appointment? This could be up to 6 weeks, although likely to be sooner",
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until your appointment? This could be up to 6 weeks, although likely to be sooner",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canKeepSelfSafe = d.response
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
    }

    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `Sorry to hear that ${name}`,
        `However ${organisationName} does not provide urgent care`,
        "Sometimes people have thoughts of harming themselves or that life isn’t worth living. If you are concerned about your safety, it is important that you talk to someone, support is available",
        "If you need urgent support, please dial NHS 111 and select Option 2",
        "In a medical emergency and life threatening situations only, please dial 999 or attend your local A&E department",
        "If you are under 25 and live in Birmingham, please contact the Forward Thinking Birmingham Crisis Team at 0300 300 0099",
        "If you are under 19 and live in Solihull, please contact Solar's Crisis Team at 0121 301 2750",
        "If you are over 18, please call the Birmingham Mind Urgent Mental Health Helpline at 0121 262 3555 (freephone - 0800 915 9292)",
        "Other helplines available:",
        "If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258. Shout offers a confidential 24/7 text service providing support if you are in need of immediate help"
      ],
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }],
        dataPointsName: "sayCrisis"
      },
      nextStep: this.handleSayCrisis
    }
  }

  @step.logState
  handleSayCrisis(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111 (Option 2), SHOUT to 85258, Forward Thinking Birmingham, Solar's Crisis Team, Birmingham Mind Urgent Mental Health Helpline"
    )
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: [
        "To recap:",
        `We’ll continue with the referral, however ${serviceName} does not provide urgent care. If you require immediate help, please contact one of the numbers listed above`
      ],
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }

  getCustomReferralType(state: State): string | undefined {
    if (state.requiresInterpreter) return "Extended Assessment"
  }
}

/* istanbul ignore next */
export default class RiskPathwayLWCDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayLWC
  readonly name: string = "RiskPathwayLWCDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayLWCDialogue.id, new RiskPathwayLWCScript(), state, snapshot)
  }
}
