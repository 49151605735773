import { ACCESS_INSTANCES } from "@limbic/types"
import { CCGCode } from "./ICCG"
import { IGPService } from "./IGPService"
import type { IReferralForm } from "./IReferralForm"

export interface IIAPTService {
  id: string
  name: string
  formattedName: string
  postcode: string
  emails?: string[]
  backendInstanceID?: keyof typeof ACCESS_INSTANCES
  clinicName?: string
  phoneNumber?: string
  ageThreshold?: number
  ageThresholdForTreatments?: number
  eligibleGPs?: IGPService[]
  referralForm?: IReferralForm
}

export enum IAPTIDs {
  "DEMO" = "demo",
  "VHG_DERBY" = "10921210",
  "VHG_BB" = "10565427",
  "VHG_CALDERDALE" = "10927593",
  "VHG_BNSSG" = "10802686",
  "VHG_KM" = "10921209",
  "VHG_LLR" = "10974279",
  "VHG_WEST_ESSEX" = "10802687",
  "VHG_NOTTS" = "72602v",
  "INSIGHT_KENT" = "2917833",
  "INSIGHT_PETTERSBOROUGH" = "76186",
  "INSIGHT_NOTTS" = "76190",
  "INSIGHT_BASSETLAW" = "3775518",
  "INSIGHT_DERBY" = "979390",
  "INSIGHT_WIRRAL" = "10290673",
  "INSIGHT_MEDWAY" = "7173844",
  "INSIGHT_KENT_DEMO" = "2917833_DEMO",
  "INSIGHT_PETTERSBOROUGH_DEMO" = "76186_DEMO",
  "INSIGHT_NOTTS_DEMO" = "76190_DEMO",
  "INSIGHT_BASSETLAW_DEMO" = "3775518_DEMO",
  "INSIGHT_DERBY_DEMO" = "979390_DEMO",
  "INSIGHT_WIRRAL_DEMO" = "10290673_DEMO",
  "INSIGHT_MEDWAY_DEMO" = "7173844_DEMO",
  "EVERYTURN_KENT" = "2917833_EVERYTURN",
  "EVERYTURN_PETTERSBOROUGH" = "76186_EVERYTURN",
  "EVERYTURN_NOTTS" = "76190_EVERYTURN",
  "EVERYTURN_BASSETLAW" = "3775518_EVERYTURN",
  "EVERYTURN_DERBY" = "979390_EVERYTURN",
  "EVERYTURN_WIRRAL" = "10290673_EVERYTURN",
  "EVERYTURN_MEDWAY" = "7173844_EVERYTURN",
  "EVERYTURN_KENT_DEMO" = "2917833_DEMO_EVERYTURN",
  "EVERYTURN_PETTERSBOROUGH_DEMO" = "76186_DEMO_EVERYTURN",
  "EVERYTURN_NOTTS_DEMO" = "76190_DEMO_EVERYTURN",
  "EVERYTURN_BASSETLAW_DEMO" = "3775518_DEMO_EVERYTURN",
  "EVERYTURN_DERBY_DEMO" = "979390_DEMO_EVERYTURN",
  "EVERYTURN_WIRRAL_DEMO" = "10290673_DEMO_EVERYTURN",
  "EVERYTURN_MEDWAY_DEMO" = "7173844_DEMO_EVERYTURN",
  "MIND_MATTERS_SAB" = "43379",
  "LINCS_STAMFORD" = "3138268",
  "LINCS_SPALDING" = "3664784",
  "LINCS_GRANTHAM" = "41430",
  "LINCS_BOSTON" = "7353720",
  "LINCS_SLEAFORD" = "4210193",
  "LINCS_SKEGNESS" = "6995542",
  "LINCS_LINCOLN" = "41434",
  "LINCS_LOUTH" = "3138278",
  "LINCS_GAINSBOROUGH" = "71684",
  "THERAPY_FOR_YOU_NE_ESSEX" = "7560833",
  "THERAPY_FOR_YOU_SE_ESSEX" = "7757974",
  "THERAPY_FOR_YOU_NE_ESSEX_DEMO" = "7560833_DEMO",
  "THERAPY_FOR_YOU_SE_ESSEX_DEMO" = "7757974_DEMO",
  "SHROPSHIRE_TELFORD_WREKIN_MPFT" = "8836752",
  "MENTAL_HEALTH_MATTERS_WARRINGTON" = "750555",
  "MENTAL_HEALTH_MATTERS_SEFTON" = "10965968",
  "MENTAL_HEALTH_MATTERS_NORTHUMBERLAND" = "4511506",
  "BRADFORD" = "3580182",
  "OXFORDSHIRE" = "73171",
  "BUCKINGHAMSHIRE" = "73172",
  // TODO tis a placeholder
  "LWC_SERVICE_1" = "LWC_SERVICE_1",
  "CW_CHE" = "CW_CHE",
  "CW_CHW" = "CW_CHW",
  "DORKING" = "DORKING",
  "BEXLEY" = "3664669",
  "MIND_MATTERS_SAB_V2" = "43379_V2"
}

export type IAPTCCGMap = Partial<Record<CCGCode, IAPTIDs>>
export type IAPTGPMap = Record<string, IAPTIDs>
