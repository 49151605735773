import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import getNextWorkingDay from "../../../utils/getNextWorkingDay"
import isValidPhoneNumber from "../../../utils/isValidPhoneNumber"
import type {
  IStepData,
  IStepResult,
  StepResultBodyType
} from "../../../backend/chatbot/models/IStep"
import type { ISurveyResponse } from "../../../models/ISurvey"
import { LanguageCodes } from "@limbic/types"

type State = BaseScriptState

export type RiskPathwayScriptState = State

export abstract class RiskPathwayScript extends BaseScript<State> {
  /** Optional Abstract Generic Handlers */

  onHandlePhoneNumber?(state: State): Promise<IStepResult>
  onSaveRiskLevelAndReferralType?(state: State): Promise<IStepResult>
  getClosingMessage?(state: State): StepResultBodyType[] | undefined

  /** Script Steps */

  async start(d: IStepData<State>): Promise<IStepResult<State>> {
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    return { nextStep: this.step1 }
  }

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askHaveYouMadePlans }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.track(`${this.name} Finished`)
    return super.end(d)
  }

  @step
  askHaveYouMadePlans(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Have you made plans to end your life?"),
      prompt: {
        id: this.getPromptId("Q9 - madePlansToEndLife"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "Q9 - madePlansToEndLife"
      },
      nextStep: this.handleHaveYouMadePlans
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "10",
    "Have you made plans to end your life?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleHaveYouMadePlans(d: IStepData<State, boolean>): IStepResult {
    const isRisk = this.clinicalStore.isRisk
    if (!isRisk && d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelModerate(d.state, RiskLevelReason.PHQ9_Q9_PLANS)
    }
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    return { nextStep: this.askAnyPreparations }
  }

  @step
  askAnyPreparations(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Have you made any actual preparations to end your life?"),
      prompt: {
        id: this.getPromptId("Q9 - preparations"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "Q9 - preparations"
      },
      nextStep: this.handleAnyPreparations
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "11",
    "Have you made any preparations to end your life?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleAnyPreparations(d: IStepData<State, boolean>): IStepResult {
    const isHighRisk = this.clinicalStore.isHighRisk
    if (!isHighRisk && d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.PHQ9_Q9_PREPARATIONS)
    }
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    return { nextStep: this.askWillYouAct }
  }

  @step
  askWillYouAct(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "How likely is it that you will act on these thoughts or plans to end your life?"
      ),
      prompt: {
        id: this.getPromptId("Q9 - willYouAct"),
        trackResponse: true,
        type: "slider",
        title: "How likely is it that you will act on these thoughts or plans to end your life?",
        min: 1,
        max: 8,
        labels: {
          1: this.t("Never"),
          4: this.t("Somewhat"),
          8: this.t("Certainly")
        },
        dataPointsName: "Q9 - willYouAct"
      },
      nextStep: this.handleWillYouAct
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("12", "riskPathwayResponses")
  handleWillYouAct(d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(1)
    const isRisk = this.clinicalStore.isRisk
    if (!isRisk && [3, 4, 5].includes(d.response.points!)) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelModerate(d.state, RiskLevelReason.PHQ9_Q9_WILL_U_ACT_SOMEWHAT)
    }
    const isHighRisk = this.clinicalStore.isHighRisk
    if (!isHighRisk && d.response.points! > 5) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.PHQ9_Q9_WILL_U_ACT_HIGHLY)
    }
    if (d.response.points! >= 3) {
      return { nextStep: this.askWhatStopsYou }
    }
    return { nextStep: this.askAreYouInRiskFromSomeoneElse }
  }

  @step.logState
  askWhatStopsYou(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("And what stops you from acting on these feelings?"),
      prompt: {
        type: "text",
        id: this.getPromptId("askWhatStopsYou"),
        forceValue: true,
        disableCrisis: true,
        dataPointsName: "askWhatStopsYou"
      },
      nextStep: this.handleWhatStopsYou
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "13",
    "And what stops you from acting on these feelings?",
    "riskPathwayResponses"
  )
  handleWhatStopsYou(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askAreYouInRiskFromSomeoneElse }
  }

  @step.logState
  askAreYouInRiskFromSomeoneElse(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Finally, do you feel that you are at risk from anyone else?"),
      prompt: {
        type: "inlinePicker",
        id: this.getPromptId("askAreYouInRiskFromSomeoneElse"),
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "Q9 - askAreYouInRiskFromSomeoneElse"
      },
      nextStep: this.handleAreYouInRiskFromSomeoneElse
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "14",
    "Do you feel that you are at risk from anyone else?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleAreYouInRiskFromSomeoneElse(d: IStepData<State, boolean>): IStepResult {
    const isHighRisk = this.clinicalStore.isHighRisk
    if (!isHighRisk && d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.PHQ9_Q9_RISK_FROM_SOMEONE_ELSE)
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  async saveRiskLevelAndReferralType(d: IStepData<State>): Promise<IStepResult> {
    const riskLevel = this.clinicalStore.riskLevel
    const riskLevelReason = this.clinicalStore.riskLevelReason ?? ""
    const canKeepSelfSafe = d.state.canKeepSelfSafe ?? true
    void this.referralStore.updateReferral({ riskLevel, riskLevelReason, canKeepSelfSafe })
    this.updateReferralType(d.state)
    await this.sendPHQ9(d.state)
    const result = await this.onSaveRiskLevelAndReferralType?.(d.state)
    if (result) return result
    return { nextStep: this.sayThanksForSharing }
  }

  @step.logState
  sayThanksForSharing(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const body = this.t(["Thanks for sharing, {name}", "I've made a note of that"], { name })
    const isRisk = this.getIsRisk(d.state)
    return {
      body: this.t(body),
      prompt: {
        id: this.getPromptId("sayThanksForSharing"),
        type: "inlinePicker",
        choices: [{ body: this.t("Thanks") }, { body: this.t("Okay") }],
        isUndoAble: false
      },
      nextStep: isRisk ? this.sayRiskExplanation : this.end
    }
  }

  @step
  sayRiskExplanation(_d: IStepData<State>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(1)
    return {
      body: this.t([
        "It sounds like you have been experiencing symptoms of depression, including thoughts of hurting yourself, or of being better off dead",
        "It is normal for people to have thoughts of this nature at times when we feel down",
        "It may be nothing to worry about",
        "However, your safety is my number one priority. And this is not an emergency response service.",
        "I'm therefore going to ask someone from our clinical team to ring you to discuss this matter with you directly"
      ]),
      nextStep: this.theyllCallYou
    }
  }

  @step
  async theyllCallYou(d: IStepData<State>): Promise<IStepResult> {
    const nextWorkingDay = await getNextWorkingDay()
    return {
      body: this.t(
        this.rootStore.applicationStore.translator?.language === LanguageCodes.EN
          ? "They'll call you {nextWorkingDay} on the number you provided earlier ({phoneNumber})"
          : "They'll call you within the next working day on the number you provided earlier ({phoneNumber})",
        {
          nextWorkingDay,
          phoneNumber: d.state.phoneNumber
        }
      ),
      prompt: {
        id: this.getPromptId("theyllCallYou"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: true },
          { body: this.t("I understand"), value: true },
          { body: this.t("Change number"), value: false }
        ]
      },
      nextStep: this.handleTheyllCallYou
    }
  }

  @step.logStateAndResponse
  handleTheyllCallYou(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      // 👇 If no number change - say instructions
      return { nextStep: this.sayInstructions }
    }
    // 👇 If a number change is needed - askPhoneNumber
    return { nextStep: this.askPhoneNumber }
  }

  @step.logState
  askPhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("What's the best phone number to call you on?"),
      prompt: {
        id: this.getPromptId("askPhoneNumber"),
        type: "phoneNumber"
      },
      nextStep: this.handlePhoneNumber
    }
  }

  @step.logState
  async handlePhoneNumber(d: IStepData<State, string>): Promise<IStepResult> {
    const isValid = isValidPhoneNumber(d.response)
    if (!isValid) {
      return {
        body: this.t("Sorry this is not a valid phone number. Let's try again"),
        nextStep: this.askPhoneNumber
      }
    }
    d.state.phoneNumber = d.response
    const nextWorkingDay = await getNextWorkingDay()
    const result = await this.onHandlePhoneNumber?.(d.state)
    if (result) return result
    return {
      body: this.t(
        [
          "Thanks",
          this.rootStore.applicationStore.translator?.language &&
          this.rootStore.applicationStore.translator.language === LanguageCodes.EN
            ? "Someone from our clinical team will call you {nextWorkingDay} on ({phoneNumber})"
            : "Someone from our clinical team will call you within the next working day on ({phoneNumber})"
        ],
        {
          nextWorkingDay,
          phoneNumber: d.state.phoneNumber
        }
      ),
      nextStep: this.sayInstructions
    }
  }

  @step
  async sayInstructions(_d: IStepData<State>): Promise<IStepResult> {
    return {
      body: this.t([
        "In the meantime, if you feel that you or someone you know is in danger, please call 999 immediately",
        "Further support is also provided by the Samaritans, available anytime by calling: 116 123"
      ]),
      prompt: {
        id: this.getPromptId("sayInstructions"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }, { body: this.t("I understand") }]
      },
      nextStep: this.handleInstructions
    }
  }

  @step.logState
  handleInstructions(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField("crisisNumbersShared", "999 and The Samaritans")
    return { nextStep: this.closeDialogue }
  }

  @step.logState
  closeDialogue(d: IStepData<State>): IStepResult {
    const body = this.getClosingMessage?.(d.state)
    return { body, nextStep: this.end }
  }

  /** Generic Handlers */

  getIsRisk(_state: State): boolean {
    // this handler looks unnecessary, but it's here
    // in case a service needs to overwrite it
    return this.clinicalStore.isRisk
  }
}
