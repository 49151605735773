import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GetNameIAPTScript, GetNameIAPTScriptState } from "./GetNameIAPTDialogue"
import { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import { MindTitles } from "@limbic/types"

interface State extends GetNameIAPTScriptState {
  preferredTitle?: string
  preferredPronouns?: string
}

export type GetNameDorkingScriptState = State

export class GetNameDorkingScript extends GetNameIAPTScript {
  readonly name: string = "GetNameDorkingScript"

  /** Script Steps */

  @step
  step1(_d: IStepData<State>): IStepResult {
    // overriding to remove the word "patient"
    const { directReferral } = this.rootStore.configStore
    const body = directReferral
      ? "I'm now going to create a record for you"
      : "Ok, I'm just going to create a record for you"
    const prompt = directReferral
      ? ({
          type: "inlinePicker",
          choices: [{ body: "Okay" }]
        } as IInlinePickerSingleSelectPrompt)
      : undefined
    return { body, prompt, nextStep: this.askFullName }
  }

  @step
  askFullName(_d: IStepData<State>): IStepResult {
    return {
      body: ["What's your full name? ✏️"],
      nextStep: this.showPromptForFullName
    }
  }

  @step.logState
  askIsPreferredName(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Is ${name} your preferred first name?`,
      prompt: {
        id: this.getPromptId("askIsPreferredName"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleIsPreferredName
    }
  }

  @step.logStateAndResponse
  async handleIsPreferredName(d: IStepData<State, boolean>): Promise<IStepResult> {
    return {
      nextStep: d.response //
        ? this.sayNiceToMeetYou
        : this.sayPleaseGivePreferredName
    }
  }

  @step
  sayPleaseGivePreferredName(_d: IStepData<State>): IStepResult {
    return {
      body: "What would you like to be called?",
      nextStep: this.showPromptForPreferredName
    }
  }

  @step.logState
  showPromptForPreferredName(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForPreferredName"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePreferredNameWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: GetNameDorkingScript) => {
    const preferredName = d.response?.trim()
    d.state.preferredName = preferredName
    script.rootStore.applicationStore.setPreferredName(preferredName)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: GetNameDorkingScript) => s.sayPleaseGivePreferredName
  })
  async handlePreferredNameWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.checkPreferredName }
  }

  @step.logState
  async checkPreferredName(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.preferredName || d.state.preferredName.trim() === "") {
      return { nextStep: this.sayPleaseGivePreferredName }
    }
    return { nextStep: this.sayNiceToMeetYou }
  }

  @step
  sayNiceToMeetYou(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Nice to meet you ${name}`,
      prompt: {
        id: this.getPromptId("sayNiceToMeetYou"),
        type: "inlinePicker",
        choices: [{ body: "Nice to meet you too" }]
      },
      nextStep: this.askHowWouldYouLikeToBeAddressed
    }
  }

  @step.logState
  askHowWouldYouLikeToBeAddressed(d: IStepData<State>): IStepResult {
    const titles = this.getTitles()
    if (!titles?.length) {
      this.logBreadcrumb("TITLES NOT FOUND", d.state, { titles })
      this.logMessage("TITLES NOT FOUND")
      return { nextStep: this.end }
    }

    return {
      body: "And how would you like to be addressed?",
      prompt: {
        id: this.getPromptId("askHowWouldYouLikeToBeAddressed"),
        type: "inlinePicker",
        choices: titles.map(title => ({ body: title, value: title })),
        isUndoAble: true
      },
      nextStep: this.handleHowWouldYouLikeToBeAddressed
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, MindTitles>) => {
    d.state.title = d.response
  })
  handleHowWouldYouLikeToBeAddressed(_d: IStepData<State, MindTitles>): IStepResult {
    return { nextStep: this.askPreferredPronouns }
  }

  @step.logState
  askPreferredPronouns(d: IStepData<State>): IStepResult {
    const pronouns = this.getPreferredPronouns(d.state)
    if (!pronouns?.length) {
      this.logBreadcrumb("PRONOUNS NOT FOUND", d.state, { pronouns })
      this.logMessage("PRONOUNS NOT FOUND")
      return { nextStep: this.end }
    }
    return {
      body: `And what are your preferred pronouns?`,
      prompt: {
        id: this.getPromptId("askPreferredPronouns"),
        trackResponse: true,
        type: "inlinePicker",
        choices: pronouns.map(g => ({ body: g, value: g }))
      },
      nextStep: this.handlePreferredPronouns
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, _script: GetNameDorkingScript) => {
    d.state.preferredPronouns = d.response
  })
  handlePreferredPronouns(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  async onCheckFullName(_state: State): Promise<IStepResult> {
    return {
      nextStep: this.askIsPreferredName
    }
  }

  getTitles(): MindTitles[] {
    return ["Mr", "Mrs", "Miss", "Ms", "Mx", "Dr", "Rev", "Prof"]
  }

  getPreferredPronouns(_state: State): string[] {
    return [
      "She/Her/Her",
      "He/Him/His",
      "They/Them/Their",
      "Ae/Aer/Aer",
      "Ey/Em/Eir",
      "Fae/Faer/Faer",
      "Per/Per/Pers",
      "Ve/Ver/Vers",
      "Xe/Xem/Xyr",
      "Ze/Hir/Hir"
    ]
  }
}

/* istanbul ignore next */
export default class GetNameDorkingDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameDorking
  readonly name: string = "GetNameDorkingDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameDorkingDialogue.id, new GetNameDorkingScript(), state, snapshot)
  }
}
