import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class NationalityPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_NATIONALITY> {
  chatFlow = ChatFlowsEnum.COLLECT_NATIONALITY

  /** Overrides */

  getDefaultPayload(): Partial<ReferralPayload> {
    const payload: Partial<ReferralPayload> = {
      nationality: this.ctx.state?.nationality
    }

    if (this.ctx.state?.nationalityOther) {
      payload.nationalityOther = this.ctx.state?.nationalityOther
    }

    return payload
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_NATIONALITY] = NationalityPayloadBuilder
