const customFields: Record<string, Record<"allFields" | "requiredFields", string[]>> = {
  VITALITY: {
    allFields: [
      "firstName",
      "middleNames",
      "lastname",
      "entityId",
      "claimNumber",
      "nextRenewalDate",
      "isEligible",
      "excessAmount",
      "excessType",
      "staffMember",
      "planType"
    ],
    requiredFields: ["entityId", "isEligible"]
  }
}

export default customFields
