import React from "react"
import IAppLaunchConfig from "../../../models/IAppLaunchConfig"
import SidebarEssex from "./SidebarEssex"
import SidebarInsight from "./SidebarInsight"
import SidebarSABP from "./SidebarSABP"
import SidebarGMHub from "./SidebarGMHub"
import SidebarLincolnshire from "./SidebarLincolnshire"
import SidebarVitality from "./SidebarVitality"
import { Props } from "./Sidebar"
import SidebarMHM from "./SidebarMHM"
import SidebarBradford from "./SidebarBradford"
import SidebarLWC from "./SidebarLWC"
import SidebarCW from "./SidebarCW"
import SidebarOxford from "./SidebarOxford"
import SidebarBucks from "./SidebarBucks"
import SidebarDorking from "./SidebarDorking"
import SidebarMPFT from "./SidebarMPFT"
import SidebarBexley from "./SidebarBexley"
import SidebarMind from "./SidebarMind"

export const sidebarsMap: Record<IAppLaunchConfig["API_KEY"], React.FC<Props>> = {
  ESSEX_FULLSCREEN: SidebarEssex,
  INSIGHT_TALKING_THERAPIES_FULLSCREEN: SidebarInsight,
  SABP_FULLSCREEN: SidebarSABP,
  GREATER_MANCHESTER_HUB_FULLSCREEN: SidebarGMHub,
  LINCOLNSHIRE_FULLSCREEN: SidebarLincolnshire,
  VITALITY: SidebarVitality,
  MENTAL_HEALTH_MATTERS: SidebarMHM,
  BRADFORD: SidebarBradford,
  LIVING_WELL_CONSORTIUM: SidebarLWC,
  CHESHIRE_AND_WIRRAL: SidebarCW,
  OXFORD: SidebarOxford,
  BUCKINGHAMSHIRE: SidebarBucks,
  DORKING: SidebarDorking,
  MPFT: SidebarMPFT,
  BEXLEY: SidebarBexley,
  MIND: SidebarMind,
  MIND_FULLSCREEN: SidebarMind
}
