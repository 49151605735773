import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = CrisisScriptState
export type CrisisCWScriptState = State

export class CrisisCWScript extends CrisisScript {
  readonly name: string = "CrisisCWScript"

  /** Script Steps */

  @step
  saySorryToHear(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t(
        [
          "Thank you for sharing this information",
          "However, {organisationName} cannot provide support to people who are in crisis or unable to keep themselves safe",
          "Sometimes people have thoughts of harming themselves or that life isn’t worth living. If you are concerned about your safety, it is important that you talk to someone, support is available",
          "If you feel unable to keep yourself safe and require support, please call NHS 111 and select the mental health option to talk to a mental health professional. They will be able to support you to get the help/advice you need. The service is open 24 hours a day, seven days a week"
        ],
        { organisationName }
      ),
      prompt: {
        id: this.getPromptId("saySorryToHear"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: this.t(
        [
          "You should still call 999 or go to A&E if you have an immediate, life-threatening emergency requiring mental or physical health assistance",
          "Other helplines available:\n\n" +
            "<b>Samaritans:</b> Call 116 12 for free (open 24 hours, 365 days a year)\n" +
            "<b>CALM:</b> Call 0800 58 58 58 (5pm - midnight, 7 days a week)\n" +
            "<b>Shout:</b> Text 'SHOUT' to 85258 to start a conversation with a trained volunteer, who will provide free confidential support (open 24 hours a day, seven days a week)",
          "We’ll continue with the referral, however {organisationName} does not provide urgent care. If you require immediate help, please contact one of the numbers listed above"
        ],
        { organisationName }
      ),
      nextStep: this.handleCrisisNumbers
    }
  }

  @step
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, Local (0800 145 6485), Samaritans (116 123), SHOUT (85258)"
    )
    return { nextStep: this.end }
  }

  @step.logState
  saySorryForTheMisunderstanding(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Okay",
        "My creators have taught me to listen carefully for specific words or phrases",
        "In case you need help",
        "Sorry for the misunderstanding",
        "Ok, let's carry on with the mental health check in"
      ],
      nextStep: this.end
    }
  }
}

/* istanbul ignore next */
export default class CrisisCWDialogue extends AdHocDialogue<State, CrisisCWScript> {
  static id = DialogueIDs.CrisisCW
  readonly name: string = "CrisisCWDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisCWDialogue.id, new CrisisCWScript(), state, snapshot)
  }
}
