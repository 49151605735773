import AdHocDialogue from "../../backend/chatbot/AdHocDialogue"
import BaseScript from "../BaseScript"
import { IDialogueSnapshot } from "../../backend/chatbot/Dialogue"
import { step } from "../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../DialogueIDs"
import type { SurveyScriptState } from "../createSurveyDialogue"
import type { IStepData, IStepResult } from "../../backend/chatbot/models/IStep"
import type { IPersistableSurveyResponse, ISurveyResponse } from "../../models/ISurvey"
import {
  CSSRSRisk,
  CSSRSRiskMap,
  CSSRSRiskScoreMap,
  IDefaultChatFlowMessagesAministerCSSRS,
  RiskLevel
} from "@limbic/types"
import { ICSSRSSurveySettings } from "../dialogues/ad-hoc/AdministerCSSRS/AdministerCSSRSDialogue"

const DEFAULT_CSSRS_QUESTIONS: Record<"1" | "2" | "3" | "4" | "5" | "6" | "7", string> = {
  "1": "Have you wished you were dead or wished you could go to sleep and not wake up?",
  "2": "Have you had any thoughts about actually killing yourself?",
  "3": "Have you been thinking about how you might do this?",
  "4": "Have you had these thoughts and had some intention of acting on them?",
  "5": "Have you started to work out, or worked out, the details of how to kill yourself? Do you intend to carry out this plan?",
  "6": "Have you done anything, started to do anything, or prepared to do anything to end your life?",
  "7": "Was this within the past 3 months?"
}

export type State = SurveyScriptState

export class CSSRS extends BaseScript<State> {
  readonly name: string = "C-SSRS"
  protected readonly messages: IDefaultChatFlowMessagesAministerCSSRS | undefined
  protected readonly shouldSkipQuestion7?: boolean

  constructor(settings?: ICSSRSSurveySettings | undefined) {
    super()
    this.messages = settings?.messages ?? {}
    this.shouldSkipQuestion7 = settings?.shouldSkipQuestion7 ?? false
  }

  /** Script Steps */

  @step
  start(_d: IStepData<State>): IStepResult<State> {
    this.timeEvent(`${this.name} Finished`)
    return { nextStep: this.sayIntro }
  }

  @step
  sayIntro(_d: IStepData<State>): IStepResult<State> {
    this.timeEvent(`${this.name} Finished`)
    return {
      body: this.t(this.messages?.cssrsIntro ?? "In the past month"),
      nextStep: this.step1
    }
  }

  @step
  step1(_d: IStepData<State>): IStepResult {
    const q = this.messages?.cssrsQuestion1 ?? DEFAULT_CSSRS_QUESTIONS["1"]
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step1"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "C-SSRS - step1"
      },
      nextStep: this.handleStep1
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>("1", DEFAULT_CSSRS_QUESTIONS["1"], "cssrsResponses", (r: boolean) =>
    r ? "Yes" : "No"
  )
  handleStep1(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step2 }
  }

  @step
  step2(_d: IStepData<State>): IStepResult {
    const q = this.messages?.cssrsQuestion2 ?? DEFAULT_CSSRS_QUESTIONS["2"]
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step2"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "C-SSRS - step2"
      },
      nextStep: this.handleStep2
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>("2", DEFAULT_CSSRS_QUESTIONS["2"], "cssrsResponses", (r: boolean) =>
    r ? "Yes" : "No"
  )
  handleStep2(d: IStepData<State, ISurveyResponse>): IStepResult {
    const q2 = this.getQuestionAnswer(d.state, "2")
    if (q2 === "Yes") {
      return { nextStep: this.step3 }
    }

    this.track("Skipped C-SSRS step 3 to 5")
    return { nextStep: this.step6 }
  }

  @step
  step3(_d: IStepData<State>): IStepResult {
    const q = this.messages?.cssrsQuestion3 ?? DEFAULT_CSSRS_QUESTIONS["3"]
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step3"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "C-SSRS - step3"
      },
      nextStep: this.handleStep3
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>("3", DEFAULT_CSSRS_QUESTIONS["3"], "cssrsResponses", (r: boolean) =>
    r ? "Yes" : "No"
  )
  handleStep3(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step4 }
  }

  @step
  step4(_d: IStepData<State>): IStepResult {
    const q = this.messages?.cssrsQuestion4 ?? DEFAULT_CSSRS_QUESTIONS["4"]
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step4"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "C-SSRS - step4"
      },
      nextStep: this.handleStep4
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>("4", DEFAULT_CSSRS_QUESTIONS["4"], "cssrsResponses", (r: boolean) =>
    r ? "Yes" : "No"
  )
  handleStep4(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step5 }
  }

  @step
  step5(_d: IStepData<State>): IStepResult {
    const q = this.messages?.cssrsQuestion5 ?? DEFAULT_CSSRS_QUESTIONS["5"]
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step5"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "C-SSRS - step5"
      },
      nextStep: this.handleStep5
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>("5", DEFAULT_CSSRS_QUESTIONS["5"], "cssrsResponses", (r: boolean) =>
    r ? "Yes" : "No"
  )
  handleStep5(_d: IStepData<State, ISurveyResponse>): IStepResult {
    return { nextStep: this.step6 }
  }

  @step
  step6(_d: IStepData<State>): IStepResult {
    const q = this.messages?.cssrsQuestion6 ?? DEFAULT_CSSRS_QUESTIONS["6"]
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step6"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "C-SSRS - step6"
      },
      nextStep: this.handleStep6
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>("6", DEFAULT_CSSRS_QUESTIONS["6"], "cssrsResponses", (r: boolean) =>
    r ? "Yes" : "No"
  )
  handleStep6(d: IStepData<State, ISurveyResponse>): IStepResult {
    if (this.shouldSkipQuestion7) {
      this.track("Skipped C-SSRS step 7 because of settings")
      return { nextStep: this.checkCSSRSRisk }
    }

    const q6 = this.getQuestionAnswer(d.state, "6")
    if (q6 === "Yes") {
      return { nextStep: this.step7 }
    }

    this.track("Skipped C-SSRS step 7 because of answer to step 6")
    return { nextStep: this.checkCSSRSRisk }
  }

  @step.logState
  step7(_d: IStepData<State>): IStepResult {
    const q = this.messages?.cssrsQuestion7 ?? DEFAULT_CSSRS_QUESTIONS["7"]
    return {
      body: this.t(q),
      prompt: {
        id: this.getPromptId("step7"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "C-SSRS - step7"
      },
      nextStep: this.handleStep7
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>("7", DEFAULT_CSSRS_QUESTIONS["7"], "cssrsResponses", (r: boolean) =>
    r ? "Yes" : "No"
  )
  handleStep7(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.checkCSSRSRisk }
  }

  @step
  checkCSSRSRisk(d: IStepData<State>): IStepResult {
    this.setCSSRSRisk(d.state)
    this.referralStore
      .updateReferral({
        riskLevel: this.clinicalStore.riskLevel,
        riskLevelReason: this.clinicalStore.riskLevelReason ?? "",
        triggerWords: this.clinicalStore.triggerWords
      })
      .catch(e => this.logException(e, "handleAreYouInCrisis -> updateReferral"))
    this.updateReferralType(d.state)
    return { nextStep: this.end }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.track(`${this.name} Finished`)
    return super.end(d)
  }

  /** Generic Handlers */

  saveResponse<T extends IPersistableSurveyResponse>(item: T, state: State): void {
    try {
      const newItem = JSON.parse(JSON.stringify(item))
      type CssrsQuestionID = `cssrsQuestion${string}`
      const questionID: CssrsQuestionID = `cssrsQuestion${item.id}`
      const customMessage = this.messages?.[questionID]
      const title = customMessage?.length
        ? customMessage.join(", ")
        : DEFAULT_CSSRS_QUESTIONS[item.id]
      newItem.title = title as string
      super.saveResponse(newItem, state, "cssrsResponses")
    } catch (error) {
      this.logException(error, "Error saving CSSRS response")
    }
  }

  setCSSRSRisk(state: State): void {
    const risk = this.calculateCSSRSRisk(state)
    this.clinicalStore.setCSSRSRiskLevel(risk)
  }

  calculateCSSRSRisk(state: State): keyof typeof CSSRSRisk {
    const lastHighRiskYesAnswer = this.getLastYesAnswerByRiskLevel(state, RiskLevel.High)
    if (lastHighRiskYesAnswer) return CSSRSRiskScoreMap[lastHighRiskYesAnswer.id]

    const lastModerateRiskYesAnswer = this.getLastYesAnswerByRiskLevel(state, RiskLevel.Moderate)
    if (lastModerateRiskYesAnswer) return CSSRSRiskScoreMap[lastModerateRiskYesAnswer.id]

    const lastLowRiskYesAnswer = this.getLastYesAnswerByRiskLevel(state, RiskLevel.Low)
    if (lastLowRiskYesAnswer) return CSSRSRiskScoreMap[lastLowRiskYesAnswer.id]

    return CSSRSRiskScoreMap["0"]
  }

  getLastYesAnswerByRiskLevel(
    state: State,
    riskLevel: RiskLevel
  ): IPersistableSurveyResponse | undefined {
    const yesAnswers = state.cssrsResponses?.filter(r => {
      const riskLevelOfQuestion = CSSRSRiskMap[CSSRSRiskScoreMap[r.id]]
      return r.answer === "Yes" && riskLevelOfQuestion === riskLevel
    })
    return yesAnswers?.[yesAnswers.length - 1]
  }

  getQuestionAnswer(state: State, id: string) {
    return state.cssrsResponses?.find(r => r.id === id)?.answer
  }
}

export default class CSSRSDialogue extends AdHocDialogue<State, CSSRS> {
  static id = DialogueIDs.CSSRS
  readonly name: string = "CSSRSDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>, settings?: ICSSRSSurveySettings) {
    super(CSSRSDialogue.id, new CSSRS(snapshot?.settings ?? settings), state, snapshot, settings)
  }
}
