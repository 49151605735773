import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { TrackingEvents } from "../../../../models/Constants"
import {
  IDefaultChatFlowMessagesSpineSearch,
  IDefaultChatFlowSettingsSpineSearch
} from "@limbic/types"
import { PostcodeStatus } from "../../../../models/IPostcode"
import { getPostCodeDetails } from "../../../../backend/api/external/postcodes"
import { IIAPTService } from "../../../../models/IIAPTService"
import { ISelectable } from "@limbic/types"
import invariant from "../../../../utils/invariant"
import {
  ServiceSearchScript,
  ServiceSearchScriptState
} from "../ServiceSearch/ServiceSearchDialogue"
import { PDSFindRequestStatus, pdsFind } from "../../../../backend/api/limbic/pds"
import moment from "moment"
import { getODSGPDetails } from "../../../../backend/api/limbic/ods"
import { joinWithAnd } from "../../../../utils/array"
import IName from "../../../../models/IName"
import formatUnicorn from "../../../../utils/formatUnicorn"
import { extractSpineName } from "../../../../utils/extractSpineName"

export type ISpineSearchSettings = IDefaultChatFlowSettingsSpineSearch & {
  messages?: IDefaultChatFlowMessagesSpineSearch
}

export interface State extends ServiceSearchScriptState {
  spineSearchCount?: number
  explanationCount?: number
  allowSkipPDS?: boolean
}

export type SpineSearchScriptState = State

export class SpineSearchScript extends ServiceSearchScript {
  readonly name: string = "SpineSearchScript"
  protected readonly messagesSpineSearch: IDefaultChatFlowMessagesSpineSearch | undefined
  protected readonly retryPostcodeTimes?: number
  protected readonly spineSearchCountThreshold?: number
  protected readonly shouldUseServiceSearchFallback?: boolean
  protected readonly canReferWithoutGP?: boolean

  constructor(settings?: ISpineSearchSettings | undefined) {
    super()
    this.messagesSpineSearch = settings?.messages ?? {}
    this.retryPostcodeTimes = settings?.retryPostcodeTimes ?? 3
    this.spineSearchCountThreshold = settings?.spineSearchCount ?? 3 // TODO: 0 or 3?
    /**
     * 👇 When the failed spine search count is reached fallback to service search
     * and ask for GP Postcode or GP Name
     */
    this.shouldUseServiceSearchFallback = settings?.shouldUseServiceSearchFallback ?? true
    /**
     * 👇 When there are no results from the GP Search, continue with referral
     * as long as there are eligible IAPTs
     */
    this.canReferWithoutGP = settings?.canReferWithoutGP ?? false
  }

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    d.state.spineSearchCount = 0
    this.timeEvent(this.name)
    return { nextStep: this.startEligibilityCheck }
  }

  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askPostCodeOfUser }
  }

  @step.logState
  @step.setState<State>({ retryPostcodeTimes: 0 } as Partial<State>)
  askPostCodeOfUser(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messagesSpineSearch?.askPostCodeOfUserSpine ?? "Please type your postcode below",
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askPostCodeOfUser"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePostCodeOfUserWithCrisis
    }
  }

  @step.logState
  sayIntroToSpineSearch(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        this.messagesSpineSearch?.sayIntroToSpineSearch ??
          "Alright, I'm just going to search you in the NHS database with the details you've given me",
        this.getContext(d.state)
      ),
      nextStep: this.fetchSpineData
    }
  }

  @step.logState
  @step.startTyping
  @step.delay(1)
  async fetchSpineData(d: IStepData<State>): Promise<IStepResult> {
    d.state.spineSearchCount ??= 0
    d.state.spineSearchCount++
    d.state.retryPostcodeTimes ??= 0
    d.state.retryPostcode = undefined
    d.state.allowSkipPDS = false // TODO: Should this be a setting?
    const { userPostcode, birthday } = d.state
    const { firstName, middleNames } = d.state.name!
    const dob = moment(birthday).format("YYYY-MM-DD")
    const pdsPayload = {
      dob,
      postcode: userPostcode?.postcode,
      nameFirst: `${firstName}${middleNames ? ` ${middleNames}` : ""}`,
      nameLast: this.getLastName(d.state)
    }
    const [data, status] = await pdsFind(pdsPayload)
    this.track(TrackingEvents.SPINE_SEARCH_RESULT, { result: status, hasGP: !!data?.gp?.length })

    if (status === PDSFindRequestStatus.SUCCESS) {
      if (d.state.spineSearchCount === 1) this.track(TrackingEvents.SPINE_SUCCESS_ON_1ST_TRY)
      this.setPeople({ spineSearchSuccessFull: true, spineSearchCount: d.state.spineSearchCount })
      const { nhsNumber, gp, gender, language, interpreterRequired } = data ?? {}
      if (data) {
        const spineName = extractSpineName(data)
        if (spineName.firstName && spineName.lastName) d.state.spineName = spineName as IName
      }

      // ignore state.nhsNumber = nhsNumber from change reminders
      // TODO: Remove the change reminder ignore comment above a few months after it's added
      if (nhsNumber) d.state.nhsNumber = nhsNumber
      if (gp?.length) this.setODSGP(d.state, gp[0])
      if (gender) d.state.spineGender = gender
      if (language) d.state.spineLanguage = language
      if (interpreterRequired != null) d.state.spineInterpreterRequired = interpreterRequired
      if (d.state.odsGP)
        return {
          body: this.t(
            this.messagesSpineSearch?.saySearchSuccessful ?? "Found you!",
            this.getContext(d.state)
          ),
          nextStep: this.selectIAPTServiceByODSGP
        }
      else return { nextStep: this.sayICouldntFindYourGP }
    }

    if (status === PDSFindRequestStatus.NO_INTERNET) {
      // this attempt doesn't need to count since they
      // didn't even make it due to lack of internet,
      // so we revert the count bump
      d.state.spineSearchCount = Math.max(0, d.state.spineSearchCount - 1)
      return { nextStep: this.askRetryInternetConnectionForFetchSpineData }
    }

    // prettier-ignore
    if (
      this.spineSearchCountThreshold &&
      d.state.spineSearchCount >= this.spineSearchCountThreshold
    ) {
      const result = await this.onFailedSpineSearchCountReached?.(d.state)
      if (result) return result
    }

    switch (status) {
      case PDSFindRequestStatus.NOT_FOUND:
        return { nextStep: this.sayICouldntFindYouInPDS }
      case PDSFindRequestStatus.MULTIPLE_RECORDS:
        d.state.allowSkipPDS = true
        return { nextStep: this.sayICouldntFindYouInPDS }
      case PDSFindRequestStatus.INTERNAL_ERROR:
      default:
        //prettier-ignore
        this.setPeople({ spineSearchSuccessFull: false, spineSearchCount: d.state.spineSearchCount })
        return { nextStep: this.sayICouldntFindYouInPDSAndGoManual }
    }
  }

  @step.logState
  sayICouldntFindYouInPDSAndGoManual(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmm, it looks like I wasn't able to find you in the NHS Database...",
      nextStep: this.sayAnotherWay
    }
  }

  @step.logState
  sayAnotherWay(_d: IStepData<State>): IStepResult {
    return {
      body: "Let me see if I can find your GP in an other way",
      nextStep: this.askSelectGPFromUserPostcode
    }
  }

  @step
  async selectIAPTServiceByODSGP(d: IStepData<State>): Promise<IStepResult> {
    const nextStep = this.checkEligibility
    try {
      // This needs to happen because if the Spine didn't return the user's
      // GP then we need to search for it through postcode or name via the
      // service search api which doesn't return the ODS version
      if (!d.state.odsGP && d.state.gp) {
        const nacsCode = d.state.gp.nacsCode
        const [odsGP] = await getODSGPDetails(nacsCode)
        if (odsGP) this.setODSGP(d.state, odsGP)
        else this.track(TrackingEvents.ODS_GP_BY_NACSCODE_NOT_FOUND, { nacsCode })
      }

      const gp = d.state.odsGP
      if (!gp?.ccgs.length) {
        this.track(TrackingEvents.NO_ODS_GP_WITH_CCGS, {
          gp: gp?.name ?? "N/A",
          gpID: gp?.id ?? "N/A",
          gpSecondaryID: gp?.secondaryID ?? "N/A",
          gpCCGs: gp?.ccgs ?? "N/A",
          isCustom: gp?.isCustom
        })
        this.logBreadcrumb("ODS GP with CCGs not found", d.state)
        this.logMessage("ODS GP with CCGs not found")
        return { nextStep }
      }
      const gpCode = d.state.odsGP?.id

      let iapts
      if (gpCode) {
        iapts = this.getIAPTsByGPCode(d.state, gpCode)
      }

      if (!iapts.length) {
        iapts = this.getIAPTsByCCGCodes(d.state, gp?.ccgs)
      }

      d.state.eligibleIAPTs = iapts?.filter(iapt => this.getIsEligibleForIAPT(d.state, iapt))
      this.setPeople({ eligibleIAPTs: d.state.eligibleIAPTs })

      if (!d.state.eligibleIAPTs?.length) {
        this.track(TrackingEvents.NO_IAPTS_FOUND_VIA_ODS_GP, {
          gp: gp?.name ?? "N/A",
          gpID: gp?.id ?? "N/A",
          gpSecondaryID: gp?.secondaryID ?? "N/A",
          gpCCGs: gp?.ccgs ?? "N/A",
          isCustom: gp?.isCustom
        })
        this.logBreadcrumb("No IAPT services found", d.state)
        this.logMessage("No IAPT services found")
        return { nextStep }
      }

      if (d.state.eligibleIAPTs.length === 1) {
        const iapt = d.state.eligibleIAPTs[0]
        this.setIAPT(d.state, iapt)
        this.setIAPTSuggestions(d.state, [])
        return { nextStep: this.checkUnderAgedForIAPT }
      }

      this.track(TrackingEvents.MULTIPLE_ELIGIBLE_IAPTS, {
        iapts: d.state.eligibleIAPTs.map(i => `${i.formattedName} (${i.id})`),
        gp: gp?.name ?? "N/A",
        gpID: gp?.id ?? "N/A",
        gpSecondaryID: gp?.secondaryID ?? "N/A",
        gpCCGs: gp?.ccgs ?? "N/A",
        isCustom: gp?.isCustom
      })
      return { nextStep: this.askSelectEligibleIAPTService }
    } catch (e) {
      this.logBreadcrumb("selectIAPTServiceByODSGP failed", undefined, { error: e.message })
      this.logException(e, "selectIAPTServiceByODSGP")
      return { nextStep }
    }
  }

  @step.logState
  askSelectEligibleIAPTService(d: IStepData<State>): IStepResult {
    if (!d.state.eligibleIAPTs?.length) {
      this.logBreadcrumb("askSelectEligibleIAPTService failed", d.state)
      this.logMessage("askSelectEligibleIAPTService called without eligible IAPTs")
      return { nextStep: this.sayICouldntFindIAPTsForYourGP }
    }
    const name = this.getName(d.state)
    const iaptNames = d.state.eligibleIAPTs!.map(i => i.formattedName)
    return {
      body: [
        `Thanks ${name}. Good news!`,
        `Based on the GP you are registered with, you are eligible for ${joinWithAnd(iaptNames)}`,
        "Which one would you like to refer to?"
      ],
      prompt: {
        id: this.getPromptId("askSelectEligibleIAPTService"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          ...d.state.eligibleIAPTs.map(i => ({ body: i.formattedName, value: i })),
          { body: "None of them", value: "none" }
        ]
      },
      nextStep: this.handleSelectEligibleIAPTService
    }
  }

  @step.logState
  handleSelectEligibleIAPTService(d: IStepData<State, IIAPTService | "none">): IStepResult {
    if (d.response === "none") return { body: "Okay", nextStep: this.closeWithCallIntoService }
    this.setIAPT(d.state, d.response, true)
    this.setIAPTSuggestions(d.state, [])
    return { nextStep: this.checkEligibility }
  }

  @step.logState
  askRetryInternetConnectionForFetchSpineData(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, It looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("askRetryInternetConnectionForFetchSpineData"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.fetchSpineData
    }
  }

  @step.logState
  sayICouldntFindYouInPDS(d: IStepData<State>): IStepResult {
    const organisationPhoneNumbers = this.rootStore.configStore.organisationPhoneNumbers?.length
      ? this.rootStore.configStore.organisationPhoneNumbers
      : this.rootStore.configStore.organisationGenericPhoneNumber
    const body =
      d.state.spineSearchCount! >= 3
        ? [
            "It seems like you are having trouble entering details that match with the NHS database",
            `If you'd rather call into the service and talk to a human, you can do so here:\n${organisationPhoneNumbers}`
          ]
        : [
            "Hmm, it looks like I wasn't able to find you in the NHS Database...",
            "Most of the time, this is because you're registered with your GP under a different name or old address still"
          ]
    return {
      body,
      nextStep: this.askConfirmDetails
    }
  }

  @step.logState
  askConfirmDetails(d: IStepData<State>): IStepResult {
    const name = d.state.name //
      ? `<b>Name</b>: ${this.getFullName(d.state)}`
      : undefined
    const birthday = d.state.birthday
      ? `<b>Date of birth</b>: ${moment(d.state.birthday).format("DD MMMM YYYY")}`
      : undefined
    const postcode = d.state.userPostcode?.postcode
      ? `<b>Postcode</b>: ${d.state.userPostcode?.postcode}`
      : undefined
    const details = [name, birthday, postcode].filter(Boolean).join("\n")
    return {
      body: `The information you've provided me with are:\n${details}`,
      nextStep: this.promptConfirmDetails
    }
  }

  @step.logState
  promptConfirmDetails(d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("promptConfirmDetails"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Change name", value: "name", fullWidth: true },
          { body: "Change date of birth", value: "dob", fullWidth: true },
          { body: "Change postcode", value: "postcode", fullWidth: true },
          d.state.allowSkipPDS
            ? { body: "Try another way", value: "skip", fullWidth: true }
            : undefined,
          !d.state.explanationCount
            ? { body: "Help me figure out what's wrong", value: "explain", fullWidth: true }
            : undefined
        ].filter(Boolean) as ISelectable[]
      },
      nextStep: this.handleConfirmDetails
    }
  }

  @step.logState
  handleConfirmDetails(
    d: IStepData<State, "name" | "dob" | "postcode" | "explain" | "skip">
  ): IStepResult {
    if (d.response === "name") return { nextStep: this.askNameAgain }
    if (d.response === "dob") return { nextStep: this.askBirthdayAgain }
    if (d.response === "postcode") return { nextStep: this.askPostcodeAgain }
    if (d.response === "skip") return { body: "Okay", nextStep: this.sayAnotherWay }
    return { nextStep: this.sayExplanation }
  }

  @step.logState
  sayExplanation(d: IStepData<State>): IStepResult {
    d.state.explanationCount ??= 0
    d.state.explanationCount++
    const organisationName = this.rootStore.configStore.organisationName
    const organisationPhoneNumbers = this.rootStore.configStore.organisationPhoneNumbers ?? ""
    return {
      body: [
        "The reason as to why you can't be found in the NHS database is because the information you have provided is different from what you are registered with at your GP",
        "Common reasons for confusion are:\n" +
          "\n" +
          "1. You are married, but you are still registered with your GP under your maiden name\n" +
          "2. You have recently moved, but you haven't updated your GP with your new address",
        `If this has helped, you can go ahead and edit some of your information. Alternatively, you can phone ${organisationName} on ${organisationPhoneNumbers} and they will be able to help you`
      ],
      nextStep: this.promptConfirmDetails
    }
  }

  @step.logState
  askNameAgain(_d: IStepData<State>): IStepResult {
    return {
      body: "Please enter your full name",
      prompt: {
        id: this.getPromptId("askNameAgain"),
        type: "name"
      },
      nextStep: this.handleNameAgainWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, IName>, script: SpineSearchScript) => {
    d.state.name = d.response
    const username = script.getFullName(d.state)
    d.state.username = username
    script.rootStore.applicationStore.setUsername(username)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getInput: (d: IStepData<State, IName>) => {
      const { firstName, lastName, middleNames } = d.response
      return `${firstName}${middleNames ? ` ${middleNames}` : ""} ${lastName}`
    },
    getNextStep: (s: SpineSearchScript) => s.askNameAgain
  })
  handleNameAgainWithCrisis(_d: IStepData<State>): IStepResult {
    return { nextStep: this.fetchSpineData }
  }

  @step.logState
  askBirthdayAgain(_d: IStepData<State>): IStepResult {
    return {
      body: "Please enter your date of birth",
      nextStep: this.showPromptForBirthdayAgain
    }
  }

  @step.logState
  showPromptForBirthdayAgain(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForBirthdayAgain"),
        trackResponse: true,
        type: "date"
      },
      nextStep: this.handleBirthdayAgain
    }
  }

  @step.logState
  handleBirthdayAgain(d: IStepData<State>): IStepResult {
    try {
      const date = moment(d.response)
      invariant(date, "I'm sorry that's not a valid date. Please enter your date of birth")
      invariant(
        date.isValid(),
        "I'm sorry that's not a valid date. Please enter your date of birth"
      )
      invariant(
        date.isBefore(moment()),
        "Hmm… I don't think humans can time-travel. Can you try and edit your date of birth?"
      )
      invariant(
        date.isAfter(moment("1899-12-31")),
        "Hmm… I don't think humans live that long. Can you try and edit your date of birth?"
      )
      d.state.birthday = date.toDate().getTime()
      this.setPeople({ age: moment().diff(date, "years") })
    } catch (e) {
      this.logException(e, "handleBirthdayAgain")
      return {
        body: this.t(e.message),
        nextStep: this.showPromptForBirthdayAgain
      }
    }
    return { nextStep: this.fetchSpineData }
  }

  @step.logState
  askPostcodeAgain(_d: IStepData<State>): IStepResult {
    return {
      body: "Please type your postcode below",
      prompt: {
        id: this.getPromptId("askPostcodeAgain"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePostcodeAgainWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getNextStep: (s: SpineSearchScript) => s.askPostcodeAgain
  })
  async handlePostcodeAgainWithCrisis(d: IStepData<State>): Promise<IStepResult> {
    d.state.postcodeEntered = d.response || d.state.retryPostcode
    d.state.retryPostcodeTimes ??= 0
    d.state.retryPostcode = d.state.postcodeEntered

    const [postcode, postcodeStatus] = await getPostCodeDetails(d.response || d.state.retryPostcode)

    if (postcodeStatus === PostcodeStatus.Success) {
      d.state.userPostcode = postcode
      return { nextStep: this.fetchSpineData }
    }

    if (postcodeStatus === PostcodeStatus.NoInternetConnection) {
      return { nextStep: this.askRetryForOfflinePostcodeAgain }
    }
    const isInvalidPostcode = postcodeStatus === PostcodeStatus.InvalidPostcode
    const isNotFoundPostcode = postcodeStatus === PostcodeStatus.PostcodeNotFound
    if (isInvalidPostcode || isNotFoundPostcode) {
      const body = isInvalidPostcode
        ? "Hmmm, this doesn't seem to be a valid UK postcode"
        : "Hmmm, unfortunately I can't find this postcode"
      return {
        body,
        nextStep: this.askTypeItCorrectlyForPostcodeAgain
      }
    }
    if (
      postcodeStatus === PostcodeStatus.RequestFailed &&
      d.state.retryPostcodeTimes < (this.retryPostcodeTimes ?? 3)
    ) {
      // 📎 check commend about retryPostcode at the top of this method
      d.state.retryPostcodeTimes = d.state.retryPostcodeTimes + 1
      return { nextStep: this.askRetryForPostcodeAgain }
    }

    d.state.retryPostcodeTimes = 0
    return {
      body: [
        "Oh dear, for some reason I can't find anything using your postcode. Sorry about that.",
        "Don't worry if your postcode is correct, I can help you find your GP another way"
      ],
      nextStep: this.askDoYouKnowThePostCodeOfGP
    }
  }

  @step.logState
  askRetryForPostcodeAgain(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, it looks like something went wrong while looking up your postcode",
      prompt: {
        id: this.getPromptId("askRetryForPostcodeAgain"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Try again", value: false },
          { body: "Oops, let me re-type the postcode", value: true }
        ],
        isUndoAble: false
      },
      nextStep: this.handleRetryForPostcodeAgain
    }
  }

  @step.logState
  askTypeItCorrectlyForPostcodeAgain(_d: IStepData<State>): IStepResult {
    return {
      body: "Could you do me a favour and double check you typed it in correctly?",
      prompt: {
        id: this.getPromptId("askTypeItCorrectlyForPostcodeAgain"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "It's correct", value: true },
          { body: "Oops, let me re-type it", value: false }
        ]
      },
      nextStep: this.handleTypeItCorrectlyForPostcodeAgain
    }
  }

  @step.logStateAndResponse
  async handleTypeItCorrectlyForPostcodeAgain(d: IStepData<State, boolean>): Promise<IStepResult> {
    if (d.response) {
      this.track(TrackingEvents.INVALID_POSTCODE, { postcode: d.state.postcodeEntered })
      return {
        body: [
          "Oh dear, for some reason I couldn't find anything using your postcode. Sorry about that.",
          "Don't worry if your postcode is correct, I can help you find your GP another way"
        ],
        nextStep: this.askDoYouKnowThePostCodeOfGP
      }
    }
    d.state.retryPostcode = undefined
    const name = this.getName(d.state)
    return { body: `No worries ${name}`, nextStep: this.askPostcodeAgain }
  }

  @step.logState
  askRetryForOfflinePostcodeAgain(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, It looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("askRetryConnection"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.handleRetryForPostcodeAgain
    }
  }

  @step.logStateAndResponse
  handleRetryForPostcodeAgain(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.track(TrackingEvents.RE_ENTER_POSTCODE)
      return { nextStep: this.askPostcodeAgain }
    }
    this.track(TrackingEvents.TRY_AGAIN_POSTCODE)
    return { nextStep: this.handlePostcodeAgainWithCrisis }
  }

  @step.logState
  sayICouldntFindYourGP(d: IStepData<State>): IStepResult {
    const body = this.messagesSpineSearch?.sayICouldntFindYourGP ?? [
      "Found you!",
      "Hmm, however it looks like I wasn't able to find your GP"
    ]

    if (this.canReferWithoutGP && !this.messagesSpineSearch?.sayICouldntFindYourGP)
      body.push("Let me see if I can find your GP in an other way")

    return {
      body: this.t(body, this.getContext(d.state)),
      nextStep: this.canReferWithoutGP
        ? this.askSelectGPFromUserPostcode
        : this.sayItsImportantToFindGP
    }
  }

  @step.logState
  sayCannotReferYou(d: IStepData<State>): IStepResult {
    let body = [
      "Hmmmm... I haven't been able to find your GP {name}",
      "It's important that we identify your GP in order to find the right mental health service for you",
      "Without it, I cannot refer you to {organisationName}",
      "I'm just a humble robot. My only goal is to help you. Sorry I wasn't able to do that on this occasion",
      "If you feel this is an error and you should be eligible for the service, please call us on {organisationGenericPhoneNumber}"
    ].map(message => formatUnicorn(message, this.getContext(d.state)))
    if (
      this.messagesSpineSearch?.sayCannotReferYou &&
      this.messagesSpineSearch?.sayCannotReferYou.length
    ) {
      body = this.messagesSpineSearch?.sayCannotReferYou.map(message =>
        formatUnicorn(message, this.getContext(d.state))
      )
    }
    return {
      body: this.t(body),
      prompt: {
        id: this.getPromptId("sayCannotReferYou"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }],
        isUndoAble: false
      },
      clearStack: true,
      nextStep: this.goToGoodbye
    }
  }

  @step
  async selectIAPTServiceByGP(d: IStepData<State>): Promise<IStepResult> {
    return await this.selectIAPTServiceByODSGP(d)
  }

  @step.logState
  sayItsImportantToFindGP(d: IStepData<State>): IStepResult {
    const eligibleIAPTs = this.getEligibleIAPTSByAgeThreshold(d.state)
    const shouldProceedWithReferral = this.canReferWithoutGP && eligibleIAPTs.length
    return {
      body: "It's important that we identify your GP in order to find the right mental health service for you",
      nextStep: shouldProceedWithReferral
        ? this.askWantMeToReferYouAnyway
        : this.sayWithoutGPICannotReferYou
    }
  }

  @step.logState
  askWantMeToReferYouAnyway(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t(
        this.messagesSpineSearch?.askWantMeToReferYouAnywaySpineSearch ?? [
          "But that's okay 😊",
          `I can still refer you to ${organisationName}`,
          "Would you like me to do that for you?"
        ],
        this.getContext(d.state)
      ),
      prompt: {
        id: this.getPromptId("askWantMeToReferYouAnyway"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleWantMeToReferYouAnyway
    }
  }

  /** Generic Handlers */

  async onPostcodeOfUserSuccessful(_state: State): Promise<IStepResult> {
    return { nextStep: this.sayIntroToSpineSearch }
  }

  async onFailedSpineSearchCountReached(_state: State): Promise<IStepResult> {
    return {
      nextStep: this.shouldUseServiceSearchFallback
        ? this.sayICouldntFindYouInPDSAndGoManual
        : this.sayCannotReferYou
    }
  }

  getContext(state: State): Record<string, any> {
    return {
      ...this.rootStore.configStore,
      name: this.getName(state),
      iaptName: this.getIAPTName(state)
    }
  }
}

export default class SpineSearchDialogue extends AdHocDialogue<State, SpineSearchScript> {
  static id = DialogueIDs.SpineSearch
  readonly name: string = "SpineSearchDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>, settings?: ISpineSearchSettings) {
    super(
      SpineSearchDialogue.id,
      new SpineSearchScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
