import createSurveyDialogue from "../createSurveyDialogue"
import ISurvey, { SurveyType } from "../../models/ISurvey"
import { DialogueIDs } from "../DialogueIDs"

const GAD_2: ISurvey = {
  startingQuestion: [
    "This next set of questions is designed to measure common symptoms of anxiety",
    "Over the last 2 weeks, how often have you been bothered by the following problems?"
  ],
  questions: [
    {
      id: "1",
      questionName: "GAD7 - step1",
      question: "1. Feeling nervous, anxious or on edge?",
      type: "inline",
      progress: 0.5,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "Several days" },
        { points: 2, answer: "More than half the days" },
        { points: 3, answer: "Nearly every day" }
      ]
    },
    {
      id: "2",
      questionName: "GAD7 - step2",
      question: "2. Not being able to stop or control worrying?",
      type: "inline",
      progress: 1,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "Several days" },
        { points: 2, answer: "More than half the days" },
        { points: 3, answer: "Nearly every day" }
      ]
    }
  ]
}

export default createSurveyDialogue(DialogueIDs.GAD2, GAD_2, SurveyType.GAD2)
