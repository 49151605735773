import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

// NOTE: List is here:
// https://docs.google.com/spreadsheets/d/1ATFldEHXfScgJkJ8qxnIIe98i9DKBolI/edit#gid=1638925846

const iaptGPsMapEveryturnMedway: IAPTGPMap = {
  G82679: IAPTIDs.EVERYTURN_MEDWAY,
  G82161: IAPTIDs.EVERYTURN_MEDWAY,
  G82123: IAPTIDs.EVERYTURN_MEDWAY,
  G82233: IAPTIDs.EVERYTURN_MEDWAY,
  G82711: IAPTIDs.EVERYTURN_MEDWAY,
  G82653: IAPTIDs.EVERYTURN_MEDWAY,
  G82706: IAPTIDs.EVERYTURN_MEDWAY,
  G82676: IAPTIDs.EVERYTURN_MEDWAY,
  G82051: IAPTIDs.EVERYTURN_MEDWAY,
  Y00449: IAPTIDs.EVERYTURN_MEDWAY,
  G82203: IAPTIDs.EVERYTURN_MEDWAY,
  G82775: IAPTIDs.EVERYTURN_MEDWAY,
  G82600: IAPTIDs.EVERYTURN_MEDWAY,
  V81998: IAPTIDs.EVERYTURN_MEDWAY,
  G82198: IAPTIDs.EVERYTURN_MEDWAY,
  G82100: IAPTIDs.EVERYTURN_MEDWAY,
  G82108: IAPTIDs.EVERYTURN_MEDWAY,
  G82139: IAPTIDs.EVERYTURN_MEDWAY,
  G82180: IAPTIDs.EVERYTURN_MEDWAY,
  G82737: IAPTIDs.EVERYTURN_MEDWAY,
  G82708: IAPTIDs.EVERYTURN_MEDWAY,
  G82763: IAPTIDs.EVERYTURN_MEDWAY,
  G82719: IAPTIDs.EVERYTURN_MEDWAY,
  G82721: IAPTIDs.EVERYTURN_MEDWAY,
  G82741: IAPTIDs.EVERYTURN_MEDWAY,
  G82635: IAPTIDs.EVERYTURN_MEDWAY,
  G82704: IAPTIDs.EVERYTURN_MEDWAY,
  G82162: IAPTIDs.EVERYTURN_MEDWAY,
  G82129: IAPTIDs.EVERYTURN_MEDWAY,
  G82744: IAPTIDs.EVERYTURN_MEDWAY,
  G82753: IAPTIDs.EVERYTURN_MEDWAY,
  G82109: IAPTIDs.EVERYTURN_MEDWAY,
  G82622: IAPTIDs.EVERYTURN_MEDWAY,
  G82095: IAPTIDs.EVERYTURN_MEDWAY,
  G82820: IAPTIDs.EVERYTURN_MEDWAY,
  G82762: IAPTIDs.EVERYTURN_MEDWAY,
  G82106: IAPTIDs.EVERYTURN_MEDWAY,
  G82113: IAPTIDs.EVERYTURN_MEDWAY,
  G82154: IAPTIDs.EVERYTURN_MEDWAY,
  G82697: IAPTIDs.EVERYTURN_MEDWAY,
  G82077: IAPTIDs.EVERYTURN_MEDWAY,
  G82184: IAPTIDs.EVERYTURN_MEDWAY,
  G82226: IAPTIDs.EVERYTURN_MEDWAY,
  G82014: IAPTIDs.EVERYTURN_MEDWAY
}

const iaptEveryturnGPsMapKent: IAPTGPMap = {
  G82658: IAPTIDs.EVERYTURN_KENT,
  G82080: IAPTIDs.EVERYTURN_KENT,
  G82730: IAPTIDs.EVERYTURN_KENT,
  G82050: IAPTIDs.EVERYTURN_KENT,
  G82142: IAPTIDs.EVERYTURN_KENT,
  G82186: IAPTIDs.EVERYTURN_KENT,
  G82053: IAPTIDs.EVERYTURN_KENT,
  G82114: IAPTIDs.EVERYTURN_KENT,
  G82049: IAPTIDs.EVERYTURN_KENT,
  G82087: IAPTIDs.EVERYTURN_KENT,
  G82094: IAPTIDs.EVERYTURN_KENT,
  V81998: IAPTIDs.EVERYTURN_KENT,
  G82119: IAPTIDs.EVERYTURN_KENT,
  G82071: IAPTIDs.EVERYTURN_KENT,
  G82115: IAPTIDs.EVERYTURN_KENT,
  G82090: IAPTIDs.EVERYTURN_KENT,
  G82082: IAPTIDs.EVERYTURN_KENT,
  G82060: IAPTIDs.EVERYTURN_KENT,
  G82039: IAPTIDs.EVERYTURN_KENT,
  GP82228: IAPTIDs.EVERYTURN_KENT,
  G82063: IAPTIDs.EVERYTURN_KENT,
  GP2090: IAPTIDs.EVERYTURN_KENT,
  G82138: IAPTIDs.EVERYTURN_KENT,
  G82140: IAPTIDs.EVERYTURN_KENT,
  G82802: IAPTIDs.EVERYTURN_KENT,
  G82790: IAPTIDs.EVERYTURN_KENT,
  G82027: IAPTIDs.EVERYTURN_KENT,
  G82228: IAPTIDs.EVERYTURN_KENT,
  G82639: IAPTIDs.EVERYTURN_KENT,
  G82647: IAPTIDs.EVERYTURN_KENT,
  G82044: IAPTIDs.EVERYTURN_KENT,
  G82809: IAPTIDs.EVERYTURN_KENT,
  G82225: IAPTIDs.EVERYTURN_KENT,
  GP2028: IAPTIDs.EVERYTURN_KENT,
  G82780: IAPTIDs.EVERYTURN_KENT,
  G82648: IAPTIDs.EVERYTURN_KENT,
  G82690: IAPTIDs.EVERYTURN_KENT,
  G82028: IAPTIDs.EVERYTURN_KENT,
  G82032: IAPTIDs.EVERYTURN_KENT,
  G82021: IAPTIDs.EVERYTURN_KENT,
  G82006: IAPTIDs.EVERYTURN_KENT,
  G82808: IAPTIDs.EVERYTURN_KENT,
  G82185: IAPTIDs.EVERYTURN_KENT,
  G82143: IAPTIDs.EVERYTURN_KENT,
  G82212: IAPTIDs.EVERYTURN_KENT,
  G82218: IAPTIDs.EVERYTURN_KENT,
  G82048: IAPTIDs.EVERYTURN_KENT,
  G82122: IAPTIDs.EVERYTURN_KENT,
  G82062: IAPTIDs.EVERYTURN_KENT,
  G82067: IAPTIDs.EVERYTURN_KENT,
  G82073: IAPTIDs.EVERYTURN_KENT,
  G82088: IAPTIDs.EVERYTURN_KENT,
  G82097: IAPTIDs.EVERYTURN_KENT,
  G82121: IAPTIDs.EVERYTURN_KENT,
  G82128: IAPTIDs.EVERYTURN_KENT,
  G82117: IAPTIDs.EVERYTURN_KENT,
  G82086: IAPTIDs.EVERYTURN_KENT,
  G82091: IAPTIDs.EVERYTURN_KENT,
  G82111: IAPTIDs.EVERYTURN_KENT,
  G82072: IAPTIDs.EVERYTURN_KENT,
  G82069: IAPTIDs.EVERYTURN_KENT,
  G82217: IAPTIDs.EVERYTURN_KENT,
  G82038: IAPTIDs.EVERYTURN_KENT,
  G82147: IAPTIDs.EVERYTURN_KENT,
  G82211: IAPTIDs.EVERYTURN_KENT,
  G82165: IAPTIDs.EVERYTURN_KENT,
  G82160: IAPTIDs.EVERYTURN_KENT,
  G82036: IAPTIDs.EVERYTURN_KENT,
  G82002: IAPTIDs.EVERYTURN_KENT,
  GP2038: IAPTIDs.EVERYTURN_KENT,
  G82731: IAPTIDs.EVERYTURN_KENT,
  G82729: IAPTIDs.EVERYTURN_KENT,
  G82665: IAPTIDs.EVERYTURN_KENT,
  G82007: IAPTIDs.EVERYTURN_KENT,
  G82015: IAPTIDs.EVERYTURN_KENT,
  G82018: IAPTIDs.EVERYTURN_KENT,
  G82652: IAPTIDs.EVERYTURN_KENT,
  G82696: IAPTIDs.EVERYTURN_KENT,
  G82700: IAPTIDs.EVERYTURN_KENT,
  G82232: IAPTIDs.EVERYTURN_KENT,
  G82227: IAPTIDs.EVERYTURN_KENT,
  G82684: IAPTIDs.EVERYTURN_KENT,
  Y02506: IAPTIDs.EVERYTURN_KENT,
  G82687: IAPTIDs.EVERYTURN_KENT,
  G82682: IAPTIDs.EVERYTURN_KENT,
  G82231: IAPTIDs.EVERYTURN_KENT,
  G82671: IAPTIDs.EVERYTURN_KENT,
  G82634: IAPTIDs.EVERYTURN_KENT,
  G82702: IAPTIDs.EVERYTURN_KENT,
  G82698: IAPTIDs.EVERYTURN_KENT,
  G82023: IAPTIDs.EVERYTURN_KENT,
  G82757: IAPTIDs.EVERYTURN_KENT,
  G82693: IAPTIDs.EVERYTURN_KENT,
  G82799: IAPTIDs.EVERYTURN_KENT,
  G82035: IAPTIDs.EVERYTURN_KENT,
  G82026: IAPTIDs.EVERYTURN_KENT,
  G82057: IAPTIDs.EVERYTURN_KENT,
  G82796: IAPTIDs.EVERYTURN_KENT,
  G82126: IAPTIDs.EVERYTURN_KENT,
  G82219: IAPTIDs.EVERYTURN_KENT,
  G82064: IAPTIDs.EVERYTURN_KENT,
  G82066: IAPTIDs.EVERYTURN_KENT,
  G82150: IAPTIDs.EVERYTURN_KENT,
  G82046: IAPTIDs.EVERYTURN_KENT,
  G82052: IAPTIDs.EVERYTURN_KENT,
  G82079: IAPTIDs.EVERYTURN_KENT,
  G82105: IAPTIDs.EVERYTURN_KENT,
  G82107: IAPTIDs.EVERYTURN_KENT,
  G82666: IAPTIDs.EVERYTURN_KENT,
  G82020: IAPTIDs.EVERYTURN_KENT,
  G82650: IAPTIDs.EVERYTURN_KENT
}

const iaptEveryturnGPsMapNotts: IAPTGPMap = {
  C82040: IAPTIDs.EVERYTURN_NOTTS,
  C84001: IAPTIDs.EVERYTURN_NOTTS,
  C84004: IAPTIDs.EVERYTURN_NOTTS,
  C84005: IAPTIDs.EVERYTURN_NOTTS,
  C84008: IAPTIDs.EVERYTURN_NOTTS,
  C84009: IAPTIDs.EVERYTURN_NOTTS,
  C84010: IAPTIDs.EVERYTURN_NOTTS,
  C84011: IAPTIDs.EVERYTURN_NOTTS,
  C84012: IAPTIDs.EVERYTURN_NOTTS,
  C84013: IAPTIDs.EVERYTURN_NOTTS,
  C84014: IAPTIDs.EVERYTURN_NOTTS,
  C84016: IAPTIDs.EVERYTURN_NOTTS,
  C84017: IAPTIDs.EVERYTURN_NOTTS,
  C84018: IAPTIDs.EVERYTURN_NOTTS,
  C84019: IAPTIDs.EVERYTURN_NOTTS,
  C84020: IAPTIDs.EVERYTURN_NOTTS,
  C84021: IAPTIDs.EVERYTURN_NOTTS,
  C84023: IAPTIDs.EVERYTURN_NOTTS,
  C84024: IAPTIDs.EVERYTURN_NOTTS,
  C84025: IAPTIDs.EVERYTURN_NOTTS,
  C84026: IAPTIDs.EVERYTURN_NOTTS,
  C84028: IAPTIDs.EVERYTURN_NOTTS,
  C84029: IAPTIDs.EVERYTURN_NOTTS,
  C84030: IAPTIDs.EVERYTURN_NOTTS,
  C84031: IAPTIDs.EVERYTURN_NOTTS,
  C84032: IAPTIDs.EVERYTURN_NOTTS,
  C84033: IAPTIDs.EVERYTURN_NOTTS,
  C84034: IAPTIDs.EVERYTURN_NOTTS,
  C84035: IAPTIDs.EVERYTURN_NOTTS,
  C84036: IAPTIDs.EVERYTURN_NOTTS,
  C84037: IAPTIDs.EVERYTURN_NOTTS,
  C84039: IAPTIDs.EVERYTURN_NOTTS,
  C84042: IAPTIDs.EVERYTURN_NOTTS,
  C84043: IAPTIDs.EVERYTURN_NOTTS,
  C84044: IAPTIDs.EVERYTURN_NOTTS,
  C84045: IAPTIDs.EVERYTURN_NOTTS,
  C84046: IAPTIDs.EVERYTURN_NOTTS,
  C84047: IAPTIDs.EVERYTURN_NOTTS,
  C84049: IAPTIDs.EVERYTURN_NOTTS,
  C84051: IAPTIDs.EVERYTURN_NOTTS,
  C84053: IAPTIDs.EVERYTURN_NOTTS,
  C84055: IAPTIDs.EVERYTURN_NOTTS,
  C84057: IAPTIDs.EVERYTURN_NOTTS,
  C84059: IAPTIDs.EVERYTURN_NOTTS,
  C84060: IAPTIDs.EVERYTURN_NOTTS,
  C84061: IAPTIDs.EVERYTURN_NOTTS,
  C84063: IAPTIDs.EVERYTURN_NOTTS,
  C84064: IAPTIDs.EVERYTURN_NOTTS,
  C84065: IAPTIDs.EVERYTURN_NOTTS,
  C84066: IAPTIDs.EVERYTURN_NOTTS,
  C84067: IAPTIDs.EVERYTURN_NOTTS,
  C84069: IAPTIDs.EVERYTURN_NOTTS,
  C84072: IAPTIDs.EVERYTURN_NOTTS,
  C84074: IAPTIDs.EVERYTURN_NOTTS,
  C84076: IAPTIDs.EVERYTURN_NOTTS,
  C84077: IAPTIDs.EVERYTURN_NOTTS,
  C84078: IAPTIDs.EVERYTURN_NOTTS,
  C84080: IAPTIDs.EVERYTURN_NOTTS,
  C84081: IAPTIDs.EVERYTURN_NOTTS,
  C84084: IAPTIDs.EVERYTURN_NOTTS,
  C84085: IAPTIDs.EVERYTURN_NOTTS,
  C84086: IAPTIDs.EVERYTURN_NOTTS,
  C84087: IAPTIDs.EVERYTURN_NOTTS,
  C84090: IAPTIDs.EVERYTURN_NOTTS,
  C84091: IAPTIDs.EVERYTURN_NOTTS,
  C84092: IAPTIDs.EVERYTURN_NOTTS,
  C84094: IAPTIDs.EVERYTURN_NOTTS,
  C84095: IAPTIDs.EVERYTURN_NOTTS,
  C84101: IAPTIDs.EVERYTURN_NOTTS,
  C84103: IAPTIDs.EVERYTURN_NOTTS,
  C84105: IAPTIDs.EVERYTURN_NOTTS,
  C84106: IAPTIDs.EVERYTURN_NOTTS,
  C84107: IAPTIDs.EVERYTURN_NOTTS,
  C84112: IAPTIDs.EVERYTURN_NOTTS,
  C84113: IAPTIDs.EVERYTURN_NOTTS,
  C84114: IAPTIDs.EVERYTURN_NOTTS,
  C84115: IAPTIDs.EVERYTURN_NOTTS,
  C84116: IAPTIDs.EVERYTURN_NOTTS,
  C84117: IAPTIDs.EVERYTURN_NOTTS,
  C84120: IAPTIDs.EVERYTURN_NOTTS,
  C84122: IAPTIDs.EVERYTURN_NOTTS,
  C84123: IAPTIDs.EVERYTURN_NOTTS,
  C84127: IAPTIDs.EVERYTURN_NOTTS,
  C84129: IAPTIDs.EVERYTURN_NOTTS,
  C84131: IAPTIDs.EVERYTURN_NOTTS,
  C84135: IAPTIDs.EVERYTURN_NOTTS,
  C84136: IAPTIDs.EVERYTURN_NOTTS,
  C84138: IAPTIDs.EVERYTURN_NOTTS,
  C84140: IAPTIDs.EVERYTURN_NOTTS,
  C84142: IAPTIDs.EVERYTURN_NOTTS,
  C84144: IAPTIDs.EVERYTURN_NOTTS,
  C84150: IAPTIDs.EVERYTURN_NOTTS,
  C84151: IAPTIDs.EVERYTURN_NOTTS,
  C84605: IAPTIDs.EVERYTURN_NOTTS,
  C84613: IAPTIDs.EVERYTURN_NOTTS,
  C84619: IAPTIDs.EVERYTURN_NOTTS,
  C84621: IAPTIDs.EVERYTURN_NOTTS,
  C84624: IAPTIDs.EVERYTURN_NOTTS,
  C84628: IAPTIDs.EVERYTURN_NOTTS,
  C84629: IAPTIDs.EVERYTURN_NOTTS,
  C84637: IAPTIDs.EVERYTURN_NOTTS,
  C84646: IAPTIDs.EVERYTURN_NOTTS,
  C84647: IAPTIDs.EVERYTURN_NOTTS,
  C84654: IAPTIDs.EVERYTURN_NOTTS,
  C84656: IAPTIDs.EVERYTURN_NOTTS,
  C84658: IAPTIDs.EVERYTURN_NOTTS,
  C84660: IAPTIDs.EVERYTURN_NOTTS,
  C84664: IAPTIDs.EVERYTURN_NOTTS,
  C84667: IAPTIDs.EVERYTURN_NOTTS,
  C84676: IAPTIDs.EVERYTURN_NOTTS,
  C84679: IAPTIDs.EVERYTURN_NOTTS,
  C84682: IAPTIDs.EVERYTURN_NOTTS,
  C84683: IAPTIDs.EVERYTURN_NOTTS,
  C84691: IAPTIDs.EVERYTURN_NOTTS,
  C84692: IAPTIDs.EVERYTURN_NOTTS,
  C84693: IAPTIDs.EVERYTURN_NOTTS,
  C84694: IAPTIDs.EVERYTURN_NOTTS,
  C84695: IAPTIDs.EVERYTURN_NOTTS,
  C84696: IAPTIDs.EVERYTURN_NOTTS,
  C84703: IAPTIDs.EVERYTURN_NOTTS,
  C84704: IAPTIDs.EVERYTURN_NOTTS,
  C84705: IAPTIDs.EVERYTURN_NOTTS,
  C84714: IAPTIDs.EVERYTURN_NOTTS,
  C84717: IAPTIDs.EVERYTURN_NOTTS,
  C84720: IAPTIDs.EVERYTURN_NOTTS,
  Y00026: IAPTIDs.EVERYTURN_NOTTS,
  Y02847: IAPTIDs.EVERYTURN_NOTTS,
  Y03124: IAPTIDs.EVERYTURN_NOTTS,
  Y05346: IAPTIDs.EVERYTURN_NOTTS,
  Y05369: IAPTIDs.EVERYTURN_NOTTS,
  Y05622: IAPTIDs.EVERYTURN_NOTTS,
  Y05690: IAPTIDs.EVERYTURN_NOTTS,
  Y06356: IAPTIDs.EVERYTURN_NOTTS,
  Y06443: IAPTIDs.EVERYTURN_NOTTS,
  Y06507: IAPTIDs.EVERYTURN_NOTTS,
  Y06792: IAPTIDs.EVERYTURN_NOTTS
}

const iaptEveryturnGPsMapDerby: IAPTGPMap = {
  C81001: IAPTIDs.EVERYTURN_DERBY,
  C81002: IAPTIDs.EVERYTURN_DERBY,
  C81003: IAPTIDs.EVERYTURN_DERBY,
  C81004: IAPTIDs.EVERYTURN_DERBY,
  C81005: IAPTIDs.EVERYTURN_DERBY,
  C81006: IAPTIDs.EVERYTURN_DERBY,
  C81007: IAPTIDs.EVERYTURN_DERBY,
  C81008: IAPTIDs.EVERYTURN_DERBY,
  C81009: IAPTIDs.EVERYTURN_DERBY,
  C81010: IAPTIDs.EVERYTURN_DERBY,
  C81012: IAPTIDs.EVERYTURN_DERBY,
  C81013: IAPTIDs.EVERYTURN_DERBY,
  C81014: IAPTIDs.EVERYTURN_DERBY,
  C81015: IAPTIDs.EVERYTURN_DERBY,
  C81016: IAPTIDs.EVERYTURN_DERBY,
  C81017: IAPTIDs.EVERYTURN_DERBY,
  C81020: IAPTIDs.EVERYTURN_DERBY,
  C81021: IAPTIDs.EVERYTURN_DERBY,
  C81022: IAPTIDs.EVERYTURN_DERBY,
  C81023: IAPTIDs.EVERYTURN_DERBY,
  C81025: IAPTIDs.EVERYTURN_DERBY,
  C81026: IAPTIDs.EVERYTURN_DERBY,
  C81027: IAPTIDs.EVERYTURN_DERBY,
  C81028: IAPTIDs.EVERYTURN_DERBY,
  C81029: IAPTIDs.EVERYTURN_DERBY,
  C81030: IAPTIDs.EVERYTURN_DERBY,
  C81031: IAPTIDs.EVERYTURN_DERBY,
  C81032: IAPTIDs.EVERYTURN_DERBY,
  C81033: IAPTIDs.EVERYTURN_DERBY,
  C81034: IAPTIDs.EVERYTURN_DERBY,
  C81035: IAPTIDs.EVERYTURN_DERBY,
  C81036: IAPTIDs.EVERYTURN_DERBY,
  C81037: IAPTIDs.EVERYTURN_DERBY,
  C81038: IAPTIDs.EVERYTURN_DERBY,
  C81039: IAPTIDs.EVERYTURN_DERBY,
  C81040: IAPTIDs.EVERYTURN_DERBY,
  C81041: IAPTIDs.EVERYTURN_DERBY,
  C81042: IAPTIDs.EVERYTURN_DERBY,
  C81044: IAPTIDs.EVERYTURN_DERBY,
  C81045: IAPTIDs.EVERYTURN_DERBY,
  C81046: IAPTIDs.EVERYTURN_DERBY,
  C81047: IAPTIDs.EVERYTURN_DERBY,
  C81048: IAPTIDs.EVERYTURN_DERBY,
  C81049: IAPTIDs.EVERYTURN_DERBY,
  C81050: IAPTIDs.EVERYTURN_DERBY,
  C81051: IAPTIDs.EVERYTURN_DERBY,
  C81052: IAPTIDs.EVERYTURN_DERBY,
  C81053: IAPTIDs.EVERYTURN_DERBY,
  C81054: IAPTIDs.EVERYTURN_DERBY,
  C81055: IAPTIDs.EVERYTURN_DERBY,
  C81056: IAPTIDs.EVERYTURN_DERBY,
  C81057: IAPTIDs.EVERYTURN_DERBY,
  C81058: IAPTIDs.EVERYTURN_DERBY,
  C81059: IAPTIDs.EVERYTURN_DERBY,
  C81060: IAPTIDs.EVERYTURN_DERBY,
  C81061: IAPTIDs.EVERYTURN_DERBY,
  C81062: IAPTIDs.EVERYTURN_DERBY,
  C81063: IAPTIDs.EVERYTURN_DERBY,
  C81064: IAPTIDs.EVERYTURN_DERBY,
  C81065: IAPTIDs.EVERYTURN_DERBY,
  C81066: IAPTIDs.EVERYTURN_DERBY,
  C81067: IAPTIDs.EVERYTURN_DERBY,
  C81068: IAPTIDs.EVERYTURN_DERBY,
  C81069: IAPTIDs.EVERYTURN_DERBY,
  C81070: IAPTIDs.EVERYTURN_DERBY,
  C81071: IAPTIDs.EVERYTURN_DERBY,
  C81072: IAPTIDs.EVERYTURN_DERBY,
  C81073: IAPTIDs.EVERYTURN_DERBY,
  C81074: IAPTIDs.EVERYTURN_DERBY,
  C81075: IAPTIDs.EVERYTURN_DERBY,
  C81077: IAPTIDs.EVERYTURN_DERBY,
  C81080: IAPTIDs.EVERYTURN_DERBY,
  C81081: IAPTIDs.EVERYTURN_DERBY,
  C81082: IAPTIDs.EVERYTURN_DERBY,
  C81083: IAPTIDs.EVERYTURN_DERBY,
  C81084: IAPTIDs.EVERYTURN_DERBY,
  C81086: IAPTIDs.EVERYTURN_DERBY,
  C81087: IAPTIDs.EVERYTURN_DERBY,
  C81089: IAPTIDs.EVERYTURN_DERBY,
  C81090: IAPTIDs.EVERYTURN_DERBY,
  C81091: IAPTIDs.EVERYTURN_DERBY,
  C81092: IAPTIDs.EVERYTURN_DERBY,
  C81094: IAPTIDs.EVERYTURN_DERBY,
  C81095: IAPTIDs.EVERYTURN_DERBY,
  C81096: IAPTIDs.EVERYTURN_DERBY,
  C81097: IAPTIDs.EVERYTURN_DERBY,
  C81099: IAPTIDs.EVERYTURN_DERBY,
  C81101: IAPTIDs.EVERYTURN_DERBY,
  C81106: IAPTIDs.EVERYTURN_DERBY,
  C81108: IAPTIDs.EVERYTURN_DERBY,
  C81110: IAPTIDs.EVERYTURN_DERBY,
  C81113: IAPTIDs.EVERYTURN_DERBY,
  C81114: IAPTIDs.EVERYTURN_DERBY,
  C81115: IAPTIDs.EVERYTURN_DERBY,
  C81118: IAPTIDs.EVERYTURN_DERBY,
  C81604: IAPTIDs.EVERYTURN_DERBY,
  C81611: IAPTIDs.EVERYTURN_DERBY,
  C81615: IAPTIDs.EVERYTURN_DERBY,
  C81616: IAPTIDs.EVERYTURN_DERBY,
  C81634: IAPTIDs.EVERYTURN_DERBY,
  C81638: IAPTIDs.EVERYTURN_DERBY,
  C81640: IAPTIDs.EVERYTURN_DERBY,
  C81642: IAPTIDs.EVERYTURN_DERBY,
  C81647: IAPTIDs.EVERYTURN_DERBY,
  C81649: IAPTIDs.EVERYTURN_DERBY,
  C81652: IAPTIDs.EVERYTURN_DERBY,
  C81653: IAPTIDs.EVERYTURN_DERBY,
  C81655: IAPTIDs.EVERYTURN_DERBY,
  C81658: IAPTIDs.EVERYTURN_DERBY,
  C81660: IAPTIDs.EVERYTURN_DERBY,
  C81661: IAPTIDs.EVERYTURN_DERBY,
  C81662: IAPTIDs.EVERYTURN_DERBY,
  Y01812: IAPTIDs.EVERYTURN_DERBY,
  Y02442: IAPTIDs.EVERYTURN_DERBY,
  Y04977: IAPTIDs.EVERYTURN_DERBY,
  Y04995: IAPTIDs.EVERYTURN_DERBY,
  Y05286: IAPTIDs.EVERYTURN_DERBY,
  Y05733: IAPTIDs.EVERYTURN_DERBY
}

export const iaptGPMapEveryturn: IAPTGPMap = {
  ...iaptEveryturnGPsMapKent,
  ...iaptGPsMapEveryturnMedway,
  ...iaptEveryturnGPsMapNotts,
  ...iaptEveryturnGPsMapDerby
}

const iaptGPsMapEveryturnMedwayDEMO: IAPTGPMap = {
  G82679: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82161: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82123: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82233: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82711: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82653: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82706: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82676: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82051: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  Y00449: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82203: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82775: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82600: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  V81998: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82198: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82100: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82108: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82139: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82180: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82737: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82708: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82763: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82719: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82721: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82741: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82635: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82704: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82162: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82129: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82744: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82753: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82109: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82622: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82095: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82820: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82762: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82106: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82113: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82154: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82697: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82077: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82184: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82226: IAPTIDs.EVERYTURN_MEDWAY_DEMO,
  G82014: IAPTIDs.EVERYTURN_MEDWAY_DEMO
}

const iaptEveryturnGPsMapKentDEMO: IAPTGPMap = {
  G82658: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82080: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82730: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82050: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82142: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82186: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82053: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82114: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82049: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82087: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82094: IAPTIDs.EVERYTURN_KENT_DEMO,
  V81998: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82119: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82071: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82115: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82090: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82082: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82060: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82039: IAPTIDs.EVERYTURN_KENT_DEMO,
  GP82228: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82063: IAPTIDs.EVERYTURN_KENT_DEMO,
  GP2090: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82138: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82140: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82802: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82790: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82027: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82228: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82639: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82647: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82044: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82809: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82225: IAPTIDs.EVERYTURN_KENT_DEMO,
  GP2028: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82780: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82648: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82690: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82028: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82032: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82021: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82006: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82808: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82185: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82143: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82212: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82218: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82048: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82122: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82062: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82067: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82073: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82088: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82097: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82121: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82128: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82117: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82086: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82091: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82111: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82072: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82069: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82217: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82038: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82147: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82211: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82165: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82160: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82036: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82002: IAPTIDs.EVERYTURN_KENT_DEMO,
  GP2038: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82731: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82729: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82665: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82015: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82018: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82652: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82696: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82700: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82232: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82227: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82007: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82684: IAPTIDs.EVERYTURN_KENT_DEMO,
  Y02506: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82687: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82682: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82231: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82671: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82634: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82702: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82698: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82023: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82757: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82693: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82799: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82035: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82026: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82057: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82796: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82126: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82219: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82064: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82066: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82150: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82046: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82052: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82079: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82105: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82107: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82666: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82020: IAPTIDs.EVERYTURN_KENT_DEMO,
  G82650: IAPTIDs.EVERYTURN_KENT_DEMO
}

const iaptEveryturnGPsMapNottsDEMO: IAPTGPMap = {
  C82040: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84001: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84004: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84005: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84008: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84009: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84010: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84011: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84012: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84013: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84014: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84016: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84017: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84018: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84019: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84020: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84021: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84023: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84024: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84025: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84026: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84028: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84029: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84030: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84031: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84032: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84033: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84034: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84035: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84036: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84037: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84039: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84042: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84043: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84044: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84045: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84046: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84047: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84049: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84051: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84053: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84055: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84057: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84059: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84060: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84061: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84063: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84064: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84065: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84066: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84067: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84069: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84072: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84074: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84076: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84077: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84078: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84080: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84081: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84084: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84085: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84086: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84087: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84090: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84091: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84092: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84094: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84095: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84101: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84103: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84105: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84106: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84107: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84112: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84113: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84114: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84115: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84116: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84117: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84120: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84122: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84123: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84127: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84129: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84131: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84135: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84136: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84138: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84140: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84142: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84144: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84150: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84151: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84605: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84613: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84619: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84621: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84624: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84628: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84629: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84637: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84646: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84647: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84654: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84656: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84658: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84660: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84664: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84667: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84676: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84679: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84682: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84683: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84691: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84692: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84693: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84694: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84695: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84696: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84703: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84704: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84705: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84714: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84717: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  C84720: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  Y00026: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  Y02847: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  Y03124: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  Y05346: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  Y05369: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  Y05622: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  Y05690: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  Y06356: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  Y06443: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  Y06507: IAPTIDs.EVERYTURN_NOTTS_DEMO,
  Y06792: IAPTIDs.EVERYTURN_NOTTS_DEMO
}

const iaptEveryturnGPsMapDerbyDEMO: IAPTGPMap = {
  C81001: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81002: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81003: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81004: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81005: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81006: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81007: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81008: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81009: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81010: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81012: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81013: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81014: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81015: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81016: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81017: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81020: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81021: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81022: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81023: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81025: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81026: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81027: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81028: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81029: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81030: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81031: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81032: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81033: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81034: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81035: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81036: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81037: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81038: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81039: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81040: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81041: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81042: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81044: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81045: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81046: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81047: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81048: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81049: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81050: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81051: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81052: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81053: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81054: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81055: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81056: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81057: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81058: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81059: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81060: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81061: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81062: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81063: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81064: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81065: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81066: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81067: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81068: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81069: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81070: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81071: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81072: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81073: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81074: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81075: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81077: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81080: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81081: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81082: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81083: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81084: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81086: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81087: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81089: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81090: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81091: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81092: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81094: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81095: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81096: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81097: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81099: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81101: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81106: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81108: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81110: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81113: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81114: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81115: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81118: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81604: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81611: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81615: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81616: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81634: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81638: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81640: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81642: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81647: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81649: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81652: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81653: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81655: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81658: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81660: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81661: IAPTIDs.EVERYTURN_DERBY_DEMO,
  C81662: IAPTIDs.EVERYTURN_DERBY_DEMO,
  Y01812: IAPTIDs.EVERYTURN_DERBY_DEMO,
  Y02442: IAPTIDs.EVERYTURN_DERBY_DEMO,
  Y04977: IAPTIDs.EVERYTURN_DERBY_DEMO,
  Y04995: IAPTIDs.EVERYTURN_DERBY_DEMO,
  Y05286: IAPTIDs.EVERYTURN_DERBY_DEMO,
  Y05733: IAPTIDs.EVERYTURN_DERBY_DEMO
}

export const iaptGPMapEveryturnDEMO: IAPTGPMap = {
  ...iaptEveryturnGPsMapKentDEMO,
  ...iaptGPsMapEveryturnMedwayDEMO,
  ...iaptEveryturnGPsMapNottsDEMO,
  ...iaptEveryturnGPsMapDerbyDEMO
}
