import React from "react"
import classNames from "classnames"
import "./ChatWindow.scss"
import ChatHeader from "../ChatHeader/ChatHeader"
import { useLauncherContext } from "../../contexts/LauncherContext"
import { useConfigStore } from "../../contexts/RootStoreContext"
import { FocusScope } from "@react-aria/focus"
import Passthrough from "../Passthrough"
import { isIE } from "../../../models/Constants"

const FocusComponent = isIE ? Passthrough : FocusScope

interface Props {
  children?: React.ReactElement | React.ReactElement[] | null
}

export default function ChatWindow(props: Props): React.ReactElement {
  const config = useConfigStore()
  const { isOpen, onToggle } = useLauncherContext()

  const css = classNames("lb-chat-window", {
    opened: isOpen,
    closed: !isOpen,
    fullscreen: config.fullscreen
  })

  return (
    <FocusComponent autoFocus contain={process.env.NODE_ENV !== "development"} restoreFocus>
      <div id="lb-chat-window" className={css} data-testid="chat-window">
        <ChatHeader onClose={onToggle} />
        {props.children}
      </div>
    </FocusComponent>
  )
}
