import ISurvey, { SurveyType } from "../../models/ISurvey"
import createSurveyDialogue from "../createSurveyDialogue"
import { DialogueIDs } from "../DialogueIDs"

const HAM_D: ISurvey = {
  startingQuestion: [
    "This next set of 21 questions is designed to measure common symptoms of depression",
    "For each question, please select the response that best describes how you have been feeling over the last 2 weeks"
  ],
  questions: [
    {
      id: "1",
      questionName: "HAM-D - step1",
      question:
        "1. Depressed Mood (Gloomy attitude, pessimism about the future, feeling of sadness, tendency to weep)",
      type: "inline",
      progress: 0.06,
      answers: [
        { points: 0, answer: "Absent" },
        { points: 1, answer: "Sadness, etc." },
        { points: 2, answer: "Occasional weeping" },
        { points: 3, answer: "Frequent weeping" },
        { points: 4, answer: "Extreme symptoms" }
      ]
    },
    {
      id: "2",
      questionName: "HAM-D - step2",
      question: "2. Feelings of guilt",
      type: "inline",
      progress: 0.12,
      answers: [
        { points: 0, answer: "Absent" },
        { points: 1, answer: "Self-reproach, feels he/she has let people down" },
        { points: 2, answer: "Ideas of guilt" },
        { points: 3, answer: "Present illness is a punishment; delusions of guilt" },
        { points: 4, answer: "Hallucinations of guilt" }
      ]
    },
    {
      id: "3",
      questionName: "HAM-D - step3",
      question: "3. Suicide",
      type: "inline",
      progress: 0.18,
      answers: [
        { points: 0, answer: "Absent" },
        { points: 1, answer: "Feels life is not worth living" },
        { points: 2, answer: "Wishes he/she were dead" },
        { points: 3, answer: "Suicidal ideas or gestures" },
        { points: 4, answer: "Attempts at suicide" }
      ]
    },
    {
      id: "4",
      questionName: "HAM-D - step4",
      question: "4. Insomnia - Initial (Difficulty in falling asleep)",
      type: "inline",
      progress: 0.24,
      answers: [
        { points: 0, answer: "Absent" },
        { points: 1, answer: "Occasional" },
        { points: 2, answer: "Frequent" }
      ]
    },
    {
      id: "5",
      questionName: "HAM-D - step5",
      question:
        "5. Insomnia - Middle (Complains of being restless and disturbed during the night. Waking during the night.)",
      type: "inline",
      progress: 0.24,
      answers: [
        { points: 0, answer: "Absent" },
        { points: 1, answer: "Occasional" },
        { points: 2, answer: "Frequent" }
      ]
    },
    {
      id: "6",
      questionName: "HAM-D - step6",
      question:
        "6. Insomnia - Delayed (Waking in early hours of the morning and unable to fall asleep again)",
      type: "inline",
      progress: 0.3,
      answers: [
        { points: 0, answer: "Absent" },
        { points: 1, answer: "Occasional" },
        { points: 2, answer: "Frequent" }
      ]
    },
    {
      id: "7",
      questionName: "HAM-D - step7",
      question: "7. Work and Interests",
      type: "inline",
      progress: 0.36,
      answers: [
        { points: 0, answer: "No difficulty" },
        { points: 1, answer: "Feelings of incapacity, listlessness, indecision and vacillation" },
        { points: 2, answer: "Loss of interest in hobbies, decreased social activities" },
        { points: 3, answer: "Productivity decreased" },
        { points: 4, answer: "Unable to work. Stopped working because of present illness only." }
      ]
    },
    {
      id: "8",
      questionName: "HAM-D - step8",
      question: "8. Retardation (Slowness of thought, speech, and activity; apathy; stupor)",
      type: "inline",
      progress: 0.42,
      answers: [
        { points: 0, answer: "Absent" },
        { points: 1, answer: "Slight retardation at interview" },
        { points: 2, answer: "Obvious retardation at interview" },
        { points: 3, answer: "Interview difficult" },
        { points: 4, answer: "Complete stupor" }
      ]
    },
    {
      id: "9",
      questionName: "HAM-D - step9",
      question: "9. Agitation (Restlessness associated with anxiety)",
      type: "inline",
      progress: 0.48,
      answers: [
        { points: 0, answer: "Absent" },
        { points: 1, answer: "Occasional" },
        { points: 2, answer: "Frequent" }
      ]
    },
    {
      id: "10",
      questionName: "HAM-D - step10",
      question: "10. Anxiety - Psychic",
      type: "inline",
      progress: 0.54,
      answers: [
        { points: 0, answer: "No difficulty" },
        { points: 1, answer: "Tension and irritability" },
        { points: 2, answer: "Worrying about minor matters" },
        { points: 3, answer: "Apprehensive attitude" },
        { points: 4, answer: "Fears" }
      ]
    },
    {
      id: "11",
      questionName: "HAM-D - step11",
      question:
        "11. Anxiety - Somatic (Gastrointestinal, indigestion, cardiovascular, palpitations, headaches, respiratory, genito-urinary)",
      type: "inline",
      progress: 0.6,
      answers: [
        { points: 0, answer: "Absent" },
        { points: 1, answer: "Mild" },
        { points: 2, answer: "Moderate" },
        { points: 3, answer: "Severe" },
        { points: 4, answer: "Incapacitating" }
      ]
    },
    {
      id: "12",
      questionName: "HAM-D - step12",
      question:
        "12. Somatic Symptoms - Gastrointestinal (Loss of appetite, heavy feeling in abdomen; constipation)",
      type: "inline",
      progress: 0.66,
      answers: [
        { points: 0, answer: "Absent" },
        { points: 1, answer: "Mild" },
        { points: 2, answer: "Severe" }
      ]
    },
    {
      id: "13",
      questionName: "HAM-D - step13",
      question:
        "13. Somatic Symptoms - General (Heaviness in limbs, back or head; diffuse backache; loss of energy and fatiguability)",
      type: "inline",
      progress: 0.72,
      answers: [
        { points: 0, answer: "Absent" },
        { points: 1, answer: "Mild" },
        { points: 2, answer: "Severe" }
      ]
    },
    {
      id: "14",
      questionName: "HAM-D - step14",
      question: "14. Genital Symptoms (Loss of libido, menstrual disturbances)",
      type: "inline",
      progress: 0.78,
      answers: [
        { points: 0, answer: "Absent" },
        { points: 1, answer: "Mild" },
        { points: 2, answer: "Severe" }
      ]
    },
    {
      id: "15",
      questionName: "HAM-D - step15",
      question: "15. Hypochondriasis",
      type: "inline",
      progress: 0.84,
      answers: [
        { points: 0, answer: "Not present" },
        { points: 1, answer: "Self-absorption (bodily)" },
        { points: 2, answer: "Preoccupation with health" },
        { points: 3, answer: "Querulous attitude" },
        { points: 4, answer: "Hypochondriacal delusions" }
      ]
    },
    {
      id: "16",
      questionName: "HAM-D - step16",
      question: "16. Loss of Weight",
      type: "inline",
      progress: 0.9,
      answers: [
        { points: 0, answer: "No weight loss" },
        { points: 1, answer: "Slight" },
        { points: 2, answer: "Obvious or severe" }
      ]
    },
    {
      id: "17",
      questionName: "HAM-D - step17",
      question:
        "17. Insight (Insight must be interpreted in terms of patient's understanding and background)",
      type: "inline",
      progress: 0.96,
      answers: [
        { points: 0, answer: "No loss" },
        { points: 0, answer: "Partial or doubtfull loss" },
        { points: 0, answer: "Loss of insight" }
      ]
    },
    {
      id: "18",
      questionName: "HAM-D - step18",
      question: "18. Diurnal Variation (Symptoms worse in the morning or evening)",
      type: "inline",
      progress: 1,
      answers: [
        { points: 0, answer: "No variation" },
        { points: 0, answer: "Mild variation in the morning" },
        { points: 0, answer: "Mild variation in the evening" },
        { points: 0, answer: "Severe variation in the morning" },
        { points: 0, answer: "Severe variation in the evening" }
      ]
    },
    {
      id: "19",
      questionName: "HAM-D - step19",
      question: "19. Depersonalization and Derealization (Feelings of unreality, nihilistic ideas)",
      type: "inline",
      progress: 1,
      answers: [
        { points: 0, answer: "Not present" },
        { points: 0, answer: "Absent" },
        { points: 0, answer: "Mild" },
        { points: 0, answer: "Moderate" },
        { points: 0, answer: "Severe" },
        { points: 0, answer: "Incapacitating" }
      ]
    },
    {
      id: "20",
      questionName: "HAM-D - step20",
      question: "20. Paranoid Symptoms (Not with a depressive quality)",
      type: "inline",
      progress: 1,
      answers: [
        { points: 0, answer: "None" },
        { points: 0, answer: "Suspicious" },
        { points: 0, answer: "Ideas of reference" },
        { points: 0, answer: "Delusions of reference and persecution" },
        { points: 0, answer: "Hallucinations, persecutory" }
      ]
    },
    {
      id: "21",
      questionName: "HAM-D - step21",
      question:
        "21. Obsessional Symptoms (Obsessive thoughts and compulsions against which the patient struggles)",
      type: "inline",
      progress: 1,
      answers: [
        { points: 0, answer: "Absent" },
        { points: 0, answer: "Mild" },
        { points: 0, answer: "Severe" }
      ]
    }
  ]
}

export default createSurveyDialogue(DialogueIDs.HAM_D, HAM_D, SurveyType.HAM_D)
