import {
  CollectPhoneNumberScript,
  CollectPhoneNumberScriptState,
  ICollectPhoneNumberSettings
} from "./CollectPhoneNumberDialogue"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { IDefaultChatFlowMessagesCollectPhoneNumber } from "@limbic/types"

type State = CollectPhoneNumberScriptState
export type CollectPhoneNumberEveryturnScriptState = State

export class CollectPhoneNumberEveryturnScript extends CollectPhoneNumberScript {
  readonly name: string = "CollectPhoneNumberEveryturnScript"
  protected readonly messages: IDefaultChatFlowMessagesCollectPhoneNumber = {
    askCanIContactYouOnPhoneNumber: this.t([
      "And do I have permission to message you or leave a voicemail on that number?"
    ])
  }
}

export default class CollectPhoneNumberEveryturnDialogue extends AdHocDialogue<
  State,
  CollectPhoneNumberEveryturnScript
> {
  static id = DialogueIDs.CollectPhoneNumberEveryturn
  readonly name: string = "CollectPhoneNumberEveryturnDialogue"
  constructor(
    state: State,
    snapshot?: IDialogueSnapshot<State>,
    settings?: ICollectPhoneNumberSettings
  ) {
    super(
      CollectPhoneNumberEveryturnDialogue.id,
      new CollectPhoneNumberEveryturnScript(snapshot?.settings ?? settings),
      state,
      snapshot,
      settings
    )
  }
}
