import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import {
  EligibilityCheckWithPDSScript,
  EligibilityCheckWithPDSScriptState
} from "./EligibilityCheckWithPDSScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IAPTIDs } from "../../../models/IIAPTService"
import moment from "moment"
import { IneligibilityReason } from "@limbic/types"

const UNDO_ENABLED = process.env.REACT_APP_UNDO === "enabled"

interface State extends EligibilityCheckWithPDSScriptState {
  requiresUrgentSupport?: boolean
}

export type EligibilityCheckInsightScriptState = State

export class EligibilityCheckInsightScript extends EligibilityCheckWithPDSScript {
  readonly name: string = "EligibilityCheckInsightScript"

  /** Script Steps */

  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askRequiresUrgentSupport }
  }

  @step
  sayINeedToAskSomeDetails(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t(
        UNDO_ENABLED
          ? [
              "In order to refer you to {organisationName}, I just need to confirm a few details with you",
              "You can re-type your answer at any point by clicking the 'Undo' button next to the message you want to change"
            ]
          : "In order to refer you to {organisationName}, I just need to confirm a few details with you",
        { organisationName }
      ),
      nextStep: this.askBirthday
    }
  }

  @step.logState
  askRequiresUrgentSupport(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Are you at immediate risk of harming yourself?"),
      prompt: {
        id: this.getPromptId("askRequiresUrgentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ]
      },
      nextStep: this.handleRequiresUrgentSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: EligibilityCheckInsightScript) => {
    d.state.requiresUrgentSupport = d.response
    script.referralStore.setCustomField<State>("requiresUrgentSupport", d.response)
  })
  handleRequiresUrgentSupport(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.setEligibility(d.state, false)
      this.clinicalStore.setRequiresUrgentSupport(true)
      this.trackUserAsIneligible(d.state, IneligibilityReason.REQUIRES_URGENT_SUPPORT)
      this.setRiskLevelHigh(d.state, "User said they require urgent support")
    }
    return {
      nextStep: d.response //
        ? this.sayCrisis
        : this.askReceivedPreviousHelp
    }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const serviceName = this.rootStore.configStore.serviceName

    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "However {serviceName} does not provide urgent care",
          "In medical emergency and life threatening situations only, please dial [999](tel:999) or attend your local A&E department",
          "If the help you need is urgent but not life-threatening, follow this [link](https://www.insightiapt.org/need-urgent-help/) to find out about other services, including your local mental health crisis team that will be able to support you",
          "Other helplines available:\n\nYou can contact Samaritans 24 hours a day, 365 days a year\nYou can call [116 123](tel:116123) (free from any phone) or email jo@samaritans.org\nIf you would prefer not to talk but want some mental health support, you could text SHOUT to 85258\nShout offers a confidential 24/7 text service providing support if you are in need of immediate help",
          "You can refer yourself back to {serviceName} when you are no longer in crisis"
        ],
        { name, serviceName }
      ),
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("I understand") }, { body: this.t("Okay") }]
      },
      nextStep: this.handleSayCrisis
    }
  }

  @step.logState
  handleSayCrisis(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField("crisisNumbersShared", "999 and service")
    return { nextStep: this.goToGoodbye }
  }

  @step.logState
  askReceivedPreviousHelp(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName

    return {
      body: this.t(
        "Have you been discharged from treatment by {iaptName} within the last 12 weeks or have you had a clinical assessment with {iaptName} within the last 4 weeks?",
        { iaptName }
      ),
      prompt: {
        id: this.getPromptId("askReceivedPreviousHelp"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleReceivedPreviousHelp
    }
  }

  @step.logState
  handleReceivedPreviousHelp(d: IStepData<State, string>): IStepResult {
    if (d.response) {
      this.setEligibility(d.state, false)
      this.trackUserAsIneligible(d.state, IneligibilityReason.PREVIOUS_TREATMENT)
      return { nextStep: this.sayWeCantOfferYouService }
    }
    return { nextStep: this.askPostCodeOfUser }
  }

  @step.logState
  sayWeCantOfferYouService(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: this.t(
        [
          "Sorry {name}, you're unable to re-refer to {organisationName} if you've been discharged from the service within 12 weeks or have received a clinical assessment from the service within 4 weeks",
          "You can re-refer after this time period",
          "In the meantime contact your GP if you need additional support",
          "If you feel that you or someone you know is in danger, please call 999 immediately"
        ],
        { name, organisationName }
      ),
      prompt: {
        id: this.getPromptId("sayWeCantOfferYouService"),
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }, { body: this.t("I understand") }]
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  sayItsImportantToFindGP(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "It's important that we identify your GP in order to find the right mental health service for you"
      ),
      nextStep: this.sayWithoutGPICannotReferYou
    }
  }

  @step
  checkEligibility(d: IStepData<State>): IStepResult {
    const nottsBassetlawIAPTs: any[] = [IAPTIDs.INSIGHT_BASSETLAW, IAPTIDs.INSIGHT_NOTTS]
    if (nottsBassetlawIAPTs.includes(d.state.iapt?.id)) {
      this.trackUserAsIneligible(d.state, "Bassetlaw or Notts IAPT")
      return { nextStep: this.signpostBassetlawOrNotts }
    }
    const kentMedwayIAPTs: any[] = [IAPTIDs.INSIGHT_KENT, IAPTIDs.INSIGHT_MEDWAY]
    if (kentMedwayIAPTs.includes(d.state.iapt?.id)) {
      this.trackUserAsIneligible(d.state, "Kent or Medway IAPT")
      return { nextStep: this.signpostKentMedway }
    }
    return super.checkEligibility(d)
  }

  @step.logState
  signpostBassetlawOrNotts(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Everyturn Mental Health (formerly known as Insight IAPT) Talking Therapies in Nottingham, Nottinghamshire and Bassetlaw has changed to a new service: NHS Nottingham and Nottinghamshire Talking Therapies",
        "This new service is provided through a partnership of ourselves Everyturn and Vita Health Group",
        "You can visit the Nottingham and Nottinghamshire NHS Talking Therapies website [here](https://notts-talk.co.uk) to create a referral",
        "In the event of an emergency, or if you're unable to keep yourself safe, contact the 24/7 Crisis Line on 0300 303 0165, NHS 111 Option 2, or call 999 / attend your local A&E department"
      ]),
      nextStep: this.end,
      clearStack: true
    }
  }

  @step.logState
  signpostKentMedway(_d: IStepData<State>): IStepResult {
    const march28 = moment("28/03/2024 00:00:00", "DD/MM/YYYY hh:mm:ss")
    return {
      body: this.t(
        [
          "Everyturn Mental Health (formerly known as Insight IAPT) NHS Talking Therapies in Kent and Medway has changed to a new service: Kent and Medway NHS Talking Therapies",
          "This new service is provided by Vita Health Group",
          `You can visit the NHS Talking Therapies website to create a referral.
- [Website](https://www.kandmtalk.co.uk)
- Number: 0333 091 0414
- Email: admin@kmtalkingtherapies.co.uk`,
          moment().isBefore(march28) ? "They will all be live from 28th March" : "",
          "In the event of an emergency, or if you're unable to keep yourself safe, contact NHS 111 Option 2, or call 999 / attend your local A&E department"
        ].filter(Boolean)
      ),
      nextStep: this.end,
      clearStack: true
    }
  }

  /** Generic Handlers */
}

/* istanbul ignore next */
export default class EligibilityCheckInsightDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckInsight
  readonly name: string = "EligibilityCheckInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckInsightDialogue.id, new EligibilityCheckInsightScript(), state, snapshot)
  }
}
