import BaseScript, { BaseScriptState } from "./BaseScript"
import Dialogue, { IDialogueSnapshot } from "../backend/chatbot/Dialogue"
import RootStore from "../app/stores/rootStore"
import {
  Actions,
  ChatFlowsEnum,
  Conditions,
  DiscussionSteps,
  IDialogueStep,
  IDialogueSteps,
  KeyType,
  LanguageCodes,
  LeftHandContextType,
  LeftHandOperand,
  RightHandContextType,
  RightHandOperand
} from "@limbic/types"
import ClinicalStore from "../app/stores/clinicalStore"
import ConfigStore from "../app/stores/configStore"
import { step as stepDecorator } from "../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../backend/chatbot/models/IStep"
import isValidPhoneNumber, { isValidMobilePhone } from "../utils/isValidPhoneNumber"
import isEmail from "validator/lib/isEmail"
import formatUnicorn from "../utils/formatUnicorn"
import IPrompt from "../backend/chatbot/models/IPrompt"
import ISelectable from "../models/ISelectable"
import { IMainStepDecoratorProps } from "../backend/chatbot/decorators/step/main"
import { CrisisDetectionProps } from "../backend/chatbot/decorators/step/checkInputForCrisis"
import getIAPTById from "../utils/getIAPTById"
import { transformersMap } from "@limbic/transformers"
import invariant from "../utils/invariant"
import { operatorsMap } from "../utils/operators"
import AdHocDialogue from "../backend/chatbot/AdHocDialogue"
import { ReferralPayloadBuilder } from "../models/PayloadBuilder/ReferralPayloadBuilder"
import { getBrowserLanguage, hasTranslationCode } from "../utils/language"
import { Translations } from "../i18n/Languages"
import { RiskLevel } from "../models/Constants"
import { submitReferral } from "../backend/api/limbic/submitReferral"

export const CHATFLOWS_DISCUSSION_STEPS_MAP: Record<ChatFlowsEnum, DiscussionSteps> = {
  [ChatFlowsEnum.ASK_COVID_DETAILS]: DiscussionSteps.CheckCovidAndDetails,
  [ChatFlowsEnum.ASK_CURRENT_MH_PROFESSIONAL]: DiscussionSteps.CollectCurrentMHTreatmentDetails,
  [ChatFlowsEnum.ASK_PREVIOUS_MH_TREATMENT]: DiscussionSteps.CollectPriorMHTreatmentDetails,
  [ChatFlowsEnum.ASK_LONG_TERM_MEDICAL_CONDITIONS]:
    DiscussionSteps.CollectLongTermMedicalConditionDetails,
  [ChatFlowsEnum.COLLECT_SUBSTANCES]: DiscussionSteps.CollectSubstances,
  [ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION]: DiscussionSteps.CollectAlcoholConsumption,
  [ChatFlowsEnum.CHECK_POSTCODE_FROM_ADDRESS_LOOKUP]:
    DiscussionSteps.CheckPostCodeFromAddressLookup,
  [ChatFlowsEnum.COLLECT_ADHD]: DiscussionSteps.CollectADHD,
  [ChatFlowsEnum.COLLECT_ASD]: DiscussionSteps.CollectASD,
  [ChatFlowsEnum.COLLECT_DISABILITY]: DiscussionSteps.CollectDisabilities,
  [ChatFlowsEnum.COLLECT_EMAIL]: DiscussionSteps.CollectEmail,
  [ChatFlowsEnum.COLLECT_ETHNICITY]: DiscussionSteps.CollectEthnicity,
  [ChatFlowsEnum.COLLECT_GENDER]: DiscussionSteps.CollectGender,
  [ChatFlowsEnum.COLLECT_GOAL_FOR_THERAPY]: DiscussionSteps.CollectGoalForTherapy,
  [ChatFlowsEnum.COLLECT_LANGUAGE]: DiscussionSteps.CollectLanguageAndInterpreter,
  [ChatFlowsEnum.COLLECT_MAIN_ISSUE]: DiscussionSteps.CollectMainIssue,
  [ChatFlowsEnum.COLLECT_NHS_NUMBER]: DiscussionSteps.CollectNHSNumber,
  [ChatFlowsEnum.COLLECT_NATIONALITY]: DiscussionSteps.CollectNationality,
  [ChatFlowsEnum.COLLECT_RELIGION]: DiscussionSteps.CollectReligion,
  [ChatFlowsEnum.COLLECT_PHONE_NUMBER]: DiscussionSteps.CollectPhoneNumber,
  [ChatFlowsEnum.COLLECT_PREFERRED_CORRESPONDENCE]: DiscussionSteps.CollectPreferredCorrespondence,
  [ChatFlowsEnum.COLLECT_SEXUALITY]: DiscussionSteps.CollectSexuality,
  [ChatFlowsEnum.COLLECT_DATE_OF_BIRTH]: DiscussionSteps.CollectDateOfBirth,
  [ChatFlowsEnum.COLLECT_NAME]: DiscussionSteps.CollectName,
  [ChatFlowsEnum.COLLECT_FEEDBACK]: DiscussionSteps.CollectFeedback,
  [ChatFlowsEnum.CHECK_CRISIS_DETECTION]: DiscussionSteps.CheckCrisisDetection,
  [ChatFlowsEnum.GET_PERMISSIONS]: DiscussionSteps.GetPermissions,
  [ChatFlowsEnum.SERVICE_SEARCH]: DiscussionSteps.ServiceSearch,
  [ChatFlowsEnum.SPINE_SEARCH]: DiscussionSteps.SpineSearch,
  [ChatFlowsEnum.ASSESSMENT_AND_TREATMENTS]: DiscussionSteps.AssessmentAndTreatments,
  [ChatFlowsEnum.ASSESSMENT_CUSTOMISABLE_ADSM]: DiscussionSteps.AssessmentCustomisableADSM,
  [ChatFlowsEnum.SUBMIT_REFERRAL]: DiscussionSteps.SubmitReferral,
  [ChatFlowsEnum.GOODBYE_RECAP]: DiscussionSteps.GoodbyeRecap,
  [ChatFlowsEnum.BOOK_APPOINTMENT]: DiscussionSteps.BookAppointmentChatflow,
  [ChatFlowsEnum.QUESTIONNAIRES]: DiscussionSteps.Questionnaires,
  [ChatFlowsEnum.COLLECT_US_ADDRESS]: DiscussionSteps.CollectUSAddress,
  [ChatFlowsEnum.COLLECT_SMI]: DiscussionSteps.CollectSMI,
  [ChatFlowsEnum.ADMINISTER_CSSRS]: DiscussionSteps.AdministerCSSRS
}

export interface DialogueScriptState extends BaseScriptState {
  nextProgress?: number
}
type State = DialogueScriptState

interface DialogueClass {
  id: string
  new (state: State, snapshot?: IDialogueSnapshot<State>): AdHocDialogue<State, BaseScript<State>>
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createDynamicScriptClass(identifier: string) {
  class DynamicScript extends BaseScript<State> {
    readonly name = `${identifier}Script`
    protected payloadMap: Record<string, Record<string, any>>
    protected actions: Record<
      Exclude<
        Actions,
        | "isDirectReferral"
        | "nextDialogue"
        | "setState"
        | "setIAPT"
        | "setLanguage"
        | "updateReferral"
        | "addClinicalNotes"
        | "setRiskLevel"
        | "submitReferral"
      >,
      (...args: any[]) => any
    >
    protected steps: IDialogueSteps = []

    constructor(steps) {
      super()
      this.payloadMap = {}
      this.actions = {
        isValidPhoneNumber,
        isValidMobilePhone,
        isEmail,
        track: (event, data) => this.track(event, data),
        setPeople: data => this.setPeople(data),
        exists: item => !!item,
        isTrue: item => !!item
      }
      this.steps = steps
      this.setupDialogueSteps()
    }

    /** Script Steps */

    @stepDecorator
    start(_d: IStepData<State>): IStepResult<State> {
      this.timeEvent(`${this.name} Started`)
      // TODO: We shouldn't need to check for a specific step, we should only
      //       use what comes from the dashboard
      const firstStep = this.steps.find(question => question.id === "sayIntro") ?? this.steps[0]
      const nextStep = firstStep ? this[firstStep.id] : this.end
      return { nextStep }
    }

    @stepDecorator
    end(d: IStepData<State>): IStepResult {
      this.track(`${this.name} Finished`)
      return super.end(d)
    }

    @stepDecorator.logStateAndResponse
    dynamicNextStep(d: IStepData<State>): IStepResult {
      const presetFlowResult = d.state.presetFlowResult
      d.state.presetFlowResult = undefined
      const nextStepID = this.discussionStore.presetFlowNextSteps[presetFlowResult ?? ""]
      if (!nextStepID) {
        this.logException(
          new Error("nextStepID is undefined at dynamicNextStep"),
          presetFlowResult ?? this.name
        )
      }
      return {
        nextStep: presetFlowResult ? this[nextStepID] : this.endGracefully
      }
    }

    @stepDecorator.logStateAndResponse
    endGracefully(_d: IStepData<State>): IStepResult {
      this.logException(new Error("Next step was not found in dynamic script"), this.name)
      return {
        body: [
          "Oops... I'm really sorry about this, but it seems like something has gone wrong",
          "I've notified my creators of this issue",
          "Please call the service directly to continue with a referral"
        ],
        prompt: {
          id: this.getPromptId("endGracefully"),
          type: "inlinePicker",
          choices: [{ body: "Okay" }]
        },
        nextStep: this.goToGoodbye
      }
    }

    /** Generic Handlers */

    getCustomReferralType(state: State): string | undefined {
      // Until this logic goes into the dashboard we're going to have to use this
      // for any service that uses the dashboard and needs custom referral types
      const key = this.rootStore.configStore.key
      switch (key) {
        case "LIVING_WELL_CONSORTIUM":
        case "LIVING_WELL_CONSORTIUM_FULLSCREEN":
          if (state.requiresInterpreter) return "Extended Assessment"
          return undefined
        default:
          return undefined
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onRiskReferralFinished(_state: State): void {}
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onReferralFinished(_state: State): void {}

    /** Dynamic Setup Handlers */

    setupDialogueSteps(): void {
      this.logBreadcrumb("setupDialogueSteps")
      if (!this.steps?.length) return

      const steps = this.steps.filter(s => s.id !== "start" && s.id !== "end")
      steps.forEach(step => {
        switch (step.type) {
          case "condition":
            this.setupConditionStep(step)
            break
          case "advancedCondition":
            this.setupAdvancedConditionStep(step)
            break
          case "chatFlow":
            this.setupChatFlowStep(step)
            break
          case "question":
            this.setupQuestionStep(step)
            break
          case "action":
            this.setupActionStep(step)
            break
          case "ineligibleUser":
            this.setupIneligibleUserStep(step)
            break
          case "flowEnd":
            this.setupEndStep(step)
            break
          case "endChat":
            this.setupEndChat(step)
            break
          default: {
            const e = new Error(`Step type provided not supported: ${step.type}`)
            this.logException(e, "setupDialogueSteps")
          }
        }
      })
    }

    setupConditionStep(step: IDialogueStep): void {
      const id = step.id
      this[id] = function (this: DynamicScript, d: IStepData<State>): IStepResult {
        checkD(d, "setupConditionStep")
        this.logBreadcrumb(id)
        const condition = step.condition!

        if (condition.if.action === Conditions.IS_DIRECT_REFERRAL) {
          const nextStep = this.rootStore.configStore.directReferral
            ? this[condition.then!.nextStep!]
            : this[condition.else!.nextStep!]
          return { nextStep }
        }

        const value = extractValue(d, condition.if.input)
        const action = this.actions[condition.if.action]
        // TODO: what happens if the non-null assertions are wrong?
        const nextStep = action?.(value)
          ? this[condition.then!.nextStep!]
          : this[condition.else!.nextStep!]
        return { nextStep }
      }
      this.decorateStep(id)
    }

    setupAdvancedConditionStep(step: IDialogueStep): void {
      const id = step.id
      this[id] = function (this: DynamicScript, d: IStepData<State>): IStepResult {
        checkD(d, "setupAdvancedConditionStep")
        this.logBreadcrumb(id)
        const advancedCondition = step.advancedCondition
        const operator = operatorsMap[advancedCondition?.operator as any]
        if (!operator) {
          this.logException(new Error(`Operator ${advancedCondition?.operator} not found`), id)
          return { nextStep: this.endGracefully }
        }

        const trueNextStep = step.advancedCondition?.nextSteps?.true
        const falseNextStep = step.advancedCondition?.nextSteps?.false
        if (!trueNextStep) {
          // prettier-ignore
          this.logException(new Error(`Advanced condition with id ${step.id} has an undefined next step for 'true' scenario`), step.id)
        }
        if (!falseNextStep) {
          // prettier-ignore
          this.logException(new Error(`Advanced condition with id ${step.id} has an undefined next step for 'false' scenario`), step.id)
        }

        const storage: Record<LeftHandContextType, any> = {
          state: d.state,
          clinicalStore: this.rootStore.clinicalStore,
          configStore: this.rootStore.configStore,
          referralStore: this.rootStore.referralStore,
          customFields: this.referralStore.customFields
        }
        const leftValue = advancedCondition?.leftOperand
          ? getValueFromStore(advancedCondition?.leftOperand, storage, "left")
          : undefined
        const rightValue = advancedCondition?.rightOperand
          ? getValueFromStore(advancedCondition?.rightOperand, storage, "right")
          : undefined
        const conditionMet = operator(leftValue, rightValue)
        const nextStep = conditionMet ? this[trueNextStep!] : this[falseNextStep!]

        /**
         * In case the nextStep is undefined, end gracefully
         * Exceptions are being logged above 👆
         * */
        return { nextStep: nextStep ?? this.endGracefully }
      }
      this.decorateStep(id)
    }

    setupChatFlowStep(step: IDialogueStep): void {
      const id = step.id
      this[id] = function (this: DynamicScript, d: IStepData<State>): IStepResult {
        checkD(d, "setupChatFlowStep")
        this.logBreadcrumb(id)
        const nextStepID = step.chatFlow!
        const discussionStep = CHATFLOWS_DISCUSSION_STEPS_MAP[nextStepID]
        const chatFlowDialogue = this.discussionStore.getDialogueClass(discussionStep)

        // TODO: 👇 when we clean up the preset flows, we should only need settings and not extra state
        const chatFlowState = this.getChatFlowState(step) ?? {}
        const chatFlowSettings = this.getChatFlowSettings(step, d.state) ?? {}
        const nextDialogue = chatFlowDialogue
          ? new chatFlowDialogue({ ...d.state, ...chatFlowState }, undefined, chatFlowSettings)
          : undefined

        if (!nextDialogue) {
          const key = this.rootStore.configStore.key
          this.logException(new Error(`[${key}] Preset flow ${discussionStep} not found`), id)
        }

        let nextStep
        if (step.nextSteps && Object.keys(step.nextSteps).length) {
          this.discussionStore.setPresetFlowNextSteps(step.nextSteps)
          nextStep = this.dynamicNextStep
        } else {
          nextStep = this[step.nextStep!] ?? this.endGracefully
        }
        return { nextStep, nextDialogue }
      }
      this.decorateStep(id)
    }

    setupQuestionStep(step: IDialogueStep): void {
      const id = step.id
      const handlerStepId = step.userResponseKey ? `handle${id}` : undefined
      const stepIdentifier = step.stepName

      this[id] = function (this: DynamicScript, d: IStepData<State>): IStepResult {
        checkD(d, "setupQuestionStep")
        this.logBreadcrumb(id)
        const messages = ([] as string[]).concat(step.body!)
        const ctx = this.getContext(d.state)
        const body = this.t(messages, ctx)
        const promptObject = this.getStepPrompt(step, d.state, id)
        const prompt = promptObject ? this.getPrompt(d.state, promptObject) : undefined
        const nextStep = handlerStepId
          ? this[handlerStepId]
          : (this[step.nextStep!] ?? this.endGracefully)
        return { body, prompt, nextStep }
      }
      this.decorateStep(id, {
        state: true,
        response: step.prompt?.trackResponse ?? false,
        identifier: stepIdentifier
      })

      if (handlerStepId) {
        this.setupQuestionHandlerStep(step, handlerStepId)

        if (step.crisisDetection?.enable) {
          this.decorateStepForCrisis(handlerStepId, {
            disableDetectionIfWrong: step.crisisDetection?.disableDetectionIfWrong ?? false,
            getNextStep: (s: DynamicScript) => s[step.crisisDetection!.nextStep] ?? s.endGracefully
          })
        }

        this.decorateStepForHandleResponse(handlerStepId, (d: IStepData<State>) => {
          // Quick hack to deal with checkbox responses...
          // TODO: need to find another way to do this because
          //       there can be other places where the response
          //       is an object, like for example the user's name
          //       or when they select a GP or IAPT.
          if (isObject(d.response)) {
            Object.keys(d.response).forEach(key => (d.state[key] = d.response[key]))
            return
          }
          d.state[(step.userResponseKey?.state as string) ?? ""] = d.response
        })
        this.decorateStep(handlerStepId, { state: true, response: step.prompt?.trackResponse })
      }
    }

    setupQuestionHandlerStep(step: IDialogueStep, id: string): void {
      this[id] = function (this: DynamicScript, d: IStepData<State, any>): IStepResult {
        checkD(d, "setupQuestionHandlerStep")
        this.trackUserAnswer(step, d, id)
        const nextStep = this[step.nextStep!] ?? this.endGracefully
        const hasMultipleNextSteps = step.nextSteps && Object.keys(step.nextSteps).length
        if (!hasMultipleNextSteps) return { nextStep }

        const nextSteps = step.nextSteps!
        const userResponse =
          typeof d.response === "boolean"
            ? d.response
              ? "Yes"
              : "No"
            : Array.isArray(d.response) // when it's a multi-select picker, this is an array so just take the first element
              ? d.response[0]
              : d.response
        const nextStepId = nextSteps[userResponse] ?? nextSteps["_other_"]
        return { nextStep: this[nextStepId] ?? this.endGracefully }
        // decorators are added in setupQuestionStep
      }
    }

    setupActionStep(step: IDialogueStep): void {
      const id = step.id
      this[id] = async function (this: DynamicScript, d: IStepData<State>): Promise<IStepResult> {
        checkD(d, "setupActionStep")
        this.logBreadcrumb(id)
        const nextStep = this[step.nextStep!] ?? this.endGracefully
        let nextDialogue

        const actions = step.actions ?? []
        for (let i = 0, { length } = actions; i < length; i++) {
          const action = actions[i]
          const data = action.data ? parseData(d, action.data) : undefined // [ "state.canLeaveVoicemailToPhoneNumber", "state.canSendTextMessagesToPhoneNumber" ]
          if (action.name === "setPeople") this.setPeople(data)
          if (action.name === "track") this.track(action.value as string, data)
          if (action.name === "nextDialogue") nextDialogue = this.getNextDialogue(step, d)
          if (action.name === "setState" && step.actionStateKey) this.handleSetStateAction(step, d)
          if (action.name === "setIAPT") this.handleSetIAPTAction(step, d)
          if (action.name === "updateReferral") await this.handleUpdateReferralAction(step, d)
          if (action.name === "addClinicalNotes") this.handleAddClinicalNotesAction(step)
          if (action.name === "setLanguage") this.handleSetLanguageAction(step)
          if (action.name === "setRiskLevel") await this.handleSetRiskLevelAction(step, d)
          if (action.name === "submitReferral") await this.handleSubmitReferral()
        }
        return { nextDialogue, nextStep }
      }
      this.decorateStep(id)
    }

    setupIneligibleUserStep(step: IDialogueStep): void {
      const id = step.id
      this[id] = async function (this: DynamicScript, d: IStepData<State>): Promise<IStepResult> {
        checkD(d, "setupIneligibleUserStep")
        this.logBreadcrumb(id)
        const nextStep = this[step.nextStep!] ?? this.endGracefully

        const shouldTrackMixpanel = step.settingsIneligibleUser?.shouldTrackMixpanel
        const shouldUpdateDatabase = step.settingsIneligibleUser?.shouldUpdateDatabase
        const location =
          shouldTrackMixpanel && shouldUpdateDatabase
            ? undefined
            : shouldTrackMixpanel
              ? "mixpanel"
              : "database"

        this.trackUserAsIneligible(
          d.state,
          step.settingsIneligibleUser?.reason ?? "Unknown",
          location
        )
        return { nextStep }
      }
      this.decorateStep(id)
    }

    handleSetStateAction(step: IDialogueStep, d: IStepData<State>): void {
      if (step.actionStateKey) {
        const ctx = this.getContext(d.state)
        const isBoolean = step.actionStateValueType === "boolean"
        d.state[step.actionStateKey] = isBoolean
          ? [true, "true"].includes(step.actionStateValue as any)
          : formatUnicorn(step.actionStateValue, ctx)
      }
    }

    handleSetIAPTAction(step: IDialogueStep, d: IStepData<State>): void {
      const iaptID = step.setIAPTid
      const customIAPTS = this.rootStore.configStore.customIAPTS
      const iapt = getIAPTById(iaptID, customIAPTS)
      if (!iapt) this.logException(new Error("IAPT does not exist"), `IAPT ID: ${iaptID}`)
      else {
        this.setIAPT(d.state, iapt, true)
        this.setEligibility(d.state, true)
      }
    }

    async handleUpdateReferralAction(step: IDialogueStep, d: IStepData<State>): Promise<void> {
      try {
        const selectedKeys = step.actionUpdateReferralWithKeys ?? []
        const payload = new ReferralPayloadBuilder()
          .withTransformMap(this.rootStore.configStore.backendMapping?.transformMap)
          .withTargetKeys(this.rootStore.configStore.backendMapping?.targetKeys)
          .withContext({ state: d.state, customFields: this.referralStore.customFields })
          .withSpecificKeysList(selectedKeys)
          .build()

        await this.referralStore.updateReferral(payload)
      } catch (e) {
        this.logException(e, "handleUpdateReferralAction")
      }
    }

    handleAddClinicalNotesAction(step: IDialogueStep): void {
      const clinicalNotes = step.actionAddClinicalNotes ?? []
      const shouldUpdateReferral = step.actionAddClinicalNotesShouldUpdateReferral ?? true
      if (clinicalNotes.length) {
        this.referralStore.addMultipleClinicalNotes(clinicalNotes, shouldUpdateReferral)
      }
    }

    handleSetLanguageAction(step: IDialogueStep): void {
      if (!step.actionLanguageToSet) return

      const translations = this.rootStore.applicationStore.translator?.translations

      try {
        const language = this.resolveLanguageCode(step.actionLanguageToSet, translations)
        invariant(language, `Translations for ${step.actionLanguageToSet} are missing`)

        const translator = this.rootStore.applicationStore.translator!
        if (translator.language === language) return

        translator.setLanguage(language)
        RootStore.ApplicationStore.setTranslator(translator)
      } catch (e) {
        this.logException(e, "setLanguage")
      }
    }

    resolveLanguageCode(
      code: LanguageCodes | "browserLanguage" | "defaultLanguage",
      translations?: Translations
    ): LanguageCodes | undefined {
      if (!translations) return undefined

      const browserLanguage = this.getBrowserLanguage()
      const defaultLanguage = this.rootStore.configStore.defaultLanguage ?? LanguageCodes.EN
      const language: LanguageCodes = { browserLanguage, defaultLanguage }[code] ?? code
      const splitLanguage = language.split("-")[0] as LanguageCodes
      if (hasTranslationCode(language, translations)) return language
      if (hasTranslationCode(splitLanguage, translations)) return splitLanguage
    }

    async handleSetRiskLevelAction(step: IDialogueStep, d: IStepData<State>): Promise<void> {
      const riskLevel = step.actionSetRiskLevel
      const reason = step.actionSetRiskLevelReason
      const isCrisis = step.actionSetRiskLevelIsCrisis

      if (!this.clinicalStore.isHighRisk && riskLevel === RiskLevel.High) {
        // only do this when we don't already have high risk
        this.setRiskLevelHigh(d.state, reason)
      }
      if (!this.clinicalStore.isRisk && riskLevel === RiskLevel.Moderate) {
        // only do this when we don't already have moderate or high risk
        this.setRiskLevelModerate(d.state, reason)
      }
      if (!this.clinicalStore.isCrisis && isCrisis != null) {
        // only do this when we haven't already set crisis
        this.clinicalStore.setIsCrisis(isCrisis)
        this.setPeople({ isCrisis: isCrisis })
      }

      if (this.referralStore.patientId && this.referralStore.instanceID) {
        await this.referralStore.updateReferral({
          riskLevel: this.clinicalStore.riskLevel,
          riskLevelReason: this.clinicalStore.riskLevelReason || ""
        })
        this.updateReferralType(d.state)
      }
    }

    async handleSubmitReferral() {
      try {
        const patientId = this.referralStore.patientId
        invariant(patientId, "patientId is required")
        await submitReferral(patientId)
        this.referralStore.stopPinging()
        this.referralStore.setReferralSubmitted(true)
      } catch (e) {
        this.logException(e, "submitReferral")
      }
    }

    getBrowserLanguage(): LanguageCodes {
      // this method is extended purely for
      // test override purposes, so that we
      // can mock the browser language concurrently
      return getBrowserLanguage()
    }

    getNextDialogue(step: IDialogueStep, d: IStepData<State>): Dialogue | undefined {
      if (step.nextDialogue) {
        const discussionStep = step.nextDialogue
        const dynamicNextDialogue = this.discussionStore.getDialogueClass(discussionStep)
        return dynamicNextDialogue ? new dynamicNextDialogue({ ...d.state }) : undefined
      }
    }

    setupEndStep(step: IDialogueStep): void {
      const id = step.id
      this[id] = function (this: DynamicScript, _d: IStepData<State>): IStepResult {
        this.logBreadcrumb(id)
        const nextStep = this[step.nextStep!] ?? this.end ?? this.endGracefully
        return { nextStep }
      }
      this.decorateStep(id)
    }

    setupEndChat(step: IDialogueStep): void {
      const id = step.id
      this[id] = function (this: DynamicScript, _d: IStepData<State>): IStepResult {
        this.logBreadcrumb(id)
        return {
          clearStack: true,
          nextStep: this.end
        }
      }
      this.decorateStep(id)
    }

    getChatFlowState(step: IDialogueStep): Record<string, any> {
      // this method should not be needed anyway, but we do because some preset flows
      // accept their settings as state for whatever reason. When we clean up the preset
      // flows to only use settings and not state we can remove this, but until then,
      // we need to add the clean ones here to make sure their settings will not polute
      // the chatbot's state.
      if ([ChatFlowsEnum.COLLECT_PHONE_NUMBER].includes(step.chatFlow!)) return {}
      return step.settings?.[step.chatFlow!] ?? {}
    }

    getChatFlowSettings(step: IDialogueStep, _state: State): Record<string, any> | undefined {
      switch (step.chatFlow) {
        case ChatFlowsEnum.COLLECT_GENDER:
          return {
            messages: step.settings?.messages,
            optionsGender: step.settings?.[step.chatFlow]?.choicesMap,
            optionsGenderSameAsBirth: step.settings?.[step.chatFlow]?.secondaryChoicesMap
          }
        case ChatFlowsEnum.COLLECT_LANGUAGE:
          return {
            messages: step.settings?.messages,
            options: step.settings?.[step.chatFlow]?.choicesMap
          }
        case ChatFlowsEnum.ASK_CURRENT_MH_PROFESSIONAL:
        case ChatFlowsEnum.COLLECT_ETHNICITY:
        case ChatFlowsEnum.COLLECT_RELIGION:
        case ChatFlowsEnum.COLLECT_NATIONALITY:
        case ChatFlowsEnum.COLLECT_SEXUALITY:
          return { options: step.settings?.[step.chatFlow]?.choicesMap }
        case ChatFlowsEnum.COLLECT_ADHD:
        case ChatFlowsEnum.COLLECT_ASD:
        case ChatFlowsEnum.COLLECT_GOAL_FOR_THERAPY:
        case ChatFlowsEnum.COLLECT_MAIN_ISSUE:
        case ChatFlowsEnum.COLLECT_NHS_NUMBER:
        case ChatFlowsEnum.COLLECT_DATE_OF_BIRTH:
          return { messages: step.settings?.messages }
        case ChatFlowsEnum.COLLECT_DISABILITY:
        case ChatFlowsEnum.COLLECT_EMAIL:
        case ChatFlowsEnum.COLLECT_NAME:
        case ChatFlowsEnum.SUBMIT_REFERRAL:
        case ChatFlowsEnum.ASK_LONG_TERM_MEDICAL_CONDITIONS:
        case ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION:
        case ChatFlowsEnum.COLLECT_SUBSTANCES:
        case ChatFlowsEnum.COLLECT_PHONE_NUMBER:
        case ChatFlowsEnum.COLLECT_FEEDBACK:
        case ChatFlowsEnum.CHECK_CRISIS_DETECTION:
        case ChatFlowsEnum.GET_PERMISSIONS:
        case ChatFlowsEnum.ASSESSMENT_AND_TREATMENTS:
        case ChatFlowsEnum.ASSESSMENT_CUSTOMISABLE_ADSM:
        case ChatFlowsEnum.SPINE_SEARCH:
        case ChatFlowsEnum.COLLECT_PREFERRED_CORRESPONDENCE:
        case ChatFlowsEnum.SERVICE_SEARCH:
        case ChatFlowsEnum.GOODBYE_RECAP:
        case ChatFlowsEnum.BOOK_APPOINTMENT:
        case ChatFlowsEnum.QUESTIONNAIRES:
        case ChatFlowsEnum.COLLECT_US_ADDRESS:
        case ChatFlowsEnum.COLLECT_SMI:
        case ChatFlowsEnum.ADMINISTER_CSSRS:
          return {
            ...step.settings?.[step.chatFlow],
            messages: step.settings?.messages
          }
        case ChatFlowsEnum.ASK_COVID_DETAILS:
        case ChatFlowsEnum.ASK_PREVIOUS_MH_TREATMENT:
        case ChatFlowsEnum.CHECK_POSTCODE_FROM_ADDRESS_LOOKUP:
        default:
          return undefined
      }
    }

    decorateStep(id: string, opts: IMainStepDecoratorProps = { state: true }): DynamicScript {
      this[id] = stepDecorator.decorate(this[id]!, id as any, opts)
      return this
    }

    decorateStepForCrisis(
      id: string,
      opts: CrisisDetectionProps<State, DynamicScript>
    ): DynamicScript {
      this[id] = stepDecorator.checkInputForCrisis.decorate(this[id]!, opts)
      return this
    }

    decorateStepForHandleResponse(
      id: string,
      handler: (d: IStepData<State>, script: DynamicScript) => void | Promise<void>
    ): DynamicScript {
      this[id] = stepDecorator.handleResponse.decorate(this[id]!, handler)
      return this
    }

    getContext(state: State): Record<string, any> {
      return {
        ...this.rootStore.configStore,
        name: this.getName(state),
        iaptName: this.getIAPTName(state),
        primaryLanguage: state.primaryLanguage
      }
    }

    getStepPrompt(step: IDialogueStep, state: State, promptId: string): IPrompt | undefined {
      const id = this.getPromptId(promptId)
      const ctx = this.getContext(state)

      if (step.prompt?.type === "date") {
        const prompt = step.prompt
        return {
          id,
          type: "date",
          displayFormat: prompt.displayFormat,
          inputFormat: prompt.inputFormat,
          isUndoAble: prompt.isUndoAble,
          trackResponse: prompt.trackResponse
        }
      }

      if (step.prompt?.type === "text") {
        const prompt = step.prompt
        return {
          id,
          type: "text",
          isUndoAble: prompt.isUndoAble,
          forceValue: prompt.forceValue,
          trackResponse: prompt.trackResponse,
          dataPointsName: prompt.dataPointsName ?? step.id
        }
      }

      if (step.prompt?.type === "inlinePicker") {
        const prompt = step.prompt

        return {
          id,
          type: "inlinePicker",
          choices: prompt.choices?.map((choice: ISelectable) => {
            const body = this.t(choice.body, ctx)
            const value = choice.value ?? choice.body
            return { body, value }
          }),
          isUndoAble: prompt.isUndoAble,
          trackResponse: prompt.trackResponse,
          textPrompt: prompt.textPrompt,
          dataPointsName: prompt.dataPointsName ?? step.id
        }
      }

      if (step.prompt?.type === "inlinePickerMultiSelect") {
        const prompt = step.prompt
        return {
          id,
          type: "inlinePickerMultiSelect",
          choices: prompt.choices?.map((choice: ISelectable) => {
            let selectIndividuallyColor
            if (choice.selectIndividually) {
              selectIndividuallyColor = "#ec9cc8"
            }

            return {
              body: this.t(choice.body, ctx),
              value: choice.value ?? choice.body,
              backgroundColor: selectIndividuallyColor,
              selectIndividually: choice.selectIndividually
            }
          }),
          isUndoAble: prompt.isUndoAble,
          trackResponse: prompt.trackResponse,
          dataPointsName: prompt.dataPointsName ?? step.id
        }
      }

      if (step.prompt?.type === "phoneNumber") {
        const prompt = step.prompt
        return {
          id,
          type: "phoneNumber",
          isUndoAble: prompt.isUndoAble,
          forceMobile: prompt.forceMobile,
          forceLandline: prompt.forceLandline,
          trackResponse: prompt.trackResponse,
          supportedCountries: prompt.supportedCountries,
          placeholder: prompt.placeholder
        }
      }

      if (step.prompt?.type === "checkbox") {
        const prompt = step.prompt
        return {
          id,
          type: "checkbox",
          options: prompt.options?.map((option: any) => {
            return {
              body: this.t(option.body, ctx),
              key: option.key,
              initialValue: option.initialValue
            }
          }),
          isUndoAble: prompt.isUndoAble,
          trackResponse: prompt.trackResponse
        }
      }

      if (step.prompt?.type === "email") {
        const prompt = step.prompt
        return {
          id,
          type: "email",
          isUndoAble: prompt.isUndoAble,
          trackResponse: prompt.trackResponse
        }
      }
    }

    trackUserAnswer(step: IDialogueStep, d: IStepData<State, any>, stepID: string): void {
      const userResponseKey = step.userResponseKey?.state
      const key = Array.isArray(userResponseKey) ? userResponseKey.join(" ") : userResponseKey

      if (step.prompt?.trackResponse) {
        if (!key) return this.logException(new Error("No key for track response found"), stepID)
        this.track(key, { body: d.response })
      }

      if (step.prompt?.setPeople) {
        if (!key) return this.logException(new Error("No key for set people found"), stepID)
        this.setPeople({ [key]: JSON.stringify(d.response) })
      }
    }
  }

  return DynamicScript
}

export default function createDynamicDialogue(
  identifier: string,
  dialogueType: DiscussionSteps
): DialogueClass {
  return class extends AdHocDialogue<State, BaseScript<State>> {
    static id = identifier
    readonly name: string = `${identifier}Dialogue`
    readonly type = "Dynamic"

    constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
      const steps = RootStore.ConfigStore.dialogueFlows?.[dialogueType] ?? []
      const DynamicScriptClass = createDynamicScriptClass(identifier)
      super(identifier, new DynamicScriptClass(steps), state, snapshot)
    }
  }
}

function parseData(obj: Record<any, any>, paths: any): any {
  if (!paths) return obj
  const parsedData = {}
  paths.forEach(path => {
    const properties = path.split(".") // ["state", "other"] 0, 1
    parsedData[properties[1]] = extractValue(obj, path)
  })
  return parsedData
}

function extractValue(obj: Record<string, any>, path?: any): any {
  if (!path || typeof path !== "string") return undefined
  const keys = path.split(".")
  return extractValue(obj[keys.shift()!], keys.join("."))
}

function isObject(value: unknown): boolean {
  return typeof value === "object" && !Array.isArray(value) && value !== null
}

function getValueFromStore(
  operand: LeftHandOperand | RightHandOperand,
  storage: {
    state: State
    configStore: ConfigStore
    clinicalStore: ClinicalStore
    customFields: Record<string, any>
  },
  type: "left" | "right"
): string | number | boolean {
  const valueType: KeyType = operand?.value?.type
  const transformers = (operand.transformers ?? [])
    .map(t => transformersMap[valueType][t])
    .filter(Boolean)

  const isRight = type === "right"
  const isCustomValueType = [
    RightHandContextType.BOOLEAN,
    RightHandContextType.STRING,
    RightHandContextType.NUMBER
  ].includes(operand.storageType as any)
  if (isRight && isCustomValueType) {
    let v: string | number | boolean | undefined = operand?.value?.customValue
    if (valueType === KeyType.Number) v = Number(v)
    if (valueType === KeyType.Text) v = String(v)
    if (valueType === KeyType.Boolean) v = ["true", true].includes(v as any)
    return transformers.reduce((acc, t) => t(acc), v)
  }

  const sourceKey: string = operand?.value?.sourceKey
  const isFromStore = [
    LeftHandContextType.STATE,
    LeftHandContextType.CLINICAL_STORE,
    LeftHandContextType.CUSTOM_FIELDS,
    LeftHandContextType.CONFIG_STORE,
    LeftHandContextType.REFERRAL_STORE
  ].includes(operand.storageType as any)
  const valueToCheck = isFromStore ? storage[operand.storageType][sourceKey] : sourceKey
  return transformers.reduce((acc, t) => t(acc), valueToCheck)
}

function checkD(d: IStepData<State>, s: string) {
  invariant(d, `d is undefined - ${s}`)
  const { state } = d
  invariant(state, `state is undefined - ${s}`)
}
