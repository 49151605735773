import Logger from "../../../utils/Logger"
import delay from "../../../utils/delay"
import { isOnline } from "../../../utils/isOnline"
import IAppointment, { AppointmentTimeslot, IAppointmentStatus } from "../../../models/IAppointment"
import moment from "moment"
import client from "./_client"

const TOTAL_RETRIES = 3
const PATH = "/v1/insight/appointments"
const DEMO_PATH = "/v1/insight/demo/appointments"

export async function getInsightAppointments(
  isDemo: boolean,
  id: string,
  retry = 0
): Promise<[IAppointment[] | undefined, IAppointmentStatus]> {
  const body = { _id: id }
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) return [undefined, IAppointmentStatus.NoInternetConnection]

    const url = isDemo ? DEMO_PATH : PATH
    const data = await client.post(`${url}/available`, body)
    const parsedData = parseAppointments(data)
    return [parsedData, IAppointmentStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "getAppointments fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`getAppointments body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("getAppointments retry")
      await delay(1)
      return await getInsightAppointments(isDemo, id, retry + 1)
    }
    return [undefined, IAppointmentStatus.RequestFailed]
  }
}

export async function reserveAppointment(
  isDemo: boolean,
  id: string,
  appointmentID: string,
  retry = 0
): Promise<[Response | undefined, IAppointmentStatus]> {
  const body = { _id: id, appointmentID }
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) return [undefined, IAppointmentStatus.NoInternetConnection]

    const url = isDemo ? DEMO_PATH : PATH
    const data = await client.post(`${url}/reserve`, body)
    return [data, IAppointmentStatus.Success]
  } catch (e) {
    Logger.getInstance().exception(e, "reserveAppointment fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`reserveAppointment body for retry ${retry}`, JSON.stringify(body))
      Logger.getInstance().message("reserveAppointment retry")
      await delay(1)
      return await reserveAppointment(isDemo, id, appointmentID, retry + 1)
    }
    return [undefined, IAppointmentStatus.RequestFailed]
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}

function parseAppointments(data: any): IAppointment[] {
  const map: Record<string, AppointmentTimeslot[]> = {}

  data.sort((a: AppointmentTimeslot, b: AppointmentTimeslot) => {
    const c: number = new Date(a.timestamp).getTime()
    const d: number = new Date(b.timestamp).getTime()
    return c - d
  })

  data.forEach(timeslot => {
    const date = moment.utc(timeslot.timestamp).format("DD MM YYYY")

    if (map[date]) {
      const formattedTimeslot = {
        ...timeslot,
        time: moment(timeslot.timestamp).format("HH:mm")
      }
      map[date] = [...map[date], formattedTimeslot]
    } else {
      const formattedTimeslot = {
        ...timeslot,
        time: moment(timeslot.timestamp).format("HH:mm")
      }
      map[date] = [formattedTimeslot]
    }
  })
  return Object.keys(map).map(date => ({ date, timeslots: map[date] }))
}
