import { ITextPrompt } from "../backend/chatbot/models/IPrompt"
import IUserResponse, { IPersistableUserResponse } from "./IUserResponse"

export interface ISurveyChoices {
  points?: number
  answer: string
}

interface ISurveyBaseQuestion {
  id: string
  question: string | string[]
  type: string
  progress?: number
}

interface ISurveyQuestionInline<T = undefined> extends ISurveyBaseQuestion {
  type: "inline"
  answers: ISurveyChoices[]
  subscales?: T
  textPrompt?: Omit<ITextPrompt, "type">
  questionName?: string
}

interface ISurveyQuestionSlider extends ISurveyBaseQuestion {
  type: "slider"
  min: number
  max: number
  labels?: { [key: number]: string }
  notApplicable?: boolean
  questionName?: string
}

export type ISurveyQuestion<T = undefined> = ISurveyQuestionInline<T> | ISurveyQuestionSlider

export interface ISurveyResponse extends IUserResponse {
  points?: number
  subscales?: string
}

export interface IPersistableSurveyResponse extends ISurveyResponse, IPersistableUserResponse {}

export default interface ISurvey<T = undefined> {
  explanation?: string | string[]
  startingQuestion?: string | string[] | null
  endingMessage?: string | string[] | null
  questions: ISurveyQuestion<T>[]
}

export enum SurveyType {
  Audit = "Audit",
  BDI2 = "BDI-II",
  DrugsAndSmoking = "DrugsAndSmoking",
  GAD2 = "GAD-2",
  GAD7 = "GAD-7",
  HAM_A = "HAM-A",
  HAM_D = "HAM-D",
  IAPTAccommodation = "IAPTAccommodation",
  IAPTEmploymentStatus = "IAPTEmploymentStatus",
  IAPTMedication = "IAPTMedication",
  IAPTPhobiaScale = "IAPTPhobiaScale",
  IAPTWorkAndSocialAdjustment = "IAPTWorkAndSocialAdjustment",
  IRQA = "IRQA",
  ITQ = "ITQ",
  OCI = "OCI",
  PCL5 = "PCL-5",
  PDSS = "PDSS",
  PHQ2 = "PHQ-2",
  PHQ9 = "PHQ-9",
  PSWQ = "PSWQ",
  SCOFF = "SCOFF",
  SHAI18 = "SHAI18",
  SPIN = "SPIN",
  SpecificPhobia = "SpecificPhobia",
  SpecificPhobiaIntro = "SpecificPhobiaIntro"
}
