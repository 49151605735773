import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"
import { parsePhoneNumber } from "awesome-phonenumber"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../../utils/isValidPhoneNumber"
import invariant from "../../../../utils/invariant"

export default class PhoneNumberPayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_PHONE_NUMBER> {
  chatFlow = ChatFlowsEnum.COLLECT_PHONE_NUMBER

  /** Overrides */

  getTransformedKeys(transformedPayload?: Record<string, any>): string[] {
    const transformedKeys = super.getTransformedKeys(transformedPayload)
    if (transformedKeys.length) return ["phoneHome", "phoneMobile"]
    return []
  }

  getDefaultPayload(): Partial<ReferralPayload> {
    const phoneNumberKey = this.getSourceKey("phoneNumberKey")
    const SMSConsentKey = this.getSourceKey("smsConsentKey")
    const VMConsentKey = this.getSourceKey("voicemailConsentKey")
    const callConsentKey = this.getSourceKey("callConsentKey")
    invariant(phoneNumberKey, "couldn't get the mapping key phoneNumberKey")
    invariant(SMSConsentKey, "couldn't get the mapping key smsConsentKey")
    invariant(VMConsentKey, "couldn't get the mapping key voicemailConsentKey")
    invariant(callConsentKey, "couldn't get the mapping key callConsentKey")

    const phoneNumberValue = this.ctx.state?.[phoneNumberKey]
    const SMSConsentValue = !!this.ctx.state?.[SMSConsentKey]
    const VMConsentValue = !!this.ctx.state?.[VMConsentKey]
    const callConsentValue = !!this.ctx.state?.[callConsentKey]
    const isValidMobile = isValidMobilePhone(phoneNumberValue || "0")
    const isValidLandline = isValidLandlineNumber(phoneNumberValue || "0") && !isValidMobile
    const isCallConsentEnabled = this.settings?.requestCallConsent
    const parsed = parsePhoneNumber(phoneNumberValue)

    return {
      phoneHome: isValidLandline
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? phoneNumberValue,
            isMobile: false,
            consentVM: VMConsentValue,
            ...(isCallConsentEnabled ? { consentCall: callConsentValue } : undefined)
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: String(parsed.countryCode || ""),
            number: parsed.number?.national.replace(/ /g, "") ?? phoneNumberValue,
            isMobile: true,
            consentSMS: SMSConsentValue,
            consentVM: VMConsentValue,
            ...(isCallConsentEnabled ? { consentCall: callConsentValue } : undefined)
          }
        : undefined
    }
  }
}

ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_PHONE_NUMBER] = PhoneNumberPayloadBuilder
