import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"

type State = RiskPathwayScriptState

export type RiskPathwayLincolnshireScriptState = State

export class RiskPathwayLincolnshireScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayLincolnshireScript"

  /** Script Steps */
  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: this.t(
        "Are you able to keep yourself, and any dependants in your care, safe until {serviceName} contacts you (within one working day)?",
        { serviceName }
      ),
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until {serviceName} contacts you (within one working day)?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canKeepSelfSafe = d.response
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
      // It seems that Lincs asked not to send a risk email - only Tags
      return { nextStep: this.askHaveYouMadePlans }
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step
  askHaveYouMadePlans(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("Have you made any specific plans to harm yourself?"),
      prompt: {
        id: this.getPromptId("Q9 - madePlansToEndLife"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes"), value: true },
          { body: this.t("No"), value: false }
        ],
        dataPointsName: "Q9 - madePlansToEndLife"
      },
      nextStep: this.handleHaveYouMadePlans
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "11",
    "Have you made any specific plans to harm yourself?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleHaveYouMadePlans(d: IStepData<State, boolean>): IStepResult {
    const isRisk = this.clinicalStore.isRisk
    if (!isRisk && d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelModerate(d.state, RiskLevelReason.PHQ9_Q9_PLANS)
    }
    this.rootStore.applicationStore.setCurrentProgress(0.88)

    const name = this.getName(d.state)

    const body = [
      "Sorry to hear that {name}",
      "It is normal for people to have thoughts of this nature at times",
      "However, this is not an emergency response service"
    ]
    if (d.response) {
      body.push(
        "Please call the NHS Emergency number 111 (and select the option for mental health support) or 999 if you need urgent help"
      )
      return {
        body: this.t(body, { name }),
        nextStep: this.saveRiskLevelAndReferralType
      }
    }
    body.push(
      "Please contact the Mental Health Matters Helpline on 0800 001 4331 or SPA on 0303 123 4000 or your GP if you feel you are at risk of harming yourself"
    )
    return {
      body: this.t(body, { name }),
      nextStep: this.saveRiskLevelAndReferralType
    }
  }

  @step
  async theyllCallYou(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.phoneNumber) {
      return { nextStep: this.askPhoneNumber }
    }

    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: this.t(
        "One of {serviceName}' clinical team will contact you within 1 to 2 working days",
        { serviceName }
      ),
      prompt: {
        id: this.getPromptId("theyllCallYou"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: this.t("Okay"), value: true },
          { body: this.t("I understand"), value: true },
          { body: this.t("Change number"), value: false }
        ]
      },
      nextStep: this.handleTheyllCallYou
    }
  }

  @step.logStateAndResponse
  handleTheyllCallYou(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.end }
    }
    return { nextStep: this.askPhoneNumber }
  }

  /** Generic Handlers */

  async onHandlePhoneNumber(_state: State): Promise<IStepResult> {
    return { nextStep: this.end }
  }

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: state.canKeepSelfSafe ? this.end : this.theyllCallYou }
  }
}

/* istanbul ignore next */
export default class RiskPathwayLincolnshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayLincolnshire
  readonly name: string = "RiskPathwayLincolnshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayLincolnshireDialogue.id, new RiskPathwayLincolnshireScript(), state, snapshot)
  }
}
