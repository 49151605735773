import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = CrisisScriptState
export type CrisisDemoPrivateProvidersState = State
export class CrisisDemoPrivateProvidersScript extends CrisisScript {
  readonly name: string = "CrisisDemoPrivateProvidersScript"

  /** Script Steps */

  @step
  async sayInstructions(d: IStepData<State>): Promise<IStepResult> {
    return {
      body: this.t([
        "In the meantime, if you feel that you or someone you know is in danger, please call 999 immediately",
        "Further support is also provided by the Samaritans, available anytime by calling: 116 123"
      ]),
      prompt: {
        id: this.getPromptId("sayInstructions"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }, { body: this.t("I understand") }]
      },
      nextStep: this.handleSayInstructions
    }
  }

  /** Generic Handlers */
}

/* istanbul ignore next */
export default class CrisisDemoPrivateProvidersDialogue extends AdHocDialogue<
  State,
  CrisisDemoPrivateProvidersScript
> {
  static id = DialogueIDs.CrisisDemoPrivateProviders
  readonly name: string = "CrisisDemoPrivateProvidersDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      CrisisDemoPrivateProvidersDialogue.id,
      new CrisisDemoPrivateProvidersScript(),
      state,
      snapshot
    )
  }
}
