import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = CrisisScriptState
export type CrisisGMHubScriptState = State

export class CrisisGMHubScript extends CrisisScript {
  readonly name: string = "CrisisGMHubScript"

  /** Script Steps */

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "Sorry to hear that {name}",
          "It is normal for people to have thoughts of this nature at times",
          "However, this is not an emergency response service",
          "If you are concerned about your safety, it is important that you talk to someone, support is available"
        ],
        { name }
      ),
      nextStep: this.sayIllAskSomeoneToCallYou
    }
  }

  @step
  sayIllAskSomeoneToCallYou(d: IStepData<State>): IStepResult {
    return {
      body: this.t(
        "Your safety is my top priority, so I'm going to ask someone from our team to ring you to discuss this matter with you directly"
      ),
      nextStep: d.state.phoneNumber ? this.theyllCallYou : this.askPhoneNumber
    }
  }

  async sayAdditionalInstructions(_d: IStepData<State>): Promise<IStepResult> {
    return {
      body: this.t([
        "You can call the NHS helpline on 111 and select the option for mental health support. This helpline is open 24 hours a day, 7 days a week",
        "If you feel there is an immediate risk of you harming yourself or attempting to end your life, please call 999 immediately.",
        "If you are experiencing a mental health crisis please go the [NHS Getting Help page](https://www.nhs.uk/mental-health/feelings-symptoms-behaviours/behaviours/help-for-suicidal-thoughts/)",
        "You can also contact Papyrus on 0800 0684141 (call), 07860 039967 (text) or [pat@papyrus-uk.org](pat@papyrus-uk.org) if you're under 35"
      ]),
      prompt: {
        id: this.getPromptId("sayInstructions"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: this.t("Okay") }]
      },
      nextStep: this.handleSayAdditionalInstructions
    }
  }

  @step.logState
  async handleSayAdditionalInstructions(_d: IStepData<State>): Promise<IStepResult> {
    this.referralStore.setCustomField("crisisLinksShared", "NHS Getting Help and Papyrus")
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  async onHandlePhoneNumber(_state: State): Promise<IStepResult | void> {
    return {
      body: this.t(["Thanks", "Someone from our team will call you within five working days"]),
      nextStep: this.sayInstructions
    }
  }

  async onHandleSayInstructions(_state: State): Promise<IStepResult | void> {
    return {
      nextStep: this.sayAdditionalInstructions
    }
  }
}

export default class CrisisGMHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.CrisisGMHub
  readonly name: string = "CrisisGMHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisGMHubDialogue.id, new CrisisGMHubScript(), state, snapshot)
  }
}
