import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = RiskPathwayScriptState

export type RiskPathwayCWScriptState = State

export class RiskPathwayCWScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayCWScript"

  /** Script Steps */
  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Are you able to keep yourself and dependents in your care safe until your next appointment?",
        "This could be up to 5 weeks although likely to be sooner"
      ],
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself and dependents in your care safe until your next appointment? This could be up to 5 weeks although likely to be sooner",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>, script: RiskPathwayCWScript) => {
    d.state.canKeepSelfSafe = d.response
    script.referralStore.setCustomField<State>("canKeepSelfSafe", d.response)
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  async sayCrisis(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: this.t(
        [
          "Thank you for sharing this information {name}",
          "However, {organisationName} cannot provide support to people who are in crisis or unable to keep themselves safe",
          "Sometimes people have thoughts of harming themselves or that life isn’t worth living. If you are concerned about your safety, it is important that you talk to someone, support is available",
          "If you feel unable to keep yourself safe and require support, please call NHS 111 and select the mental health option to talk to a mental health professional. They will be able to support you to get the help/advice you need. The service is open 24 hours a day, seven days a week"
        ],
        { organisationName, name }
      ),
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: this.t(
        [
          "You should still call 999 or go to A&E if you have an immediate, life-threatening emergency requiring mental or physical health assistance",
          "Other helplines available:\n\n" +
            "<b>Samaritans:</b> Call 116 12 for free (open 24 hours, 365 days a year)\n" +
            "<b>CALM:</b> Call 0800 58 58 58 (5pm - midnight, 7 days a week)\n" +
            "<b>Shout:</b> Text 'SHOUT' to 85258 to start a conversation with a trained volunteer, who will provide free confidential support (open 24 hours a day, seven days a week)",
          "We’ll continue with the referral, however {organisationName} does not provide urgent care. If you require immediate help, please contact one of the numbers listed above"
        ],
        { organisationName }
      ),
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName

    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, Local (0800 145 6485), Samaritans (116 123), SHOUT (85258)"
    )
    return {
      body: [
        `We’ll continue with the referral, however ${organisationName} does not provide urgent care`,
        "If you require immediate help, please contact one of the numbers listed above"
      ],
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }
}

/* istanbul ignore next */
export default class RiskPathwayCWDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayCW
  readonly name: string = "RiskPathwayCWDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayCWDialogue.id, new RiskPathwayCWScript(), state, snapshot)
  }
}
