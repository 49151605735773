import ChatFlowPayloadBuilder from "../ChatFlowPayloadBuilder"
import { ChatFlowsEnum, ReferralPayload } from "@limbic/types"

export default class PreferredCorrespondencePayloadBuilder extends ChatFlowPayloadBuilder<ChatFlowsEnum.COLLECT_PREFERRED_CORRESPONDENCE> {
  chatFlow = ChatFlowsEnum.COLLECT_PREFERRED_CORRESPONDENCE

  /** Overrides */

  getDefaultPayload(): Partial<ReferralPayload> {
    return {
      correspondencePreferred: this.ctx.state?.preferredContactMethod
    } as Partial<ReferralPayload>
  }
}

// prettier-ignore
ChatFlowPayloadBuilder.builders[ChatFlowsEnum.COLLECT_PREFERRED_CORRESPONDENCE] = PreferredCorrespondencePayloadBuilder
