import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GetNameIAPTScript, GetNameIAPTScriptState } from "./GetNameIAPTDialogue"

type CommunicationSupport = "sight" | "hearing" | null

interface State extends GetNameIAPTScriptState {
  communicationSupport?: CommunicationSupport
  britishSignLanguage?: boolean
  useSignHealth?: boolean
}

export type GetNameInsightScriptState = State

export class GetNameInsightScript extends GetNameIAPTScript {
  readonly name: string = "GetNameInsightScript"

  /** Script Steps */

  @step
  sayNiceToMeetYou(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t("Nice to meet you {name}", { name }),
      prompt: {
        id: this.getPromptId("sayNiceToMeetYou"),
        type: "inlinePicker",
        choices: [{ body: this.t("Nice to meet you too") }]
      },
      nextStep: this.askCommunicationSupport
    }
  }

  @step.logState
  askCommunicationSupport(_d: IStepData<State>): IStepResult {
    return {
      body: this.t("I’m going to check if you need any communication support"),
      prompt: {
        id: this.getPromptId("askCommunicationSupport"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Sight 👀"), value: "sight" },
          { body: this.t("Hearing 👂"), value: "hearing" },
          { body: this.t("Nothing"), value: null }
        ],
        isUndoAble: true
      },
      nextStep: this.handleCommunicationSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, CommunicationSupport>) => {
    d.state.communicationSupport = d.response
  })
  handleCommunicationSupport(d: IStepData<State, CommunicationSupport>): IStepResult {
    if (d.response === "sight") return { nextStep: this.goToSightSupport }
    if (d.response === "hearing") return { nextStep: this.goToHearingSupport }
    return { nextStep: this.askWhereDidYouHearAboutUs }
  }

  @step.logState
  goToSightSupport(_d: IStepData<State>): IStepResult {
    return {
      body: this.t([
        "Got it 👍",
        "We can do your whole referral right here in chat️",
        "I’m designed to be compatible with screen readers"
      ]),
      prompt: {
        id: this.getPromptId("goToSightSupport"),
        type: "inlinePicker",
        choices: [{ body: this.t("Good to know") }],
        isUndoAble: true
      },
      nextStep: this.handleGoToSightSupport
    }
  }

  @step.logState
  handleGoToSightSupport(_d: IStepData<State, CommunicationSupport>): IStepResult {
    return { nextStep: this.askWhereDidYouHearAboutUs }
  }

  @step.logState
  goToHearingSupport(_d: IStepData<State>): IStepResult {
    return {
      body: this.t(["Got it 👍", "Would you prefer a therapist who uses British Sign Language?"]),
      prompt: {
        id: this.getPromptId("goToHearingSupport"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Yes 👋"), value: true },
          { body: this.t("No thanks"), value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleGoToHearingSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.britishSignLanguage = d.response
  })
  handleGoToHearingSupport(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askSignHealth }
    }
    return { body: this.t("Ok, we're ready!"), nextStep: this.askWhereDidYouHearAboutUs }
  }

  @step.logState
  askSignHealth(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: this.t([
        "If you like, I can put you through to SignHealth",
        "They specialise in mental health support for people who are Deaf",
        "Here is a link for you to check out [https://signhealth.org.uk/](https://signhealth.org.uk/)"
      ]),
      prompt: {
        id: this.getPromptId("askSignHealth"),
        type: "inlinePicker",
        choices: [
          { body: this.t("Switch to SignHealth"), value: true },
          {
            body: this.t("No, carry on with {organisationName}", { organisationName }),
            value: false
          }
        ],
        isUndoAble: true
      },
      nextStep: this.handleSignHealth
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.useSignHealth = d.response
  })
  handleSignHealth(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.goToSignHealth }
    }
    return { body: this.t("Ok, we're ready!"), nextStep: this.askWhereDidYouHearAboutUs }
  }

  @step.logState
  goToSignHealth(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(
        [
          "It’s been great chatting to you, {name}",
          "I’ll take you there right now",
          "Write 'Hello Limbic' at any time if you want to carry on with me instead"
        ],
        { name }
      ),
      prompt: {
        id: this.getPromptId("goToSignHealth"),
        type: "inlinePicker",
        choices: [
          {
            body: this.t("SignHealth referral 👉"),
            onPress: () => {
              const a = document.createElement("a")
              a.setAttribute(
                "href",
                "https://gateway.mayden.co.uk/referral-v2/fc684e57-b052-4fec-99ac-5c46ce74a33a"
              )
              a.setAttribute("target", "_blank")
              a.click()
            }
          }
        ],
        textPrompt: {
          placeholder: this.t('Type "Hello Limbic" to continue here'),
          forceValue: true
        }
      },
      nextStep: this.handleSayHelloToContinue
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.useSignHealth = false
  })
  handleSayHelloToContinue(d: IStepData<State, boolean>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: this.t(["Welcome back {name}", "Ok, we're ready to continue!"], { name }),
      nextStep: this.askWhereDidYouHearAboutUs
    }
  }

  @step.logState
  askWhereDidYouHearAboutUs(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: this.t("So, before we start, where did you hear about {organisationName}?", {
        organisationName
      }),
      prompt: {
        id: this.getPromptId("askWhereDidYouHearAboutUs"),
        type: "inlinePicker",
        choices: [
          { body: this.t("GP recommendation"), value: "GP recommendation" },
          { body: this.t("Internet search"), value: "Internet search" },
          { body: this.t("Word of mouth"), value: "Word of mouth" },
          { body: this.t("Social media"), value: "Social media" },
          { body: this.t("Leaflet/poster"), value: "Leaflet/poster" },
          { body: this.t("Radio advert"), value: "Radio advert" },
          { body: this.t("Billboard"), value: "Billboard" },
          { body: this.t("Event"), value: "Event" }
        ],
        textPrompt: {
          cancelLabel: this.t("Skip"),
          cancelIsEmptySubmit: true,
          forceValue: false
        },
        isUndoAble: true
      },
      nextStep: this.handleWhereDidYouHearAboutUs
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.whereDidYouHearAboutService = d.response
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: GetNameInsightScript) => s.askWhereDidYouHearAboutUs
  })
  handleWhereDidYouHearAboutUs(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.end }
  }

  /** Generic Handlers */
}

/* istanbul ignore next */
export default class GetNameInsightDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameInsight
  readonly name: string = "GetNameInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameInsightDialogue.id, new GetNameInsightScript(), state, snapshot)
  }
}
