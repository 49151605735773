import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = RiskPathwayScriptState

export type RiskPathwayDorkingScriptState = State

export class RiskPathwayDorkingScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayDorkingScript"

  /** Script Steps */
  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Are you able to keep yourself and dependents in your care safe until your next appointment?",
        "This could be up to 10 days, although likely to be sooner"
      ],
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself and dependents in your care safe until your next appointment? This could be up to 10 days, although likely to be sooner",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>, script: RiskPathwayDorkingScript) => {
    d.state.canKeepSelfSafe = d.response
    script.referralStore.setCustomField<State>("canKeepSelfSafe", d.response)
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  async sayCrisis(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const serviceName = this.rootStore.configStore.serviceName

    return {
      body: [
        `Thank you for sharing this information ${name}`,
        "Sometimes people have thoughts of harming themselves or that life isn’t worth living. If you are concerned about your safety, it is important that you talk to someone, support is available",
        `However ${serviceName} is not an emergency response service`,
        "If you need urgent support, please dial NHS 111 and select Option 2"
      ],
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName

    return {
      body: [
        "You could also visit a local 'safe haven' for support. [Where to find your local safe haven?](https://www.sabp.nhs.uk/our-services/mental-health/safe-havens)",
        "If you are hard of hearing, have speech difficulties or prefer not to talk over the phone, you can contact the following organisations for support via text message:\n\n" +
          "NHS Mental Health Crisis Line – text service available on 07717 989 024. If you have speech or hearing difficulties, this service can be accessed by dialling through the BT Relay app or textphone: 18001 0800 915 4644 [More info here](https://www.relayuk.bt.com/)\n\n" +
          "Shout – text shout to 85258 for 24/7 free support\n\n" +
          "For immediate support if you cannot keep yourself safe text 999 if you have registered your phone, or go to A&E",
        "Other helplines available:\n\n" +
          "You can contact Samaritans 24 hours a day, 365 days a year on 116 123 (free from any phone) or email jo@samaritans.org\n\n" +
          `We’ll continue with the referral, however ${serviceName} does not provide urgent care. If you require immediate help, please contact one of the numbers listed above`
      ],
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName

    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111 (Option 2), NHS (07717 989 024), Local (0800 145 6485), Samaritans (116 123), SHOUT (85258)"
    )
    return {
      body: [
        `We’ll continue with the referral, however ${serviceName} does not provide urgent care`,
        "If you require immediate help, please contact one of the numbers listed above"
      ],
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }
}

/* istanbul ignore next */
export default class RiskPathwayDorkingDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayDorking
  readonly name: string = "RiskPathwayDorkingDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayDorkingDialogue.id, new RiskPathwayDorkingScript(), state, snapshot)
  }
}
