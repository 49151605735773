export enum Errors {
  RECAPTCHA = "recaptcha",
  FAILED_TO_FETCH = "failed to fetch",
  LOAD_FAILED = "load failed",
  INVALID_POSTCODE = "invalid postcode",
  POSTCODE_NOT_FOUND = "postcode not found"
}

export const ignoredErrors = [
  Errors.RECAPTCHA,
  Errors.FAILED_TO_FETCH,
  Errors.LOAD_FAILED,
  Errors.INVALID_POSTCODE,
  Errors.POSTCODE_NOT_FOUND
]

export function isIgnoredError(message?: string): boolean {
  if (!message?.length) return false

  return ignoredErrors.some(r => getErrorMessageRegex(r).test(message))
}

export function isError(e: Error, message: string): boolean {
  return getErrorMessageRegex(message).test(e.message)
}

function getErrorMessageRegex(message: string): RegExp {
  return new RegExp(message.trim().replace(/ /g, "\\s?"), "ig")
}
