import React, { useState } from "react" // New
import Tracker from "../../../utils/Tracker"
import { useApplicationStore, useConfigStore } from "../../contexts/RootStoreContext"
import { Menu, MenuItem } from "@material-ui/core"
import { Settings } from "@material-ui/icons"
import DeviceLabel from "../DeviceLabel/DeviceLabel"
import "./SettingsMenu.scss"
import { observer } from "mobx-react"
import { FF } from "../../../featureFlags"

export const IFUEntries = observer(() => {
  const app = useApplicationStore()
  if (!app.getFeatureFlag(FF.AC432_MD2_IFU)) return null

  const handleInstructionsForUse = () => {
    const url = `${process.env.REACT_APP_VERSION_BASE_URL}${process.env.REACT_APP_VERSION_PDF_NAME}`
    Tracker.getInstance().track("Instructions For Use button clicked.")
    window.open(url.endsWith(".pdf") ? url : `${url}.pdf`, "_blank")
  }

  return (
    <>
      <li>
        <button
          data-testid="ifu_container"
          aria-flowto="faqButton"
          id="instructionsForUseButton"
          onClick={handleInstructionsForUse}>
          {app.t("Instructions For Use")}
        </button>
      </li>
      <li tabIndex={-1} data-testid="ifu_label">
        <DeviceLabel />
      </li>
    </>
  )
})

interface Props {
  faqLink: string
}

export default function SettingsMenu(props: Props): JSX.Element {
  const { faqLink } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const config = useConfigStore()
  const app = useApplicationStore()
  const settingsMenu = document.querySelector(".lb-settings-menu")
  const settingsMenuBackdrop = document.querySelector(".lb-settings-menu-backdrop")
  const poweredByLimbic = document.querySelector(".lb-powered-by-limbic")

  const handleClick = e => {
    setAnchorEl(e.currentTarget)
    if (isMenuOpen) {
      /**
       * I am well aware that we need to move away from this approach
       * and use React state to manage the visibility of the settings
       * but it will require a lot of refactoring and testing to make
       * sure that it doesn't break anything. So, for now, we will keep
       * using this approach until we focus on refactoring this component
       * (this is a good example of tech debt).
       */
      settingsMenu?.setAttribute("style", "display:none")
      settingsMenuBackdrop?.setAttribute("style", "display:none")
    } else {
      Tracker.getInstance().track("Settings menu opened")
      settingsMenu?.setAttribute("style", "display:block")
      settingsMenuBackdrop?.setAttribute("style", "display:block")
    }
    setIsMenuOpen(!isMenuOpen)
  }

  const handleFAQ = () => {
    Tracker.getInstance().track("FAQ button clicked")
    window.open(faqLink, "_blank")
  }

  const handlePrivacyPolicy = () => {
    Tracker.getInstance().track("FAQ button clicked")
    window.open("https://www.limbic.ai/privacy", "_blank")
  }

  const handleTermsOfService = () => {
    Tracker.getInstance().track("FAQ button clicked")
    window.open("https://www.limbic.ai/terms-of-use", "_blank")
  }

  const [isContrastModeOn, setContrastMode] = useState(false)
  const handleContrastMode = () => {
    Tracker.getInstance().track("Contrast Mode button clicked.")
    if (isContrastModeOn) {
      setContrastMode(false)
      Tracker.getInstance().track("Contrast has been turned off.")
      document.documentElement.style.setProperty("--primary", "")
      document.documentElement.style.setProperty("--button", "")
      document.documentElement.style.setProperty("--background", "")
      document.documentElement.style.setProperty("--primaryText", "")
      document.documentElement.style.setProperty("--placeholder", "")
      document.documentElement.style.setProperty("--lines", "")
      document.documentElement.style.setProperty("--secondary", "")
      document.documentElement.style.setProperty("--checkbox", "")
      document.documentElement.style.setProperty("--textBoxBackground", "")
      document.documentElement.style.setProperty("--scrollBarColor", "")
      document.documentElement.style.setProperty("--scrollBarBackgroundColor", "")
      document.documentElement.style.setProperty("--buttonOpacity", "")
      document.documentElement.style.setProperty("--limbicLogoContrast", "1")
      poweredByLimbic?.setAttribute("style", "display:flex")
    } else {
      setContrastMode(true)
      Tracker.getInstance().track("Contrast has been turned on.")
      document.documentElement.style.setProperty("--primary", "#17227A")
      document.documentElement.style.setProperty("--button", "#17227A")
      document.documentElement.style.setProperty("--background", "#FFFFFF")
      document.documentElement.style.setProperty("--primaryText", "#000")
      document.documentElement.style.setProperty("--placeholder", "#000")
      document.documentElement.style.setProperty("--lines", "#17227A")
      document.documentElement.style.setProperty("--secondary", "#f5f5f8")
      document.documentElement.style.setProperty("--checkbox", "#17227A")
      document.documentElement.style.setProperty("--textBoxBackground", "#17227A")
      document.documentElement.style.setProperty("--scrollBarColor", "#17227A")
      document.documentElement.style.setProperty("--scrollBarBackgroundColor", "#FFFFFF")
      document.documentElement.style.setProperty("--buttonOpacity", "1")
      document.documentElement.style.setProperty("--limbicLogoContrast", "0.6")
      poweredByLimbic?.setAttribute("style", "display:none")
    }
  }

  const [isBiggerTextModeOn, setBiggerTextMode] = useState(false)
  const handleBiggerTextMode = () => {
    Tracker.getInstance().track("Bigger Text Mode button clicked.")
    if (isBiggerTextModeOn) {
      setBiggerTextMode(false)
      Tracker.getInstance().track("Bigger Text has been turned off.")
      document.documentElement.style.setProperty("--lineHeight", "")
      document.documentElement.style.setProperty("--fontSize", "")
      document.documentElement.style.setProperty("--buttonfontSize", "")
    } else {
      setBiggerTextMode(true)
      Tracker.getInstance().track("Bigger Text has been turned on.")
      document.documentElement.style.setProperty("--lineHeight", "2.4rem")
      document.documentElement.style.setProperty("--fontSize", "20px")
      document.documentElement.style.setProperty("--buttonfontSize", "20px")
    }
  }

  const faqButton = document.getElementById("faqButton")
  const biggerTextButton = document.querySelector("#biggerTextButton")
  const settingsButton = document.getElementById("settingsButton")

  const handleMenuFocus = e => {
    if (e.key === "Escape") {
      setIsMenuOpen(false)
      settingsMenu?.setAttribute("style", "display:none")
      settingsMenuBackdrop?.setAttribute("style", "display:none")
    }
  }

  const handleClose = () => {
    setIsMenuOpen(false)
    setAnchorEl(null)
    settingsMenu?.setAttribute("style", "display:none")
    settingsMenuBackdrop?.setAttribute("style", "display:none")
  }

  biggerTextButton?.addEventListener("focusout", function () {
    faqButton?.focus()
  })

  settingsButton?.addEventListener("keydown", function (e) {
    if (e.key === "Tab") {
      settingsButton?.blur()
    }
  })

  // TODO: Menu here, introduces a strict mode error
  //       about the deprecated findDOMNode that the
  //       Modal inside of it uses. Debug and remove
  //       for future peace of mind
  return (
    <div onKeyDown={handleMenuFocus}>
      <button
        aria-label="Settings"
        id="settingsButton"
        className="lb-settings-button"
        aria-controls="simple-menu"
        aria-haspopup="true"
        tabIndex={0}
        onClick={handleClick}>
        <Settings />
      </button>

      <div className="lb-settings-menu">
        <ul>
          {!!config.faqLink && (
            <li>
              <button
                aria-flowto="privacyPolicyButton"
                id="faqButton"
                onClick={handleFAQ}
                tabIndex={0}>
                {app.t("FAQ")}
              </button>
            </li>
          )}
          <li>
            <button
              tabIndex={0}
              aria-flowto="termsOfServiceButton"
              id="privacyPolicyButton"
              onClick={handlePrivacyPolicy}>
              {app.t("Privacy Policy")}
            </button>
          </li>
          <li>
            <button
              tabIndex={0}
              aria-flowto="contrastButton"
              id="termsOfServiceButton"
              onClick={handleTermsOfService}>
              {app.t("Terms of Service")}
            </button>
          </li>
          <li>
            <button
              tabIndex={0}
              aria-flowto="biggerTextButton"
              id="contrastButton"
              onClick={handleContrastMode}
              className={isContrastModeOn ? "lb-mode-toggle" : ""}>
              {app.t("Contrast Mode")}
            </button>
          </li>
          <li>
            <button
              tabIndex={0}
              aria-flowto="instructionsForUseButton"
              id="biggerTextButton"
              onClick={handleBiggerTextMode}
              className={isBiggerTextModeOn ? "lb-mode-toggle" : ""}>
              {app.t("Bigger Text")}
            </button>
          </li>
          <IFUEntries />
        </ul>
      </div>
      <div
        tabIndex={0}
        aria-label="Close"
        onClick={handleClose}
        className="lb-settings-menu-backdrop"></div>
      <Menu
        keepMounted
        className="lb-settings-menu"
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={handleFAQ}>{app.t("FAQ")}</MenuItem>
      </Menu>
    </div>
  )
}
